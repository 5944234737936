import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { LangService } from '@services/lang.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {

  currentLanguage: string;
  @Output() onUpdateCustomer ;
  @Output() scrollTop = new EventEmitter();
  @Input() language : string ;
  private unsubscribe = new Subject<void>();

  constructor(public langService: LangService) {
    this.onUpdateCustomer = new EventEmitter();
   }

  ngOnInit() {
    this.currentLanguage = this.langService.getCurrentLang(); 

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
          
        this.currentLanguage = result.lang
      }
    )
  }

  changeLang(lang) {
    if(lang != this.currentLanguage){
      this.langService.changeLanguage()
      this.currentLanguage = this.langService.getCurrentLang();
      this.scrollTop.emit(false);
    }
  }

  changeLangComunication(lang) {
    if(this.language !=lang)
    this.updateCustomer(lang);
  }

  updateCustomer(lang: string) {
    let args = { language: lang };
    this.onUpdateCustomer.emit(args);
  }

}
