import { Injectable } from '@angular/core';
import { AsyncSubject, Observable, forkJoin, throwError, from, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

export type CustomBehaviorObservableType<T> = BehaviorSubject<T>
export type CustomObservableType<T> = AsyncSubject<T>
export type ObservableType<T> = Observable<T>

@Injectable({
  providedIn: 'root'
})
export class RxjsService {
  constructor() { }
  // Subjects
  createCustomSubject<T>(): CustomObservableType<T> {
    return new AsyncSubject<T>()
  }
  createCustomBehaviorSubject<T>(stuff: T): BehaviorSubject<T> {
    return new BehaviorSubject(stuff)
  }
  // Observables
  createCustomObservable<T>(stuff?: T): CustomObservableType<T> {
    let customObservable: CustomObservableType<T> = this.createCustomSubject<T>()
    return customObservable
  }
  createObservableFromPromise<T>(q: Promise<T>): ObservableType<T> {
    return this.stopObservablePropagation(from(q))
  }
  createObservableFromMultipleRequests(requestList: ObservableType<any>[]): ObservableType<any> {
    return this.stopObservablePropagation(forkJoin(requestList))
  }
  stopObservablePropagation<T>(o: CustomObservableType<T> | ObservableType<T>): CustomObservableType<T> | ObservableType<T> {
    return o.pipe(take(1))
  }
  // Throw Error
  throwCustomError(msg: string): ObservableType<any> {
    return throwError(msg)
  }
}
