import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { LangService } from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class HereService {

  private newLocationEmitter: EventEmitter<any> = new EventEmitter();
  private newLocationEmitterFromMap: EventEmitter<any> = new EventEmitter();
  private newLocationEmitterFromOldLocation: EventEmitter<any> = new EventEmitter();
  private location :any | boolean;
  private newgetBranchesAndFitBound: EventEmitter<any> = new EventEmitter();
  private resolvePositionEmitter: EventEmitter<any> = new EventEmitter();

  private readonly discoverUrl: string = environment.hereMaps.discover;
  private readonly reverseGeoUrl: string = environment.hereMaps.reverseGeocode;

  private readonly apiKey: string = environment.hereMaps.apiKey;
  public mapInteractionLocked : boolean = false;
  private discoverInputData = {
    selected: {},
    discovered: []
  };

  private  filters  = {
    // gArea : 'in=countryCode:ESP', // geografic area
    gArea : 'in=countryCode:SAU,ESP', // geografic area
    ctxCenter: `at=${environment.hereMaps.centerMap.lat},${environment.hereMaps.centerMap.lng}`,
    maxResults : 'limit=5',
    lang : 'lang=en'
  }



  constructor(private readonly httpClient: HttpClient,  public langService: LangService) {
    this.filters.lang = 'lang='+langService.getCurrentLang();
    this.location = false;
   }
  
  private handleRequest(method: string, endpoint: string, body?: any): Observable<any> {
    return this.httpClient[method]<any>(endpoint, body)
  }

  public setContextCenter(position : any){
    this.filters.ctxCenter = `at=${position.lat},${position.lng}`;
  }

  public setLang(lang : any){
    this.filters.lang =  'lang='+lang;
  }

  public discover(userInput:string): Observable<any> {
    return this.handleRequest('get',`${this.discoverUrl}?q=${userInput}&apiKey=${this.apiKey}&${this.filters.gArea}&${this.filters.ctxCenter}&${this.filters.maxResults}&${this.filters.lang}`);
  }

  public reveseGeoCode(coords:string): Observable<any> {
    return this.handleRequest('get',`${this.reverseGeoUrl}?at=${coords}&apiKey=${this.apiKey}&${this.filters.lang}`);
  }

  public branchesAndFitBoundEmmit(newLocation:any|boolean) : void{ // new location from user input selected
    this.newgetBranchesAndFitBound.emit(newLocation);
  }

  public getBranchesAndFitBoundEmmiter() : EventEmitter<any> {
    return this.newgetBranchesAndFitBound;
  }

  public resolveCustomerPosition(newLocation:any){
      this.setLocation(newLocation);
      this.resolvePositionEmitter.emit(newLocation);
  }

  public getResolverPositionEmitter() : EventEmitter<any> {
    return this.resolvePositionEmitter;
  }

  public newLocationEmmit(newLocation:any|boolean) : void{ // new location from user input selected
    this.newLocationEmitter.emit(newLocation);
    this.location = newLocation;
  }

  public setLocation(newLocation:any){
    this.location = newLocation;
  }

  public getLocationEmitter() : EventEmitter<any> {
    return this.newLocationEmitter;
  }

  public getLocation():any |boolean{
    return this.location;
  }

  public newLocationEmmitFromMap(newLocation:any) : void{ // new location from geolocation or location by default
    //  console.log("newLocationEmmitFromMap",newLocation);
    this.newLocationEmitterFromMap.emit(newLocation);
    this.location = newLocation;
    this.setContextCenter(this.location);
  }


  public getLocationEmitterFromMap() : EventEmitter<any> {
    return this.newLocationEmitterFromMap;
  }

  public newLocationEmmitFromOldLocations(address:any) : void{ // new location from address saved
    // console.log("newLocationEmmitFromOldLocations",address);
    this.newLocationEmitterFromOldLocation.emit(address);
    this.location = {lat:address.lat,lng:address.lng};
    this.setContextCenter(this.location);
  }


  public getLocationEmitterFromOldLocation() : EventEmitter<any> {
    return this.newLocationEmitterFromOldLocation;
  }

  newClearLocationEmmit() {
    this.newLocationEmitterFromOldLocation.emit(false);
  }

  



}
