import { Injectable, } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { UserDataService } from '@services/user-data.service';
import { Router } from '@angular/router';
import { switchMap, catchError, finalize, tap } from 'rxjs/operators';
import { LoaderService } from '@services/loader.service';
import { ObservableType, RxjsService } from '@services/rxjs.service';
import { environment } from '@src/environments/environment';
// import {FaeventsService} from '@services/faevents.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTokenInterceptor implements HttpInterceptor {
    private requests = []
    private excluded = [
        environment.hereMaps.discover,
        environment.hereMaps.reverseGeocode,
        environment.gateWays.applePay.checkout.url,
        // environment.gateWays.applePay.hyperPay.url,
        environment.gateWays.applePay.hyperPay.checkoutIDUrl,
        environment.gateWays.applePay.hyperPay.paymentResultUrl,
        environment.gateWays.invoice.tap.url
    ];
    private NoLoadings = [
        'tickets?tracking=true',
        'zones?location=',
        "loading=false",
        'favorites',
        ...this.excluded
    ];

    constructor(
        private readonly _router: Router,
        private userDataService: UserDataService,
        private readonly loaderService: LoaderService,
        private readonly rxJsService: RxjsService,
        // private faevents : FaeventsService
    ) { }
    shouldBeIntercepted(evt) {
        // try {
        //     if (evt instanceof HttpResponse && evt.url) {
        //         if (evt.url.includes("/auth/customer/authenticate") || evt.url.includes("/auth/customer/login/email")) { // logins
        //             if(evt.status == 201){
        //                 // this.faevents.preLogEvent("login",{})
        //             }
        //         }
        //     }
        // } catch (e) {

        // }
    }
    exclude(request: HttpRequest<any>): boolean {
        let requestUrl = request.url;
        let res = false;
        this.excluded.forEach(excluded => {
            if (requestUrl.startsWith(excluded)) {
                res = true;
            }
        });
        return res;
    }
    excludeLoadings(request: HttpRequest<any>): boolean {
        let requestUrl = request.url;
        let res = false;
        this.NoLoadings.forEach(excluded => {
            if (requestUrl.includes(excluded)) {
                res = true;
            }
        });
        return res;
    }
    removeRequests(req: HttpRequest<any>): void {
        let headers = req.headers || [];
        let id = '';
        headers["headers"].forEach((value: boolean, key: string) => {
            if (key == 'id') {
                id = value[0];
            }
        });
        const requestPosition: number = this.requests.indexOf(id)
        if (requestPosition >= 0) {
            this.requests.splice(requestPosition, 1);
            this.loaderService.removeLoader.next(id);
            // console.log("LOG REMOVE REQUEST ID == ",id);
        }
    }
    checkUnauthorizedRequests(err: HttpErrorResponse) {
        let removeUser: boolean = false
        let unauthorizedCode: number = 401
        if (err && 'error' in err && 'statusCode' in err.error && err.error.statusCode == unauthorizedCode) {
            removeUser = true
        } else if (err && 'status' in err && err.status == unauthorizedCode) {
            removeUser = true
        }
        if (removeUser) {
            this.userDataService.getUser().subscribe(
                r => {
                    this.userDataService.logout()
                    this._router.navigate(['/login'])
                }
            )
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next))
        /*
        let httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        })

        let httpHeadersUrlEncoded = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })

        const obs: ObservableType<any> = from(this.userDataService.getToken())
            .pipe(
                switchMap(
                    token => {
                        if (this.exclude(request)) {
                            httpHeaders = httpHeadersUrlEncoded;
                        } else {
                            httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`)
                        }
                        request = request.clone({ headers: httpHeaders })
                        return next.handle(request).pipe(
                            tap(
                                () => { },
                                (err: HttpErrorResponse) => {
                                    this.checkUnauthorizedRequests(err)
                                }
                            )
                        )
                    }
                ),
                catchError(
                    err => {
                        if (this.exclude(request)) {
                            httpHeaders = httpHeadersUrlEncoded;
                        }
                        request = request.clone({ headers: httpHeaders })
                        return next.handle(request).pipe(
                            tap(
                                evt => {
                                    console.log(evt)
                                    this.shouldBeIntercepted(evt);
                                },
                                (err: HttpErrorResponse) => {
                                    this.checkUnauthorizedRequests(err)
                                }
                            )
                        )
                    }
                ),
                finalize(
                    () => {
                        this.removeRequests(request)
                    }
                )
            )
        let id = request.url + "id=" + new Date().getTime();
        httpHeaders = httpHeaders.set('id', String(id));

        if (!this.excludeLoadings(request) && !(this.requests.indexOf(id) >= 0)) {
            this.requests.push(id);
            return this.rxJsService.createObservableFromPromise(this.loaderService.showLoader(id))
                .pipe(
                    switchMap(
                        () => {
                            // console.log("LOG SHOW LOADER ID == ", id, request.url);
                            return obs
                        }
                    ),
                    catchError(
                        err => {
                            return throwError(err);
                            // return obs
                        }));

        } else {
            return obs;
        }
        */
    }
    async handle(request: HttpRequest<any>, next: HttpHandler)/*: Promise<HttpEvent<any>>*/ {
        let httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        let httpHeadersUrlEncoded = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
        let id = request.url + "id=" + new Date().getTime()
        let token: string = null
        httpHeaders = httpHeaders.set('id', String(id))
        try {
            token = await this.userDataService.getToken().toPromise()
        } catch (e) { }
        if (this.exclude(request)) {
            httpHeaders = httpHeadersUrlEncoded;
        } else {
            if (token) {
                httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`)
            }
        }
        //
        if (!this.excludeLoadings(request) && !(this.requests.indexOf(id) >= 0)) {
            this.requests.push(id);
            try {
                await this.loaderService.showLoader(id)
            } catch (e) { }
        } else { }
        request = request.clone({ headers: httpHeaders })
        return next.handle(request).pipe(
            tap(
                evt => {
                    this.shouldBeIntercepted(evt);
                },
                (err: HttpErrorResponse) => {
                    this.checkUnauthorizedRequests(err)
                }
            ),
            finalize(
                () => {
                    this.removeRequests(request)
                }
            )
        ).toPromise()
    }
}

