import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';

import { environment } from '@environments/environment';
import { StorageService } from '@services/storage.service';
import { StorageVariables } from '@core/constants';

@Component({
  selector: 'app-slider-full-screen',
  templateUrl: './slider-full-screen.component.html',
  styleUrls: ['./slider-full-screen.component.scss'],
})
export class SliderFullScreenComponent implements AfterViewInit {

  @ViewChild('slides') sliderElement: IonSlides;
  size: number;
  position: number = 0;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
    autoHeight: false
  };

  sli: any;

  slider = [
    { title: "LANG_COMPLETE_YOUR_PROFILE", subtitle: "LANG_YOUR_PROFILE_IS", link: "LANG_EDIT_YOU_PROFILE", image: "completeProfile" },
    { title: "LANG_TRACK_YOUR_ORDER", subtitle: "LANG_THANKS_TO_THE_NEW_APP", link: "", image: "delivery" },
    { title: "LANG_SHARE_WITH_FRIENDS", subtitle: "LANG_SHARE_AN_MAESTRO_APP", link: "", image: "shareFriends" },
  ]

  assets: string = environment.assetsUrl;
  constructor(private readonly _router: Router, private readonly storageService: StorageService) {

  }


  ngAfterViewInit() {
    this.sliderElement.length().then(
      (result) => {
        this.size = result;
      });
  }


  nextSlide() {
    if (this.position != this.size - 1) {
      this.sliderElement.slideNext()
    } else {
      this._router.navigate(["/menu"]);
      this.storageService.setItem(StorageVariables.showedOpeningScreen, true)
    }

  }

  editOrExit(){
    this.storageService.setItem(StorageVariables.showedOpeningScreen, true)
  }

  slideChanged() {
    this.sliderElement.getActiveIndex().then(
      (result) => {
        this.position = result;
      });
  }


}
