import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as JSLZString from 'lz-string';

import { CustomObservableType, ObservableType, RxjsService } from '@services/rxjs.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private storage: Storage,
    private rxjsService: RxjsService
  ) { }
  getItem(name: string): CustomObservableType<any> {
    const getItemResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
    this.storage.get(name).then(
      (result: any) => {
        if (result != null) {
          getItemResult.next(result)
          getItemResult.complete()
        } else {
          getItemResult.error(false)
          getItemResult.complete()
        }
      },
      () => {
        getItemResult.error(false)
        getItemResult.complete()
      }
    )
    return getItemResult
  }
  setItem(name: string, value: any): ObservableType<any> {
    return this.rxjsService.createObservableFromPromise(this.storage.set(name, value))
  }
  removeItem(name: string): ObservableType<any> {
    return this.rxjsService.createObservableFromPromise(this.storage.remove(name))
  }

  getItemDecompressedFromLStorage(key): CustomObservableType<any> {

    const getItemResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()

    this.getItem(key).subscribe(
      (result) => {
        const decompressed = JSLZString.decompressFromBase64(result);
        getItemResult.next(decompressed)
        getItemResult.complete()

      },
      (notFound) => {
        getItemResult.error(false)
        getItemResult.complete()
      })
    return getItemResult;
  }

}
