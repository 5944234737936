import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HereService } from '@services/here.service';
import { FormComponent } from '@core/modules/angular-ionic-forms/form/form.component';
import { FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { BranchesService } from '@services/branches.service';

const fields: FormMetadataFieldInterface[] = [
  {
    group: "1",
    id: "address",
    isReadOnly: false,
    fieldType: 'text',
    name: 'address',
    placeholder: 'LANG_TYPE_BRANCH',
    position: 2,
    class: "",
    disabled: false,
    label: "",
    options: {
      icon: {'name': 'locate','color':'primary', 'class': 'circleButtonInput', 'position': 'start'},
      label: {
        color: "secondary",
        position: "stacked"
      },
      size: {
        lg: '12',
        md: '12',
        sm: '12',
        xl: '12',
        xs: '12'
      }
    },
    validators: [{
      errorMsg: '',
      params: {
        regEx: ''
      },
      validatorType: 'pattern'
    }],
    value: ''
  }
]

@Component({
  selector: 'app-discoverinput',
  templateUrl: './discoverinput.component.html',
  styleUrls: ['./discoverinput.component.scss'],
})
export class DiscoverinputComponent implements OnInit {

  wkngMethods = ["pickup","delivery","account"];
  wkngMethod = '';
  subscription: any;
  @Output() inputCleared: EventEmitter<any>; // implement this listener to receive event when marker is clicked, marker contain information about branch or user
  @Output() onIconInputClick: EventEmitter<FormMetadataFieldInterface> = new EventEmitter()

  @ViewChild('locationAddress',{static:false}) formAddress: FormComponent;
  formMetadata: FormMetadata

  discover = {
    selected: {},
    discovered: []
  };
  @Input() branches: any;

  constructor(private readonly hereS: HereService,    private readonly branchesService: BranchesService,
    ) { 
    this.wkngMethod = this.wkngByDefault();
    this.inputCleared = new EventEmitter<any>();
    this.onIconInputClick = new EventEmitter<any>();

  }

  ngOnInit() {
    this.listenerResolverPosition();
    this.formMetadata = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fields,
      formName: 'deliveryForm1',
      groups: [],
      isReadOnly: false
    })

  }

  changeIconLoading(loading) {
    if (loading) {
     this.formMetadata.fields[0].options.icon = { 'name': 'locate', 'color': 'primary', 'class': 'spinner', 'position': 'start' };
    } else {
      this.formMetadata.fields[0].options.icon = { 'name': 'locate', 'color': 'primary', 'class': 'circleButtonInput', 'position': 'start' };
    }
  }

  private filterDiscovered(items): any {
    let exclude = ['administrativeArea','intersection'];
    let filtered = [];

    items.forEach(x => {
      if (exclude.indexOf(x.resultType) == -1) {
        filtered.push(x);
      }
    });
    return filtered;
  }

  getBranchesFiltered(name) {
    const result = this.branches.filter(b => b.name.toUpperCase().includes(name.toUpperCase()));
    return result;
  }

  discoverAddress(event: any) {
    if(event.value.address){
      if(event.value.address.length>0 &&  event.value.address.length<=6 ){
        this.branches.forEach(element => {
          element["resultType"] = 'branch';
        });
       
        this.discover.discovered = this.getBranchesFiltered(event.value.address);
        return;
      }
    }
    // this.branchesService.resetError();
    // console.log(event);
    let address = event.value.address;
    // console.log("address",address);
    if (address != '') {
      this.hereS.discover(address).subscribe(
        (result) => {
          let temp = this.discover.discovered;
          this.discover.discovered = this.filterDiscovered(result.items);
          temp.forEach(element => {
            if(element.resultType == 'branch'){
              this.discover.discovered.unshift(element);
            }
          });
        },
        (notFound) => {
          console.log(notFound);
        })
    } else {
      this.discover.selected = {};
      this.discover.discovered = [];
      this.formAddress.updateForm('address', '');
      this.inputCleared.emit({});

      // this.formInfo.updateForm('city', '');
      // this.hereS.newLocationEmmit(false);

    }


  }

  selectDiscoveredBranch(b){
    this.formAddress.updateForm('address', b.streetName);
    this.hereS.branchesAndFitBoundEmmit({lat:b.lat,lng:b.lng});
  }

  selectDiscovered(data: any, launchEmitter: boolean = true) {
    if(data?.resultType == 'branch'){
      this.selectDiscoveredBranch(data);
      return;
    }
    let display = '';
    let city = '';
    let instructions = '';
    let name = '';
    let type = '';

    if (data?.resultType && data?.resultType == 'place' &&  data?.title) {
      display = data.title+", ";
    }

    if (data.address?.street) {
      display += data.address?.street
    }
    if(data.address?.district){
      display += "," +data.address?.district
    }
    if (data.address?.city) {
      city += data.address?.city
    }
    if (data.address?.instructions) {
      instructions = data.address?.instructions
    }
    if (data.address?.name) {
      name = data.address?.name
    }
    if (data.address?.type) {
       type = data.address?.type
    }

    this.discover.selected = data;
    this.formAddress.updateForm('address', display);
    // this.formInfo.updateForm('city', city);
    this.discover.discovered = [];

    if (launchEmitter) {
      if(this.wkngPickup()){
        this.hereS.branchesAndFitBoundEmmit(data.position);
      }else{
        this.hereS.newLocationEmmit(data.position);
      }
    } else {
      if(this.wkngPickup()){

      }else{
        this.hereS.setLocation(data.position);

      }

    }
  }

  wkngPickup(): boolean{
    return this.wkngMethod == 'pickup';
  }
  wkngAccount(): boolean{
    return this.wkngMethod == 'pickup';
  }
  wkngDelivery(): boolean{
    return this.wkngMethod == 'pickup';
  }
  wkngByDefault(): string {
    return this.wkngMethods[0];
  }

  public resolveAddresFromString(newLocation:any){
    let Str = newLocation.lat + "," + newLocation.lng;
    let that = this;

    this.hereS.reveseGeoCode(Str).subscribe(
      (result) => {
        if (result.items.length > 0) {
          let newLocation = result.items[0];
          that.selectDiscovered(newLocation, false);
        }
      },
      (notFound) => {
        console.log(notFound);
      })
  }

  listenerResolverPosition() {
    this.subscription = this.hereS.getResolverPositionEmitter()
      .subscribe(newLocation => {
        // console.log(newLocation);
        if (newLocation) {
          if(this.wkngPickup()){

          }else{
            this.resolveAddresFromString(newLocation);
          }
        }

      });
  }

  public setTextToAddress(display : string) {
    this.formAddress.updateForm('address', display);
  }

  onIconInputClicked(){
    this.onIconInputClick.emit()

    // console.log("onIconInputClick");
  }

 public emptyDisplayDiscover(){
    this.discover.discovered = [];
  }

}
