import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login-types',
  templateUrl: './login-types.component.html',
  styleUrls: ['./login-types.component.scss'],
})
export class LoginTypesComponent implements OnInit {

  assets: string = environment.assetsUrl;
  types: object[]

  constructor() { }

  ngOnInit() {
    this.types = [{name:"facebook",  text:"LANG_CONNECT_WITH_FACEBOOK", function:"login(facebook)"},
    { name:"twitter", text:"LANG_SIGN_IN_WITH_TWITTER", function:"login(twitter)"},
    { name:"google",  text:"LANG_SIGN_IN_WITH_GOOGLE", function:"login(google)"},
    { name:"apple",  text:"LANG_SIGN_WITH_APPLE", function:"login(apple)"},
    { name:"email", text:"LANG_LOGIN_WITH_EMAIL", function:"login(email)"},
    { name:"phone", text:"LANG_LOGIN_WITH_PHONE", function:"login(phone)"}
  ]

  }

  login(type:string){
    // console.log(type);

  }


}
