import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import {
  MenuFamilyCustomToppingInterface,
  PgCommon,
  PgCustomizer,
  PgOrderPromoLine
} from '@lib/utils';
import { CurrentItemsInterface } from '@pages/customizer/customizer.page';
import { ModalService } from '@services/modal.service';
import { OnActionEventInterface } from '@core/components';
import { kiosk } from '@core/constants';
import { PlatformService } from '@services/platform.service';

@Component({
  selector: 'app-card-customizer',
  templateUrl: './card-customizer.component.html',
  styleUrls: ['./card-customizer.component.scss']
})
export class CardCustomizerComponent implements OnInit {

  //Edit
  @Output() doughSelectedChange = new EventEmitter<any>();

  @Input() set currentData(value: CurrentItemsInterface) {
    if (value && value.family && value.menu && value.product) {
      let updateToppings: boolean = (value.forceReload) ? true : ((!this.current.family || !this.current.product) || (this.current.family.id !== value.family.id) || (this.current.product.id !== value.product.id))
      let updateCurrentDoughs: boolean = (value.forceReload) ? true : JSON.stringify(this.current.doughs) !== JSON.stringify(value.doughs)
      let updateCurrentToppings: boolean = (value.forceReload) ? true : JSON.stringify(this.current.toppings) !== JSON.stringify(value.toppings)
      if (updateToppings) {
        this.current = this.pgCustomizer.copy(value)
        this.setToppings()
        this.showRecipeToppings = false;
      }
      if (updateCurrentDoughs) {
        this.current.doughs = value.doughs
        this.updateCurrentDoughs(this.current.doughs)
      }
      if (updateCurrentToppings) {
        this.current.toppings = value.toppings
        this.updateCurrentToppings(this.current.toppings)
      }
      this.new = this.checkBadge(this.current.product.highlightWeb, 'N')
      this.veg = this.checkBadge(this.current.product.highlightWeb, 'V')
      this.hot = this.checkBadge(this.current.product.highlightWeb, 'H')
      this.bake = this.checkBadge(this.current.product.highlightWeb, 'B')
      this.topSales = this.checkBadge(this.current.product.highlightWeb, 'T')
      this.current.dough = value.dough
      this.doughSelected = this.pgCustomizer.getDoughs().find(t => t.id == this.current.dough)
      this.doughSelectedChange.emit(this.doughSelected); //Edit
      this.isPizza = this.pgCustomizer.isPizza()
      this.current.forceReload = false
    }
  }
  @Input() loyalty: boolean
  @Input() modifyingPromo: boolean;
  @Input() promoLine: PgOrderPromoLine
  //Variables edit.
  @Input() currentStep
  @Input() currentStepTopping
  @Output() numberEmitter = new EventEmitter<number>();

  @Output() onToppingsChange: EventEmitter<MenuFamilyCustomToppingInterface[]> = new EventEmitter()
  current: CurrentItemsInterface
  pgCustomizer: PgCustomizer
  productsUrl: string = environment.productsUrl
  assets: string = environment.assetsUrl;
  isPizza: boolean
  showRecipeToppings: boolean = false
  toppings: MenuFamilyCustomToppingInterface[]
  piano: boolean;
  new: boolean;
  veg: boolean;
  hot: boolean;
  bake: boolean;
  doughSelected: MenuFamilyCustomToppingInterface
  topSales: boolean;
  isCustomPizza: boolean;
  description: string
  public pgCommon: PgCommon = new PgCommon()
  isKiosk: boolean = kiosk
  kioskBranch: any
  constructor(
    private readonly modalService: ModalService,
    private platformService: PlatformService
  ) {
    this.current = {
      currentSide: null,
      dough: null,
      doughs: null,
      family: null,
      forceReload: false,
      forceUpdate: false,
      menu: null,
      product: null,
      promoEngineOptions: null,
      promoLine: null,
      selectionMode: null,
      stockProducts: null,
      stockToppings: null,
      recipeToppings: null,
      toppings: null
    }
    this.toppings = []
    this.pgCustomizer = new PgCustomizer(null, null, {
      families: [],
      majorGroups: [],
      toppingGroups: []
    }, this.productsUrl)

  }
  ngOnInit(): void {
    //console.log("Estas en promos?",this.modifyingPromo)
  }

  addTopping(t: MenuFamilyCustomToppingInterface): void {
    const addedResult: boolean = this.pgCustomizer.addTopping(
      t,
      t.side,
      this.current.promoEngineOptions,
      this.current.promoLine
    )
    this.updateRecipeToppings()
    this.getCurrentToppings()
    if (addedResult) {
    } else {
      const errorModal: Promise<any> = (t.quantity > 0) ? this.modalService.openModalWithCustomMsgNdChooseOption(1018, t.description,) : this.modalService.openModalWithCustomMsg('LANG_TOPPING_LIMIT_EXCEEDED')
      errorModal.then(
        (resp: any) => {
          if (resp && 'data' in resp && 'result' in resp.data) {
            if (resp.data.result === true) {
              this.pgCustomizer.removeTopping(t)
              this.updateRecipeToppings()
              this.getCurrentToppings()
            }
          }
        }
      )
    }
  }
  changeQuantity(event: OnActionEventInterface, topping: MenuFamilyCustomToppingInterface): void {
    if (event.action === 'addQty') {
      this.addTopping(topping)
    } else if (event.action === 'reduceQty') {
      if (topping.quantity > 0) {
        let isToppingRequired: boolean = (topping.requiredQuantity > 0)
        let minQty: number = isToppingRequired ? topping.requiredQuantity : 1
        if (topping.quantity > minQty) {
          this.pgCustomizer.modifyTopping(topping, topping.side, topping.quantity - 1)
        } else {
          if (!isToppingRequired) {
            this.pgCustomizer.removeTopping(topping)
          }
        }
        /*
        if (topping.quantity > 1) {
          this.pgCustomizer.modifyTopping(topping, topping.side, topping.quantity - 1)
        } else {
          this.pgCustomizer.removeTopping(topping)
        }
        */
      }
    }
    this.updateRecipeToppings()
    this.getCurrentToppings()
  }
  getCurrentToppings(): void {
    const result: MenuFamilyCustomToppingInterface[] = this.pgCustomizer.getToppings()// getRecipeToppings()
    this.onToppingsChange.emit(result)
  }

  setToppings(): void {
    this.pgCustomizer = new PgCustomizer(this.current.family, this.current.product, this.current.menu, this.productsUrl, this.current.stockToppings)
    if (this.current.forceUpdate === true) {
      this.pgCustomizer.updateDoughs(this.current.doughs)
      this.pgCustomizer.updateRecipeToppings(this.current.recipeToppings)
      this.pgCustomizer.updateToppings(this.current.toppings)
      this.current.forceUpdate = false
    }
    // this.pgCustomizer.setDoughsByDefault()
    this.isCustomPizza = this.pgCustomizer.checkProductCustom()
    this.toppings = this.pgCustomizer.getRecipeToppings()
    this.updateDescription()
  }
  toggleShowRecipeToppings(): void {
    this.showRecipeToppings = !this.showRecipeToppings
  }
  updateCurrentDoughs(t: MenuFamilyCustomToppingInterface[]): void {
    this.pgCustomizer.updateDoughs(t)
    this.updateRecipeToppings()
  }
  updateCurrentToppings(t: MenuFamilyCustomToppingInterface[]): void {
    this.pgCustomizer.updateToppings(t)
    this.updateRecipeToppings()
  }
  updateRecipeToppings(): void {
    this.toppings.forEach(
      value => {
        const topping: MenuFamilyCustomToppingInterface = this.pgCustomizer.findInArray(this.pgCustomizer.getRecipeToppings(), 'id', value.id)
        value.quantity = topping.quantity
      }
    )
    this.updateDescription()
  }
  checkBadge(stringBadges: string, word: string): boolean {
    if (stringBadges !== undefined) {
      if (stringBadges.indexOf(word) !== -1) {
        return true
      }
    }
    return false
  }
  updateDescription(): void {
    this.description = this.pgCommon.cutText(this.pgCustomizer.getToppingsDescription(), 0, 170)
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.productsUrl + "images/productnotfound.png") {
        event.target.src = this.productsUrl + "images/productnotfound.png"
      }
    }
  }

  //Emite el número para editar crust o size.
  emitNumber(number: number) {
    this.numberEmitter.emit(number);
  }
}
