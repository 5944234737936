import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PickerController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import { SelectPromiseTimeService } from '@services/select-promise-time.service';
import { ModalService } from '@services/modal.service';
import { PlatformService } from '@services/platform.service';
import { DeliveryService } from '@services/delivery.service';

@Component({
  selector: 'app-selectPromiseTimes',
  templateUrl: './selectpromisetimes.component.html',
  styleUrls: ['./selectpromisetimes.component.scss'],
})

export class SelectPromiseTimesComponent implements OnInit {

  promiseTimeSelected: any;
  @Input() toogleDeliveryTime: boolean = true;

  subscription: any;
  subscription2: any;
  subscription3: any;

  picker: HTMLIonPickerElement;
  defaultValue : boolean | any = false;
  @Input() wkngMethod: string = 'pickup';
  @Input() disableSelection: boolean = true;

  @Output() callPromiseTime;
  windowWidth: number

  constructor(
    private pickerController: PickerController,
    private pTimeService: SelectPromiseTimeService,
    private readonly modalService: ModalService,
    private platformService: PlatformService,
    private deliveryService : DeliveryService
  ) {
    this.callPromiseTime = new EventEmitter();
    this.promiseTimeSelected = this.pTimeService.getPromiseTimeSelected();
   }

  ngOnInit() {

    if (!this.platformService.isServer()) {
      this.windowWidth = window.innerWidth;
    }


    this.subscription = this.pTimeService.onClearPromiseTime()
      .subscribe(clear => {
       if(this.toogleDeliveryTime){
         this.toogleDeliveryTime = false;
       }
        this.promiseTimeSelected = false;
        try { this.picker.dismiss(); } catch (e) { };
      });

      this.subscription2 = this.pTimeService.OnDefaultValue()
      .subscribe(defaultValue => {
        this.defaultValue = defaultValue;
      });

      this.subscription3 = this.pTimeService.OnUpdatePromiseTime()
      .subscribe(updated => {
        if(updated){
          // console.log(updated);
          this.promiseTimeSelected = updated;
          try { this.showPicker() } catch (e) { };
        }
      });
  }


  async showPicker() {
    
    let options: PickerOptions = {
      buttons: [
        {
          text: "Cancel",
          role: 'cancel'
        },
        {
          text: 'Ok',
          handler: (value: any) => {
            if ((value.DeliveryTime.value)) {
              this.promiseTimeSelected = { text: value.DeliveryTime.text, value: value.DeliveryTime.value };
              this.pTimeService.setPromiseTimeSelected(this.promiseTimeSelected);
            }
          }
        }
      ],
      columns: [{
        name: 'DeliveryTime',
        options: this.pTimeService.getPromiseTimesOptions()
      }]
    };

    if(this.windowWidth <= 575){
      this.picker = await this.pickerController.create(options);
      this.picker.present()
    }else{
      this.modalService.openModalHours("LANG_SET_TIME",this.pTimeService.getPromiseTimesOptions()).then(
        (res) => {
          // console.log('Success', res)
          // console.log('Success', res.data.result)
          if(res?.data?.result){
            this.promiseTimeSelected = { text: res.data.result.text, value: res.data.result.value };
            this.pTimeService.setPromiseTimeSelected(this.promiseTimeSelected);
          }
        },
        () => {
          console.log('Error')
        }
      )
    }





  }

  getPromiseTimes(event){
    if(this.wkngMethod == 'delivery' && !this.deliveryService.getZoneDelivery()){
      this.deliveryService.placeOrderEmmit({address:{},zone:false,promiseTime:null});
      return;
    }
    this.toogleDeliveryTime=!this.toogleDeliveryTime;
      if(this.toogleDeliveryTime){
        this.callPromiseTime.emit({});
      }else{
       this.promiseTimeSelected = false;
       this.pTimeService.onHideChangeTime();
      }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();


  }

}
