import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PlatformService } from '@services/platform.service';
import { environment } from '@environments/environment'
import { LegacySources, OrderTypes, BranchInterface, OrderInterface, CrossSalesType, PromoInterface, MenuInterface } from '@lib/promo-engine';
import { AddFavProductInterface } from '@core/components';
import { map } from 'rxjs/operators';
import { branchByDefault } from '@core/constants';
import { GetTicketDetailtParamsInterface, SelectedAddressInterface } from '@core/interfaces/services';
import { setImageIDtoMenu } from '@core/functions';
import { Version } from '@core/constants';
import { PgCommon } from '../../../../../utils/src/lib/common';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private readonly apiUrl: string = environment.backendUrl
  data: any;
  pickupBranchPromotions: BranchInterface
  deliveryBranchPromotions: BranchInterface
  selectedAddress: SelectedAddressInterface
  pickupBranchLastOrder: BranchInterface
  deliveryBranchLastOrder: BranchInterface
  stateToGo: string = 'tracking'
  changeBranchType: string
  savedPickup: BranchInterface
  savedDelivery: BranchInterface

  private datos = new BehaviorSubject({});

  getNewValue = this.datos.asObservable();

  private dataForm = new BehaviorSubject({});
  getNewDataForm = this.dataForm.asObservable();

  constructor(private readonly httpClient: HttpClient, private platformService: PlatformService) { }
  handleRequest(method: string, endpoint: string, body?: any): Observable<any> {
    return this.httpClient[method]<any>(`${this.apiUrl}${endpoint}`, body)
  }

  getAssetsUrl() {
    return environment.assetsUrl
  }

  getProductsUrl() {
    return environment.productsUrl
  }

  /**
   * Branches
   */
  getAllBranches(orderType: string, lang: string): Observable<[]> {
    return this.handleRequest('get', `/branches?orderType=${orderType}&language=${lang}`)
  }
  getBranchById(branch: number, lang: string, serviceType: OrderTypes, showLoading = true): Observable<BranchInterface> {
    if (showLoading) {
      return this.handleRequest('get', `/branches/${branch}?serviceType=${serviceType}&language=${lang}`)
    } else {
      return this.handleRequest('get', `/branches/${branch}?serviceType=${serviceType}&language=${lang}&loading=false`)
    }
  }

  getNearestBranches(location: string, lang: string, limit?: number): Observable<BranchInterface> {
    if (limit) {
      return this.handleRequest('get', `/branches?language=${lang}&location=${location}&limit=${limit}`)
    } else {
      return this.handleRequest('get', `/branches?language=${lang}&location=${location}`)
    }
  }

  getPaymentMethods(branch: number, serviceType: OrderTypes): Observable<[]> {
    let source = this.platformService.getSource() 
    return this.handleRequest('get', `/branches/${branch}/payment-methods?source=${source}&orderType=${serviceType}`)
  }

  /**
   * Menu
   */

  getMenu(branch: number, lang: string): Observable<any> {
    let source = this.platformService.getSource() 
    return this.handleRequest('get', `/branches/${branch ? branch : branchByDefault}/menu?language=${lang}&source=${source}`).pipe(
      map(
        (menu: MenuInterface) => {
          return setImageIDtoMenu(menu)
        }
      )
    )
  }
  getOutOfStock(branch: number): Observable<any> {
    return this.handleRequest('get', `/branches/${branch ? branch : branchByDefault}/out-stock`)
  }

  /**
 * Family
 */

  getFamily(id: number): Observable<[]> {
    return this.handleRequest('get', `/branches/7/families/${id}`)
  }

  /**
   * Promotions
   */
  getPromotionsByBranch(branch: number, lang: string): Observable<PromoInterface[]> {
    let source = this.platformService.getSource() 
    return this.handleRequest('get', `/branches/${branch ? branch : 7}/promotions?language=${lang}&source=${source}`).pipe(
      map(
        (promotions: PromoInterface[]) => {
          return promotions.filter(p => p.crossType !== CrossSalesType.Wallet)
        }
      )
    )
  }

  setPickupBranchPromotions(branch: BranchInterface) {
    this.pickupBranchPromotions = branch;
  }

  setDeliveryBranchPromotions(branch: BranchInterface, address: SelectedAddressInterface) {
    this.deliveryBranchPromotions = branch;
    this.selectedAddress = address;
  }

  setPickupBranchLastOrder(branch: BranchInterface) {
    this.pickupBranchLastOrder = branch;
  }

  setDeliveryBranchLastOrder(branch: BranchInterface, address: SelectedAddressInterface) {
    this.deliveryBranchLastOrder = branch;
    this.selectedAddress = address;
  }

  setStatusToGo(status: string) {
    this.stateToGo = status;
  }

  setChangeBranchType(type: string) {
    this.changeBranchType = type;
  }

  getStatusToGo() {
    return this.stateToGo;
  }

  getChangeBranchType() {
    return this.changeBranchType;
  }

  getPickupBranchPromotions() {
    return this.pickupBranchPromotions;
  }

  getDeliveryBranchPromotions() {
    return { deliveryBranch: this.deliveryBranchPromotions, address: this.selectedAddress };
  }

  getPickupBranchLastOrder() {
    return this.pickupBranchLastOrder;
  }

  getDeliveryBranchLastOrder() {
    return { deliveryBranch: this.deliveryBranchLastOrder, address: this.selectedAddress };
  }



  /** Set and get pickup and delivery branches in promotions for dont call other time to get promos */

  setPickupSaved(branch: BranchInterface) {
    this.savedPickup = branch;
  }

  setDeliverySaved(branch: BranchInterface) {
    this.savedDelivery = branch;
  }

  getPickupSaved() {
    return this.savedPickup;
  }

  getDeliverySaved() {
    return this.savedDelivery;
  }

  /**
   * Cross sales
   */

  getCrossSalesByBranch(branch: number, lang: string): Observable<[]> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    // return this.handleRequest('get', `/branches/${branch ? branch : 7}/cross-sales?language=${lang}&source=${source}`)
    return
  }

  /**
   * Tickets
   */
  validatePayments(order: any, merchantReference: string, responseCode: string, responseMessage: string, isSuccess: boolean): Observable<any> {
    let pgCommon: PgCommon = new PgCommon()
    let data = {
    'amount': pgCommon.parseToDecimals((pgCommon.parseToDecimals(order.total, 2) * 100), 0),
    'branchID': order.branchID,
    'merchantReference': merchantReference,
    'paymentMethod': order.paymentMethod,
    'pgReference': order.orderNumber,
    'responseCode': (isSuccess === true ? 1 : 0),
    'responseMessage': `${responseCode}-${responseMessage}`,
    'source': order.source,
    'token': null
   }
    return this.handleRequest('put', '/tickets/payment', data)
  }
  saveTicket(order: OrderInterface): Observable<any> {
    // try {
    //   window["insider_object"] = {
    //     "page": {
    //       "type": "Confirmation"
    //     }
    //   };
    //   window["Insider"].eventManager.dispatch('init-manager:re-initialize');
    // } catch (e) { }
  return this.handleRequest('post', '/tickets/kiosk', order)
  }
  
  modifyTicket(paymentMethod, orderNumber, branchID, source): Observable<any> {
    return this.handleRequest('put', '/tickets', { "paymentMethod": paymentMethod, "orderNumber": orderNumber, "branchID": branchID, "source": source })
  }

  setData<T = any>(data: T) {
    this.datos.next(data);
  }
  checkPromoCode(order: OrderInterface, promoCode: string): Observable<any> {
    return this.handleRequest('post', '/tickets/promo-code', {
      promoCode: promoCode,
      ticket: order
    })
  }
  getTicketDetail(order: GetTicketDetailtParamsInterface, ignoreLoading: boolean = false): Observable<any> {
    let endpoint: string = '/tickets/detail'
    if (ignoreLoading) {
      endpoint += '?loading=false'
    }
    return this.handleRequest('post', endpoint, order)
  }
  customerAuthenticate(provider: string, token: string, emailOrSecretOrUidOrPass?: string, isSafari?: boolean): Observable<any> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    console.log(source, provider)
    if (provider == 'google.com') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, token: token });
    }
    if (provider == 'magicLink') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, oobCode: token, email: emailOrSecretOrUidOrPass });
    }
    if (provider == 'twitter.com') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, token: token, secret: emailOrSecretOrUidOrPass });
    }
    if (provider == 'facebook.com') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, token: token, uid: emailOrSecretOrUidOrPass });
    }
    if (provider == 'apple.com') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, authorizationCode: token, uid: emailOrSecretOrUidOrPass, isSafari: isSafari });
    }
    if (provider == 'phone') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, idToken: token, password: emailOrSecretOrUidOrPass, source: source });
    }
    if (provider == 'unifonic') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, idToken: token, password: emailOrSecretOrUidOrPass, source: source });
    }
    if (provider == 'internal') {
      return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, idToken: token, password: emailOrSecretOrUidOrPass });
    }
    
  }

  clientAuthenticate(provider: string, token: string, emailOrSecretOrUidOrPass?: string, isSafari?: boolean): Observable<any> {
      return this.handleRequest('post', '/auth/client/authenticate', { username: token, password: emailOrSecretOrUidOrPass });
  }

  customerAuthenticatePhoneAndEmail(provider: string, token: string, email: string): Observable<any> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    return this.handleRequest('post', '/auth/customer/authenticate', { provider: provider, idToken: token, email: email, source: source });

  }

  customerAuthenticateByPhone(phone: string, pass: string): Observable<any> {
    return this.handleRequest('post', '/auth/customer/login/phone', { username: phone, password: pass });
  }

  customerAuthenticateByEmail(email: string, pass: string): Observable<any> {
    return this.handleRequest('post', '/auth/customer/login/email', { username: email, password: pass });
  }
  customerAuthenticateReset(email: string, provider: string): Observable<any> {
    return this.handleRequest('post', '/auth/customer/reset', { username: email, provider: provider });
  }

  customerUpdatePhone(acces_token, idToken,provider:string) {
    return this.handleRequest('post', '/customers/updatephone', { acces_token: acces_token, idToken: idToken ,provider:provider});
  }

  customerUpdatePhone_(idToken,providerPhone) {
    return this.handleRequest('put', '/customers', { phone: idToken,providerPhone:providerPhone });
  }
  customerUpdate(args: any) {
    return this.handleRequest('put', '/customers', { name: args.name, birthDate: args.birthDate, language: args.language, marketingInfo: args.marketingInfo, email: args.email });
  }

  customerRegisterSocial(providerPhone:string,phToken: string, provToken: string, provider: string, SecOrUid?: string, isSafari?: boolean, id_token?: string): Observable<any> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    if (provider == 'google.com') {
      return this.handleRequest('post', '/customers/social', { providerPhone:providerPhone,provider: provider, token: provToken, idToken: phToken, source: source });
    }
    if (provider == 'twitter.com') {
      return this.handleRequest('post', '/customers/social', { providerPhone:providerPhone,provider: provider, token: provToken, secret: SecOrUid, idToken: phToken, source: source });
    }
    if (provider == 'facebook.com') {
      return this.handleRequest('post', '/customers/social', { providerPhone:providerPhone,provider: provider, token: provToken, uid: SecOrUid, idToken: phToken, source: source });
    }

    if (provider == 'apple.com') {
      if (!isSafari) {
        return this.handleRequest('post', '/customers/social', {providerPhone:providerPhone, provider: provider, token: provToken, uid: SecOrUid, idToken: phToken, isSafari: isSafari, source: source, id_token: id_token });
      } else {
        return this.handleRequest('post', '/customers/social', {providerPhone:providerPhone, provider: provider, token: provToken, uid: SecOrUid, idToken: phToken, isSafari: isSafari, source: source });
      }
    }

  }

  customerRegister(provider:string,idToken: string, password: string, email?: string | boolean): Observable<any> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    if (email) {
      return this.handleRequest('post', '/customers', {provider:provider, idToken: idToken, email: email, password: password, source: source });
    } else {
      return this.handleRequest('post', '/customers', {provider:provider, idToken: idToken, password: password, source: source });
    }
  }

  customerResetPass(idToken: string, password: string, provider: string): Observable<any> {
    return this.handleRequest('put', '/customers/reset-pass', { idToken: idToken, password: password, provider: provider });
  }

  getCustomerProfile(): Observable<any> {
    return this.handleRequest('get', '/customers', {});
  }

  getCustomerStatus(): Observable<[]> {
    return this.handleRequest('get', '/customers/status', {});
  }

  getCustomerLoyaltyPoints(): Observable<[]> {
    return this.handleRequest('get', '/customers/loyalty-program', {});
  }

  getCustomerAddresses(): Observable<[]> {
    return this.handleRequest('get', '/customers/addresses', {});
  }

  getCustomerInfoAddress(id: number): Observable<[]> {
    return this.handleRequest('get', `/customers/addresses/${id}`, {});
  }

  getCustomerWallet(): Observable<[]> {
    return this.handleRequest('get', '/customers/wallet', {});
  }

  getCustomerWalletSummary(start?: Date, end?: Date): Observable<[]> {
    if (start && end) {
      return this.handleRequest('get', `/customers/wallet/summary?start=${start}&end=${end}`, {});
    } else {
      return this.handleRequest('get', `/customers/wallet/summary`, {});
    }
  }

  rechargeWallets(voucher: string): Observable<any> {
    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;
    return this.handleRequest('put', '/customers/wallet', { voucher: voucher, source: source });
  }


  deleteAddress(address: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: address
    }
    return this.httpClient.delete(`${this.apiUrl}/customers/addresses`, options);
  }

  updateAddress(action, address) {
    let type = action == 'n' ? 'post' : (action == 'u' ? 'put' : 'delete');
    if (action == 'n' || action == 'u') {
      return this.handleRequest(type, '/customers/addresses', address);
    } else {
      return this.deleteAddress(address);
    }
  }

  customerLink(provider: string, token: string, emailOrSecretOrUidOrPass?: string, isSafari?: boolean): Observable<any> {

    let source = (this.platformService.isIos() || this.platformService.isAndroid()) ? LegacySources.Apps : LegacySources.Web;

    if (provider == 'google.com') {
      return this.handleRequest('put', '/customers', { social: { provider: provider, idToken: token, source: source } });
    }
    if (provider == 'twitter.com') {
      return this.handleRequest('put', '/customers', { social: { provider: provider, idToken: token, secret: emailOrSecretOrUidOrPass, source: source } });
    }
    if (provider == 'facebook.com') {
      return this.handleRequest('put', '/customers', { social: { provider: provider, idToken: token, uid: emailOrSecretOrUidOrPass, source: source } });
    }
    if (provider == 'apple.com') {
      return this.handleRequest('put', '/customers', { social: { provider: provider, idToken: token, uid: emailOrSecretOrUidOrPass, isSafari: isSafari, source: source } });
    }
  }

  getCustomerOrders(limit: number, orderType?: string, ignoreLoading: boolean = false): Observable<any> {

    /*if (orderType) {
      return this.handleRequest('get', `/customers/tickets?limit=${limit}&orderType=${orderType}`, {});
    } else {
      return this.handleRequest('get', `/customers/tickets?limit=${limit}`, {});
    }*/
    let endpoint: string = `/customers/tickets?limit=${limit}`
    if (orderType) {
      endpoint += `&orderType=${orderType}`
    }
    if (ignoreLoading) {
      endpoint += '&loading=false'
    }
    return this.handleRequest('get', endpoint, {})
  }

  getCustomerPromotions(): Observable<[]> {
    return this.handleRequest('get', `/customers/promotions?loading=false`, {});
  }

  findZoneDelivery(location: string): Observable<any> {
    return this.handleRequest('get', `/zones?location=${location}`)

  }

  getPromiseTimes(id: number, orderType: string): Observable<[]> {
    return this.handleRequest('get', `/branches/${id}/promise-times?orderType=${orderType}`)
  }

  getCustomerOrdersTracking(limit?: number): Observable<[]> {
    if (limit) {
      return this.handleRequest('get', `/customers/tickets?tracking=${true}&limit=${limit}&loading=false`, {});
    } else {
      return this.handleRequest('get', `/customers/tickets?tracking=${true}&loading=false`, {});
    }
  }

  getTrackingByPhone(lang:string, phone: string): Observable<[]> {
    return this.handleRequest('get', `/tracking/${phone}?language=${lang}&loading=false`, {});
  }

  addCustomerFavProduct(favProduct: AddFavProductInterface): Observable<any> {
    return this.handleRequest('post', '/customers/favorites', favProduct)
  }

  getCustomerFavProducts(): Observable<any> {
    return this.handleRequest('get', '/customers/favorites')
  }

  removeCustomerFavProduct(id: number): Observable<any> {
    return this.handleRequest('delete', `/customers/favorites/${id}`)
  }


  public getMerchantData(Gateway: string) {
    let checkout = environment.gateWays.applePay.checkout;
    let hyperpay = environment.gateWays.applePay.hyperPay;

    if (Gateway == checkout.letter) {
      return environment.gateWays.applePay.checkout
    }
    if (Gateway == hyperpay.letter) {
      return environment.gateWays.applePay.hyperPay;
    }
  }

  public getInvoiceData(gateway: string) {
    let tap = environment.gateWays.invoice.tap;
    if (gateway == tap.letter) {
      return tap
    }
  }

  public getCheckoutId(Gateway, checkout): Observable<any> {

    let body = {
      orderCode: checkout.OrderCode,
      amount: checkout.Amount,
      origin: checkout.origin,
      ion: checkout.ion,
      webVersion: checkout.WebVersion,
      branchID: checkout.BranchID
    }
    var strJSON = encodeURIComponent(JSON.stringify(body));

    let checkoutUrl = this.getMerchantData(Gateway).checkoutIDUrl;

    return this.httpClient['post']<any>(`${checkoutUrl}`, "data=" + strJSON)

  }

  public getPaymentResult(Gateway, idHyperPay): Observable<any> {
    let paymentResultUrl = this.getMerchantData(Gateway).paymentResultUrl;
    return this.httpClient['get']<any>(`${paymentResultUrl}?id=${idHyperPay}`, {})
  }

  public payWithApplePay(paymentToken: string, checkout: string, Gateway: string): Observable<any> { //Checkout Gateway

    let body = {
      "paymentToken": paymentToken,
      "params": checkout
    }
    let merchantUrl = this.getMerchantData(Gateway).url;

    return this.httpClient['post']<any>(`${merchantUrl}`, body)

  }

  qitafOTP(data: any): Observable<any> {
    return this.handleRequest('post', '/customers/qitafOTP', data);
  }

  qitafRedeem(data: any): Observable<any> {
    return this.handleRequest('post', '/customers/qitafRedeem', data);
  }

  payWithWallet(orderNumber: number, branchID: number): Observable<any> {
    return this.handleRequest('post', '/customers/wallet', { orderNumber: orderNumber, branchID: branchID });
  }

  updatePayMethod(orderNumber: number, branchID: number): Observable<any> {
    return this.handleRequest('post', '/customers/wallet', { orderNumber: orderNumber, branchID: branchID });
  }

  registerClickCarLocation(data: any) {
    return this.handleRequest('post', '/customers/click-car/location', data);
  }


  updateFCMtoken(token: string, lang: string, device: string): Observable<any> {
    return this.handleRequest('post', '/customers/fcm-token?loading=false', { token: token, language: lang, device: device })
  }

  getCustomerAddressesNoLoading(): Observable<[]> {
    return this.handleRequest('get', '/customers/addresses?loading=false', {});
  }

  shareReferal(): Observable<any> {
    return this.handleRequest('post', '/customers/share-referal', {})
  }

  applyReferal(link: string): Observable<any> {
    return this.handleRequest('post', '/customers/apply-referal?loading=false', { link: link })
  }

  rate(data: any): Observable<any> {
    return this.handleRequest('post', '/customers/ratings', data)
  }

  ratingKiosk(data: any): Observable<any> {
    return this.handleRequest('post', '/tickets/ratings', data)
  }


  getLastOrder(limit: number): Observable<[]> { // to testing reorder
    return this.handleRequest('get', `/customers/tickets?limit=${limit}&loading=false&orderType=D`, {});
  }

  logger(level: string, message: string, payload: any,ctx:string): Observable<any> {
    let device = this.platformService.getDevice();
        payload["webVersion"]= Version;
        payload["device"]= device;
    return this.handleRequest('post', '/logger?loading=false', { context: ctx, level: level, message: message, extra: payload })
  }

  requestSms(phone: string): Observable<any> {
    return this.handleRequest('get', `/auth/customer/verify/${phone}`);
  }

  verifySms(verification_code: string,pin:string): Observable<any> {
    return this.handleRequest('get', `/auth/customer/check/${verification_code}/${pin}`);
  }

  private _eventPg(payload: any,evtName: string,level="info", ctx = "event"): Observable<any> {
    let device = this.platformService.getDevice();
        payload["webVersion"]= Version;
        payload["device"]= device;
    return this.handleRequest('post', '/logger?loading=false', { context: ctx, level: level, message: evtName, extra: payload })
  }

  getCustomerDetails(): Observable<[]> {
    return this.handleRequest('get', `/customers/details`, {});
  }

  public averaPayment(order, config) {
    let data = {
      'amount': parseFloat(order.total).toFixed(2),
      'branch': order.branchID,
      'client': order.pgCustomerID,
      'terminal': `${order.branchID}K${order.kioskNumber}`,
      'ticket': order.orderNumber,
      'paymentMethod' : order.paymentMethod
    }
    let url = `http://${config.privateIP}:${config.kioskPort}/api/AveraPayment`
    // return fetch(url)
    return this.httpClient.post<any>(url, data, { observe: 'response' })
  }

  public geideaPayment (branchId, kioskNumber,  numTicket, total, ip, port, branch, paymentMethod){
    let pos = branchId + "K" + kioskNumber;
    let url = "http://" + ip + ":" + port + "/api/GeideaPayment?p=" + (total * 100) + "&t=" + numTicket + "&pos=" + pos  + "&b=" + branch + "&pm=" + paymentMethod;
    console.log(url)

    return fetch(url)
  }

  public invoiceTap(checkout: string, Gateway: string) {
    let body = {
      "params": checkout
    }
    let merchantUrl = this.getInvoiceData(Gateway).url;
    return this.httpClient['post']<any>(`${merchantUrl}`, body)
  }

 getKioskConfig(branchId: number): Observable<[]> {
  return this.handleRequest('get', `/branches/${branchId}/kiosk-config`)
}

setDataForms<T = any>(data: T) {
  this.dataForm.next(data);
}

  public eventPg(payload: any,evtName: string){

    let data = {};

    if(evtName == 'product_click'){
       data = {id:payload.id,description:payload.description};
    }
    if(evtName == 'promo_click'){
      data = {id:payload.id,description:payload.description};
    }
    if(evtName == 'select_promotion'){
      data = {id:payload.id,description:payload.description};
    }
    if(evtName == 'add_to_cart'){
      data = {id:payload?.productID || payload.id,qty:payload?.quantity || 1};
    }
    if(evtName == 'remove_from_cart'){
      data = {id:payload?.productID || payload.id,qty:payload?.quantity || 1};
    }
    if(evtName == 'purchase'){
      data = payload;
    }
    if(evtName == 'checkout_start'){
      data = {id:payload?.productID || payload.id,qty:payload?.quantity || 1};
    }
    this._eventPg(data,evtName).subscribe(
      (res) => {
        console.log(res);
      }
    );

  }

}
