// DatePicker

import { FormMetadataFieldOptionsCheckInterface } from './interfaces'

export const FormDatePickerDisplayFormat: string = 'DD/MM/YYYY'
export const FormDatePickerFormat: string = 'DD-MM-YYYY'

// Default Value

export const FormMetadataFieldBooleanValue: string[] = [
    'checkbox',
    'switch'
]

// Password eye

export const FormPasswordIcons: string[] = [
    'eye-off-outline',
    'eye-outline'
]

// RegExp Validations

export const FormEmailValidatorPattern: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

export const FormOnlyNumbersPattern: RegExp = /\d+/

// Metadata

export const FormMetadataFieldOptionsCheck: FormMetadataFieldOptionsCheckInterface[] = [
    {
        fieldType: 'checkbox',
        requiredValueType: 'boolean'
    },
    {
        fieldType: 'datepicker',
        requiredOptions: ['datePicker']
    },
    {
        fieldType: 'email',
        requiredInputMode: 'email'
    },
    {
        fieldType: 'number',
        requiredFieldType: 'text',
        requiredInputMode: 'numeric',
        requiredPattern: FormOnlyNumbersPattern
    },
    {
        fieldType: 'password',
        requiredIcon: FormPasswordIcons['1'],
        requiredOptions: ['showPassword']
    },
    {
        fieldType: 'radio',
        requiredOptions: ['data']
    },
    {
        fieldType: 'segment',
        requiredOptions: ['data']
    },
    {
        fieldType: 'select',
        requiredOptions: ['data', 'select']
    },
    {
        fieldType: 'switch',
        requiredValueType: 'boolean'
    },
    {
        fieldType: 'tel',
        requiredFieldType: 'text',
        requiredInputMode: 'tel',
        requiredPattern: FormOnlyNumbersPattern
    }
]