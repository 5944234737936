import { Component, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { RxjsService, CustomObservableType } from '@services/rxjs.service';
import { HereService } from '@services/here.service'
import { Router } from '@angular/router';
import { OrderTypes } from '@lib/promo-engine';
declare var H: any;
@Component({
  selector: 'app-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.scss'],
})
export class HereMapComponent {
  @ViewChild("map")
  public mapElement: ElementRef;
  public map_: any;
  public assets: string = environment.assetsUrl;
  public bMarkers: any = [];
  @Output() tapMarker: EventEmitter<any>; // implement this listener to receive event when marker is clicked, marker contain information about branch or user
  @Output() mapReady: EventEmitter<any>; // implement this listener to receive event when map is ready, after event is recevied, we can call to function to draw in map
  @Input() wkngMethod: string;
  private subscription: any;
  public actualRoute: string;

  private houseSvg: string = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="42" viewBox="0 0 42 42"><defs> <filter id="prefix__b" width="202.4%" height="202.4%" x="-51.2%" y="-41.7%" filterUnits="objectBoundingBox"><feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5"/>  <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0813483392 0"/> </filter> <circle id="prefix__a" cx="21" cy="21" r="21"/> </defs> <g fill="none" fill-rule="evenodd"> <mask id="prefix__c" fill="#fff"> <use xlink:href="#prefix__a"/> </mask><use fill="#000" filter="url(#prefix__b)" xlink:href="#prefix__a"/> <use fill="#FFF" xlink:href="#prefix__a"/> <g stroke-linecap="round" stroke-linejoin="round" mask="url(#prefix__c)"><g stroke="#676767" stroke-width=".5"> <g><path d="M13.003.024L0 11.101 2.259 13.328 13.003 4.911 23.746 13.328 26.005 11.101zM3.973 14.251L3.973 22.233 10.22 22.233 10.22 14.251 15.784 14.251 15.784 22.233 22.032 22.233 22.032 14.251" transform="translate(8 9)"/></g><path d="M20.317 1.533L20.317 5.367" transform="translate(8 9)"/></g></g></g></svg>';
  private customLocationSvg: string = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42" height="42" viewBox="0 0 42 42"><defs> <filter id="prefix__b" width="202.4%" height="202.4%" x="-51.2%" y="-41.7%" filterUnits="objectBoundingBox"><feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/> <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5"/> <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0813483392 0"/> </filter>  <circle id="prefix__a" cx="21" cy="21" r="21"/>  <path id="prefix__d" d="M0 0.019L17.183 0.019 17.183 25.673 0 25.673z"/> </defs> <g fill="none" fill-rule="evenodd"> <mask id="prefix__c" fill="#fff"> <use xlink:href="#prefix__a"/> </mask> <use fill="#000" filter="url(#prefix__b)" xlink:href="#prefix__a"/><use fill="#FFF" xlink:href="#prefix__a"/><g mask="url(#prefix__c)"><g> <g transform="translate(12 8)"><mask id="prefix__e" fill="#fff"><use xlink:href="#prefix__d"/></mask><path stroke="#676767" stroke-width=".5" d="M8.591 25.654S0 15.556 0 8.782C0 3.932 3.847 0 8.591 0c4.745 0 8.592 3.932 8.592 8.782 0 6.774-8.592 16.872-8.592 16.872z" mask="url(#prefix__e)"/></g><path stroke="#676767" stroke-width=".5" d="M9 5c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z" transform="translate(12 8)"/></g></g></g></svg>';
  public constructor(
    private rxjsService: RxjsService,
    private readonly hereS: HereService,
    private readonly _router: Router

  ) {
    this.tapMarker = new EventEmitter<any>();
    this.mapReady = new EventEmitter<any>();
  }
  public loadHereMaps(centerMap: any,locked : boolean = false): CustomObservableType<any> {
    return this.loadMap(centerMap,locked);
  }
  public drawMarkers(user: any = environment.hereMaps.centerMap, branches: Array<any> = []) {
    this.bMarkers = [this.createUserMaker({ lat: user.lat, lng: user.lng }, { isBranch: false })];
    branches.forEach(branch => {
      let bra = this.createBranchMarker({ lat: branch.lat, lng: branch.lng }, { isBranch: true, data: branch });
      this.bMarkers.push(bra);
    });
    // this.addMarker(b);
    // markers && fitbounds or not
    this.adddMultipleMarkers(this.bMarkers, false);
  }
  // private whenMapReady() {
  //   this.mapReady.emit(true);
  // }
  private onTapMarker(marker: any) {
    let bCode = marker.data.data.id;
    this.overrideIconMarker(bCode);
    let position = this.getMarkerPosition(marker);

    let zoomDef = environment.hereMaps.zoomBranchSelected;
    let zoom = this.getZoom() > zoomDef ? this.getZoom() : zoomDef;
    this.moveMapTo(position, zoom);
    this.tapMarker.emit({ data: this.getMarkerExtraParams(marker), position: position });
  }
  public moveToPosition(branch: any) {
    this.overrideIconMarker(branch.id);
    let position = { lat: branch.lat, lng: branch.lng };
    this.moveMapTo(position, environment.hereMaps.zoomBranchSelected);
  }
  private loadMap(centerMap,locked : boolean = false): CustomObservableType<any> {
    const loadMapResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
    let platform = new H.service.Platform({ "apikey": environment.hereMaps.apiKey });
    let defaultLayers = platform.createDefaultLayers();
    let map = new H.Map(this.mapElement.nativeElement, defaultLayers.vector.normal.map, {
      zoom: environment.hereMaps.zoom,
      center: centerMap
    });
    let ui = H.ui.UI.createDefault(map, defaultLayers);
    var mapSettings = ui.getControl('mapsettings');
    var zoom = ui.getControl('zoom');
    var scalebar = ui.getControl('scalebar');

    mapSettings.setVisibility(false)
    scalebar.setVisibility(false)
    zoom.setAlignment('bottom-right');
    map.getViewPort().resize();
    let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    this.map_ = {};
    this.map_.platform = platform;
    this.map_.defaultLayers = defaultLayers;
    this.map_.map = map;
    this.map_.behavior = behavior;
    this.map_.group = false;
    if(!locked){
      this.setUpClickListener();
    }

    loadMapResult.next(true)
    loadMapResult.complete()
    return loadMapResult
    // this.whenMapReady();
  }
  private addMarker(marker: any) {
    // add the marker to group
    var group = new H.map.Group();
    group.addObjects([marker]);
    // Add the marker to the map
    this.addObject(group);
    //center the map at the location of the marker:
    this.moveMapTo(this.getMarkerPosition(marker), environment.hereMaps.zoom);
  }
  private addObject(group: any,blockMap = false) {
    if (this.map_.group) {
      this.map_.group.removeAll();
    }
    let that = this;
    if (!blockMap) {
      group.addEventListener('tap', function (evt) {
        that.onTapMarker(evt.target);
      }, false);
    }
    this.map_.map.addObject(group);
    this.map_.group = group;
  }
  private moveMapTo(coords: any, zoom: number) {
    this.map_.map.setCenter(coords);
    // this.map_.map.setZoom(zoom);
  }
  
  private moveMapToGroup(group: any) {
    this.map_.map.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
    let that = this;
    setTimeout(function(){
      that.map_.map.setZoom(that.getZoom()-5);
    },250)

  }
  private getMarkerPosition(marker: any) {
    return marker.getGeometry();
  }
  private getMarkerExtraParams(marker: any) {
    return marker.getData() ? marker.getData() : {};
  }
  private getZoom() {
    return this.map_.map.getZoom();
  }
  private createBranchMarker(coords: any, data?: any) {
    var icon = new H.map.Icon(this.assets + "images/pickup.png");
    var marker = new H.map.Marker(coords, { icon: icon });
    if (data) { marker.setData(data); }
    return marker;
  }
  private createUserMaker(coords: any, data?: any) {
    var icon = new H.map.Icon(this.assets + "images/userLocation.png");
    var marker = new H.map.Marker(coords, { icon: icon });
    if (data) { marker.setData(data); }
    return marker;
  }
  private adddMultipleMarkers(markers: Array<any>, fitBounds?: boolean,disableSetCenter : any = false) {
    var group = new H.map.Group();
    group.addObjects(markers);
    // add group to map
    this.addObject(group);
    // center to markers
    if (fitBounds) {
      this.moveMapToGroup(group);
    } else {
      if(!disableSetCenter){
        this.moveMapTo(this.getMarkerPosition(markers[0]), environment.hereMaps.zoom);
      }
    }
  }
  private overrideIconMarker(branchCode: number) {
    var bSelectedIcon = new H.map.Icon(this.assets + "images/pickup-selected.png");
    var bNotSelectedIcon = new H.map.Icon(this.assets + "images/pickup.png");
    this.bMarkers.forEach(bMarker => {
      let isBranch = bMarker.data.isBranch;
      let bId = bMarker.data.data.id;
      if (isBranch) {
        if (branchCode == bId) {
          bMarker.setIcon(bSelectedIcon);
        } else {
          bMarker.setIcon(bNotSelectedIcon);
        }
      }
    });
  }

  private setUpClickListener() {
    if (this.wkngMethod == 'pickup' || this.wkngMethod =='tracking') {
      return;
    }
    let that = this;
    this.map_.map.addEventListener('tap', function (evt) {
      var coordFromEvt = that.map_.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
      let mapPosition = { lat: (coordFromEvt.lat), lng: (coordFromEvt.lng) };
      that.hereS.newLocationEmmitFromMap(mapPosition);
      if (that.wkngMethod == 'delivery') {
         mapPosition["addressType"] = 'H';
        that.drawMarkersFromDelivery(mapPosition,true);
      }else{
        that.drawMarkerAddresses(mapPosition);
      }
    });
  }

  public drawMarkerAddresses(user: any) {
    let markers = [this.createUserMaker({ lat: user.lat, lng: user.lng }, { isBranch: false, data: {} })];
    this.adddMultipleMarkers(markers, false);
  }

  public resize() {
    this.map_.map.getViewPort().resize();
  }

  ngOnInit() {
    this.subscription = this.hereS.getLocationEmitter()
      .subscribe(newLocation => {
        if(newLocation){
          if (this.wkngMethod == 'delivery') {
            newLocation["addressType"] = 'H';
            try{this.drawMarkersFromDelivery(newLocation);}catch(e){};
          }else{
            this.drawMarkerAddresses(newLocation);
          }
        }

      });

    this.actualRoute = this._router.url

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setMapViewBounds(from, to) {
    var bbox = new H.geo.Rect(from.lat, from.lng, to.lat, to.lng);
    this.map_.map.getViewModel().setLookAtData({
      bounds: bbox
    });
    // let zoomDef = environment.hereMaps.zoomBranchSelected;

    // let zoom = this.getZoom() > zoomDef ? this.getZoom() : zoomDef;
    // this.map_.map.setZoom(zoom);
  }

  private adddMultipleMarkersFromPickup(markers: Array<any>, from: boolean | any = false, to: boolean | any = false,blockMap = false) {
    var group = new H.map.Group();
    group.addObjects(markers);
    this.addObject(group,blockMap);
    
    if (from && to) {
      let supIzquierda = {lat : from.lat> to.lat ? from.lat: to.lat,lng : from.lng< to.lng ? from.lng: to.lng}
      let infDerecha = {lat : from.lat< to.lat ? from.lat: to.lat,lng : from.lng> to.lng ? from.lng: to.lng}
      this.setMapViewBounds(supIzquierda,infDerecha);

      // supIzquierda.lat = supIzquierda.lat + 0.100000;
      // infDerecha.lng = infDerecha.lng + 0.100000;

      let that = this;
      setTimeout(function(){
        that.map_.map.setZoom(that.getZoom()-1);
      },250)
    
    } else if(to) {
      this.moveMapTo(to, environment.hereMaps.zoom);
    }else{
      this.moveMapToGroup(group);
    }

  }

  public drawMarkersFromPickup(user: any | boolean = false, branches: Array<any> = [], nearest: any | boolean = false,blockMap = false,lockFitBound = false) {

    let userLoc, nearestBranch: any | boolean = false;
    this.bMarkers = [];

    if (user) {
      userLoc = { lat: user.lat, lng: user.lng };
      this.bMarkers.push(this.createUserMaker(userLoc, { isBranch: false, data: {} }));
    }

    branches.forEach(branch => {
      let Branchloc = { lat: branch.lat, lng: branch.lng };
      this.bMarkers.push(this.createBranchMarker(Branchloc, { isBranch: true, data: branch }));
      if (nearest) {
        if (nearest.id == branch.id) {
          nearestBranch = Branchloc;
        }
      }
    });

    this.adddMultipleMarkersFromPickup(this.bMarkers, !lockFitBound ? userLoc : false, nearestBranch,blockMap);

    if(nearest){
      this.overrideIconMarker(nearest.id );
    }
    let that = this;
    setTimeout(function () {
      that.resize();
    }, 250);
  }

  toogleBranches(showOpened){
    this.bMarkers.forEach(marker => {
      let isclosed = marker.data.isBranch && !marker.data.data.customData.opened;
      if(showOpened && isclosed ){
          marker.setVisibility(false);
      }else{
        marker.setVisibility(true);
      }
    });
  }

  getCustomMarker(position,data) : any{
    let type = position.addressType;
    let coords = { lat: position.lat, lng: position.lng };
    let imageName =  ( type == 'U' || type == 'H' ) ? 'house.svg' : (  type == 'O'  ? 'office.svg' : (  type == 'A'  ? 'apartment.svg' :  'custom-location.svg' ) )
    var icon = new H.map.Icon(this.assets + "images/"+imageName);
    if(imageName == 'house.svg'){
      icon = new H.map.Icon(this.houseSvg);
    }
    if(imageName == 'custom-location.svg'){
      icon = new H.map.Icon(this.customLocationSvg);
    }
    var marker = new H.map.Marker(coords, { icon: icon });
    if (data) { marker.setData(data); }
    return marker;
  }

  public drawMarkersFromDelivery(position:any,disableSetCenter: any = false) {
    // console.log("drawMarkersFromDelivery",{lat:position.lat,lng:position.lng});
    this.bMarkers = [this.getCustomMarker(position,{ isBranch: false, data: {} })];
    this.adddMultipleMarkers(this.bMarkers, false,disableSetCenter);
  }

  private createDriverMarker(coords: any, data?: any) {
    var icon = new H.map.Icon(this.assets + "images/driver.svg");
    var marker = new H.map.Marker(coords, { icon: icon });
    if (data) { marker.setData(data); }
    return marker;
  }

  private createBranchMarkerDelivery(coords: any, data?: any) {
    var bSelectedIcon = new H.map.Icon(this.assets + "images/dostawca.svg");
    var marker = new H.map.Marker(coords, { icon: bSelectedIcon });
    if (data) { marker.setData(data); }
    return marker;
  }

  private createOrderMakerDelivery(coords: any, data?: any) {
    var icon = new H.map.Icon(this.assets + "images/userLocation.png",{anchor: {x: 20, y: 25}});
    var marker = new H.map.Marker(coords, { icon: icon });
    if (data) { marker.setData(data); }
    return marker;
  }
  public drawMarkersFromTracking(branchLocation: any = false, driverLocation: any = false, orderLocation: any = false, clientLocation: any = false,orderType = OrderTypes.Recoger ) {

    this.bMarkers = [];

    if (branchLocation) {
      if(orderType == OrderTypes.Domicilio){
        let bMarker = this.createBranchMarkerDelivery({ lat: branchLocation.lat, lng: branchLocation.lng }, { isBranch: false, data: {} });
        this.bMarkers.push(bMarker);
      }else{
        let bMarker = this.createBranchMarker({ lat: branchLocation.lat, lng: branchLocation.lng }, { isBranch: false, data: {} });
        this.bMarkers.push(bMarker);
      }
    }

    if (driverLocation) {
      let bMarker = this.createDriverMarker({ lat: driverLocation.lat, lng: driverLocation.lng }, { isBranch: false, data: {} });
      this.bMarkers.push(bMarker);
    }

    if (orderLocation) {
      let bMarker = this.createOrderMakerDelivery({ lat: orderLocation.lat, lng: orderLocation.lng }, { isBranch: false, data: {} });
      this.bMarkers.push(bMarker);
    }

    if (clientLocation) {
      let bMarker = this.createOrderMakerDelivery({ lat: clientLocation.lat, lng: clientLocation.lng }, { isBranch: false, data: {} });
      this.bMarkers.push(bMarker);
    }

    if (this.bMarkers.length > 0) {
      var group = new H.map.Group();
      group.addObjects(this.bMarkers);
      this.addObject(group);

      if (orderLocation && driverLocation) {
        let points = [
          { lat: orderLocation.lat, lng: orderLocation.lng },
          { lat: driverLocation.lat, lng: driverLocation.lng }
        ];

        var linestring = new H.geo.LineString();
        points.forEach(function (point) {
          linestring.pushPoint(point);
        });

        var polyline = new H.map.Polyline(linestring, { style: { lineWidth: 6, strokeColor: "#769d91" } });

        this.map_.map.addObject(polyline);
      } else if (orderLocation && branchLocation) {

          let points = [
            { lat: orderLocation.lat, lng: orderLocation.lng },
            { lat: branchLocation.lat, lng: branchLocation.lng }
          ];
  
          var linestring = new H.geo.LineString();
          points.forEach(function (point) {
            linestring.pushPoint(point);
          });
  
          var polyline = new H.map.Polyline(linestring, { style: { lineWidth: 6, strokeColor: "#769d91" } });
  
          this.map_.map.addObject(polyline);
        
      }else if (clientLocation && branchLocation) {

        let points = [
          { lat: clientLocation.lat, lng: clientLocation.lng },
          { lat: branchLocation.lat, lng: branchLocation.lng }
        ];

        var linestring = new H.geo.LineString();
        points.forEach(function (point) {
          linestring.pushPoint(point);
        });

        var polyline = new H.map.Polyline(linestring, { style: { lineWidth: 6, strokeColor: "#769d91" } });

        this.map_.map.addObject(polyline);
      
    }
      this.moveMapToGroup(group);
    }

  }



}