import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-statusbar-profile',
  templateUrl: './statusbar-profile.component.html',
  styleUrls: ['./statusbar-profile.component.scss'],
})
export class StatusbarProfileComponent implements OnChanges {
  

  @Input() set profilePoints(value: number) {
    this.qtyPoints = value
  }

  divisionBar: number;
  qtyPoints: number = 0;
  classStar: string;
  numberPortions: number = 3;
  totalPoints: number = 3;
  
  constructor() { }


  ngOnChanges(): void {
    // console.log(this.qtyPoints)

    this.divisionBar = this.totalPoints / this.numberPortions;


    if(this.qtyPoints < this.divisionBar) {
      this.classStar = "star-loyalty-0";
    }

    if((this.qtyPoints >= this.divisionBar) && (this.qtyPoints < this.divisionBar*2)) {
      this.classStar = "star-loyalty-1";
    }

    if((this.qtyPoints >= this.divisionBar*2) && (this.qtyPoints < this.divisionBar*3)) {
      this.classStar = "star-loyalty-2";
    }

    if(this.qtyPoints >= this.totalPoints) {
      this.classStar = "star-loyalty-3";
    }
  }



}
