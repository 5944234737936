import {
  MenuFamilyInterface,
  MenuMajorGroupInterface,
  MenuToppingGroupInterface,
  MenuFamilyProductInterface,
  PromoDetailApplicableTo,
  OrderInterface,
  PromoInterface,
  PromoLineInterface,
  OrderTypes,
  PromoTypes,
  OrderLineTypes,
  LegacySources,
  MenuPriceTypes,
  HalvesProductTypes,
  ProductOptions,
  MenuFamilyToppingInterface,
  MenuFamilyProductToppingInterface,
} from './interfaces';
import {
  CalculatedLinesResponse,
  CartCalculatedResponse,
  CrossSalesType,
  IsPromoLineApplicable,
  ProductCalculatedResponse,
  PromoDetailUnityDiscount,
  PromoOptions,
  PromotionCalculatedResponse,
} from './interfaces/promo';

export enum EngineExpectionTypes {
  Error,
}
export enum ProductExpectionTypes {
  NotAvailableOrderType,
  ProductNotFound,
}
export enum HalvesExceptionTypes {
  NotAvailableOrderType,
  NotEnabledGroup,
  NotEnabledFamily,
  NotEnabledProduct,
  ProductNotFound,
}
export enum ToppingExceptionTypes {
  ToppingNotFound,
  CanNotBeRemovedRecipe,
  CanNotBeRemovedRequired,
  GroupMinNotReached,
}
export enum PromotionExceptionTypes {
  PromoNotFound,
  NotAvailableOrderType,
  NotAvailableSource,
  NotValidType,
  NotAplicableHours,
  NoLinesToApply,
  NoMatchLines,
  CrossNotAplicable,
  CrossManual,
  OnePerOrder,
  MinimumOrderAmount,
  NoEnoughPoints,
  PromoCodeNotApplicable,
  PromoCustomerNotApplicable,
}
export class EngineError extends Error {
  cause: string;
  code: any;
  type: string;
  description: string;
  constructor(
    message: string,
    code: any,
    cause?: string,
    description?: string,
  ) {
    super(message);
    this.type = EngineExpectionTypes[code];
    this.cause = cause;
    this.code = code;
    this.description = description;
  }
}
export class ProductException extends EngineError {
  constructor(
    message: string,
    code: ProductExpectionTypes,
    cause?: string,
    description?: string,
  ) {
    super(message, code, cause, description);
    this.type = ProductExpectionTypes[code];
  }
}
export class HalvesException extends EngineError {
  constructor(
    message: string,
    code: HalvesExceptionTypes,
    cause?: string,
    description?: string,
  ) {
    super(message, code, cause, description);
    this.type = HalvesExceptionTypes[code];
  }
}
export class ToppingException extends EngineError {
  constructor(
    message: string,
    code: ToppingExceptionTypes,
    cause?: string,
    description?: string,
  ) {
    super(message, code, cause, description);
    this.type = ToppingExceptionTypes[code];
  }
}
export class PromotionException extends EngineError {
  constructor(
    message: string,
    code: PromotionExceptionTypes,
    cause?: string,
    description?: string,
  ) {
    super(message, code, cause, description);
    this.type = PromotionExceptionTypes[code];
  }
}

export class PromoEngine {
  private menu: MenuFamilyInterface[];
  private groups: MenuMajorGroupInterface[];
  private toppingGroups: MenuToppingGroupInterface[];
  private promotions: any[];
  private feePromotions: any[];
  private crossSales: any[];
  private order: OrderInterface;
  private branch: any;
  private _list: any[];
  private _HALVES_PRODUCT = 'HALVES_PRODUCT_INTERNAL';
  private _enabledOrderTypes: OrderTypes[] = [
    OrderTypes.Domicilio,
    OrderTypes.Recoger,
    OrderTypes.Local,
  ];
  private _enabledPromoTypes: PromoTypes[] = [
    PromoTypes.Acumulable,
    PromoTypes.Pack,
    PromoTypes.Descuento,
    PromoTypes.CrossSales,
    PromoTypes.Loyalty,
  ];
  private _options = {
    salePricePickupTakeaway: MenuPriceTypes.Local,
    taxIncludedPrices: 'N',
    taxesPromoDinein: 0,
    taxesPromoDelivery: 0,
    promisedTime: null,
    loyaltyPoints: 0,
    webMaxAmountDeliveryFee: null,
    webDeliveryFeeItem: null,
    webDeliveryFeeItem2: null,
    maxAmountDeliveryFee: null,
    deliveryFeeItem: null,
    deliveryFeeItem2: null,
    customerPromos: null,
    userStatus: null,
    feePromoCode: null,
  };
  constructor(
    families: MenuFamilyInterface[],
    groups: MenuMajorGroupInterface[],
    toppingGroups: MenuToppingGroupInterface[],
    promotions?: any[],
    cross?: any[],
    order?: OrderInterface,
  ) {
    this.menu = families;
    this.groups = groups;
    this.toppingGroups = toppingGroups;
    this.crossSales = cross;
    this.order = order;
    this.filterPromos(promotions || []);
  }
  //SETS
  setOptions(options: {
    loyaltyPoints?: number;
    userStatus?: string;
    salePricePickupTakeaway?: MenuPriceTypes;
    taxIncludedPrices?: string;
    taxesPromoDinein?: number;
    taxesPromoDelivery?: number;
    promisedTime?: Date;
    deliveryFeeItem?: string;
    maxAmountDeliveryFee?: number;
    deliveryFeeItem2?: string;
    minAmountDeliveryFee?: number;
    webDeliveryFeeItem?: string;
    webMaxAmountDeliveryFee?: number;
    webDeliveryFeeItem2?: string;
    maxAmountGateway?: number;
    maxAmountNoGateway?: number;
    customerPromos?: any[];
    feePromoCode?: string;
  }) {
    this._options = { ...this._options, ...options };
  }
  setPromotions(promotions: any[]) {
    this.filterPromos(promotions || []);
  }
  setCrossSales(crossSales: any[]) {
    this.crossSales = crossSales;
  }
  setOrder(order: OrderInterface) {
    this.order = order;
  }
  filterPromos(promotions) {
    this.promotions = promotions.filter((p) => p.promoType !== 'delivery');
    this.feePromotions = promotions.filter((p) => p.promoType === 'delivery');
  }
  //GETS
  getOptions() {
    return this._options;
  }
  getFee(
    options: ProductOptions,
    lines,
    customerPromos = [],
  ): ProductCalculatedResponse | PromotionCalculatedResponse {
    const total =
      lines && Array.isArray(lines) && lines.length > 0
        ? lines.map((l) => l.unitPrice).reduce((a, b) => a + b)
        : 0;
    let articulo;
    if (options.orderType !== OrderTypes.Domicilio) {
    }
    if (
      options.source === LegacySources.Apps ||
      options.source === LegacySources.Web
    ) {
      if (options.orderType === OrderTypes.Domicilio) {
        if ('webMaxAmountDeliveryFee' in this._options) {
          if (
            this._options.webMaxAmountDeliveryFee > 0 &&
            this._options.webMaxAmountDeliveryFee <= total
          ) {
            articulo = 'webDeliveryFeeItem2';
          } else {
            articulo = 'webDeliveryFeeItem';
          }
        }
      }
    } else {
      if (options.orderType === OrderTypes.Domicilio) {
        if ('webMaxAmountDeliveryFee' in this._options) {
          if (
            this._options.webMaxAmountDeliveryFee > 0 &&
            this._options.maxAmountDeliveryFee <= total
          ) {
            articulo = 'deliveryFeeItem2';
          } else {
            articulo = 'deliveryFeeItem';
          }
        }
      }
    }
    const fee: MenuFamilyProductInterface = this.buscar_articulo(
      this._options[articulo],
    );
    const grupo = this.groups.find((g) => {
      return g.id === fee.majorGroupID;
    });
    if (!fee) {
      throw new ProductException(
        `No se encuentra el producto`,
        ProductExpectionTypes.ProductNotFound,
        this._options[articulo],
      );
    }
    let promoClientID = null;
    const fee_obj = {
      saleItemID: fee.id,
      unitPrice: fee.salePricePUOnline,
      total_num_ingredientes: 0,
      total_precio_ingredientes: 0,
      precio_venta: fee.salePricePUOnline,
      precio_base: fee.salePricePUOnline,
      calories: 0,
      allergens: '00000000000000',
      type: OrderLineTypes.simple,
      errors: [],
      toppings: [],
      points: 0,
      tax: grupo.taxDelivery,
    } as ProductCalculatedResponse;
    if (this.feePromotions.length > 0) {
      const prom: PromoInterface = this.feePromotions.reduce(
        (result: PromoInterface, p: PromoInterface) => {
          if (
            p.promoType == PromoTypes.Delivery &&
            total >= p.minimumOrderAmount
          ) {
            if (
              p.crossType == CrossSalesType.Loyalty &&
              p.crossFindCode.split(';').includes(this._options.userStatus)
            ) {
              result = p;
            } else if (
              p.crossType == CrossSalesType.Fee &&
              p.manuallyAdded == 'N'
            ) {
              result = p;
            } else if (
              p.promoCodes !== '' &&
              this._options.feePromoCode !== null
            ) {
              if (p.promoCodes !== '*') {
                if (
                  p.promoCodes
                    .split(';')
                    .filter((o) => o !== '')
                    .includes(this._options.feePromoCode)
                ) {
                  result = p;
                }
              } else {
                result = p;
              }
            } else if (
              p.promoCodes === '' &&
              customerPromos !== null &&
              Array.isArray(customerPromos) &&
              customerPromos.length > 0
            ) {
              //oferta cliente
              const customerPromo = customerPromos.find(
                (c) => c.promoID === p.id,
              );
              if (
                customerPromo &&
                'promoID' in customerPromo &&
                'ID' in customerPromo
              ) {
                result = p;
                promoClientID = customerPromo.ID;
              }
            }
          }
          return result;
        },
        null,
      );
      if (prom) {
        const descuento = this.calcular_descuento(
          fee_obj.unitPrice,
          prom.byPrice,
          prom.salePrice,
        );
        const up = fee_obj.unitPrice - descuento;
        const min_charge = Math.abs(prom.minimumPromoCharge) || 0;
        const unit_price = up < min_charge ? min_charge : up;
        const discount =
          fee_obj.unitPrice - unit_price > 0
            ? fee_obj.unitPrice - unit_price
            : 0;
        fee_obj.price_discount = unit_price;
        fee_obj.amount_discount = discount;
        return {
          saleItemID: prom.id,
          sublines: [fee_obj],
          type: OrderLineTypes.promotion,
          quantity: 1,
          unitPrice: unit_price,
          discount: discount,
          promoClientID: promoClientID,
        } as PromotionCalculatedResponse;
      }
    }
    return fee_obj;
  }
  getOrder() {
    return this.order;
  }
  getTotal() {
    const precios = this.order.lines.map((l) => l.unitPrice);
    return precios.reduce((a, b) => a + b, 0);
  }
  //FOO
  sumAllergens(a1, a2) {
    const o1 = a1.split(''),
      o2 = a2.split('');
    return o1
      .map((x, i) => (x === '1' ? '1' : o2[i] === '1' ? '1' : '0'))
      .join('');
  }
  buscar_articulo(productID: string): MenuFamilyProductInterface {
    if (this._list === undefined) {
      const list = this.menu.map((f) => {
        return f.products;
      });
      this._list = [].concat(...list);
    }
    return this._list.find((p) => {
      if (p.id === productID) {
        return p;
      }
    });
  }
  buscar_familia(familyID: number) {
    return this.menu.find((f) => f.id === familyID);
    /*return this.menu.find(
        family => {
          if (family.id === familyID) {
            return family
          }
        }
      )*/
  }
  buscar_full_oferta(promoID: string) {
    const full = [...this.feePromotions, ...this.promotions];
    return full.find((p) => {
      if (p.id === promoID) {
        return p;
      }
    });
  }
  buscar_oferta(promoID: string) {
    return this.promotions.find((p) => {
      if (p.id === promoID) {
        return p;
      }
    });
  }
  buscar_topping(toppingID: string) {
    const toppings = [];
    this.menu.map((f) => {
      return toppings.push(...f.toppings);
    });
    return toppings.find((t) => t.id === toppingID);
  }
  calcular_media(c1: number, c2: number) {
    return Math.round(((c1 + c2) / 2) * 100) / 100;
  }
  calcular_precio_compuesto(
    options: ProductOptions,
    ...product
  ): ProductCalculatedResponse {
    const p1 = this.buscar_articulo(product[0].saleItemID || product[0].id);
    if (!p1) {
      throw new HalvesException(
        `No se encuentra el producto`,
        HalvesExceptionTypes.ProductNotFound,
        (product[0].saleItemID || product[0].id),
      );
    }
    const p2 = this.buscar_articulo(product[1].saleItemID || product[1].id);
    if (!p2) {
      throw new HalvesException(
        `No se encuentra el producto`,
        HalvesExceptionTypes.ProductNotFound,
        (product[1].saleItemID || product[1].id),
      );
    }
    const f1 = this.buscar_familia(p1.familyGroupID);
    const f2 = this.buscar_familia(p2.familyGroupID);
    const g1 = this.groups.find((g) => g.id === p1.majorGroupID);
    const g2 = this.groups.find((g) => g.id === p2.majorGroupID);
    if (!(g1.id === g2.id && g1.halfNdHalfAllowed === 'S')) {
      throw new HalvesException(
        'Compatible groups',
        HalvesExceptionTypes.NotEnabledGroup,
        g1.id.toString(),
      );
    }
    if (!(f1.hNDhGroupping === f2.hNDhGroupping)) {
      throw new HalvesException(
        'Compatible famlies',
        HalvesExceptionTypes.NotEnabledFamily,
        g1.id.toString(),
      );
    }
    if (p1.noHalfNDHalf === 'S' || p2.noHalfNDHalf === 'S') {
      throw new HalvesException(
        'Compatible products',
        HalvesExceptionTypes.NotEnabledProduct,
        g1.id.toString(),
      );
    }
    if (options && 'checkOrderType' in options && options.checkOrderType === true) {
      if (!p1.orderType.includes(options.orderType)) {
        throw new HalvesException(
          `Ambito no disponible mitad`,
          HalvesExceptionTypes.NotAvailableOrderType,
          p1.id.toString(),
        );
      }
      if (!p2.orderType.includes(options.orderType)) {
        throw new HalvesException(
          `Ambito no disponible mitad`,
          HalvesExceptionTypes.NotAvailableOrderType,
          p2.id.toString(),
        );
      }
    }
    let precios = product.map((p) =>
      this.calcular_precio_producto(p.saleItemID || p.id, p.toppings, options),
    );
    let precio_base,
      precio_venta,
      total_num_ingredientes,
      total_precio_ingredientes,
      calories;
    if (
      f1.hNDnType === HalvesProductTypes.Aritmetica &&
      f1.hNDnType === HalvesProductTypes.Aritmetica
    ) {
      precio_base = this.calcular_media(
        precios[0].precio_base,
        precios[1].precio_base,
      );
      precio_venta = this.calcular_media(
        precios[0].precio_venta,
        precios[1].precio_venta,
      );
      total_num_ingredientes = this.calcular_media(
        precios[0].total_num_ingredientes,
        precios[1].total_num_ingredientes,
      );
      total_precio_ingredientes = this.calcular_media(
        precios[0].total_precio_ingredientes,
        precios[1].total_precio_ingredientes,
      );
      calories = this.calcular_media(precios[0].calories, precios[1].calories);
      precios = precios.map((p) => {
        p.precio_venta = precio_venta / 2;
        p.unitPrice = precio_venta / 2;
        return p;
      });
    } else {
      if (precios[0].precio_venta > precios[1].precio_venta) {
        precio_base = precios[0].precio_base;
        precio_venta = precios[0].precio_venta;
        total_num_ingredientes = precios[0].total_num_ingredientes;
        total_precio_ingredientes = precios[0].total_precio_ingredientes;
        calories = precios[0].calories;
      } else {
        precio_base = precios[1].precio_base;
        precio_venta = precios[1].precio_venta;
        total_num_ingredientes = precios[1].total_num_ingredientes;
        total_precio_ingredientes = precios[1].total_precio_ingredientes;
        calories = precios[1].calories;
      }
      precios = precios.map((p) => {
        p.precio_venta = precio_venta / 2;
        p.unitPrice = precio_venta / 2;
        return p;
      });
    }
    const precio_compuesto = {
      total_num_ingredientes,
      total_precio_ingredientes,
      precio_venta: precio_venta + (f1.hNdhFee || 0),
      precio_base,
      calories,
      allergens: this.sumAllergens(precios[0].allergens, precios[1].allergens),
      unitPrice: precio_venta + (f1.hNdhFee || 0),
      saleItemID: this._HALVES_PRODUCT,
      toppings: precios.map((p) => p.toppings) as Array<string[]>,
      sublines: precios.map((p) => p),
      type: OrderLineTypes.composed,
      errors: [],
      points: precios[0].points,
    };
    return precio_compuesto;
  }
  calcular_precio_producto(
    product: string,
    toppings: string[],
    options?: ProductOptions,
  ): ProductCalculatedResponse {
    //falta tiendas.taxIncludedPrices en grupos.taxDinein i grupod.taxDinein
    if (!options) {
      options = {} as ProductOptions;
    }
    if (!('orderType' in options)) {
      options.orderType = OrderTypes.Recoger;
    }
    if (!('source' in options)) {
      options.source = LegacySources.Web;
    }
    const articulo = this.buscar_articulo(product);
    if (articulo === undefined) {
      throw new ProductException(
        `No se encuentra el producto`,
        ProductExpectionTypes.ProductNotFound,
        product,
      );
    }
    if (options && 'checkOrderType' in options && options.checkOrderType === true) {
      if (!articulo.orderType.includes(options.orderType)) {
        throw new ProductException(
          `Ambito no disponible`,
          ProductExpectionTypes.NotAvailableOrderType,
          product,
        );
      }
    }
    return this.contar_ingredientes_producto(
      articulo,
      JSON.parse(JSON.stringify(toppings)),
      options.orderType,
      options.source,
    );
  }
  getToppingPrice(orderType, topping) {
    if (this._options.salePricePickupTakeaway === MenuPriceTypes.Domicilio) {
      switch (orderType) {
        case OrderTypes.Recoger:
          return topping.salePriceDel;
          break;
        case OrderTypes.Domicilio:
          return topping.salePriceDel;
          break;
        default:
          return topping.salePriceDel;
      }
    } else {
      switch (orderType) {
        case OrderTypes.Recoger:
          return topping.salePrice;
          break;
        case OrderTypes.Domicilio:
          return topping.salePriceDel;
          break;
        default:
          return topping.salePrice;
      }
    }
  }
  getToppingPromotedPrice(orderType, topping) {
    if (this._options.salePricePickupTakeaway === MenuPriceTypes.Domicilio) {
      switch (orderType) {
        case OrderTypes.Recoger:
          return topping.promotedDeliveryPrice;
          break;
        case OrderTypes.Domicilio:
          return topping.promotedDeliveryPrice;
          break;
        default:
          return topping.promotedDeliveryPrice;
      }
    } else {
      switch (orderType) {
        case OrderTypes.Recoger:
          return topping.promotedPrice;
          break;
        case OrderTypes.Domicilio:
          return topping.promotedDeliveryPrice;
          break;
        default:
          return topping.promotedPrice;
      }
    }
  }
  getPriceProduct(orderType, articulo) {
    if (this._options.salePricePickupTakeaway === MenuPriceTypes.Domicilio) {
      switch (orderType) {
        case OrderTypes.Domicilio:
          return articulo.salePriceDEOnline;
          break;
        case OrderTypes.Recoger:
          return articulo.salePriceDEOnline;
          break;
        default:
          return articulo.salePriceDEOnline;
          break;
      }
    } else {
      switch (orderType) {
        case OrderTypes.Domicilio:
          return articulo.salePriceDEOnline;
          break;
        case OrderTypes.Recoger:
          return articulo.salePricePUOnline;
          break;
        default:
          return articulo.salePrice;
          break;
      }
    }
  }
  orderToppingsByPrice(orderType, toppings) {
    let prop = 'salePrice';
    if (this._options.salePricePickupTakeaway === MenuPriceTypes.Domicilio) {
      switch (orderType) {
        case OrderTypes.Domicilio:
          prop = 'salePriceDel';
          break;
        case OrderTypes.Recoger:
          prop = 'salePriceDel';
          break;
        default:
          prop = 'salePriceDel';
          break;
      }
    } else {
      switch (orderType) {
        case OrderTypes.Domicilio:
          prop = 'salePriceDel';
          break;
        case OrderTypes.Recoger:
          prop = 'salePrice';
          break;
        default:
          prop = 'salePrice';
          break;
      }
    }
    return toppings.sort(function (a, b) {
      return b[prop] - a[prop];
    });
  }
  contar_ingredientes_producto(
    arti: MenuFamilyProductInterface,
    toppings: string[] | string,
    orderType: OrderTypes,
    source: string,
  ): ProductCalculatedResponse {
    //calorias
    //alergenos
    const articulo = JSON.parse(JSON.stringify(arti));
    const pg_toppings = [];
    if (!toppings) {
      toppings = [];
    }
    if (typeof toppings === 'string') {
      toppings = toppings.split(';').filter((t) => t !== '');
    }
    const origin_toppings = JSON.parse(JSON.stringify(toppings));
    let allergens = articulo.allergens;
    let total_num_ingredientes = 0,
      total_precio_ingredientes = 0,
      //ingredientes = [],
      //ingredientes_cliente = [],
      errors = [],
      points: number = 0;
    errors = [];
    const familia: MenuFamilyInterface = this.menu.find((f) => {
      return f.id === articulo.familyGroupID;
    });
    const grupo = this.groups.find((g) => {
      return g.id === articulo.majorGroupID;
    });
    const agrupaciones: MenuToppingGroupInterface[] = JSON.parse(
      JSON.stringify(this.toppingGroups),
    );
    // primero nos ocupamos de los "-"
    let topping, _topping; //, _agrupacion_ingredientes;
    // const _precio_ingrediente = 0;
    // const elecciones = [];
    let numero_ingredientes_promocionados = articulo.promotedToppingsQtty;
    let calories: number = articulo.calories ? articulo.calories : 0;
    total_num_ingredientes += articulo.toppingsEq;
    let full_toppings = [];
    // viene_receta - required
    if (toppings.length > 0) {
      //Quitamos de la recetas los modificaciones permitidas
      for (let i = 0; i <= toppings.length - 1; i++) {
        topping = toppings[i];
        if (topping.charAt(0) != '-') {
          continue;
        }
        if (topping == '') {
          continue;
        }
        topping = topping.substr(1, topping.length);
        _topping = familia.toppings.find((t) => {
          return t.id === topping;
        });
        if (!_topping) {
          errors.push(
            new ToppingException(
              'Topping not found',
              ToppingExceptionTypes.ToppingNotFound,
              topping,
            ),
          ); //('No se encuentra el ingrediente')
          continue;
        }
        // const _agrupacion_ingredientes = this.toppingGroups.find((a) => {
        //   return a.id === _topping.grouppingID;
        // });
        // Borramos la modificacion
        pg_toppings.push(toppings[i]);
        toppings[i] = '';
        // Buscamos en la receta
        const top = articulo.recipe.find((t) => {
          return t.invSaleItemID === _topping.id;
        });
        if (!top) {
          errors.push(
            new ToppingException(
              'Topping not found to remove',
              ToppingExceptionTypes.CanNotBeRemovedRecipe,
              topping,
            ),
          ); //(`El topping ${_topping.id} no se puede quitar porque no esta en la receta de ${articulo.id}`)
          continue;
        }
        if (top.required === 'S') {
          errors.push(
            new ToppingException(
              'Required topping can not be removed',
              ToppingExceptionTypes.CanNotBeRemovedRequired,
              topping,
            ),
          ); //(`El topping ${_topping.id} no se puede quitar porque esta obligado en ${articulo.id}`)
          continue;
        }
        const recipe = articulo.recipe.filter((t) => {
          return t.invSaleItemID !== topping;
        });
        articulo.recipe = recipe;
      }
      // ahora volvermos a crear la lista de topping con los que quedan
      toppings =
        toppings.filter((t) => {
          return t !== '';
        }) || [];
      // this.toppingGroups.forEach(tg => {
      //   if(tg.maxToppings === 1 && tg.minToppings === 1){
      //     elecciones.push(tg.id)
      //   }
      // })
      // buscar elecciones cliente
      toppings.forEach((t, index, object) => {
        if (t.charAt(0) == '+') {
          t = t.substr(1, t.length);
        }
        const topping = familia.toppings.find((top) => {
          return top.id === t;
        });
        if (topping) {
          full_toppings.push({
            ...topping,
            required: 'N',
            de_receta: false,
            charge: 'S',
            invSaleItemID: t,
            id: t,
          });
        } else {
          errors.push(
            new ToppingException(
              'Topping not found',
              ToppingExceptionTypes.ToppingNotFound,
              t,
            ),
          ); //(`El topping ${t} no existe en la familia ${familia.id}`)
          object[index] = '';
        }
      });
    }
    articulo.recipe.forEach((t) => {
      const topping_receta = familia.toppings.find((top) => {
        return top.id === t.invSaleItemID;
      });
      if (!topping_receta) {
        return;
      }
      full_toppings.push({ ...t, ...topping_receta, de_receta: true });
    });
    full_toppings = this.orderToppingsByPrice(orderType, full_toppings);
    full_toppings = full_toppings.map((t) => {
      return {
        errors,
        source,
        ...t,
        ...familia.toppings.find((top) => {
          return top.id === t.invSaleItemID;
        }),
      };
    });
    const contador_promocionados_agrupaciones = agrupaciones.reduce(function (
      result,
      item,
    ) {
      result[item.id] = item.maxFreeToppings;
      return result;
    }, {});
    const max_group_toppings = {};
    const min_group_toppings = {};
    if (full_toppings.length > 0) {
      this.toppingGroups.forEach((g) => {
        const toppings =
          familia &&
          'toppings' in familia &&
          Array.isArray(familia.toppings) &&
          familia.toppings.length > 0
            ? familia.toppings.filter((t) => t.grouppingID === g.id)
            : [];
        if (toppings.length > 0) {
          max_group_toppings[g.id] = g.maxToppings;
          min_group_toppings[g.id] = g.minToppings;
        }
      });
      full_toppings.forEach((ingr) => {
        let precio = 0;
        const max = max_group_toppings[ingr.grouppingID];
        if (max > 0) {
          if (!ingr.de_receta) {
            points = points + ingr.points;
          }
          if (total_num_ingredientes < articulo.maxToppings) {
            if (ingr.required === 'N') {
              pg_toppings.push(
                ingr.de_receta === true
                  ? ingr.invSaleItemID
                  : '+' + ingr.invSaleItemID,
              );
            }
            calories += ingr.calories; //* quantity)
            allergens = this.sumAllergens(allergens, ingr.allergens);
            if (ingr.salePrice > 0) {
              if (ingr.required === 'N') {
                if (ingr.charge === 'S') {
                  if (
                    contador_promocionados_agrupaciones[ingr.grouppingID] > 0 &&
                    ingr.canBePromoted === 'S'
                  ) {
                    total_num_ingredientes++;
                    total_precio_ingredientes += this.getToppingPromotedPrice(
                      orderType,
                      ingr,
                    );
                    numero_ingredientes_promocionados--;
                    contador_promocionados_agrupaciones[ingr.grouppingID]--;
                    precio = this.getToppingPromotedPrice(orderType, ingr);
                  } else {
                    total_num_ingredientes++;
                    total_precio_ingredientes += this.getToppingPrice(
                      orderType,
                      ingr,
                    );
                    precio = this.getToppingPrice(orderType, ingr);
                  }
                } else {
                  total_num_ingredientes++;
                }
              }
            }
          }
          max_group_toppings[ingr.grouppingID] -= 1;
          min_group_toppings[ingr.grouppingID] -= 1;
        }
        ingr.precio_venta = precio;
      });
    }
    if (numero_ingredientes_promocionados > 0) {
      full_toppings
        .sort((a, b) => b.precio_venta - a.precio_venta)
        .forEach((t) => {
          if (
            numero_ingredientes_promocionados > 0 &&
            this.getToppingPrice(orderType, t) > 0 &&
            total_precio_ingredientes > 0 &&
            t.canBePromoted === 'S'
          ) {
            total_precio_ingredientes -=
              this.getToppingPrice(orderType, t) -
              this.getToppingPromotedPrice(orderType, t);
            t.precio_venta -=
              this.getToppingPrice(orderType, t) -
              this.getToppingPromotedPrice(orderType, t);
            numero_ingredientes_promocionados--;
          }
        });
    }
    let reachedMin = true;
    let groupNotReached;
    Object.keys(min_group_toppings).forEach((k) => {
      if (min_group_toppings[k] > 0) {
        reachedMin = false;
        groupNotReached = k;
      }
    });
    if (!reachedMin) {
      errors.push(
        new ToppingException(
          'Group min not reached',
          ToppingExceptionTypes.GroupMinNotReached,
          groupNotReached,
        ),
      );
    }
    const precio_base = this.getPriceProduct(orderType, articulo);
    const precio_venta = total_precio_ingredientes + precio_base;
    // let tax = 0
    // if(this._options.taxIncludedPrices === 'N'){
    //   if(this._options.salePricePickupTakeaway === MenuPriceTypes.Local){
    //     if(orderType === OrderTypes.Recoger){
    //       tax =  (precio_venta*this._options.taxesPromoDinein)/100
    //     }else{
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }
    //   }else{
    //     if(orderType === OrderTypes.Recoger){
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }else{
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }
    //   }
    // }else{
    //   if(this._options.salePricePickupTakeaway === MenuPriceTypes.Local){
    //     if(orderType === OrderTypes.Recoger){
    //       tax =  (precio_venta*this._options.taxesPromoDinein)/100
    //     }else{
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }
    //   }else{
    //     if(orderType === OrderTypes.Recoger){
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }else{
    //       tax =  (precio_venta*this._options.taxesPromoDelivery)/100
    //     }
    //   }
    // }
    return {
      total_num_ingredientes,
      total_precio_ingredientes,
      precio_venta,
      precio_base,
      calories,
      allergens,
      unitPrice: precio_venta,
      saleItemID: articulo.id,
      toppings: origin_toppings, //toppings,
      full_toppings: full_toppings,
      pg_toppings: pg_toppings,
      type: OrderLineTypes.simple,
      errors: errors,
      portions: articulo.portions,
      fee: 0,
      tax:
        orderType === OrderTypes.Recoger ? grupo.taxDinein : grupo.taxDelivery,
      points: arti.points + points,
    };
  }
  calcular_descuento(precio, tipo, cantidadDescuento) {
    let descuento = 0;
    if (tipo == 'D') {
      descuento = precio >= cantidadDescuento ? cantidadDescuento : precio;
    } else {
      descuento = precio * (cantidadDescuento / 100);
    }
    return descuento;
  }
  isAplicableHours(
    fecha: Date | string,
    options: {
      hourlyRestriction: string;
      startingTime1: string;
      endingTime1: string;
      startingTime2: string;
      endingTime2: string;
    },
  ) {
    if (typeof fecha === 'string') {
      fecha = new Date(fecha);
    }
    if (options.hourlyRestriction === 'N') {
      return true;
    }
    const hora = fecha.toString().split(' ')[4];
    const now = Date.parse('01/01/1970 ' + hora);
    let end1 = Date.parse('01/01/1970 ' + options.endingTime1);
    let end2 = Date.parse('01/01/1970 ' + options.endingTime2);
    const start1 = Date.parse('01/01/1970 ' + options.startingTime1);
    const start2 = Date.parse('01/01/1970 ' + options.startingTime2);
    if (end1 < start1) {
      end1 += 86400000;
    }
    if (end2 < start2) {
      end2 += 86400000;
    }
    if ((now > start1 && now < end1) || (now > start2 && now < end2)) {
      return true;
    } else {
      return false;
    }
  }
  isAplicableDays() {}
  isAplicable(sbl, line_product, oa_line): IsPromoLineApplicable {
    const isAplicable = {
      product: true,
      include: true,
      toppings: true,
      exclude: false,
      include_topping: true,
      excluded_toppings: false,
      max_quantity: true,
      min_quantity: true,
    };
    const saleItems = [
      ...(sbl.saleItemID
        ? sbl.saleItemID.split(';').filter((e) => e !== '')
        : []),
      ...(sbl.saleItemID2
        ? sbl.saleItemID2.split(';').filter((e) => e !== '')
        : []),
      ...(sbl.saleItemID3
        ? sbl.saleItemID3.split(';').filter((e) => e !== '')
        : []),
    ];
    const saleItemID = oa_line.id || oa_line.saleItemID;
    const pg_toppings: string[] =
      saleItemID === this._HALVES_PRODUCT
        ? oa_line.sublines.reduce((result: string[], value) => {
            value.pg_toppings.forEach((v) => {
              if (!result.includes(v)) {
                result.push(v);
              }
            });
            return result;
          }, [])
        : oa_line.pg_toppings;
    const added_toppings: string[] = pg_toppings
      .filter((t) => t.charAt(0) !== '-')
      .map((t) => (t.charAt(0) === '+' ? t.substring(1) : t));
    // let removed_toppings: string[] = pg_toppings.filter(t => t.charAt(0) === '-').map(t => t.substring(1))
    if (saleItems.length > 0) {
      switch (sbl.applicable) {
        case PromoDetailApplicableTo.family:
          isAplicable.product = saleItems.includes(
            line_product.familyGroupID.toString(),
          );
          break;
        case PromoDetailApplicableTo.multiple:
          isAplicable.product = saleItems.includes(
            line_product.familyGroupID.toString(),
          );
          break;
        case PromoDetailApplicableTo.group:
          isAplicable.product = saleItems.includes(
            line_product.majorGroupID.toString(),
          );
          break;
        case PromoDetailApplicableTo.saleItem:
          isAplicable.product = saleItems.includes(line_product.id.toString());
          break;
        case PromoDetailApplicableTo.product:
          isAplicable.product = saleItems.includes(line_product.id.toString());
          break;
      }
    } else {
      isAplicable.product = true;
    }
    if (isAplicable.product === false) {
      return isAplicable;
    }
    if (sbl.includedItemsID !== '' && sbl.includedItemsID != null) {
      const included_items = sbl.includedItemsID
        .split(';')
        .filter((e) => e !== '');
      isAplicable.include = included_items.includes(line_product.id);
    }
    if (isAplicable.include === false) {
      return isAplicable;
    }
    if (sbl.excludedItemsID !== '' && sbl.excludedItemsID !== null) {
      const excluded_items = sbl.excludedItemsID
        .split(';')
        .filter((e) => e !== '');
      isAplicable.exclude = excluded_items.includes(line_product.id);
    }
    if (isAplicable.exclude === true) {
      return isAplicable;
    }
    // Included Toppings
    if (sbl.includedToppingsID !== '' && sbl.includedToppingsID !== null) {
      const included_toppings = sbl.includedToppingsID
        .split(';')
        .filter((e) => e !== '');
      isAplicable.include_topping = included_toppings.reduce((resp, value) => {
        if (
          !added_toppings.includes(
            value,
          ) /*!oa_line.full_toppings.some(t => t.id === value)*/
        ) {
          resp = false;
        }
        return resp;
      }, true);
    }
    if (isAplicable.include_topping === false) {
      return isAplicable;
    }
    // Excluded Toppings
    if (sbl.excludedToppingsID !== '' && sbl.excludedToppingsID !== null) {
      const excluded_toppings = sbl.excludedToppingsID
        .split(';')
        .filter((e) => e !== '');
      isAplicable.excluded_toppings = excluded_toppings.reduce(
        (resp, value) => {
          if (
            added_toppings.includes(
              value,
            ) /*oa_line.full_toppings.some(t => t.id === value)*/
          ) {
            resp = true;
          }
          return resp;
        },
        false,
      );
    }
    if (isAplicable.excluded_toppings === true) {
      return isAplicable;
    }
    isAplicable.max_quantity =
      oa_line.total_num_ingredientes <= sbl.maxToppingsQtty;
    isAplicable.min_quantity =
      oa_line.total_num_ingredientes >= sbl.minToppingsQtty;
    if (
      isAplicable.max_quantity === false ||
      isAplicable.min_quantity === false
    ) {
      isAplicable.product = false;
    }
    return isAplicable;
  }
  calcular_precio_promo(
    promo: string,
    lines: any[],
    options?: PromoOptions,
  ): CartCalculatedResponse {
    if (!options) {
      options = {} as PromoOptions;
    }
    if (!('orderType' in options)) {
      options.orderType = OrderTypes.Recoger;
    }
    if (!('source' in options)) {
      options.source = LegacySources.Web;
    }
    const full_lines = lines;
    const oferta_aplicable = this.buscar_oferta(promo);
    if (!oferta_aplicable) {
      return {
        discarded: full_lines,
        promo: null,
        complete: full_lines,
        errors: [
          new PromotionException(
            'Promotion not found',
            PromotionExceptionTypes.PromoNotFound,
            promo,
          ),
        ],
      };
    }
    if (!oferta_aplicable.orderType.includes(options.orderType)) {
      return {
        discarded: full_lines,
        promo: null,
        complete: full_lines,
        errors: [
          new PromotionException(
            `Promotion not available orderType`,
            PromotionExceptionTypes.NotAvailableOrderType,
            promo,
            options.orderType,
          ),
        ],
      };
    }
    if (!oferta_aplicable.source.includes(options.source)) {
      return {
        discarded: full_lines,
        promo: null,
        complete: full_lines,
        errors: [
          new PromotionException(
            `Promotion not available source`,
            PromotionExceptionTypes.NotAvailableSource,
            promo,
            options.source,
          ),
        ],
      };
    }
    if (!this._enabledPromoTypes.includes(oferta_aplicable.promoType)) {
      return {
        discarded: full_lines,
        promo: null,
        complete: full_lines,
        errors: [
          new PromotionException(
            `Promotion not valid type`,
            PromotionExceptionTypes.NotValidType,
            promo,
            options.source,
          ),
        ],
      };
    }
    if (this._options.promisedTime !== null) {
      const isAplicable = this.isAplicableHours(
        this._options.promisedTime,
        oferta_aplicable,
      );
      if (!isAplicable) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `Promotion not available hours`,
              PromotionExceptionTypes.NotAplicableHours,
              promo,
              this._options.promisedTime,
            ),
          ],
        };
      }
    }
    lines = lines.map((l, i) => ({ ...l, "order": i }));
    const lineas_oferta = lines.filter((l) => {
      if (
        l.type === OrderLineTypes.promotion ||
        l.type === OrderLineTypes.crossSales
      ) {
        return l;
      }
    });
    const lineas_sin_oferta = lines.filter((l) => {
      if (
        l.type !== OrderLineTypes.promotion &&
        l.type !== OrderLineTypes.crossSales &&
        l.type !== OrderLineTypes.composed
      ) {
        return l;
      }
    });
    let lineas_compuestos = lines.filter((l) => {
      if (l.type === OrderLineTypes.composed) {
        return l;
      }
    });
    lines = lineas_sin_oferta.map((l) => {
      return {
        ...l,
        ...this.calcular_precio_producto(l.saleItemID || l.id, l.toppings, {
          orderType: options.orderType,
          source: options.source,
        }),
      };
    });
    lineas_compuestos = lineas_compuestos.map((l) => {
      return {
        ...l,
        ...this.calcular_precio_compuesto(
          options,
          {
            saleItemID: l.sublines[0].saleItemID,
            toppings: l.sublines[0].toppings,
          },
          {
            saleItemID: l.sublines[1].saleItemID,
            toppings: l.sublines[1].toppings,
          },
        ),
      };
    });
    lines = lines.concat(lineas_compuestos).sort((a, b) => a.order - b.order);
    const tax =
      options.orderType === OrderTypes.Recoger
        ? this._options.taxesPromoDinein
        : this._options.taxesPromoDelivery;
    if (
      oferta_aplicable.onePerOrder === 'S' ||
      oferta_aplicable.onePerCustomer === 'S'
    ) {
      const match_oferta = lineas_oferta.find(
        (l) =>
          l.saleItemID === oferta_aplicable.id || l.id === oferta_aplicable.id,
      );
      if (match_oferta) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `Promotion applied already`,
              PromotionExceptionTypes.OnePerOrder,
              oferta_aplicable.id,
            ),
          ],
        };
      }
    }
    if (oferta_aplicable.hidden === 'S') {
      if (
        oferta_aplicable.promoCodes !== '*' &&
        oferta_aplicable.promoCodes !== '' &&
        oferta_aplicable.promoCodes !== null
      ) {
        //promo code
        if (
          !oferta_aplicable.promoCodes
            .split(';')
            .filter((o) => o !== '')
            .includes(options.promoCode)
        ) {
          return {
            discarded: full_lines,
            promo: null,
            complete: full_lines,
            errors: [
              new PromotionException(
                `PromoCode not applicable`,
                PromotionExceptionTypes.PromoCodeNotApplicable,
                promo,
                this._options.promisedTime,
              ),
            ],
          };
        }
      } else if (
        oferta_aplicable.promoCodes === '' &&
        this._options.customerPromos !== null
      ) {
        //oferta cliente
        if (!this._options.customerPromos.includes(options.promoCode)) {
          return {
            discarded: full_lines,
            promo: null,
            complete: full_lines,
            errors: [
              new PromotionException(
                `PromoCustomer not applicable`,
                PromotionExceptionTypes.PromoCustomerNotApplicable,
                promo,
                this._options.promisedTime,
              ),
            ],
          };
        }
      }
    }
    // Precios
    let oferta_aplicada,
      noMatches = [],
      lineas_precios = [];
    noMatches = [];
    if (oferta_aplicable.promoType === PromoTypes.Descuento) {
      if (lines.length < 1) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `No lines to apply`,
              PromotionExceptionTypes.NoLinesToApply,
              promo,
            ),
          ],
        };
      }
      /*
          lineas_precios = lineas_sin_oferta.map(
            m => {
              return {
                ...m,
                ...this.calcular_precio_producto(m.saleItemID || m.id, m.toppings)
              }
            }
          )
          */
      lineas_precios = lines;
      const precio = lineas_precios
        .map((i) => i['unitPrice'])
        .reduce((a, b) => a + b);
      const descuento = this.calcular_descuento(
        precio,
        oferta_aplicable.byPrice,
        oferta_aplicable.salePrice,
      );
      const descuento_linea = descuento / lineas_precios.length;
      lineas_precios.map((i) => {
        i['price_discount'] = i['unitPrice'] - descuento_linea;
        i['amount_discount'] = descuento_linea;
        return i;
      });
      const up = precio - descuento;
      const min_charge = oferta_aplicable.minimumPromoCharge || 0;
      const unit_price = up < min_charge ? min_charge : up;
      oferta_aplicada = {
        id: promo,
        saleItemID: promo,
        discount: precio - unit_price > 0 ? precio - unit_price : 0,
        unitPrice: unit_price,
        sublines: lineas_precios,
        code: options.promoCode,
        type: OrderLineTypes.promotion,
      };
    } else if (oferta_aplicable.promoType === PromoTypes.CrossSales) {
      if (oferta_aplicable.crossType === CrossSalesType.Wallet) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `Cross not applicable`,
              PromotionExceptionTypes.CrossNotAplicable,
              oferta_aplicable.id,
            ),
          ],
        };
      }
      let temp_lines = oferta_aplicable.lines;
      const matches = [];
      lines.map((oa_line) => {
        const cod_articulo = oa_line.id || oa_line.saleItemID;
        let line_match;
        let line_product;
        if (cod_articulo === this._HALVES_PRODUCT) {
          const art1 = this.buscar_articulo(oa_line.sublines[0].saleItemID);
          const art2 = this.buscar_articulo(oa_line.sublines[1].saleItemID);
          line_product = { art1, art2 };
          line_match = temp_lines.filter((sbl) => {
            const aplicable1 = this.isAplicable(sbl, art1, oa_line);
            const aplicable2 = this.isAplicable(sbl, art2, oa_line);
            return (
              aplicable1.product &&
              !aplicable1.exclude &&
              aplicable1.include &&
              !aplicable1.excluded_toppings &&
              aplicable1.include_topping &&
              aplicable2.product &&
              !aplicable2.exclude &&
              aplicable2.include &&
              !aplicable2.excluded_toppings &&
              aplicable2.include_topping
            );
          });
        } else {
          line_product = this.buscar_articulo(cod_articulo);
          line_match = temp_lines.filter((sbl) => {
            const aplicable = this.isAplicable(sbl, line_product, oa_line);
            return (
              aplicable.product &&
              !aplicable.exclude &&
              aplicable.include &&
              !aplicable.excluded_toppings &&
              aplicable.include_topping
            );
          });
        }
        if (line_match.length > 0) {
          /*
              let precios_producto = {
                ...oa_line,
                ...this.calcular_precio_producto(oa_line.id || oa_line.saleItemID, oa_line.toppings)
              }
              */
          const precios_producto = oa_line;
          const precio_oferta =
            line_match[0].chargeLine === 'S'
              ? this.calcular_descuento(
                  precios_producto.precio_venta,
                  line_match[0].discountType,
                  line_match[0].discountAmount,
                )
              : 0;
          const price_discount =
            line_match[0].chargeLine === 'S'
              ? precios_producto.precio_venta - precio_oferta
              : precio_oferta;
          const linea_carrito = {
            ...oa_line,
            ...precios_producto,
            price_discount,
            amount_discount: precio_oferta,
          };
          matches.push({
            line_oferta: line_match[0],
            linea_carrito,
            product: line_product,
            precio: precios_producto,
            precio_oferta:
              line_match[0].chargeLine === 'S'
                ? precios_producto.precio_venta - precio_oferta
                : precio_oferta,
          });
          temp_lines = temp_lines.filter(
            (tl) => tl.lineNbr !== line_match[0].lineNbr,
          );
        } else {
          noMatches.push(oa_line);
        }
      });
      if (oferta_aplicable.lines.length !== matches.length) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `No match lines`,
              PromotionExceptionTypes.NoMatchLines,
              oferta_aplicable.id,
              temp_lines.map((l) => l.lineNbr).join(','),
            ),
          ],
        };
      }
      const precios = matches.map((m) => m.precio.precio_venta);
      const precios_descuento = matches.map((m) => m.precio_oferta);
      lineas_precios = lineas_sin_oferta.map((m) => {
        return {
          ...m,
          ...this.calcular_precio_producto(m.saleItemID || m.id, m.toppings),
        };
      });
      const precio = precios.reduce((a, b) => a + b, 0);
      const descuento = precios_descuento.reduce((a, b) => a + b, 0);
      oferta_aplicada = {
        id: promo,
        saleItemID: promo,
        discount: precio - descuento,
        unitPrice: descuento,
        sublines: matches.map((m) => {
          return m.linea_carrito;
        }),
        code: options.promoCode,
        type: OrderLineTypes.promotion,
      };
    } else if (oferta_aplicable.promoType === PromoTypes.Loyalty) {
      let temp_lines = [
        {
          lineNbr: 0,
          applicable: '',
          saleItemID: '',
          saleItemID2: '',
          saleItemID3: '',
          includedItemsID: '',
          excludedItemsID: '',
          chargeLine: 'N',
        },
      ];
      oferta_aplicable.lines = temp_lines;
      const matches = [];
      const points_max = [];
      lines.map((oa_line) => {
        const cod_articulo = oa_line.id || oa_line.saleItemID;
        let line_match = [];
        if (cod_articulo === this._HALVES_PRODUCT) {
          line_match = temp_lines.filter(() => {
            const aplicable1 =
              oa_line.points > 0 &&
              this._options.loyaltyPoints >= oa_line.points;
            const aplicable2 =
              oa_line.points > 0 &&
              this._options.loyaltyPoints >= oa_line.points;
            if (aplicable1 === true) {
              this._options.loyaltyPoints =
                this._options.loyaltyPoints - oa_line.points;
            }
            return aplicable1 && aplicable2;
          });
        } else {
          line_match = temp_lines.filter(() => {
            points_max.push(oa_line.points);
            const aplicable =
              oa_line.points > 0 &&
              this._options.loyaltyPoints >= oa_line.points;
            if (aplicable === true) {
              this._options.loyaltyPoints =
                this._options.loyaltyPoints - oa_line.points;
            }
            return aplicable;
          });
        }
        if (line_match.length > 0) {
          /*
              let precios_producto = {
                ...oa_line,
                ...this.calcular_precio_producto(oa_line.id || oa_line.saleItemID, oa_line.toppings)
              }
              */
          const precios_producto = oa_line;
          const precio_oferta =
            line_match[0].chargeLine === 'S'
              ? this.calcular_descuento(
                  precios_producto.precio_venta,
                  line_match[0].discountType,
                  line_match[0].discountAmount,
                )
              : 0;
          const price_discount = precios_producto.precio_venta - precio_oferta;
          const linea_carrito = {
            ...oa_line,
            ...precios_producto,
            price_discount: precio_oferta,
            amount_discount: price_discount,
          };
          matches.push({
            line_oferta: line_match[0],
            linea_carrito,
            product: oa_line,
            precio: precios_producto,
            precio_oferta: price_discount,
          });
          temp_lines = temp_lines.filter(
            (tl) => tl.lineNbr !== line_match[0].lineNbr,
          );
        } else {
          noMatches.push(oa_line);
        }
      });
      if (oferta_aplicable.lines.length !== matches.length) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `Not enough points`,
              PromotionExceptionTypes.NoEnoughPoints,
              oferta_aplicable.id,
            ),
          ],
        };
      }
      const precios = matches.map((m) => m.precio.precio_venta);
      //const precios_descuento = matches.map((m) => m.precio_oferta);
      lineas_precios = lineas_sin_oferta.map((m) => {
        return {
          ...m,
          ...this.calcular_precio_producto(m.saleItemID || m.id, m.toppings),
        };
      });
      const precio = precios.reduce((a, b) => a + b, 0);
      // const precio_oferta =
      //   oferta_aplicable.promoType === PromoTypes.Pack
      //     ? oferta_aplicable.salePrice
      //     : precios_descuento.reduce((a, b) => a + b, 0);
      oferta_aplicada = {
        id: promo,
        saleItemID: promo,
        discount: precio,
        unitPrice: 0,
        sublines: matches.map((m) => {
          return m.linea_carrito;
        }),
        code: options.promoCode,
        type: OrderLineTypes.promotion,
      };
    } else {
      //acumulable + pack
      let temp_lines = oferta_aplicable.lines;
      const matches = [];
      lines.map((oa_line) => {
        const cod_articulo = oa_line.id || oa_line.saleItemID;
        let line_match;
        let line_product;
        if (cod_articulo === this._HALVES_PRODUCT) {
          const art1 = this.buscar_articulo(oa_line.sublines[0].saleItemID);
          const art2 = this.buscar_articulo(oa_line.sublines[1].saleItemID);
          line_product = { art1, art2 };
          line_match = temp_lines.filter((sbl) => {
            const aplicable1 = this.isAplicable(sbl, art1, oa_line);
            const aplicable2 = this.isAplicable(sbl, art2, oa_line);
            return (
              aplicable1.product &&
              !aplicable1.exclude &&
              aplicable1.include &&
              !aplicable1.excluded_toppings &&
              aplicable1.include_topping &&
              aplicable2.product &&
              !aplicable2.exclude &&
              aplicable2.include &&
              !aplicable2.excluded_toppings &&
              aplicable2.include_topping
            );
          });
        } else {
          line_product = this.buscar_articulo(cod_articulo);
          line_match = temp_lines.filter((sbl) => {
            const aplicable = this.isAplicable(sbl, line_product, oa_line);
            return (
              aplicable.product &&
              !aplicable.exclude &&
              aplicable.include &&
              !aplicable.excluded_toppings &&
              aplicable.include_topping
            );
          });
        }
        if (line_match.length > 0) {
          /*
              let precios_producto = {
                ...oa_line,
                ...this.calcular_precio_producto(oa_line.id || oa_line.saleItemID, oa_line.toppings)
              }
              */
          const precios_producto = oa_line;
          const precio_oferta =
            line_match[0].chargeLine === 'S'
              ? this.calcular_descuento(
                  precios_producto.precio_venta,
                  line_match[0].discountType,
                  line_match[0].discountAmount,
                )
              : 0;
          const price_discount =
            line_match[0].chargeLine === 'S'
              ? precios_producto.precio_venta - precio_oferta
              : precio_oferta;
          const linea_carrito = {
            ...oa_line,
            ...precios_producto,
            price_discount,
            amount_discount: precio_oferta,
          };
          matches.push({
            line_oferta: line_match[0],
            linea_carrito,
            product: line_product,
            precio: precios_producto,
            precio_oferta: price_discount,
          });
          temp_lines = temp_lines.filter(
            (tl) => tl.lineNbr !== line_match[0].lineNbr,
          );
        } else {
          noMatches.push(oa_line);
        }
      });
      if (oferta_aplicable.lines.length !== matches.length) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `No match lines`,
              PromotionExceptionTypes.NoMatchLines,
              oferta_aplicable.id,
              temp_lines.map((l) => l.lineNbr).join(','),
            ),
          ],
        };
      }
      const precios = matches.map((m) => m.precio.precio_venta);
      lineas_precios = lineas_sin_oferta.map((m) => {
        return {
          ...m,
          ...this.calcular_precio_producto(m.saleItemID || m.id, m.toppings),
        };
      });
      const precios_descuento = matches.map((m) => m.precio_oferta);
      const precio = precios.reduce((a, b) => a + b, 0);
      const precio_oferta =
        oferta_aplicable.promoType === PromoTypes.Pack
          ? oferta_aplicable.salePrice
          : precios_descuento.reduce((a, b) => a + b, 0);
      const lines_oferta = matches.map((m) => {
        return m.linea_carrito;
      });
      if (oferta_aplicable.promoType === PromoTypes.Pack) {
        const descuento = precio - oferta_aplicable.salePrice;
        const descuento_linea = descuento / lines_oferta.length;
        lines_oferta.map((i) => {
          i['price_discount'] = i['unitPrice'] - descuento_linea;
          i['amount_discount'] = descuento_linea;
          return i;
        });
      }
      oferta_aplicada = {
        id: promo,
        saleItemID: promo,
        discount: precio - precio_oferta,
        unitPrice: precio_oferta,
        sublines: lines_oferta,
        code: options.promoCode,
        type: OrderLineTypes.promotion,
      };
    }
    if (oferta_aplicable.minimumOrderAmount > 0) {
      let precio_total = lineas_oferta
        .concat(lineas_precios)
        .map((l) => l.unitPrice)
        .reduce((a, b) => a + b);
      try {
        if (
          oferta_aplicable.promoType === PromoTypes.CrossSales &&
          oferta_aplicada !== null &&
          'unitPrice' in oferta_aplicada &&
          'discount' in oferta_aplicada
        ) {
          // precio_total -= (oferta_aplicada.unitPrice + oferta_aplicada.discount)
          precio_total -=
            oferta_aplicada.discount > 0
              ? oferta_aplicada.discount
              : oferta_aplicada.unitPrice;
        }
      } catch (e) {}
      const linesAlreadyAdded = lineas_oferta.reduce((result, l) => {
        const promo = this.promotions.find((p) => p.id === l.saleItemID);
        if (
          promo &&
          'minimumOrderAmount' in promo &&
          promo.minimumOrderAmount > 0 &&
          promo.promoType === PromoTypes.CrossSales
        ) {
          const line = JSON.parse(JSON.stringify(l));
          line['minimumOrderAmount'] = promo.minimumOrderAmount;
          result.push(line);
        }
        return result;
      }, []);
      if (
        linesAlreadyAdded.length > 0 &&
        oferta_aplicable.promoType === PromoTypes.CrossSales
      ) {
        precio_total -= linesAlreadyAdded.reduce(
          (result, l) => result + l.minimumOrderAmount,
          0,
        );
      }
      if (precio_total < oferta_aplicable.minimumOrderAmount) {
        return {
          discarded: full_lines,
          promo: null,
          complete: full_lines,
          errors: [
            new PromotionException(
              `Min amount not reached`,
              PromotionExceptionTypes.MinimumOrderAmount,
              promo,
              oferta_aplicable.minimumOrderAmount,
            ),
          ],
        };
      }
    }
    lineas_oferta.push(oferta_aplicada);
    oferta_aplicada.tax = tax;
    return {
      discarded: noMatches,
      complete: noMatches.concat(lineas_oferta),
      promo: oferta_aplicada,
      errors: [],
    };
  }
  calcular_precio_money_back(promotion, order, payment, date) {
    let moneyBack = 0;
    if (promotion.minimumOrderAmount <= payment.amount) {
      const isAplicable = this.isAplicableHours(date, { ...promotion });
      if (isAplicable) {
        moneyBack = this.calcular_descuento(
          payment.amount,
          promotion.byPrice,
          promotion.salePrice,
        );
      }
    }
    return moneyBack;
  }
  auto(lines, options?: any, extraPromotions?: PromoInterface[]) {
    let lines_with_price = lines.map((l) => {
      if (l.saleItemID === this._HALVES_PRODUCT) {
        return {
          ...l,
          ...this.calcular_precio_compuesto(
            options,
            {
              saleItemID: l.sublines[0].saleItemID,
              toppings: l.sublines[0].toppings,
            },
            {
              saleItemID: l.sublines[1].saleItemID,
              toppings: l.sublines[1].toppings,
            },
          ),
        };
      } else {
        try {
          return {
            ...l,
            ...this.calcular_precio_producto(
              l.saleItemID || l.id,
              l.toppings,
              options,
            ),
          };
        } catch (e) {
          //console.error(e)
        }
      }
    });
    lines_with_price = lines_with_price.filter((l) => l !== undefined);
    if (lines_with_price.length < 1) {
      console.error('No hay lineas para calcular');
      return;
    }
    const lines_ordered = lines_with_price.sort(
      (a, b) => b.precio_venta - a.precio_venta,
    );
    let temp_lines = JSON.parse(JSON.stringify(lines_ordered));
    const available_promotions = this.promotions.filter(
      (p) => p.manuallyAdded === 'N',
    );
    if (
      extraPromotions &&
      Array.isArray(extraPromotions) &&
      extraPromotions.length > 0
    ) {
      available_promotions.push(...extraPromotions);
    }
    const aplicables = [];
    available_promotions.forEach((p) => {
      const aplicada = this.calcular_precio_promo(p.id, lines_ordered, options);
      if (aplicada.promo) {
        let temp_lines = aplicada.discarded;
        let aplicaciones = 1;
        let c = true;
        while (aplicada.discarded.length >= p.lines.length && c) {
          const ap = this.calcular_precio_promo(p.id, temp_lines, options);
          if (ap.promo) {
            aplicaciones++;
            temp_lines = ap.discarded;
          } else {
            c = false;
          }
        }
        p.aplicaciones = aplicaciones;
        p.descuento = aplicada.promo.discount;
        aplicables.push(p);
      }
    });
    // let descuentos_aplicables = aplicables.filter(a => a.promoType === PromoTypes.Descuento )
    // aplicables = aplicables.filter(a => a.promoType !== PromoTypes.Descuento )
    aplicables.sort(
      (a, b) => b.descuento * b.aplicaciones - a.descuento * a.aplicaciones,
    );
    const result = [];
    aplicables.forEach((p) => {
      const aplicada = this.calcular_precio_promo(
        p.id,
        temp_lines.concat(result),
        options,
      );
      if (aplicada.promo) {
        result.push(aplicada.promo);
        temp_lines = aplicada.discarded;
        //const aplicaciones = 1;
        let c = true;
        // while(aplicada.discarded.length >= p.lines.length && c){
        while (
          p.promoType === PromoTypes.Descuento
            ? aplicada.discarded.length > p.lines.length && c
            : aplicada.discarded.length >= p.lines.length && c
        ) {
          const ap = this.calcular_precio_promo(
            p.id,
            temp_lines.concat(result),
            options,
          );
          if (ap.promo) {
            result.push(ap.promo);
            temp_lines = ap.discarded;
          } else {
            c = false;
          }
        }
      }
    });
    // descuentos_aplicables.forEach(p => {
    //   let aplicada = this.calcular_precio_promo(p.id, temp_lines.concat(result), options)
    //   if(aplicada.promo){
    //     result.push(aplicada.promo)
    //     temp_lines = aplicada.discarded
    //     let aplicaciones = 1
    //     let c = true
    //     while(aplicada.discarded.length > p.lines.length && c){
    //         let ap = this.calcular_precio_promo(p.id, temp_lines.concat(result), options)
    //         if(ap.promo){
    //           result.push(ap.promo)
    //           temp_lines = ap.discarded
    //         }else{
    //           c = false
    //         }
    //     }
    //   }
    // })
    temp_lines.map((tl) => {
      let line;
      if (tl.saleItemID === this._HALVES_PRODUCT) {
        line = this.calcular_precio_compuesto(
          options,
          {
            saleItemID: tl.sublines[0].saleItemID,
            toppings: tl.sublines[0].toppings,
          },
          {
            saleItemID: tl.sublines[1].saleItemID,
            toppings: tl.sublines[1].toppings,
          },
        );
      } else {
        line = this.calcular_precio_producto(
          tl.saleItemID || tl.id,
          tl.toppings,
        );
      }
      result.push({ ...line, ...tl });
    });
    return result;
  }
  recalcLines(lines, options) {
    let count = 0;
    const discarded_lines: any[] = [];
    let new_lines = lines.map((l) => {
      let line;
      switch (l.type) {
        case OrderLineTypes.composed:
          try {
            line = {
              ...l,
              ...this.calcular_precio_compuesto(
                options,
                {
                  saleItemID: l.sublines[0].id || l.sublines[0].saleItemID,
                  toppings: l.sublines[0].toppings || [],
                },
                {
                  saleItemID: l.sublines[1].id || l.sublines[1].saleItemID,
                  toppings: l.sublines[1].toppings || [],
                },
              ),
            };
            line.sublines[0]['lineNbr'] = count++;
            line.sublines[1]['lineNbr'] = count++;
            return line;
          } catch (e) {
            return null;
          }
        case OrderLineTypes.promotion:
          try {
            line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines,
              options,
            );
            if (line.errors.length === 0) {
              line.promo['lineNbr'] = count++;
              let c = 0;
              line.promo.code = l.code;
              line.promo.sublines = line.promo.sublines.map((sl) => {
                sl.orderLineNbr = c++;
                return sl;
              });
              return line.promo;
            } else {
              discarded_lines.push(...l.sublines);
              return null;
            }
          } catch (e) {
            discarded_lines.push(...l.sublines);
            return null;
          }
        case OrderLineTypes.crossSales:
          try {
            line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines,
              options,
            );
            if (line.errors.length === 0) {
              line.promo['lineNbr'] = count++;
              let c = 0;
              line.promo.code = l.code;
              line.promo.sublines = line.promo.sublines.map((sl) => {
                sl.orderLineNbr = c++;
                return sl;
              });
              return line.promo;
            } else {
              discarded_lines.push(...l.sublines);
              return null;
            }
          } catch (e) {
            discarded_lines.push(...l.sublines);
            return null;
          }
        default:
          try {
            line = {
              ...l,
              ...this.calcular_precio_producto(
                l.id || l.saleItemID,
                l.toppings,
                options,
              ),
            };
            line['lineNbr'] = count++;
            return line;
          } catch (e) {
            return null;
          }
      }
    });
    new_lines = new_lines
      .filter((l) => l !== null)
      .concat(
        discarded_lines.map((value) => {
          const calculatedLine = {
            ...value,
            ...this.calcular_precio_producto(
              value.id || value.saleItemID,
              value.toppings,
              options,
            ),
          };
          calculatedLine['lineNbr'] = count++;
          return calculatedLine;
        }),
      );
    return new_lines;
  }
  checkLines(lines, options) {
    let count = 0;
    const errors: any[] = [];
    // let acu_lines : any[] = []
    const full_lines: any[] = [];
    const new_lines = lines.map((l) => {
      let line;
      switch (l.type) {
        case OrderLineTypes.composed:
          try {
            if ('lines' in l && l.sublines === undefined) {
              l.sublines = l.lines;
            }
            line = this.calcular_precio_compuesto(
              options,
              {
                saleItemID: l.sublines[0].id || l.sublines[0].saleItemID,
                toppings: l.sublines[0].toppings || [],
              },
              {
                saleItemID: l.sublines[1].id || l.sublines[1].saleItemID,
                toppings: l.sublines[1].toppings || [],
              },
            );
            if (line !== null) {
              line.sublines[0]['lineNbr'] = count++;
              line.sublines[1]['lineNbr'] = count++;
            }
            if ('comments' in l) {
              line.comments = l.comments;
            }
            // acu_lines.push(line)
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          full_lines.push(line);
          return line;
        case OrderLineTypes.promotion:
          try {
            options.promoCode = l.code;
            line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines.concat(full_lines),
              options,
            );
            if (
              'promo' in line &&
              line.promo !== null &&
              line.promo !== undefined
            ) {
              line.promo['lineNbr'] = count++;
              let c1 = 0;
              line.promo.code = l.code;
              line.promo.sublines = line.promo.sublines.map((sl) => {
                if (sl.type === OrderLineTypes.composed) {
                  sl.sublines = sl.sublines.map((sll) => {
                    sll.orderLineNbr = c1++;
                    return sll;
                  });
                }
                sl.orderLineNbr = c1++;
                return sl;
              });
            }
            //  acu_lines = line.complete[0].sublines
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          full_lines.push(line.promo);
          return line.promo;
        case OrderLineTypes.crossSales:
          try {
            line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines.concat(full_lines),
              options,
            );
            if ('promo' in line && line.promo !== null) {
              line.promo['lineNbr'] = count++;
              let c2 = 0;
              line.promo.code = l.code;
              line.promo.sublines = line.promo.sublines.map((sl) => {
                sl.orderLineNbr = c2++;
                return sl;
              });
            }
            // acu_lines = line.complete
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          full_lines.push(line.promo);
          return line.promo;
        default:
          try {
            line = this.calcular_precio_producto(
              l.id || l.saleItemID,
              l.toppings,
              options,
            );
            if (line !== null) {
              line['lineNbr'] = count++;
            }
            if ('comments' in l) {
              line.comments = l.comments;
            }
            // acu_lines.push(line)
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          full_lines.push(line);
          return line;
      }
    });
    return {
      index: count,
      lines: new_lines,
      errors,
    };
  }
  checkErrorLines(lines, options) {
    const errorPromoLines: any[] = [];
    const errors: any[] = [];
    const full_lines: any[] = [];
    const productTypes: OrderLineTypes[] = [
      OrderLineTypes.composed,
      OrderLineTypes.simple,
    ];
    lines.sort((a, b) => {
      if (productTypes.includes(a.type)) {
        return -1;
      } else if (productTypes.includes(b.type)) {
        return 1;
      }
      return 0;
    });
    lines.forEach((l) => {
      switch (l.type) {
        case OrderLineTypes.composed:
          try {
            if ('lines' in l && l.sublines === undefined) {
              l.sublines = l.lines;
            }
            const line = {
              ...l,
              ...this.calcular_precio_compuesto(
                options,
                {
                  saleItemID: l.sublines[0].id || l.sublines[0].saleItemID,
                  toppings: l.sublines[0].toppings || [],
                },
                {
                  saleItemID: l.sublines[1].id || l.sublines[1].saleItemID,
                  toppings: l.sublines[1].toppings || [],
                },
              ),
            };
            full_lines.push(line);
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          break;
        case OrderLineTypes.promotion:
          try {
            options.promoCode = l.code;
            const line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines.concat(JSON.parse(JSON.stringify(full_lines))),
              options,
            );
            if (line.errors.length === 0) {
              full_lines.push(line.promo);
            } else {
              errorPromoLines.push(l);
            }
          } catch (e) {
            errors.push(e);
          }
          break;
        case OrderLineTypes.crossSales:
          try {
            const line = this.calcular_precio_promo(
              l.id || l.saleItemID,
              l.sublines.concat(JSON.parse(JSON.stringify(full_lines))),
              options,
            );
            if (line.errors.length === 0) {
              full_lines.push(line.promo);
            } else {
              errorPromoLines.push(l);
            }
          } catch (e) {
            errors.push(e);
          }
          break;
        default:
          try {
            const line = {
              ...l,
              ...this.calcular_precio_producto(
                l.id || l.saleItemID,
                l.toppings,
                options,
              ),
            };
            full_lines.push(line);
            errors.push(...line.errors);
          } catch (e) {
            errors.push(e);
          }
          break;
      }
    });
    if (errorPromoLines.length > 0) {
      errorPromoLines.forEach((l) => {
        switch (l.type) {
          case OrderLineTypes.promotion:
            try {
              options.promoCode = l.code;
              const line = this.calcular_precio_promo(
                l.id || l.saleItemID,
                l.sublines.concat(JSON.parse(JSON.stringify(full_lines))),
                options,
              );
              if (line.errors.length === 0) {
                full_lines.push(line.promo);
              } else {
                errors.push(...line.errors);
              }
            } catch (e) {
              errors.push(e);
            }
            break;
          case OrderLineTypes.crossSales:
            try {
              const line = this.calcular_precio_promo(
                l.id || l.saleItemID,
                l.sublines.concat(JSON.parse(JSON.stringify(full_lines))),
                options,
              );
              if (line.errors.length === 0) {
                full_lines.push(line.promo);
              } else {
                errors.push(...line.errors);
              }
            } catch (e) {
              errors.push(e);
            }
            break;
        }
      });
    }
    return errors;
  }
  checkReorderLines(
    reorderLines: CalculatedLinesResponse[],
    options: (ProductOptions | PromoOptions)
  ) {
    const errors: any[] = [];
    const lines: CalculatedLinesResponse[] = [];
    const productTypes: OrderLineTypes[] = [
      OrderLineTypes.composed,
      OrderLineTypes.simple,
    ]
    reorderLines
      .sort((a, b) => {
        if (productTypes.includes(a.type)) {
          return -1;
        } else if (productTypes.includes(b.type)) {
          return 1;
        }
        return 0;
      })
      .forEach(
        l => {
          switch (l.type) {
            case OrderLineTypes.composed:
              try {
                const line = this.calcular_precio_compuesto(
                  options,
                  {
                    saleItemID: l.sublines[0].saleItemID,
                    toppings: l.sublines[0].toppings || [],
                  },
                  {
                    saleItemID: l.sublines[1].saleItemID,
                    toppings: l.sublines[1].toppings || [],
                  },
                );
                if (line.errors.length === 0) {
                  lines.push(line);
                } else {
                  errors.push(
                    {
                      "exceptions": line.errors,
                      "line": l
                    }
                  );
                }
              } catch (e) {
                errors.push(
                  {
                    "exceptions": (Array.isArray(e) ? e : [e]),
                    "line": l
                  }
                );
              }
              break
            case OrderLineTypes.crossSales:
            case OrderLineTypes.promotion:
              try {
                const line = this.calcular_precio_promo(
                  l.saleItemID,
                  l.sublines.concat(JSON.parse(JSON.stringify(lines))),
                  options,
                );
                if (line.promo !== null && line.errors.length === 0) {
                  lines.push(line.promo);
                } else {
                  errors.push(
                    {
                      "exceptions": line.errors,
                      "line": l
                    }
                  );
                }
              } catch (e) {
                errors.push(
                  {
                    "exceptions": (Array.isArray(e) ? e : [e]),
                    "line": l
                  }
                );
              }
              break
            default:
              try {
                const line = this.calcular_precio_producto(
                  l.saleItemID,
                  ((l as ProductCalculatedResponse).toppings as string[]),
                  options,
                );
                if (line.errors.length === 0) {
                  lines.push(line);
                } else {
                  errors.push(
                    {
                      "exceptions": line.errors,
                      "line": l
                    }
                  );
                }
              } catch (e) {
                errors.push(
                  {
                    "exceptions": (Array.isArray(e) ? e : [e]),
                    "line": l
                  }
                );
              }
              break
          }
        }
      )
    return {
      errors,
      lines,
    }
  }
  cleanLines(lines) {
    const new_lines = lines.map((l) => {
      return {
        saleItemID: l.saleItemID || l.id,
        ...('toppings' in l &&
          l.type !== OrderLineTypes.composed && { toppings: l.toppings }),
        ...('sublines' in l && { sublines: this.cleanLines(l.sublines) }),
        ...('type' in l && { type: l.type }),
        ...('code' in l && { code: l.code }),
        ...('points' in l && l.points > 0 && { points: l.points }),
        ...('quantity' in l && { quantity: l.quantity }),
        ...('comments' in l && { comments: l.comments }),
        ...('promoClientID' in l && { promoClientID: l.promoClientID }),
      };
    });
    return new_lines;
  }
  compareLines(l1, l2) {
    const l1Comments: string = l1 && 'comments' in l1 ? l1.comments : null;
    const l2Comments: string = l2 && 'comments' in l2 ? l2.comments : null;
    const separator: string = ';';
    return (
      l1.saleItemID === l2.saleItemID &&
      l1.type === l2.type &&
      l1.toppings.join(separator) === l2.toppings.join(separator) &&
      l1.unitPrice === l2.unitPrice &&
      l1Comments === l2Comments
    );
  }
  comparePromoLines(p1, p2) {
    const p1Code: string =
      p1 && 'code' in p1 && typeof p1.code === 'string' && p1.code.length > 0
        ? p1.code
        : null;
    const p2Code: string =
      p2 && 'code' in p2 && typeof p2.code === 'string' && p2.code.length > 0
        ? p2.code
        : null;
    let result = true;
    if (
      p1 &&
      p2 &&
      p1.id === p2.id &&
      p1.type === p2.type &&
      p1.unitPrice === p2.unitPrice &&
      p1.sublines.length === p2.sublines.length &&
      p1Code === null &&
      p2Code === null
    ) {
      for (let i = 0; i < p1.sublines.length; i++) {
        const p1s = p1.sublines[i];
        const p2s = p2.sublines[i];
        if (!this.compareLines(p1s, p2s)) {
          result = false;
          break;
        }
      }
    } else {
      result = false;
    }
    return result;
  }
  disengageLines(lines) {
    const new_lines = lines.reduce((result, l) => {
      const line = JSON.parse(JSON.stringify(l));
      line.quantity = 1;
      const qty = l.quantity || 1;
      for (let i = 0; i < qty; i++) {
        result.push(line);
      }
      return result;
    }, []);
    return new_lines;
  }
  engageLines(lines, engagePromotions = true) {
    const promotionTypes: OrderLineTypes[] = [
      OrderLineTypes.crossSales,
      OrderLineTypes.promotion,
    ];
    const new_lines = lines.reduce((result, l) => {
      const line = JSON.parse(JSON.stringify(l));
      const isPromotion = promotionTypes.indexOf(line.type) >= 0;
      const position = result.reduce((r, v, i) => {
        if (line.type === v.type) {
          if (isPromotion) {
            if (this.comparePromoLines(line, v) && engagePromotions) {
              r = i;
            }
          } else {
            if (this.compareLines(line, v)) {
              r = i;
            }
          }
        }
        return r;
      }, -1);
      if (position >= 0) {
        result[position].quantity += 1;
      } else {
        line.quantity = 1;
        result.push(line);
      }
      return result;
    }, []);
    return new_lines;
  }
  // Wizard
  checkPromoLineItems(items: string): string[] {
    return items && items.length > 0 ? items.split(';').filter(Boolean) : [];
  }
  getProductsFromPromoLine(
    products: MenuFamilyProductInterface[],
    promoLine: PromoLineInterface,
  ): MenuFamilyProductInterface[] {
    const result: MenuFamilyProductInterface[] = [];
    const excludedItems = this.checkPromoLineItems(promoLine.excludedItemsID);
    const excludedToppings = this.checkPromoLineItems(
      promoLine.excludedToppingsID,
    );
    const includedItems = this.checkPromoLineItems(promoLine.includedItemsID);
    const includedToppings = this.checkPromoLineItems(
      promoLine.includedToppingsID,
    );
    products.forEach((value) => {
      let canAdd: boolean = true;
      // Checking items
      if (
        excludedItems.length > 0 &&
        (excludedItems.indexOf(value.id) >= 0 ||
          excludedItems.indexOf(value.erpID) >= 0)
      ) {
        canAdd = false;
      }
      if (
        includedItems.length > 0 &&
        includedItems.indexOf(value.id) < 0 &&
        includedItems.indexOf(value.erpID) < 0
      ) {
        canAdd = false;
      }
      // Checking toppings
      if (
        value.hasOwnProperty('recipe') &&
        Array.isArray(value.recipe) &&
        value.recipe.length > 0
      ) {
        value.recipe.forEach((v) => {
          if (
            excludedToppings.length > 0 &&
            excludedToppings.indexOf(v.invSaleItemID) >= 0
          ) {
            canAdd = false;
          }
          if (
            includedToppings.length > 0 &&
            includedToppings.indexOf(v.invSaleItemID) < 0
          ) {
            canAdd = false;
          }
        });
      }
      if (canAdd === true) {
        result.push(value);
      }
    });
    return result;
  }
  getProductPriceFromPromo(
    promoLine: PromoLineInterface,
    product: MenuFamilyProductInterface,
    options: any,
  ) {
    if (promoLine.chargeLine != null && promoLine.chargeLine == 'N') {
      return 0;
    }
    const dto_amount =
      promoLine.discountAmount == null ? 0 : promoLine.discountAmount;
    const dto_type = promoLine.discountType;
    const sale_price = this.getPriceProduct(options.orderType, product);
    if (dto_type == PromoDetailUnityDiscount.fix) {
      if (dto_amount < 0) {
        return 0;
      } else {
        return dto_amount;
      }
    }
    if (dto_type == PromoDetailUnityDiscount.percentage) {
      const price_dto = sale_price - (sale_price / 100) * dto_amount;
      if (price_dto < 0) {
        return 0;
      } else {
        return price_dto;
      }
    }
    if (dto_type == PromoDetailUnityDiscount.money) {
      const price_dto = sale_price - dto_amount;
      if (price_dto < 0) {
        return 0;
      } else {
        return price_dto;
      }
    }
    return 0;
  }
  setProductPrice(
    product: MenuFamilyProductInterface,
    salePrice: number,
    options: any,
  ) {
    product.salePrice = salePrice;
    if (options.orderType == OrderTypes.Recoger) {
      product.salePricePUOnline = salePrice;
    } else if (options.orderType == OrderTypes.Domicilio) {
      product.salePriceDEOnline = salePrice;
    }
  }
  applyProductsPriceAggregator(options: any) {
    this.menu.forEach((family: MenuFamilyInterface) => {
      const familyToppings: MenuFamilyToppingInterface[] = family.toppings;
      family.products.forEach((product: MenuFamilyProductInterface) => {
        if (product.recipe.length > 0) {
          const recipe: MenuFamilyProductToppingInterface[] = product.recipe;
          recipe.forEach((topping) => {
            if (topping.charge == 'S' && topping.required == 'N') {
              const toppingFamily = familyToppings.filter(
                (toppingF) => toppingF.id == topping.invSaleItemID,
              )[0];
              const toppingPrice = this.getToppingPrice(
                options.orderType,
                toppingFamily,
              );
              if (toppingPrice > 0) {
                const price = parseFloat(
                  (
                    this.getPriceProduct(options.orderType, product) +
                    toppingPrice
                  ).toFixed(2),
                );
                this.setProductPrice(product, price, options);
              }
            }
          });
        }
      });
    });
  }
  removeWhiteSpaces(array) {
    const res = array.filter(function (str) {
      return /\S/.test(str);
    });
    return res;
  }
  applyPromoPriceOverrideAggregator(options: any) {
    const promotions = this.promotions.filter(
      (promo: PromoInterface) => promo.promoType === PromoTypes.Multiple,
    );
    promotions.forEach((promo: PromoInterface) => {
      promo.lines.forEach((promoLine: PromoLineInterface) => {
        const applicable = promoLine.applicable;
        const excludedItems = this.checkPromoLineItems(
          promoLine.excludedItemsID,
        ).filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        const includedItems = this.checkPromoLineItems(
          promoLine.includedItemsID,
        ).filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        const saleItemID = this.removeWhiteSpaces([
          ...new Set(promoLine.saleItemID.split(';')),
        ]);
        const saleItemID2 = promoLine.saleItemID2;
        const saleItemID3 = promoLine.saleItemID3;
        const items_ = [saleItemID2, saleItemID3];
        saleItemID.forEach((cod) => {
          items_.push(cod);
        });
        const saleItemsIDS = items_.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.menu.forEach((family: MenuFamilyInterface) => {
          family.products.forEach((product: MenuFamilyProductInterface) => {
            const product_id = product.id;
            const family_id = family.id;
            const majorGroupID = family.majorGroupID;
            if (
              applicable == PromoDetailApplicableTo.family ||
              applicable == PromoDetailApplicableTo.saleItem
            ) {
              if (
                applicable == PromoDetailApplicableTo.family &&
                saleItemID.includes(String(family_id))
              ) {
                if (includedItems.length > 0) {
                  if (
                    includedItems.includes(product_id) &&
                    !excludedItems.includes(product_id)
                  ) {
                    const salePrice = this.getProductPriceFromPromo(
                      promoLine,
                      product,
                      options,
                    );
                    this.setProductPrice(product, salePrice, options);
                  }
                } else {
                  if (excludedItems.length > 0) {
                    if (!excludedItems.includes(product_id)) {
                      const salePrice = this.getProductPriceFromPromo(
                        promoLine,
                        product,
                        options,
                      );
                      this.setProductPrice(product, salePrice, options);
                    }
                  } else {
                    const salePrice = this.getProductPriceFromPromo(
                      promoLine,
                      product,
                      options,
                    );
                    this.setProductPrice(product, salePrice, options);
                  }
                }
              } else if (
                applicable == PromoDetailApplicableTo.saleItem &&
                saleItemID.includes(product_id)
              ) {
                const salePrice = this.getProductPriceFromPromo(
                  promoLine,
                  product,
                  options,
                );
                this.setProductPrice(product, salePrice, options);
              }
            } else if (applicable == PromoDetailApplicableTo.group) {
              if (saleItemID.includes(String(majorGroupID))) {
                if (includedItems.length > 0) {
                  if (
                    includedItems.includes(product_id) &&
                    !excludedItems.includes(product_id)
                  ) {
                    const salePrice = this.getProductPriceFromPromo(
                      promoLine,
                      product,
                      options,
                    );
                    this.setProductPrice(product, salePrice, options);
                  }
                } else {
                  if (excludedItems.length > 0) {
                    if (!excludedItems.includes(product_id)) {
                      const salePrice = this.getProductPriceFromPromo(
                        promoLine,
                        product,
                        options,
                      );
                      this.setProductPrice(product, salePrice, options);
                    }
                  } else {
                    const salePrice = this.getProductPriceFromPromo(
                      promoLine,
                      product,
                      options,
                    );
                    this.setProductPrice(product, salePrice, options);
                  }
                }
              }
            } else if (applicable == PromoDetailApplicableTo.multiple) {
              if (saleItemsIDS.includes(String(family_id))) {
                if (saleItemID.includes(String(majorGroupID))) {
                  if (includedItems.length > 0) {
                    if (
                      includedItems.includes(product_id) &&
                      !excludedItems.includes(product_id)
                    ) {
                      const salePrice = this.getProductPriceFromPromo(
                        promoLine,
                        product,
                        options,
                      );
                      this.setProductPrice(product, salePrice, options);
                    }
                  } else {
                    if (excludedItems.length > 0) {
                      if (!excludedItems.includes(product_id)) {
                        const salePrice = this.getProductPriceFromPromo(
                          promoLine,
                          product,
                          options,
                        );
                        this.setProductPrice(product, salePrice, options);
                      }
                    } else {
                      const salePrice = this.getProductPriceFromPromo(
                        promoLine,
                        product,
                        options,
                      );
                      this.setProductPrice(product, salePrice, options);
                    }
                  }
                }
              }
            }
          });
        });
      });
    });
  }
}
