import { Component, ElementRef, OnInit, ViewChild,Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-resent-link',
  templateUrl: './resent-link.component.html',
  styleUrls: ['./resent-link.component.scss'],
})
export class ResentLinkComponent implements OnInit {


  @Input() type: string;
  @Output() resend ;

  countdownTime: string;
  secondsCountdown: number;
  totalTime: number;
  tempsDividit: number;
  divisionesTiempo:number = 4;

  resta: number;


  numVuelta: number;

  bottonEnabled: boolean = false;



  constructor() {
    this.resend = new EventEmitter();

  }

  ngOnInit() {
    this.resetTiempo();

  }

  resetTiempo() {
    let that = this;

    that.secondsCountdown = 60;
    that.totalTime = 60;

    that.bottonEnabled = false;

    that.tempsDividit = that.secondsCountdown / that.divisionesTiempo;
    var vueltas = 1;
    that.numVuelta = vueltas;
    that.resta = that.totalTime - that.tempsDividit;
  
    var intervalCountdown = setInterval(function () {

      that.secondsCountdown--;

      if(that.secondsCountdown > that.resta) {
        that.numVuelta = 1;
      }

      if((that.secondsCountdown > that.resta - that.tempsDividit) && (that.secondsCountdown < that.resta)) {
        that.numVuelta = 2;
      }

      if((that.secondsCountdown > that.resta - that.tempsDividit * 2) && (that.secondsCountdown < that.resta - that.tempsDividit)) {
        that.numVuelta = 3;
      }

      if((that.secondsCountdown > that.resta - that.tempsDividit * 3) && (that.secondsCountdown < that.resta - that.tempsDividit * 2)) {
        that.numVuelta = 4;
      }

      if(that.secondsCountdown == 0) {
        that.numVuelta = 5;
        
        that.bottonEnabled = true;
        clearInterval(intervalCountdown);
      }

      let tiempo = Number(that.secondsCountdown);
    
      var h = Math.floor(tiempo / 3600);
      var m = Math.floor(tiempo % 3600 / 60);
      var s = Math.floor(tiempo % 3600 % 60);

      var hDisplay = h > 0 ? (h < 10 ? ('0' + h + ':') : (h + ':')) : "";
      var mDisplay = m < 10 ? ('0' + m + ':') : (m + ':');
      var sDisplay = s < 10 ? ('0' + s) : s;

      that.countdownTime = hDisplay + mDisplay + sDisplay;

    }, 1000);
  
  }

  public resendType(){
    this.resend.emit({type:this.type});

  }

}
