import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalsComponent } from '@core/components/modals/modals.component';
import { LangService } from '@services/lang.service';
import { MenuFamilyInterface, PromoInterface } from '@lib/promo-engine';
import { CustomErrorManager } from '@core/error-codes';
import { UncompletedLinesInterface } from '@core/interfaces/services';
import {
  MenuCustomToppingGroupInterface,
  MenuFamilyCustomToppingInterface
} from '@lib/utils';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private readonly modalController: ModalController,
    private readonly alertController: AlertController,
    private readonly langService: LangService
  ) { }
  dismissModal<T = any>(items?: T): void {
    this.modalController.dismiss(items, '', 'customizer')
  }
  // Open Modals
  openModal(modalName: string, modalMsg: string, dismissBackDrop: boolean = true, customParams: object = {}): Promise<HTMLIonModalElement> {
    return this.modalController.create(
      {
        backdropDismiss: dismissBackDrop,
        cssClass: 'auto-height',
        component: ModalsComponent,
        componentProps: {
          customMsg: modalMsg,
          customParams: customParams,
          templateName: modalName
        },
        id: modalName
      }
    )
  }
  async openModalStartOrder(dismissBackDrop: boolean = true): Promise<HTMLIonModalElement> {
    const modalName: string = 'startOrder'
    return new Promise(
      async resolve => {
        const modal: HTMLIonModalElement = await this.openModal(modalName, '', dismissBackDrop)
        await modal.present()
        resolve(modal)
      }
    )
  }
  async openModalWithCustomMsg(modalMsg: string, errorCode: number = null, dismissBackDrop: boolean = true, data: any = {}): Promise<any> {
    const modalName: string = 'customMsg'
    if (errorCode) {
      const errorManager = new CustomErrorManager(errorCode)
      modalMsg = errorManager.getErrorMsg()
    }
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, dismissBackDrop, data)
    await modal.present()
    return modal.onWillDismiss()
  }
  async openModalWithCustomMsgNdChooseOption(errorCode: number, toppingDescription: string): Promise<any> {
    const modalName: string = 'customMsgNdChooseOption'
    const errorManager = new CustomErrorManager(errorCode)
    const modalMsg: string = errorManager.getErrorMsg()
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, false, {
      value: toppingDescription
    })
    await modal.present()
    return modal.onWillDismiss()
  }
  async openModaAddresses(modalMsg: string, addresses: Array<{}>): Promise<any> {
    const modalName: string = 'deliveryAddresses'
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, true, {
      value: addresses
    })
    await modal.present()
    return modal.onWillDismiss()
  }
  async openModalWithCustomMsgNdChooseOrderby(modalMsg: string, toppingDescription: string): Promise<any> {
    const modalName: string = 'customMsgNdChooseOrderBy'
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, true, {
      value: toppingDescription
    })
    await modal.present()
    return modal.onWillDismiss()
  }
  async openModalPromoCode(dismissBackDrop: boolean = true): Promise<any> {
    const modalName: string = 'promoCode'
    const modal: HTMLIonModalElement = await this.openModal(modalName, '', dismissBackDrop)
    await modal.present()
    return modal.onWillDismiss()
  }
  async openModalCheckBranches(dismissBackDrop: boolean = true, keyString, data: any): Promise<any> {
    const modalName: string = 'checkBranches';
    const modal: HTMLIonModalElement = await this.openModal(modalName, keyString, dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalChooseDough(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'chooseDough';
    const modal: HTMLIonModalElement = await this.openModal(modalName, '', dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalChooseCreditCard(dismissBackDrop: boolean = true, keyString, data: any): Promise<any> {
    const modalName: string = 'chooseCreditCard';
    const modal: HTMLIonModalElement = await this.openModal(modalName, keyString, dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalCustomizer(family: MenuFamilyInterface, component: any): Promise<any> {
    const modalCssClass: string = (family.toppings.length === 0) ? 'customizerModalNoCustomizable' : 'customizerModal'
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component,
      cssClass: modalCssClass,
      mode: 'ios',
      swipeToClose: true,
      id: 'customizer'
    })
    await modal.present()
    return modal.onDidDismiss()
  }

  async openModalSettingsLocation(dismissBackDrop: boolean = true, keyString, data: any): Promise<any> {
    const modalName: string = 'openSettingsLocation';
    const modal: HTMLIonModalElement = await this.openModal(modalName, keyString, dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalOutOfStock(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'outOfStock';
    const modal: HTMLIonModalElement = await this.openModal(modalName, "LANG_OPS_OUT_OF_STOCK", dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalExcludedItems(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'excludedItems';
    const modal: HTMLIonModalElement = await this.openModal(modalName, "LANG_OPS_OUT_OF_STOCK", dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalErrorsInCart(errorCodes: UncompletedLinesInterface[], dismissBackDrop: boolean = true): Promise<any> {
    const modalName: string = 'errorCodesFromCart'
    const errorManager = new CustomErrorManager(null)
    const modal: HTMLIonModalElement = await this.openModal(modalName, 'LANG_OPS_OUT_OF_STOCK', dismissBackDrop, {
      errorCodes: errorManager.updateUncompletedLinesFromCart(errorCodes)
    })
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalDeliverToMyCar(dismissBackDrop: boolean = true): Promise<any> {
    const modalName: string = 'deliverToMyCar';
    const modal: HTMLIonModalElement = await this.openModal(modalName, '', dismissBackDrop)
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalHours(modalMsg: string, hours: Array<{}>): Promise<any> {
    const modalName: string = 'hours'
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, true, {
      value: hours
    })
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalNoPhoneValidated(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'noPhoneValidated';
    const modal: HTMLIonModalElement = await this.openModal(modalName, data, dismissBackDrop);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalNutritionalValues(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'nutritionalValues';
    const modal: HTMLIonModalElement = await this.openModal(modalName, '', dismissBackDrop)
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalCommentCrossales(dismissBackDrop: boolean = true, data: any): Promise<any> {
    const modalName: string = 'commentCrossales'
    const modal: HTMLIonModalElement = await this.openModal(modalName, "", dismissBackDrop, data);
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalAddPromotion(dismissBackDrop: boolean = true, promo: PromoInterface): Promise<any> {
    const modalName: string = 'addPromotion'
    const modal: HTMLIonModalElement = await this.openModal(modalName, "", dismissBackDrop, promo)
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalToppingError(topping: MenuFamilyCustomToppingInterface, toppingGroup: MenuCustomToppingGroupInterface) {
    const modalName: string = 'customMsgNdChooseOptionToppingError'
    const errorManager = new CustomErrorManager(1066)
    const modalMsg: string = errorManager.getErrorMsg()
    const modal: HTMLIonModalElement = await this.openModal(
      modalName,
      modalMsg,
      false,
      {
        'name': toppingGroup.description,
        'quantity': topping.quantity,
        'value': topping.description
      }
    )
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalToppingGroupsError(toppingGroups: MenuCustomToppingGroupInterface[]) {
    const modalName: string = 'toppingGroupsError'
    const modal: HTMLIonModalElement = await this.openModal(
      modalName,
      'LANG_OPS_SOME_SELECTIONS_ARE_EMPTY',
      false,
      {
        toppingGroups
      }
    )
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalToppingsPromotion(excluded_toppings: string[], included_topping: string[]) {
    const modalName: string = 'toppingsPromotion'
    const modalMsg: string = (excluded_toppings && excluded_toppings.length > 0) ? 'LANG_OPS_TOPPINGS_NOT_AVAILABLE_THIS_PROMO' : 'LANG_OPS_TOPPINGS_REQUIRED_THIS_PROMO'
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, true, { excluded_toppings, included_topping })
    await modal.present()
    return modal.onWillDismiss()
  }

  async openModalKioskErrorPayment(modalMsg: string, dismissBackDrop: boolean = true): Promise<any> {
    const modalName: string = 'kioskErrorPayment'
    const modal: HTMLIonModalElement = await this.openModal(modalName, modalMsg, false)
    await modal.present()
    return modal.onWillDismiss()
  }

}
