import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { FormMetadataButtonInterface, FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { buttonLogin, buttonReset, fieldsLoginPhone, fieldsLoginEmail, fielResetByPhone, fieldResetByEmail, fieldsLoginKiosk } from './forms';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {

  formMetadata: FormMetadata
  assets: string = environment.assetsUrl;
  @Input() loginType: string;
  @Output() onFormSubmited: EventEmitter<any> = new EventEmitter()
  buttonLogin: FormMetadataButtonInterface[];
  buttonReset: FormMetadataButtonInterface[];
  fieldsLoginPhone: FormMetadataFieldInterface[];
  fieldsLoginEmail: FormMetadataFieldInterface[];
  fieldsLoginKiosk: FormMetadataFieldInterface[];
  fielResetByPhone: FormMetadataFieldInterface[];
  fieldResetByEmail: FormMetadataFieldInterface[];
  @Output() showLogins ;
  @Input() notCustomer: string;

  constructor() {
    this.buttonLogin = buttonLogin;
    this.buttonReset = buttonReset;
    this.fieldsLoginPhone = fieldsLoginPhone;
    this.fieldsLoginEmail = fieldsLoginEmail;
    this.fieldsLoginKiosk = fieldsLoginKiosk;
    this.fielResetByPhone = fielResetByPhone;
    this.fieldResetByEmail = fieldResetByEmail;
    this.showLogins = new EventEmitter();

  }

  ngOnInit() {
    this.formMetadata = this.getFormConfig();
  }

  public onForSubmitLogin(event): void {
// console.log(event);
    let data: any = {};
    data.loginType = this.loginType;

    if (this.loginType == 'phone') {
      data.phone = event.value.phone;
      data.country = event.value.country
      // data.password = event.value.password;
    }

    if (this.loginType == 'email') {
      data.email = event.value.email;
      data.password = event.value.password;
    }

    if (this.loginType == 'kiosk') {
      data.user = event.value.user;
      data.password = event.value.password;
    }

    if (this.loginType == 'resetByPhone') {
      data.phone = event.value.phone;
      data.country = event.value.country
    }

    if (this.loginType == 'resetByEmail') {
      data.email = event.value.email;
    }

    console.log(data)

    if(event.valid === true){
       this.onFormSubmited.emit(data);
    }


  }

  private getFormConfig(): FormMetadata {

    if (this.loginType == 'phone') {
      return new FormMetadata({
        buttons: this.buttonLogin,
        commitMode: 'submit',
        fields: this.fieldsLoginPhone,
        focusOnFirstElement: true,
        formName: 'loginPhoneForm',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("loginPhoneForm", "submit", false, this.buttonLogin, [], this.fieldsLoginPhone, true)
    }
    if (this.loginType == 'email') {
      return new FormMetadata({
        buttons: this.buttonLogin,
        commitMode: 'submit',
        fields: this.fieldsLoginEmail,
        focusOnFirstElement: true,
        formName: 'loginEmailForm',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("loginEmailForm", "submit", false, this.buttonLogin, [], this.fieldsLoginEmail, true)
    }
    if (this.loginType == 'kiosk') {
      return new FormMetadata({
        buttons: this.buttonLogin,
        commitMode: 'submit',
        fields: this.fieldsLoginKiosk,
        focusOnFirstElement: false,
        formName: 'loginKioskForm',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("loginEmailForm", "submit", false, this.buttonLogin, [], this.fieldsLoginEmail, true)
    }
    if (this.loginType == 'resetByPhone') {
      return new FormMetadata({
        buttons: this.buttonReset,
        commitMode: 'submit',
        fields: this.fielResetByPhone,
        focusOnFirstElement: true,
        formName: 'resetByPhone',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("restByPhone", "submit", false, this.buttonReset, [], this.fielResetByPhone, true)
    }
    if (this.loginType == 'resetByEmail') {
      return new FormMetadata({
        buttons: this.buttonReset,
        commitMode: 'submit',
        fields: this.fieldResetByEmail,
        focusOnFirstElement: true,
        formName: 'resetByEmail',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("restByPhone", "submit", false, this.buttonReset, [], this.fieldResetByEmail, true)
    }
  }

  public IdontRemember() {
    this.loginType = 'resetByEmail';
    this.formMetadata = this.getFormConfig();

  }

  public showLoginsScreen(){
    // console.log("showLoginsScreen");
    this.showLogins.emit({});
  }

}
