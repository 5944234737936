import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { UserDataService } from "@services/user-data.service";

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {
  constructor(private userDataService: UserDataService) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve, reject) => {
      if (this.userDataService.isLoged) {
        resolve(true);
      } else {
        this.userDataService.getUser().toPromise().then(res => {
          resolve(res);
        }, error => {
          resolve(false);
        });
      }
    });

  }
}