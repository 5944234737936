import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-counter-cart',
  templateUrl: './counter-cart.component.html',
  styleUrls: ['./counter-cart.component.scss'],
})
export class CounterCartComponent implements OnInit {

  assets: string = environment.assetsUrl;
  @Input() hiddeDesktop: any;

  @Input() set quantity(value: number) {
    this.productsQuantity = value ? value : 0
  }

  @Input() clickAvailable: boolean = true;

  @Output() closeCart = new EventEmitter();
  productsQuantity: number = 0
  windowWidth: number
  constructor(private readonly _router: Router, private readonly _api: ApiService) { }
  ngOnInit(): void { }
  goToCart() {

    if (this.clickAvailable) {
      if (this.windowWidth <= 576) {
        this._router.navigate(['/cart'])
      } else {
        this.closeCart.emit(false);
        this._api.setData("showCart");
      }
    }

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;

  }

}
