import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from '@core/components/header/header.component';
import { CardProductComponent } from '@core/components/card-product/card-product.component';
import { SubmenuComponent } from '@core/components/submenu/submenu.component';
import { SliderPromosComponent } from '@core/components/slider-promos/slider-promos.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardCustomizerComponent } from '@core/components/card-customizer/card-customizer.component';
import { ButtonAddMenuComponent } from '@core/components/button-add-menu/button-add-menu.component';
import { DoughtsComponent } from '@core/components/doughts/doughts.component';
import { SizesComponent } from '@core/components/sizes/sizes.component';
import { ToppingListComponent } from '@core/components/topping-list/topping-list.component';
import { ButtonAddFavoriteComponent } from '@core/components/button-add-favorite/button-add-favorite.component';
import { ButtonAddQuantityCartComponent } from '@core/components/button-add-quantity-cart/button-add-quantity-cart.component';
import { FooterComponent } from '@core/components/footer/footer.component';
import { AlertFullScreenComponent } from '@core/components/alert-full-screen/alert-full-screen.component';
import { SliderFullScreenComponent } from '@core/components/slider-full-screen/slider-full-screen.component';
import { SearchPipe } from '@core/pipes/search.pipe';
import { PromotionDetailComponent } from '@core/components/promotion-detail/promotion-detail.component';
import { ToolbarComponent } from '@core/components/toolbar/toolbar.component';
import { CounterCartComponent } from '@core/components/counter-cart/counter-cart.component';
import { LoyaltyComponent } from '@core/components/loyalty/loyalty.component';
import { PhoneContactsComponent } from '@core/components/phone-contacts/phone-contacts.component';
import { ReferalsComponent } from '@core/components/referals/referals.component';
import { LoginTypesComponent } from '@core/components/login-types/login-types.component';
import { AngularIonicFormsModule } from '@core/modules/angular-ionic-forms/angular-ionic-forms.module';
import { RegisterComponent } from '@core/components/register/register.component';
import { AccountDetailsComponent } from '@core/components/account-details/account-details.component';
import { DeliveryDetailsComponent } from '@core/components/delivery-details/delivery-details.component';
import { LanguageComponent } from '@core/components/language/language.component';
import { PaymentsComponent } from '@core/components/payments/payments.component';
import { ContactComponent } from '@core/components/contact/contact.component';
import { AccountDetailsFormComponent } from '@core/components/account-details-form/account-details-form.component';
import { DeliveryDetailsFormComponent } from '@core/components/delivery-details-form/delivery-details-form.component';
import { ModalsComponent } from '@core/components/modals/modals.component';
import { HereMapComponent } from '@core/components/here-map/here-map.component';
import { LoginFormComponent } from '@core/components/login-form/login-form.component'
import { WaitingMagicLinkComponent } from '@core/components/waiting-magic-link/waiting-magic-link.component'
import { ButtonAddQuantityComponent } from '@core/components/button-add-quantity/button-add-quantity.component';
import { CalendarComponent } from '@core/components/calendar/calendar.component';
import { CalendarModule } from 'ion2-calendar';
import { ResentLinkComponent } from '@core/components/resent-link/resent-link.component';
import { StatusbarLoyaltyComponent } from '@core/components/statusbar-loyalty/statusbar-loyalty.component';
import { StatusbarProfileComponent } from '@core/components/statusbar-profile/statusbar-profile.component';
import { CartLineComponent } from '@core/components/cart-line/cart-line.component';
import { CartPromoLineComponent } from '@core/components/cart-promo-line/cart-promo-line.component';
import { DiscoverinputComponent } from '@core/components/discoverinput/discoverinput.component';
import { SelectPaymentComponent } from '@core/components/select-payment/select-payment.component';
import { MenuListComponent } from '@core/components/menu-list/menu-list.component';
import { SelectPromiseTimesComponent } from '@core/components/selectpromisetimes/selectpromisetimes.component';
import { PromoCodesComponent } from '@core/components/promo-codes/promo-codes.component';
import { CrossSalesComponent } from '@core/components/cross-sales/cross-sales.component';
import { TrackingStatusComponent } from '@core/components/tracking-status/tracking-status.component';
import { QitafComponent } from '@core/components/qitaf/qitaf.component'
import { RatingComponent } from '@core/components/rating/rating.component'
import { LoyaltySuccessComponent } from '@core/components/loyalty-success/loyalty-success.component'
import { LazyloadDirective } from './directives/lazyload.directive';
import { SummaryComponent } from '@core/components/summary/summary.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { CreateYourOwnComponent } from '@core/components/create-your-own/create-your-own.component';

@NgModule({
  declarations: [
    HeaderComponent,
    CardProductComponent,
    SubmenuComponent,
    SliderPromosComponent,
    CardCustomizerComponent,
    ButtonAddMenuComponent,
    DoughtsComponent,
    SizesComponent,
    ToppingListComponent,
    ButtonAddFavoriteComponent,
    ButtonAddQuantityCartComponent,
    FooterComponent,
    AlertFullScreenComponent,
    SliderFullScreenComponent,
    SearchPipe,
    PromotionDetailComponent,
    ToolbarComponent,
    CounterCartComponent,
    LoyaltyComponent,
    PhoneContactsComponent,
    ReferalsComponent,
    LoginTypesComponent,
    RegisterComponent,
    AccountDetailsComponent,
    DeliveryDetailsComponent,
    LanguageComponent,
    PaymentsComponent,
    ContactComponent,
    AccountDetailsFormComponent,
    DeliveryDetailsFormComponent,
    ModalsComponent,
    HereMapComponent,
    LoginFormComponent,
    WaitingMagicLinkComponent,
    ButtonAddQuantityComponent,
    CalendarComponent,
    ResentLinkComponent,
    StatusbarLoyaltyComponent,
    StatusbarProfileComponent,
    CartLineComponent,
    CartPromoLineComponent,
    DiscoverinputComponent,
    SelectPaymentComponent,
    MenuListComponent,
    SelectPromiseTimesComponent,
    PromoCodesComponent,
    CrossSalesComponent,
    TrackingStatusComponent,
    QitafComponent,
    RatingComponent,
    LoyaltySuccessComponent,
    LazyloadDirective,
    SummaryComponent,
    NoCommaPipe,
    CreateYourOwnComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    AngularIonicFormsModule,
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true
    })
  ],
  exports: [
    TranslateModule,
    HeaderComponent,
    CardProductComponent,
    SubmenuComponent,
    SliderPromosComponent,
    CardCustomizerComponent,
    ButtonAddMenuComponent,
    DoughtsComponent,
    SizesComponent,
    ToppingListComponent,
    ButtonAddFavoriteComponent,
    ButtonAddQuantityCartComponent,
    FooterComponent,
    AlertFullScreenComponent,
    SliderFullScreenComponent,
    SearchPipe,
    PromotionDetailComponent,
    ToolbarComponent,
    CounterCartComponent,
    LoyaltyComponent,
    PhoneContactsComponent,
    ReferalsComponent,
    LoginTypesComponent,
    AngularIonicFormsModule,
    RegisterComponent,
    AccountDetailsComponent,
    DeliveryDetailsComponent,
    LanguageComponent,
    PaymentsComponent,
    ContactComponent,
    AccountDetailsFormComponent,
    DeliveryDetailsFormComponent,
    ModalsComponent,
    HereMapComponent,
    LoginFormComponent,
    WaitingMagicLinkComponent,
    ButtonAddQuantityComponent,
    CalendarComponent,
    ResentLinkComponent,
    StatusbarLoyaltyComponent,
    StatusbarProfileComponent,
    CartLineComponent,
    CartPromoLineComponent,
    DiscoverinputComponent,
    SelectPaymentComponent,
    MenuListComponent,
    SelectPromiseTimesComponent,
    PromoCodesComponent,
    CrossSalesComponent,
    TrackingStatusComponent,
    QitafComponent,
    RatingComponent,
    LoyaltySuccessComponent,
    LazyloadDirective,
    SummaryComponent,
    NoCommaPipe,
    CreateYourOwnComponent
  ]
})
export class SharedModule { }
