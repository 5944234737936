import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { environment } from '@environments/environment'
import {UserDataService} from '@services/user-data.service';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlatformService } from '@services/platform.service';
import {StorageService} from '@services/storage.service';
import { StorageVariables } from '@core/constants';
import { ActivatedRoute } from '@angular/router';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {

  formMetadata: FormMetadata
  assets: string = environment.assetsUrl;
  types: object[]
  currentLanguage: string;
  @Output() Show = new EventEmitter();
  @Output() profilePoints = new EventEmitter();
  public user : any;
  conectedFacebook : boolean;
  conectedGoogle : boolean;
  conectedTwitter : boolean;
  conectedApple : boolean;
  marketingInfo : boolean;
  points: number = 0;
  detailIcon: any;
  private unsubscribe = new Subject<void>();

  constructor(private userDataService: UserDataService,  private readonly _router: Router,  private readonly _api: ApiService,    private platformService: PlatformService,    private storage: StorageService ,    private route: ActivatedRoute,
    public langService: LangService, private dataService: DataService
    ) {
   
    let that = this;
    this.types = [
      {name:"facebook",  text:"LANG_CONNECT_WITH_FACEBOOK",action: function () { that.linkwith('F') } },
      { name:"twitter", text:"LANG_SIGN_IN_WITH_TWITTER", action: function () { that.linkwith('T') } },
      { name: "google", text: "LANG_SIGN_IN_WITH_GOOGLE", action: function () { that.linkwith('G') } }
    ]

    if (this.platformService.isIos() || this.platformService.isSafari()) {
      this.types.push({ name: "apple", text: "LANG_SIGN_WITH_APPLE", action: function () { that.linkwith('A') } },
      )
    }
   }

   ngOnDestroy(): void {
    // console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  ngOnInit() {
   
    this.marketingInfo = false;


    this.detailIcon = this.langService.isRtl() ? 'chevron-back' : 'chevron-forward';
    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
          
         this.detailIcon = result.lang === 'ar' ? 'chevron-back' : 'chevron-forward';
        //  console.log( this.detailIcon)
      }
    )
  }

  edit(data: any) {
      this.Show.emit({goTo:'editAccountDetails',data:data});
  }

  ionViewDidEnter() {
    this.loadUserData();
  }

  loadUserData() {
    this.storage.removeItem(StorageVariables.linkwith);
    this.userDataService.getUser().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (user) => {
        this.points = 0;
        // console.log(user)
        this.user = JSON.parse(user);
        this.marketingInfo = this.user.marketingInfo && this.user.marketingInfo == 'S' ? true : false;

        if (this.user.name.length > 0) {
          this.points = this.points + 1;
        }

        if (this.user.birthDate != null) {
          this.points = this.points + 1;
        }

        this.user.profiles.forEach(
          value => {
            switch (value.source) {
              case 'facebook.com':
                this.conectedFacebook = true;
                break
              case 'google.com':
                this.conectedGoogle = true;
                break
              case 'twitter.com':
                this.conectedTwitter = true;
                break
              case 'apple.com':
                this.conectedApple = true;
                break
              case 'phone':
                this.user.phone = value.uid;
                break
              case 'email':
                this.user.email = value.uid;
                if (value.validated == 'S') {
                  this.user.emailValidated = true
                  this.points = this.points + 1;
                } else {
                  this.user.emailValidated = false
                }
                break
            }

          })

        this.profilePoints.emit(this.points);
      }, (error) => {
        this.user = {};
      }
    )
  }

  logout() {
    this.userDataService.logout();
    this._router.navigate(['/login'])
  }

  public linkwith(type) {
    this.userDataService.setlinkStatus(true);
    this._router.navigate(['/login'], { queryParams: {link:true,provider:type} })
  }
  
}
