import { Validators } from '@angular/forms';
import { FormEmailValidatorPattern } from '../common/constants';
import { FormMetadataFieldValidatorParamsInterface, FormMetadataFieldInterface, FormValidationInfoInterface } from '../common/interfaces';
import { FormCustomValidatorType, FormValidatorType } from '../common/types';
import { OnMatch } from '../custom-validators/on-match';

export class FormValidation {
    customList: string[]
    params: FormMetadataFieldValidatorParamsInterface
    constructor() {
        this.customList = [
            'match'
        ]
    }
    // Custom Validators
    get OnMatchValidator(): FormCustomValidatorType[] {
        return (this.params.hasOwnProperty('name') && this.params.hasOwnProperty('matchingName')) ? [OnMatch(this.params.name, this.params.matchingName)] : []
    }
    // Default Validators
    get EmailValidator(): FormValidatorType[] {
        return [Validators.pattern(FormEmailValidatorPattern)]
    }
    get MaxLengthValidator(): FormValidatorType[] {
        return (this.params.hasOwnProperty('length')) ? [Validators.maxLength(this.params.length)] : []
    }
    get MaxValidator(): FormValidatorType[] {
        return (this.params.hasOwnProperty('maximum')) ? [Validators.max(this.params.maximum)] : []
    }
    get MinLengthValidator(): FormValidatorType[] {
        return (this.params.hasOwnProperty('length')) ? [Validators.minLength(this.params.length)] : []
    }
    get MinValidator(): FormValidatorType[] {
        return (this.params.hasOwnProperty('minimum')) ? [Validators.min(this.params.minimum)] : []
    }
    get PatternValidator(): FormValidatorType[] {
        return (this.params.hasOwnProperty('regEx')) ? [Validators.pattern(this.params.regEx)] : []
    }
    get RequiredValidator(): FormValidatorType[] {
        return [Validators.required]
    }
    get RequiredTrueValidator(): FormValidatorType[] {
        return [Validators.requiredTrue]
    }
    // Getters
    getValidators(field: FormMetadataFieldInterface): FormValidationInfoInterface[] {
        let formValidators: FormValidationInfoInterface[] = []
        if (field.validators.length > 0) {
            for (const fieldValidator of field.validators) {
                const validatorType: string = fieldValidator.validatorType
                this.params = fieldValidator.params ? fieldValidator.params : {}
                if (validatorType === 'match') {
                    this.params.matchingName = field.name
                }
                formValidators = formValidators.concat(this.getValidatorByType(validatorType))
            }
        }
        return formValidators
    }
    getValidatorByType(validatorType: string): FormValidationInfoInterface {
        let alias: string[] = []
        let conditionalParams: string[] = []
        let isCustom: boolean = false
        let params: string[] = []
        let formValidators: FormValidatorType[] = []
        switch (validatorType.toLowerCase()) {
            case 'email':
                alias = ['email']
                formValidators = this.EmailValidator
                break
            case 'match':
                alias = ['mustMatch']
                isCustom = true
                formValidators = this.OnMatchValidator
                break
            case 'max':
                alias = ['max']
                params = ['actual', 'max']
                formValidators = this.MaxValidator
                break
            case 'maxlength':
                alias = ['maxlength']
                conditionalParams = ['requiredLength']
                params = ['actualLength']
                formValidators = this.MaxLengthValidator
                break
            case 'min':
                alias = ['min']
                params = ['actual', 'min']
                formValidators = this.MinValidator
                break
            case 'minlength':
                alias = ['minlength']
                conditionalParams = ['requiredLength']
                params = ['actualLength']
                formValidators = this.MinLengthValidator
                break
            case 'pattern':
                alias = ['pattern']
                formValidators = this.PatternValidator
                break
            case 'required':
                alias = ['required']
                formValidators = this.RequiredValidator
                break
            case 'requiredtrue':
                alias = ['required']
                formValidators = this.RequiredTrueValidator
                break
        }
        return {
            conditionalParams: conditionalParams,
            isCustom: isCustom,
            name: alias,
            params: params,
            validators: formValidators
        }
    }
}