import { Directive, ElementRef } from '@angular/core';
import { PlatformService } from '@services/platform.service';

@Directive({
  selector: '[appLazyload]'
})
export class LazyloadDirective {

//   constructor(el: ElementRef) {
//     el.nativeElement.style.backgroundColor = 'yellow';
//  }

  constructor(
    private element: ElementRef<HTMLImageElement>,
    private readonly platformService: PlatformService
    ) {
    if(!this.platformService.isServer()){
      const supports = 'loading' in HTMLImageElement.prototype;
      if (supports) {
        this.element.nativeElement.setAttribute('loading', 'lazy');
      } else {
        // fallback to IntersectionObserver
      }
    }
  }

}
