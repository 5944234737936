import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface AddFavProductInterface {
  id?: number
  name: string
  productID: string
  toppings: string
}

@Component({
  selector: 'app-button-add-favorite',
  templateUrl: './button-add-favorite.component.html',
  styleUrls: ['./button-add-favorite.component.scss'],
})
export class ButtonAddFavoriteComponent implements OnInit {
  @Input() set isLogged(value: boolean) {
    this.isLoggedIn = value
  }
  @Input() set value(v: boolean) {
    this.active = (this.isLoggedIn) ? v : false
    this.checkIconData()
  }
  @Output() onChange = new EventEmitter<boolean>()
  active: boolean = false
  favoriteClass: string
  isLoggedIn: boolean = false
  nameIcon: string
  constructor() { }
  ngOnInit(): void {
    this.checkIconData()
  }
  checkIconData(): void {
    this.favoriteClass = this.active ? 'favoriteButtonCheked' : 'favoriteButton'
    this.nameIcon = this.active ? 'heart' : 'heart-outline'
  }
  toggleFavorite(): void {
    // this.active = (this.isLoggedIn) ? !this.active : false
    this.onChange.emit((this.isLoggedIn) ? !this.active : false)
    // this.checkIconData()
  }
}
