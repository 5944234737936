import { Component, OnInit } from '@angular/core';
import { ContactList } from '@core/constants';
import { environment } from '@environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { PlatformService } from '@services/platform.service';
import { CustomBrowserService } from '@services/custom-browser.service';
import { ScriptService } from '@services/script.service';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  contactList =  ContactList
  assets: string = environment.assetsUrl;
  detailIcon: any;
  isDesktop: boolean
  private unsubscribe = new Subject<void>();
  
  constructor(public langService: LangService,
    private platformService: PlatformService,
    private cbs: CustomBrowserService,
    private readonly scriptService: ScriptService,
    private readonly _api: ApiService,
    private readonly _router: Router,
    ) {

  }

  ngOnInit() {
    this.isDesktop = this.platformService.isWeb()
    this.detailIcon = this.langService.isRtl() ? 'chevron-back' : 'chevron-forward';
    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
         this.detailIcon = result.lang === 'ar' ? 'chevron-back' : 'chevron-forward';
        //  console.log( this.detailIcon)
      }
    )

  }

  openFaq(){
    this.cbs.openUrl(`https://help.maestropizza.com/`,true, true);
  }

  openChat(): void {
    this.scriptService.loadScriptsChatBot().then(
      (result: Promise<any>[]) => {
          
        // console.log(window['Freshbots'])
        if (window && window['Freshbots']) {
          let chatBot: any = window['Freshbots']
          chatBot.initiateWidget({
            autoInitChat: true,
            getClientParams: function () {
              return {
                "cstmr::eml": "",
                "cstmr::lng": "ar",
                "cstmr::nm": ""
              };
            }
          },
            (successResponse) => {
              chatBot.showWidget(
                () => {
                  // console.log('Hi')
                },
                () => {
                  // console.log('bye')
                }
              )
              // chatBot.deActivate()
            },
            (errorResponse) => {
              console.log(errorResponse)
            })
        }
      }
    )
  }

  goToBranches(){
    this._api.setStatusToGo('account');
    this._router.navigate(['/branches'])
  }

  

}
