import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {
  CounterInterface,
  MenuGroupInterface,
  PgMenu,
  PgOrderPromoLine
} from '@lib/utils';
import {
  MenuFamilyInterface,
  MenuFamilyProductInterface,
  MenuInterface,
  PromoInterface,
  PromoLineInterface
} from '@lib/promo-engine';
import { CustomizerSelectedDataInterface } from '@core/interfaces/services';
import { ApiService } from '@services/api.service';
import { environment } from '@environments/environment';
import { PlatformService } from '@services/platform.service';
import { kiosk } from '@core/constants';
import { Router } from '@angular/router';
import { CardProductComponent } from '../card-product/card-product.component';
import { isGroundr, isMaestro } from '../../functions';

export interface MenuDataInterface {
  groups: MenuGroupInterface[]
  menu: MenuInterface
  orderPromoLine?: PgOrderPromoLine
  promoLine?: PromoLineInterface
  promotions: PromoInterface[]
}

export interface ShowCustomizerEvent {
  family: MenuFamilyInterface
  status: boolean
}

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls : ['./menu-list.component.scss','./menu-list.groundr.component.scss']
})


export class MenuListComponent implements OnInit {
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  numberOfCardProducts: number = 0;
  @Input() wizard: boolean = false;
  @Input() hasPromotions: boolean = false;

  @Input() set data(value: MenuDataInterface) {
    this.updateMenuData(value)
  }
  @Input() set width(value: number) {
    this.windowWidth = value ? value : 0
  }

  @Input() loyalty: boolean
  @Input() points: number


  @Input() set scrollProductID(value: string) {
if(value){
  this.scrollToProduct(value)
}

  }

  @ViewChild('containerMenuList', { read: ElementRef, static: false }) containerMenuList: ElementRef;


  


  @Output() addPromotion = new EventEmitter<PromoInterface>()
  @Output() showCustomizer = new EventEmitter<ShowCustomizerEvent>()
  @Output() scrollEnded = new EventEmitter<number>();
  isKiosk: boolean = kiosk
  kioskBranch: any
  public assets: string = environment.assetsUrl
  public groups: MenuGroupInterface[] = []
  public hasCustomPizza: boolean = false
  public isWizard: boolean = false
  public orderPromoLine: PgOrderPromoLine
  public pgMenu: PgMenu
  public promoLine: PromoLineInterface = null
  public productCounter: CounterInterface
  public windowWidth: number = 0
  currentPlatform: string
  numProductsByGroup: Array<any> = []
  columnFix: string = "lastToLeft"
  currentRoute: string
  scroll: number = 0;
  private scrollTimeout: any;

  constructor(
    private elRef: ElementRef,
    private readonly apiService: ApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly platformService: PlatformService,
    public readonly _router: Router
  ) {

    this.currentRoute = this._router.url
  }

  scrollToProduct(productId: string) {
    const container = this.containerMenuList.nativeElement;
    const productElement = container.querySelector(`#${productId}`);
  
    if (productElement) {
      const containerRect = container.getBoundingClientRect();
      const productRect = productElement.getBoundingClientRect();
  
      // Calcula el desplazamiento necesario con margen
      const offsetTop = productRect.top - containerRect.top - 130;
      
      // Desplaza el contenedor al valor calculado
      container.scrollTo({
        top: offsetTop + container.scrollTop,
        behavior: 'smooth'
      });
    }
  }

  ngAfterViewInit() {
    this.initializeScrollListener();
  }

  ngAfterViewChecked() {
    //Cuando hayan más de 9 cartas entonces muestra el scrollBar
    this.updateScrollBarVisibility();
  }

  initializeScrollListener() {
    const container = this.containerMenuList.nativeElement;

    container.addEventListener('scroll', () => {
      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.handleScrollEnd(container);
      }, 50); 
    });
  }


  handleScrollEnd(container) {
    const scrollTop = container.scrollTop;
    this.scrollEnded.emit(scrollTop);
  }
  
  private updateScrollBarVisibility() {
    const cardProducts = this.elRef.nativeElement.querySelectorAll('card-product');
    this.numberOfCardProducts = cardProducts.length;
  
    const scrollBarElement = this.elRef.nativeElement.querySelector('.scrollBar-wizard');
    if (scrollBarElement) {
      if (this.numberOfCardProducts <= 9) {
        scrollBarElement.style.overflowY = 'hidden';
      } else {
        scrollBarElement.style.overflowY = 'scroll';
      }
    } 
  }

  ngOnInit(): void {
    this.assets = this.apiService.getAssetsUrl()

    if (this.platformService.isIos()) {
      if (window.innerHeight < 812) {
        this.currentPlatform = 'ios2';
      } else {
        this.currentPlatform = 'ios';
      }
    } else {
      if (this.platformService.isAndroid()) {
        this.currentPlatform = 'android';
      } else {
        this.currentPlatform = 'browser';
      }
    }

  }
  private setProductCounter(): void {
    let counter: number = 0
    this.productCounter = {}
    let numPromos: number
    let numProducts: number
    this.numProductsByGroup = [];


    if (this.groups.length > 0) {
      this.groups.forEach(
        value => {
          numPromos = 0
          numProducts = 0
          value.subGroups.forEach(
            val => {

              if (val.promotions.length > 0 && !this.isWizard) {
                val.promotions.forEach(
                  v => {
                    counter++
                    this.productCounter[v.id] = counter
                    numPromos++
                  }
                )
              } else {
                val.products.forEach(
                  v => {
                    let familyId: number = v.familyGroupID
                    let allowCounter: boolean = /*val.baseProducts[familyId] ? (val.baseProducts[familyId] !== v.id) : */true
                    if (allowCounter) {
                      counter++
                      this.productCounter[v.id] = counter
                      numProducts++
                    }
                  }
                )
              }


            }
          )
          this.numProductsByGroup.push({ "id": value.id, "quantity": numPromos + numProducts })
        }
      )


      let leftColum = 0
      let rightColumn = 0

      this.numProductsByGroup.forEach(
        value => {
          if (value.quantity % 2 == 0) {
            leftColum++
          } else {
            rightColumn++
          }
        }
      )

      if ((leftColum > rightColumn) && ((leftColum - rightColumn) != 2)) { //Si la diferencia es de solo 2 no hay que moverlo a la derecha
        this.columnFix = "lastToRight"
      }


    }
  }
  private updateMenuData(menuData: MenuDataInterface): void {
    this.groups = []
    this.productCounter = {}
    this.cdr.detectChanges()
    if (menuData) {
      this.pgMenu = new PgMenu(
        (menuData.menu) ? menuData.menu : null,
        (menuData.promotions) ? menuData.promotions : null
      )
      if (menuData.promoLine) {
        this.isWizard = true
        this.hasCustomPizza = false
        this.promoLine = menuData.promoLine
      }
      if (menuData.groups && Array.isArray(menuData.groups) && menuData.groups.length > 0) {
        this.groups = menuData.groups
        if (!this.platformService.isServer()) {
          this.setProductCounter()
        }
      }
      if (menuData.orderPromoLine) {
        this.orderPromoLine = menuData.orderPromoLine
      }
      if (this.isWizard) {
        this.groups.forEach(
          value => {
            if (this.pgMenu.someInArray(value.subGroups, 'hasProductBase', true)) {
              this.hasCustomPizza = true
            }
          }
        )
      } else {
        this.hasCustomPizza = this.pgMenu.someInArray(this.groups, 'hasProductBase', true)
      }
    }
  }
  public addCustomPizza(): void {
    const baseProductList: CustomizerSelectedDataInterface[] = []
    this.groups.forEach(
      value => {
        value.subGroups.forEach(
          val => {
            for (const prop in val.baseProducts) {
              if (val.baseProducts[prop]) {
                const family: MenuFamilyInterface = this.pgMenu.getFamily(parseInt(prop))
                const product: MenuFamilyProductInterface = this.pgMenu.getProduct(val.baseProducts[prop])
                if (family && product) {
                  // const isHalfNdHalf: boolean = (value.halfNdHalfAllowed === 'S' && product.noHalfNDHalf !== 'S')
                  // if (isHalfNdHalf) {
                  const items: CustomizerSelectedDataInterface = {
                    family: family,
                    product: product
                  }
                  if (this.orderPromoLine) {
                    items.orderPromoLine = this.orderPromoLine
                  }
                  if (this.promoLine) {
                    items.promoLine = this.promoLine
                  }
                  baseProductList.push(items)
                  // }
                }
              }
            }
          }
        )
      }
    )
    if (baseProductList.length > 0) {
      baseProductList.sort((a, b) => a.family.order - b.family.order)
      this.apiService.setData(baseProductList[0])
      this.updateShowCustomizer(true, baseProductList[0].product)
    }
  }
  // Update data from Outputs
  public updateShowCustomizer(event: boolean, product: MenuFamilyProductInterface): void {
    this.showCustomizer.emit({
      family: this.pgMenu.getFamily(product.familyGroupID),
      status: event
    })
  }
  public updateShowCustomizerPromo(event: PromoInterface): void {
    this.addPromotion.emit(event)
  }
}
