import { Injectable } from '@angular/core';
import { CustomObservableType, RxjsService } from '@services/rxjs.service';
import { BranchInterface, OrderTypes } from '@lib/promo-engine';
import { DeliveryService } from '@services/delivery.service';
import { HereService } from '@services/here.service';
import { ModalService } from '@services/modal.service';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';
import { PgCommon } from '@lib/utils';
import { UserDataService } from '@services/user-data.service';
import { LangService } from '@services/lang.service';
import { FaeventsService } from '@services/faevents.service';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {
  private currrentBranchSchedule : any; // solo para obtener informacion cuando no estamos en el horario
  private errorCheckBranch  = {
    text : '',
    show : false,
    navigate : true,
    nearest : false
  };
  lastCli:any = false;
  lastBranch: any = false;
  public branchPickup : any = false;
  public branches : any = [];
  lang: string
  orderType : OrderTypes = OrderTypes.Recoger // only use to get data to events
  branch : any = false; // only use to get data to events
  constructor(
    private readonly rxJsService: RxjsService,
    private readonly hereS: HereService,
    private deliveryService:DeliveryService,
    private readonly modalService: ModalService,
    private readonly _api: ApiService,
    private readonly _router: Router,
    private userDataService: UserDataService,
    private langService: LangService,
    private faevents : FaeventsService
  ) { 
    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang

  }
  checkBranch(branch: BranchInterface, orderType: OrderTypes): CustomObservableType<boolean> {
    const checkBranchObs: CustomObservableType<boolean> = this.rxJsService.createCustomObservable()
    const timeProperty: string = orderType === OrderTypes.Domicilio ? 'deliveryTime' : 'pickupTime'

    function resolve(data) {
      checkBranchObs.next(data)
      checkBranchObs.complete()
    }

    function error(code) {
      checkBranchObs.error(code)
      checkBranchObs.complete()
    }


    if (branch.opened) {
   
      let schedule = this.getSchedeule(branch);
      let checkSchedule = this.checkSchedule(schedule,branch);
   
      if (branch.isAlive == 'S' ||  branch.recentActivity == 'S') {
        if (branch[timeProperty] != '00:00:00') {
              if(checkSchedule.canContinue){
                resolve(true);
              }else{
                this.currrentBranchSchedule = {schedule:schedule,checkSchedule:checkSchedule};
                error(7);
              }
        } else {
          error(orderType === OrderTypes.Domicilio ? 5 : 6);
        }

      } else {
        this.currrentBranchSchedule = {schedule:schedule,checkSchedule:checkSchedule};
        error(7);
      }
    } else {
      error(3);
    }


    return checkBranchObs
  }

  canPlaceOrder(orderType: OrderTypes, data: any): CustomObservableType<boolean> {
    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang
    this.orderType = orderType;
    this.branch = false;
    const checkBranchObs: CustomObservableType<boolean> = this.rxJsService.createCustomObservable()
    let that = this;

    function resolve(data) {
      checkBranchObs.next(data)
      checkBranchObs.complete()
    }

    function error(code, orderType: any = false) {
      that.errorsPlacerOrder(code, orderType);
      checkBranchObs.error(code)
      checkBranchObs.complete()
    }
    let payload = {};
        payload["orderType"] = orderType;


    if (orderType == OrderTypes.Domicilio) {

      let address = data.address || false;
      let zone = data.zone || false;
      let promiseTime = data.promiseTime || false;

      payload["zone"] = zone;
      payload["address"] = address;

      if (address) {
        if (zone) {
          this.branch = data.zone.branch;
          // if (promiseTime) {           
          this._api.getBranchById(data.zone.branch.id, this.lang, OrderTypes.Domicilio).subscribe(
            resBranch => {
              this.checkBranch(resBranch, orderType).subscribe(
                result => {
                  if(this.userDataService.getPaymentMethod().method){
                    resolve(result);
                  }else{
                    error(8, orderType);
                  }
                },
                errorCode => {
                  error(errorCode, orderType);
                  this.logger(errorCode,payload);
                });
            },error=>{
              error(1, orderType);
              this.logger(1,payload);
            });
          // } else {
          //   error(2,orderType);
          // }
        } else {
          error(1,orderType); // aqui va un 1
          this.logger(1,payload);
        }
      } else {
        error(0,orderType);
        this.logger(0,payload);
      }
    } else {
      let promiseTime = data.promiseTime || false;
      this.branch = data.branch;
      // if (promiseTime) {
        this.checkBranch(data.branch, orderType).subscribe(
          result => {
            if (this.userDataService.getPaymentMethod().method) {
              resolve(result);
            } else {
              error(8, orderType);
            }
          },
          errorCode => {
            error(errorCode, orderType);
            payload["branch"] = data.branch;
            this.logger(errorCode,payload);
          });
      // } else {
      //   error(2,orderType);
      // }
    }

    return checkBranchObs
  }

  getSchedeule(branch: BranchInterface) {

    let schedule = {
      split: false,
      openToday: true,
      continued: { open: new Date(), close: new Date() },
      first: { open: new Date(), close: new Date() },
      second: { open: new Date(), close: new Date() }
    };

    if (branch.opening1 != branch.closing1 && branch.opening2 != branch.closing2) {

      schedule.first.open = new Date(branch.opening1)
      schedule.first.close = new Date(branch.closing1)
      schedule.second.open = new Date(branch.opening2)
      schedule.second.close = new Date(branch.closing2)

      schedule.split = true;

    } else {

      if (branch.opening1 != branch.closing1) {
        schedule.continued.open = new Date(branch.opening1)
        schedule.continued.close = new Date(branch.closing1)
      } else if (branch.opening2 != branch.closing2) {
        schedule.continued.open = new Date(branch.opening2)
        schedule.continued.close = new Date(branch.closing2)
      } else {
        schedule.first.open = new Date(branch.opening1)
        schedule.first.close = new Date(branch.closing1)
        schedule.second.open = new Date(branch.opening2)
        schedule.second.close = new Date(branch.closing2)
        schedule.openToday = false
      }
    }

    return schedule;
  }

  checkSchedule(schedule, branch) {

    let res = { before: false, in_middle: false, after: false, canContinue: false }
    let now = new Date(branch.currentTime);

    if (schedule.split) {

      if (branch.isAlive == 'S') {
        if (now >= schedule.first.open && now < schedule.first.close || now >= schedule.second.open && now < schedule.second.close) {
          res.canContinue = true;
        } else if (now < schedule.first.open) {
          res.before = true;
        } else if (now > schedule.second.close) {
          res.after = true;
        } else {
          res.canContinue = true;
          res.in_middle = true;
        }
        if(!res.canContinue && res.before){ // isAlive =S, (hora actual < que apertura ) y recentActivity = S
          if (branch.recentActivity == 'S'){
            res.canContinue = true;
          }
        }
      } else if (branch.recentActivity == 'S') {
        if (now < schedule.first.open || (now > schedule.first.close && now < schedule.second.open)) {
          res.canContinue = true;
          res.before = true;
        }else if(now > schedule.second.close ){
          res.after = true;
        }
      }else{
        if (now < schedule.first.open) {
          res.before = true;
        } else if (now > schedule.second.close) {
          res.after = true;
        }
      }

    } else {

      if (branch.isAlive == 'S') {
        if (now >= schedule.continued.open && now < schedule.continued.close) {
          res.canContinue = true;
        } else if (now < schedule.continued.open) {
          res.before = true;
        } else if (now > schedule.continued.close) {
          res.after = true;
        }
        if(!res.canContinue && res.before){ // isAlive =S, (hora actual < que apertura ) y recentActivity = S
          if (branch.recentActivity == 'S'){
            res.canContinue = true;
          }
        }
      } else if (branch.recentActivity == 'S') {
        if (now < schedule.continued.open) {
          res.canContinue = true;
          res.before = true;
        }else if(now > schedule.continued.close){
          res.after = true;
        }
      }else{
        if(now< schedule.continued.open){
          res.before = true;
        }else if(now>schedule.continued.close){
          res.after = true;
        }
      }

    }
    return res;
  }

  private onlyShowModal(keyLng) : void{
    let data = { nearestBranch: false, orderType: '' }
    this.modalService.openModalCheckBranches(true, keyLng, data);
  }

  private findNearestAndShowModal(keyLng, orderType) {

      if (orderType != OrderTypes.Domicilio) {

        let data = { nearestBranch: false, orderType: orderType }
        this.modalService.openModalCheckBranches(true, keyLng, data);

      } else {

        function openModal(ctx, keyLng, data) {
          ctx.modalService.openModalCheckBranches(true, keyLng, data).then(result => {
            if (result.data?.result) {
              ctx.errorCheckBranch.nearest = data.nearestBranch;
              ctx._router.navigate(['/branches']);
            }
          },
            (err: any) => { }
          )
        }

        let cli = this.hereS.getLocation();
        if (keyLng == 'LANG_NO_DELIVERY_LOCATION' && this.lastCli) {
          if (this.lastCli.lat == cli.lat && this.lastCli.lng == cli.lng) {
            if (this.lastBranch) {
              openModal(this, keyLng, { nearestBranch: this.lastBranch, orderType: orderType });
              return;
            }
          }
        }
        this.lastCli = cli;
        this._api.getNearestBranches(cli.lat + "," + cli.lng, this.lang, 1).subscribe(
          result => {
            this.lastBranch = result[0];
            openModal(this, keyLng, { nearestBranch: this.lastBranch, orderType: orderType });
          }
        )
      }
    
  }

  private setError(text,navigate = true) {
    this.errorCheckBranch.text = text;
    this.errorCheckBranch.show = true;
    this.errorCheckBranch.navigate = navigate;
  }
  public resetError() {
    this.errorCheckBranch.text = '';
    this.errorCheckBranch.show = false;
    this.errorCheckBranch.navigate = true;
    this.errorCheckBranch.nearest = false;


  }
  public getError() {
    let res =  this.errorCheckBranch.show ? this.errorCheckBranch : false;
    return res;
  }

  public getDisplayNearest() {
    let near = this.errorCheckBranch.nearest ? this.errorCheckBranch.nearest : false;
    this.errorCheckBranch.nearest = false;
    return near;
  }

  errorsPlacerOrder(code, orderType) {
    // console.log(code);
    switch (code) {
      case 0: {
        this.setError('LANG_NO_ADDRESS_SELECTED',false);
      } break;
      case 1: {
        // console.log("No delivery zone on this location", this.hereS.getLocation());
        this.findNearestAndShowModal('LANG_NO_DELIVERY_LOCATION',orderType);
      } break;
      case 2: {
        // console.log("the branch doesn't have promiseTimes", this.deliveryService.getZoneDelivery());
        this.findNearestAndShowModal('LANG_BRANCH_NOT_PROMISETIMES',orderType);
      } break;
      case 3: {
        // console.log("branch.open==0", this.deliveryService.getZoneDelivery());
        this.onlyShowModal('LANG_BRANCH_FORCE_CLOSED' );
      } break;
      case 4: {
        // console.log("branch is not Alive", this.deliveryService.getZoneDelivery());
        this.findNearestAndShowModal('LANG_IS_NOT_ALIVE',orderType);
      } break;
      case 5: {
        // console.log("No pickupTime or DeliveryTime", this.deliveryService.getZoneDelivery(), orderType);
        this.findNearestAndShowModal('LANG_NO_DELIVERY_TIME',orderType);
      } break;
      case 6: {
        // console.log("No pickupTime or DeliveryTime", this.deliveryService.getZoneDelivery(), orderType);
        this.findNearestAndShowModal('LANG_NO_PICKUP_TIME',orderType);
      } break;
      case 7: {
        // console.log(this.currrentBranchSchedule, orderType);
        {
          let checkSchedule = this.currrentBranchSchedule.checkSchedule;
          let schedule = this.currrentBranchSchedule.schedule;

          if (schedule.openToday) {
            if (checkSchedule.before) {
              this.onlyShowModal('LANG_BEFORE_OPENING_HOURS');
            } else if (checkSchedule.after) {
              this.onlyShowModal('LANG_AFTER_CLOSING_HOURS');
            } else {
              if(orderType == OrderTypes.Domicilio){
                this.findNearestAndShowModal('LANG_IS_NOT_ALIVE',orderType);
              }else{
                this.errorsPlacerOrder(4, orderType);
              }
            }
          } else {
            this.onlyShowModal('LANG_NOT_OPEN_TODAY');
          }

        }
      } break;
      case 8 : {
        this.onlyShowModal('LANG_CHOOSE_PAYMENT_METHOD');
      }break;

      default: break;
    }
  }

  public getPickBrStatus(data: any): number {

    if (data.branch.opened) {

      let schedule = this.getSchedeule(data.branch);
      let checkSchedule = this.checkSchedule(schedule, data.branch);

      if (checkSchedule.canContinue) {
        if (checkSchedule.before) {
          return 2; // (open in x TIME)
        } else {
          return 3; // (close in x TIME)
        }
      } else {
        if (schedule.openToday) {
          if (checkSchedule.before) {// (closed) 
            return 1; 
          } else if (checkSchedule.after) { // (closed)
            return 4;
          } else {
            return 3; // Activity Lost, (close in x TIME)
          }
        } else {
          return 5; // Branch no open today (closed today)
        }
      }

    } else {
      return 1; // (closed) 
    }

  }

  public getClosedIn(data: any) {

    let branch = data.branch;
    let schedule = this.getSchedeule(branch);
    let now = new Date(branch.currentTime);

    if (schedule.split) {
      if (now >= schedule.first.open && now <= schedule.first.close) {
        return PgCommon.timeDiff(schedule.first.close, now);
      }
      if (now >= schedule.second.open && now <= schedule.second.close) {
        return PgCommon.timeDiff(schedule.second.close, now);
      }
    } else {
      if (now >= schedule.continued.open && now <= schedule.continued.close) {
        return PgCommon.timeDiff(schedule.continued.close, now);
      }
    }

  }

  public getOpenIn(data: any) {
    let branch = data.branch;
    let schedule = this.getSchedeule(branch);
    let now = new Date(branch.currentTime);

    if (schedule.split) {
      if (now < schedule.first.open) {
        return PgCommon.timeDiff(schedule.first.open, now);
      }
      if (now < schedule.second.open) {
        return PgCommon.timeDiff(schedule.second.open, now);
      }
    } else {
      return PgCommon.timeDiff(schedule.continued.open, now);
    }

  }

  public calculateDiff(current,selected){
    return PgCommon.timeDiffDeliveryTime(selected, current);
  }

  getOpeningTime(branch) {
    let schedule = this.getSchedeule(branch);
    let now = new Date(branch.currentTime);

    if (schedule.split) {
      if (now < schedule.first.open) {
        return (schedule.first.open);
      }
      if (now < schedule.second.open) {
        return schedule.second.open;
      }
    } else {
      return schedule.continued.open;
    }
  }

  getTextErrorCode(code) {
    switch (code) {
      case 0: { this.sendEvtGeneric("LANG_NO_ADDRESS_SELECTED"); return 'LANG_NO_ADDRESS_SELECTED' }
      case 1: { this.sendEvtGeneric("LANG_NO_DELIVERY_LOCATION"); return 'LANG_NO_DELIVERY_LOCATION' }
      case 2: { this.sendEvtGeneric("LANG_BRANCH_NOT_PROMISETIMES"); return 'LANG_BRANCH_NOT_PROMISETIMES' }
      case 3: { this.sendEvtGeneric("LANG_BRANCH_FORCE_CLOSED"); return 'LANG_BRANCH_FORCE_CLOSED' }
      case 4: { this.sendEvtGeneric("LANG_IS_NOT_ALIVE"); return 'LANG_IS_NOT_ALIVE' }
      case 5: { this.sendEvtGeneric("LANG_NO_DELIVERY_TIME"); return 'LANG_NO_DELIVERY_TIME' }
      case 6: { this.sendEvtGeneric("LANG_NO_PICKUP_TIME"); return 'LANG_NO_PICKUP_TIME' }
      case 7: {
        {
          let checkSchedule = this.currrentBranchSchedule.checkSchedule;
          let schedule = this.currrentBranchSchedule.schedule;

          if (schedule.openToday) {
            if (checkSchedule.before) {
              this.sendEvtBeforeOrAfterOpening("LANG_BEFORE_OPENING_HOURS");
              return 'LANG_BEFORE_OPENING_HOURS';
            } else if (checkSchedule.after) {
              this.sendEvtBeforeOrAfterOpening("LANG_AFTER_CLOSING_HOURS");
              return 'LANG_AFTER_CLOSING_HOURS';
            } else {
              this.sendEvtGeneric("LANG_IS_NOT_ALIVE");
              return 'LANG_IS_NOT_ALIVE';
            }
          } else {
            this.sendEvtGeneric("LANG_NOT_OPEN_TODAY");
            return 'LANG_NOT_OPEN_TODAY';
          }
        }
      };
      default: return '';
    }
  }

  async sendEvtBeforeOrAfterOpening(message) {
    try {
      let payload = { branch_name: this.branch.name, orderType: this.orderType };
      let evtName = 'AfterClosingTime';

      if (message == 'LANG_BEFORE_OPENING_HOURS') {
        evtName = 'BeforeOpeningTime'
      }

      this.faevents.preLogEvent(evtName, payload);
    } catch (e) {
      this._api.logger("error", "", e, message)
    }
  }

  async sendEvtGeneric(message) {

    try {
      let evtName = message == 'LANG_NO_DELIVERY_LOCATION' ? 'OutOfDeliveryArea' : this.orderType == OrderTypes.Domicilio ? 'NoDelivery' : 'NoPickUp';
      let payload: any = { short_name: message, orderType: this.orderType };

      if (this.branch) {
        payload.branch_name = this.branch.name;
      }

      this.langService.getTranslation(message).subscribe(translated => {
        payload.short_name = translated
        this.faevents.preLogEvent(evtName, payload);
      }, error => {
        this.faevents.preLogEvent(evtName, payload);
      });
    } catch (e) {
      this._api.logger("error", "", e, message)
    }
  }

  async logger(code, payload) {
    try {
      let level = 'info';
      let message = this.getTextErrorCode(code);
      let user: any = await this.userDataService.getUser_()

      if (user) {
        let user_ = JSON.parse(user);
        payload["userInfo"] = user_;
      }

      this._api.logger(level, message, payload,"branches").subscribe(res => { }, error => { });
    } catch (e) {
      this._api.logger("error", '', e,"branches").subscribe(res => { }, error => { });
    }

  }

  public setBranchReorder(branch){
    this.errorCheckBranch.nearest = branch;
  }
}