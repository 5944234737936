import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomObservableType, RxjsService } from '@services/rxjs.service';
import { UserDataService } from '@services/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private readonly userDataService: UserDataService, private readonly rxjsService: RxjsService, private readonly router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const resultObservable: CustomObservableType<any> = this.rxjsService.createCustomObservable()
    const user: CustomObservableType<any> = this.userDataService.getUser()
    user.subscribe(
      (result: any) => {
          
        resultObservable.next(true)
        resultObservable.complete()
      },
      (err: any) => {
        console.log(err)
        this.router.navigateByUrl('/login')
        resultObservable.next(false)
        resultObservable.complete()
      }
    )
    return resultObservable
  }
}
