import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { PromoInterface, PromoUserInterface } from '@lib/promo-engine';
import { WizardSelectedDataInterface } from '@core/interfaces/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '@services/storage.service';
import { StorageVariables, kiosk } from '@core/constants';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { PlatformService } from '@services/platform.service';
import { PgCommon } from '@lib/utils';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.scss'],
})
export class PromotionDetailComponent implements OnInit {
  @Input() promotion: PromoInterface
  @Output() addPromotion = new EventEmitter<PromoInterface>()
  @Output() closed = new EventEmitter<boolean>()
  private unsubscribe = new Subject<void>()
  public assets: string = environment.assetsUrl
  public promo: PromoInterface
  public promosUrl: string = environment.productsUrl
  public language: string = "ar"
  public promoUser: PromoUserInterface
  public hideDatePromo: boolean = false
  public hideDatePromoUser: boolean = false
  public disableButton: boolean = false
  public windowWidth: number
  public pgCommon: PgCommon = new PgCommon()
  isKiosk: boolean = kiosk
  kioskBranch: any
  
  constructor(
    private readonly _api: ApiService,
    private readonly storageService: StorageService,
    public langService: LangService,
    private readonly platformService: PlatformService,
  ) {  }
  ngOnInit(): void {



    // console.log('promo')
    this._api.getNewValue.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (data: WizardSelectedDataInterface) => {
        if (data && data.hasOwnProperty('promotion')) {
          this.promo = data.promotion
          let fecha = new Date(this.promo.endingDate)
          let today = new Date()
          today.setFullYear(today.getFullYear() + 1)

          if (fecha > today) {
            this.hideDatePromo = true;
          }else{
            this.hideDatePromo = false;
          }


          this.storageService.getItem(StorageVariables.order).subscribe(order => {
            if (order.excludedOrderType == this.promo.orderType) {
              this.disableButton = true
            } else {
              this.disableButton = false
            }
          }, error => {
            console.log(error)
          })


          if (data && data.hasOwnProperty('promoUser')) {
            this.promoUser = data.promoUser
            let fecha = new Date(this.promoUser.endingDate)
            let today = new Date()
            today.setFullYear(today.getFullYear() + 1)

            if (fecha > today) {
              this.hideDatePromoUser = true;
            }else{
              this.hideDatePromoUser = false;
            }
          }

        }
      }
    )

    const langConfig = this.langService.getLangConfig()
    this.language = langConfig.lang

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
        this.language = result.lang
      }
    )




  }
  ngOnDestroy(): void {
    console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
  close(): void {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth >= 1200 || this.isKiosk) {
      this._api.setData("showCart");
    } else {
      this._api.setData({})
    }

    this.closed.emit(true)

  }
  selectPromotion(): void {
    let promotion: PromoInterface = this.pgCommon.copy(this.promo)
    if (this.promoUser && 'ID' in this.promoUser) {
      promotion.promoUserID = this.promoUser.ID.toString()
    }
    this.addPromotion.emit(promotion)
    // this._api.setData({
    //   selectedPromotion: this.promo
    // })
    // this.closed.emit(true)
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.assets + "images/promonotfound.png") {
        event.target.src = this.assets + "images/promonotfound.png"
      }
    }
  }



}
