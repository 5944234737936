import { filterInArray } from './common';
import { FormConfig } from './config';
import { FormMetadataFieldOptionsCheck } from './constants';
import { FormMetadataGroupInterface, FormMetadataButtonInterface, FormMetadataFieldInterface, FormMetadataFieldOptionsCheckInterface, FormMetadataCheckFieldInterface, FormMetadataFieldValidatorInterface, FormMetadataCheckFieldValuesInterface } from './interfaces'
import { FormCommitType } from './types';

export class FormMetadata {
    buttons: FormMetadataButtonInterface[]
    commitMode: FormCommitType
    disableSubmitButtonOnInvalidForm: boolean
    fields: FormMetadataFieldInterface[]
    focusOnFirstElement: boolean
    formName: string
    groups: FormMetadataGroupInterface[]
    isReadOnly: boolean
    validationMode: string
    constructor(formConfig: FormConfig) {
        this.buttons = formConfig.buttons
        this.commitMode = 'change'//formConfig.commitMode
        this.disableSubmitButtonOnInvalidForm = (formConfig.hasOwnProperty('disableSubmitButtonOnInvalidForm')) ? formConfig.disableSubmitButtonOnInvalidForm : true
        this.focusOnFirstElement = (formConfig.hasOwnProperty('focusOnFirstElement')) ? formConfig.focusOnFirstElement : false
        this.formName = formConfig.formName
        this.groups = formConfig.groups
        this.isReadOnly = formConfig.isReadOnly
        this.fields = this.getMetadataFields(formConfig.fields)
    }
    checkMetadataFieldOptions(field: FormMetadataFieldInterface): FormMetadataCheckFieldInterface & FormMetadataCheckFieldValuesInterface {
        let result: FormMetadataCheckFieldInterface = {
            fieldType: true,
            icon: true,
            inputMode: true,
            max: true,
            maxLength: true,
            min: true,
            minLength: true,
            options: true,
            pattern: true,
            valueType: true
        }
        let values: FormMetadataCheckFieldValuesInterface = {
            requiredFieldType: null,
            requiredIcon: '',
            requiredInputMode: 'text',
            requiredMax: 9999,
            requiredMaxLength: 9999,
            requiredMin: (field.fieldType === 'datepicker') ? 1900 : 0,
            requiredMinLength: 0,
            requiredPattern: null,
            requiredValueType: ''
        }
        let options: FormMetadataFieldOptionsCheckInterface[] = filterInArray(FormMetadataFieldOptionsCheck, 'fieldType', field.fieldType)
        if (options.length > 0) {
            for (const option of options) {
                if (option.hasOwnProperty('requiredFieldType') && option.requiredFieldType) {
                    if (field.fieldType !== option.requiredFieldType) {
                        result.fieldType = false
                        values.requiredFieldType = option.requiredFieldType
                    }
                }
                if (option.hasOwnProperty('requiredIcon') && option.requiredIcon) {
                    result.icon = false
                    values.requiredIcon = option.requiredIcon
                }
                if (option.hasOwnProperty('requiredInputMode') && option.requiredInputMode) {
                    result.inputMode = false
                    values.requiredInputMode = option.requiredInputMode
                }
                if (option.hasOwnProperty('requiredOptions') && option.requiredOptions.length > 0) {
                    if (field.hasOwnProperty('options')) {
                        for (const prop of option.requiredOptions) {
                            if (!field.options.hasOwnProperty(prop)) {
                                result.options = false
                            }
                        }
                    } else {
                        result.options = false
                    }
                }
                if (option.hasOwnProperty('requiredPattern') && option.requiredPattern) {
                    result.pattern = false
                    values.requiredPattern = option.requiredPattern
                }
                if (option.hasOwnProperty('requiredValueType') && option.requiredValueType) {
                    if (typeof field.value !== option.requiredValueType) {
                        result.valueType = false
                        values.requiredValueType = option.requiredValueType
                    }
                }
            }
        }
        field.validators.forEach(
            value => {
                if (value.validatorType === 'max' && value.params && value.params.hasOwnProperty('maximum')) {
                    result.max = false
                    values.requiredMax = value.params.maximum
                }
                if (value.validatorType === 'maxLength' && value.params && value.params.hasOwnProperty('length')) {
                    result.maxLength = false
                    values.requiredMaxLength = value.params.length
                }
                if (value.validatorType === 'min' && value.params && value.params.hasOwnProperty('minimum')) {
                    result.min = false
                    values.requiredMin = value.params.minimum
                }
                if (value.validatorType === 'minLength' && value.params && value.params.hasOwnProperty('length')) {
                    result.minLength = false
                    values.requiredMinLength = value.params.length
                }
            }
        )
        return {
            ...result,
            ...values
        }
    }
    getMetadataFields(fields: FormMetadataFieldInterface[]): FormMetadataFieldInterface[] {
        const result: FormMetadataFieldInterface[] = []
        for (const field of fields) {
            const checkResult: FormMetadataCheckFieldInterface & FormMetadataCheckFieldValuesInterface = this.checkMetadataFieldOptions(field)
            if (checkResult.options) {
                const metadataField: FormMetadataFieldInterface = {
                    ...field
                }
                if (this.isReadOnly === true) {
                    metadataField.isReadOnly = this.isReadOnly
                }
                // Checking Results
                if (!checkResult.fieldType) {
                    metadataField.fieldType = checkResult.requiredFieldType
                }
                if (!checkResult.icon) {
                    if (metadataField.hasOwnProperty('options')) {
                        if (metadataField.options.hasOwnProperty('icon')) {
                            metadataField.options.icon.name = checkResult.requiredIcon
                        } else {
                            metadataField.options.icon = {
                                color: 'medium',
                                name: checkResult.requiredIcon
                            }
                        }
                    }
                }
                if (!checkResult.valueType) {
                    switch (checkResult.requiredValueType) {
                        case 'boolean':
                            metadataField.value = false
                            break
                        default:
                            metadataField.value = ''
                            break
                    }
                }
                metadataField.inputMode = checkResult.requiredInputMode
                metadataField.max = checkResult.requiredMax
                metadataField.maxLength = checkResult.requiredMaxLength
                metadataField.min = checkResult.requiredMin
                metadataField.minLength = checkResult.requiredMinLength
                metadataField.pattern = checkResult.requiredPattern
                result.push(metadataField)
            }
        }
        return result
    }
}