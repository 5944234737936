import { Injectable } from '@angular/core';
import { Geolocation, Geoposition, PositionError } from '@ionic-native/geolocation/ngx';
import { RxjsService, CustomObservableType } from '@services/rxjs.service';
import { PlatformService } from '@services/platform.service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ModalService } from '@services/modal.service';
import { enumDeviceTypes } from '@lib/promo-engine';

export interface CurrentPositionCoords {
    latitude: number | string
    longitude: number | string
}

const errorCodes = {
    1: 'User denied Geolocation',
    2: 'The gps is off',
    3: 'Timeout expired',
    5: 'We Can´t request permissions, user denied us to request permissions',
    7: 'The user denied authorization to access current location'
}

const actions = {
    "I": {
        1: "application_details",
        2: "locations",
        5: "application_details",
        7: "application_details"
    },
    "A": {1:"application_details",2:"location",5:"application_details",7:"application_details"},
    "M": {1:"info",2:"info",3:"info",5:"info",7:"info"},
    "D": {1:"info",2:"info",3:"info",5:"info",7:"info"}
} 



@Injectable({
    providedIn: 'root'
})

export class GeolocationService {
    public userPosition : any = false;
    constructor(
        private geolocation: Geolocation,
        private rxjsService: RxjsService,
        private platformService: PlatformService,
        private diagnostic: Diagnostic,
        private openNativeSettings: OpenNativeSettings,
        private modalService : ModalService

    ) { }

    public showModal(errorCode: number) {
        let device = this.platformService.getDevice();
        let keyLang = 'LANG_HELP_LOCATION';
        if(device == enumDeviceTypes.android || enumDeviceTypes.ios){
            keyLang = errorCode == 2  ? ( device == enumDeviceTypes.android  ? 'LANG_NO_LOCATION_ANDROID' : 'LANG_NO_LOCATION_IOS') :  'LANG_NO_PERMISSION_GRANTED';
        }
        this.modalService.openModalSettingsLocation(true, keyLang , {}).then(result => {
            if (result?.data?.result) {
                this.openNativeSettings_(errorCode);
            }
        },
            (err: any) => { }
        )
    }

    public openNativeSettings_(code) {
        let device = this.platformService.getDevice();
        let action = actions[device][code];
        if (device == enumDeviceTypes.webDesktop || device == enumDeviceTypes.webMobile) {
            let url  = this.platformService.isChrome() ? 'https://support.google.com/chrome/answer/142065' : this.platformService.isSafari() ? device == enumDeviceTypes.webMobile ? 'https://support.apple.com/HT207092' : 'https://support.apple.com/HT204690' : false;
            if(url){
                window.open(url);
            }
        } else {
            this.openNativeSettings.open(action).then(res => {
                console.log(res);
            }, error => {
                console.log(error);
            })
        }
    }

    getErrorMessage(errorCode, showModal = false): string {
        if (showModal) {
            this.showModal(errorCode)
        }
        return errorCodes[errorCode];
    }

    weCanStartLocation(): CustomObservableType<any> {
        const isLocationEnabledResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()

        if (this.platformService.isWeb()) {
            isLocationEnabledResult.next(true)
            isLocationEnabledResult.complete()
        } else {
            this.diagnostic.isLocationEnabled().then(
                (enabled) => {
                    if (enabled) {
                        this.isLocationAuthorized().subscribe(
                            (authorized) => {
                                isLocationEnabledResult.next(true)
                                isLocationEnabledResult.complete()
                            },
                            (error) => {
                                isLocationEnabledResult.error(error)
                                isLocationEnabledResult.complete()
                            }
                        )

                    } else {
                        isLocationEnabledResult.error({ 'code': 2, 'message': 'Device location is off' })
                        isLocationEnabledResult.complete()
                    }
                }
            ).catch(
                (error) => {
                    isLocationEnabledResult.error({ 'code': 0, 'message': 'Failed to check location status "on/off"' })
                    isLocationEnabledResult.complete()
                }
            );
        }
        return isLocationEnabledResult
    }

    isLocationAuthorized(): CustomObservableType<any> {
        const isLocationAuthorizedResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
        this.diagnostic.isLocationAuthorized().then(
            (authorized) => {
                if (authorized) {
                    isLocationAuthorizedResult.next(true)
                    isLocationAuthorizedResult.complete()
                } else {
                    this.getLocationAuthorizationStatus().subscribe(
                        (authStatus) => {
                            this.requestLocationAuthorization().subscribe(
                                (status) => {
                                    isLocationAuthorizedResult.next(true)
                                    isLocationAuthorizedResult.complete()
                                },
                                (error) => {
                                    isLocationAuthorizedResult.error(error)
                                    isLocationAuthorizedResult.complete()
                                }
                            )
                        },
                        (error) => {
                            isLocationAuthorizedResult.error(error)
                            isLocationAuthorizedResult.complete()
                        }
                    )
                }
            }
        ).catch(
            (error) => {
                isLocationAuthorizedResult.error({ 'code': 3, 'message': 'Failed to check location authorization status' })
                isLocationAuthorizedResult.complete()
            }
        );
        return isLocationAuthorizedResult
    }

    getLocationAuthorizationStatus(): CustomObservableType<any> {
        const getLocationAuthorizationStatusResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
        this.diagnostic.getLocationAuthorizationStatus().then(
            (authStatus) => {
                if (authStatus == 'not_determined' || authStatus == 'NOT_REQUESTED' || authStatus == 'DENIED_ONCE') {
                    getLocationAuthorizationStatusResult.next(true)
                    getLocationAuthorizationStatusResult.complete()
                } else {
                    getLocationAuthorizationStatusResult.error({ 'code': 5, 'message': 'Can´t request permission, authorization is ' + authStatus })
                    getLocationAuthorizationStatusResult.complete()
                }
            }
        ).catch(
            (error) => {
                getLocationAuthorizationStatusResult.error({ 'code': 4, 'message': 'Failed to get location authorization status' })
                getLocationAuthorizationStatusResult.complete()
            }
        );
        return getLocationAuthorizationStatusResult
    }

    requestLocationAuthorization(): CustomObservableType<any> {
        const requestLocationAuthorizationResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
        this.diagnostic.requestLocationAuthorization().then(
            (status) => {
                if (status == 'GRANTED' || status == 'authorized' || status == 'authorized_when_in_use') {
                    requestLocationAuthorizationResult.next(true)
                    requestLocationAuthorizationResult.complete()
                } else {
                    requestLocationAuthorizationResult.error({ 'code': 7, 'message': 'Authorization to access location is not granted' })
                    requestLocationAuthorizationResult.complete()
                }
            }
        ).catch(
            (error) => {
                requestLocationAuthorizationResult.error({ 'code': 6, 'message': 'Failed to request location authorization' })
                requestLocationAuthorizationResult.complete()
            }
        );
        return requestLocationAuthorizationResult
    }

    getCurrentPosition(): CustomObservableType<CurrentPositionCoords | PositionError> {

        let currentPositionResult: CustomObservableType<CurrentPositionCoords | PositionError> = this.rxjsService.createCustomObservable()


        if( this.platformService.isBrowser()){
        this.weCanStartLocation().subscribe(
            (wenCan) => {
                this.geolocation.getCurrentPosition( { timeout: 10000 }).then(
                    (result: Geoposition) => {
                        this.userPosition = { 'lat': result.coords.latitude, 'lng': result.coords.longitude };
                        currentPositionResult.next({ 'latitude': result.coords.latitude, 'longitude': result.coords.longitude })
                        currentPositionResult.complete()
                    },
                    (error: PositionError) => {
                        this.userPosition = false;
                        currentPositionResult.error({ 'code': error.code, 'message': error.message })
                        currentPositionResult.complete()
                    }
                )
            },
            (error) => {
                currentPositionResult.error(error)
                currentPositionResult.complete()
            }
        )
        }else{
            currentPositionResult.error('Mensaje de error')
            currentPositionResult.complete()
          }
        return currentPositionResult
    }

}
