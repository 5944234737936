import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statusbar-loyalty',
  templateUrl: './statusbar-loyalty.component.html',
  styleUrls: ['./statusbar-loyalty.component.scss'],
})
export class StatusbarLoyaltyComponent implements OnInit {

  @Input() set loyaltyPoints(value: number) {
    this.points = value ? value : 0
  }
  @Input() colorStatus: string;
  @Input() session: boolean;

  divisionBar: number;
  numberProportion: number = 5;
  totalPointGold: number = 9900;
  classStar: string;
  points: number
  
  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.divisionBar = this.totalPointGold / this.numberProportion;

    console.log(this.points)
    console.log(this.divisionBar)
    if(this.loyaltyPoints < this.divisionBar) {
      this.classStar = "star-loyalty-0";
    }

    if((this.points >= this.divisionBar) && (this.points < this.divisionBar*2)) {
      this.classStar = "star-loyalty-1";
    }

    if((this.points >= this.divisionBar*2) && (this.points < this.divisionBar*3)) {
      this.classStar = "star-loyalty-2";
    }

    if((this.points >= this.divisionBar*3) && (this.points < this.divisionBar*4)) {
      this.classStar = "star-loyalty-3";
    }

    if((this.points >= this.divisionBar*4) && (this.points < this.divisionBar*5)) {
      this.classStar = "star-loyalty-4";
    }

    if(this.points >= this.totalPointGold) {
      this.classStar = "star-loyalty-5";
    }

  }

}
