import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { IonSlides } from '@ionic/angular';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CrossSalesType } from '@lib/promo-engine';
import { LangService } from '@services/lang.service';
import { PlatformService } from '@services/platform.service';
import { PgCommon, WizardLineInterface } from '@lib/utils';
import { OnActionEventInterface } from '@core/components/button-add-quantity-cart/button-add-quantity-cart.component';

export interface CrossSalesListInterface {
  description: string
  lines: WizardLineInterface[]
  promoID: string
  webDescription: string
  type: CrossSalesType
}

@Component({
  selector: 'app-cross-sales',
  templateUrl: './cross-sales.component.html',
  styleUrls: ['./cross-sales.component.scss'],
})
export class CrossSalesComponent implements OnInit {
  public pgCommon = new PgCommon()
  public productsUrl: string = environment.productsUrl
  windowWidth: number;
  crossSalesList: CrossSalesListInterface[]
  orientation: string;
  @Input() set crossSales(value: CrossSalesListInterface[]) {
    this.crossSalesList = value
  }
  @Output() onAdd = new EventEmitter<CrossSalesListInterface>()
  slideOpts : {}
  
  private unsubscribe = new Subject<void>()
  @ViewChild('slidesCrossales') sliderElement: IonSlides;
  
  constructor(
    private readonly langService: LangService,
    private readonly platformService: PlatformService
    ) { }
  ngOnInit() {

    const langConfig = this.langService.getLangConfig()
    this.orientation = langConfig.dir


    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
        const langConfig = this.langService.getLangConfig()
        this.orientation = langConfig.dir
        this.sliderElement.update();
      }
    )

    if(!this.platformService.isServer()){
      this.windowWidth = window.innerWidth;
    }else{
      this.windowWidth = 600
    }


  }
  changeQuantity(crossSales: CrossSalesListInterface, eventQuantity: OnActionEventInterface): void {
    if(eventQuantity.action = "addQty")
    this.onAdd.emit(crossSales)
  }


  ngAfterContentChecked() {

    if (this.windowWidth <= 576) {
      this.slideOpts = {
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        slidesPerView: 1.1,
        spaceBetween: 0,
        centeredSlides: true,
      }
    } else {
      this.slideOpts = {
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true
      }
    }
  }



  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
  }
}