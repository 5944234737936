import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PlatformService } from '@services/platform.service';
import {LoaderService} from '@services/loader.service'
import copy from 'copy-to-clipboard';
import { Platform } from '@ionic/angular';
import { LangService } from '@services/lang.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-referals',
  templateUrl: './referals.component.html',
  styleUrls: ['./referals.component.scss'],
})
export class ReferalsComponent implements OnInit {

  assets: string = environment.assetsUrl;
  steps: string[];
  showTerms: boolean = false;
  windowWidth: number;
  private unsubscribe = new Subject<void>();
  lang: string;
  
  constructor(
    private apiService : ApiService,
    private socialSharing: SocialSharing,
    private platformService: PlatformService,
    private loaderService : LoaderService,
    private readonly platform: Platform,
    private readonly langService: LangService

  ) {}
  ngOnInit() {
    this.steps = ["LANG_INVITE_YOUR_FRIENDS_APP", "LANG_YOU_WILL_RECEIVE"];
    this.windowWidth = window.innerWidth;

    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang

    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.lang = result.lang
          }
        )
      }
    )
  }

  toggleTerms(){
    this.showTerms = !this.showTerms;
  }

  share() {
    this.apiService.shareReferal().subscribe(url => {
      if (this.platformService.isWeb()) {
        copy(url.link);
        this.loaderService.loadingController.create({
          message: 'Copied on clipboard',
          duration: 2000,
        }).then((res) => {
          res.present()
          res.onDidDismiss().then((dis) => {})
        })
      } else {
        this.socialSharing.shareWithOptions({ url: url.link });
      }
    }, error => { })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
  }

}
