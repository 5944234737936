import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { CustomBehaviorObservableType, RxjsService } from '@services/rxjs.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public removeLoader: CustomBehaviorObservableType<string>
  private unsubscribe = new Subject<void>()
  private status : boolean = false;
  constructor(
    public loadingController: LoadingController,
    private readonly rxJsService: RxjsService
  ) {
    this.removeLoader = this.rxJsService.createCustomBehaviorSubject<string>(null)
    this.removeLoader.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (value: string) => {
        if (value !== null) {
          this.hideLoader(value).then(
            (res) => {
              this.status = false;
              // console.log('Loading dismissed!', res)
            },
            (error) => {
              // console.log('error', error)
            }
          )
        }
      }
    )
  }
  ngOnDestroy(): void {
    // console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
  // This will show then autohide the loader
  showHideAutoLoader() {
    this.loadingController.create({
      message: 'This Loader Will Auto Hide in 2 Seconds',
      duration: 2000
    }).then((res) => {
      res.present()
      res.onDidDismiss().then((dis) => {
        console.log('Loading dismissed! after 2 Seconds', dis)
      })
    })
  }
  // Show the loader for infinite time
  showLoader(loaderID: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        id: loaderID,
        message: 'Please wait...'
      }).then(
        (res) => {
          this.status = true;
          res.present()
          resolve(true)
        },
        () => {
          reject(false)
        }
      ).catch(
        () => {
          reject(false)
        }
      )
    })
  }

  // Hide the loader if already created otherwise return error
  async hideLoader(loaderID: string): Promise<boolean> {
    try{
    return await this.loadingController.dismiss(null, null, loaderID)
    }catch(e){
      console.log(e)
    }
  }
  getStatus() : boolean{
    return this.status;
  }
}
