import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

import { FormValidatorType } from '../common/types';

export function OnMatch(controlName: string, matchingControlName: string): FormValidatorType {
    return (formGroup: FormGroup) => {
        const control: AbstractControl = formGroup.get(controlName)
        const matchingControl: AbstractControl = formGroup.get(matchingControlName)
        if (control.touched) {
            matchingControl.markAsTouched()
        }
        // return if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return
        }
        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true })
        } else {
            matchingControl.setErrors(null)
        }
        return formGroup
    }
}