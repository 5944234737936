import {
    MenuFamilyInterface,
    MenuFamilyProductInterface,
    MenuInterface,
    OrderLineTypes,
    ProductCalculatedResponse,
    ProductOptions,
    PromoDetailApplicableTo,
    PromoInterface,
    PromoLineInterface,
    PromoOptions,
    PromotionCalculatedResponse,
    PromoTypes
} from '../../../promo-engine/public-api';
import { PgCommon } from './common';
import { PgCustomizer } from './customizer';
import { MenuGroupInterface, PgMenu } from './menu';
import { CreateOrderLineItemsInterface, CreateOrderPromotionLineItemsInterface } from './order';

export interface WizardLineItemsInterface {
    group: MenuGroupInterface
    // parent: MenuFamilyInterface | MenuGroupInterface | MenuFamilyProductInterface
    // products: MenuFamilyProductInterface[]
}

export interface WizardLineInterface {
    addedItems: CreateOrderLineItemsInterface[]
    calculatedItems: CreateOrderLineItemsInterface[]
    id: number
    items: MenuGroupInterface[]
    promoLine: PromoLineInterface
    showInCart: boolean
    type: PromoDetailApplicableTo
}

export class PgWizard extends PgCommon {
    private crossSaleCommentLines: (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[]
    private currentStep: number
    private lines: WizardLineInterface[]
    private pgMenu: PgMenu
    private readonly promoEngineOptions: ProductOptions | PromoOptions
    private readonly promotion: PromoInterface
    private readonly promotions: PromoInterface[]
    private readonly promoUserID: string
    private quantity: number
    private totalSteps: number
    private valid: boolean
    constructor(menu: MenuInterface, promo: PromoInterface, promotions: PromoInterface[], promoEngineOptions: ProductOptions | PromoOptions) {
        super()
        this.currentStep = 0
        this.valid = true
        this.pgMenu = new PgMenu(menu, promotions)
        this.promoEngineOptions = promoEngineOptions
        this.promotion = promo
        this.promotions = promotions
        this.promoUserID = ('promoUserID' in promo) ? promo.promoUserID : null
        this.quantity = 1
        this.lines = this.getWizardLines(promo)
        this.totalSteps = this.filterInArray(this.getLines(), 'showInCart', true).length
        this.updateCurrentStep()
    }
    /**
     * @returns the list of products for the lines that do not need to choose product
     */
    private getWizardLineAddedItems(promoLine: PromoLineInterface): CreateOrderLineItemsInterface[] {
        const items: MenuFamilyProductInterface[] = this.getWizardLineItemProducts(this.getWizardSaleItems(promoLine) as MenuFamilyProductInterface[], promoLine)
        const result: CreateOrderLineItemsInterface[] = []
        if (items.length > 0) {
            items.forEach(
                value => {
                    if (promoLine.applicable === PromoDetailApplicableTo.saleItem && 'familyGroupID' in value) {
                        result.push(this.getItemToAddLineToWizard(value))
                    }
                }
            )
        }
        return result
    }
    /**
     * @returns the list of products that meet the exclusion and inclusion rules
     */
    private getWizardLineItemProducts(products: MenuFamilyProductInterface[], promoLine: PromoLineInterface): MenuFamilyProductInterface[] {
        const excludedItems = this.checkItems(promoLine.excludedItemsID)
        const excludedToppings = this.checkItems(promoLine.excludedToppingsID)
        const includedItems = this.checkItems(promoLine.includedItemsID)
        const includedToppings = this.checkItems(promoLine.includedToppingsID)
        const result: MenuFamilyProductInterface[] = []
        products.forEach(
            value => {
                let canAdd: boolean = true
                // Checking items
                if (excludedItems.length > 0 && (excludedItems.indexOf(value.id) >= 0 || excludedItems.indexOf(value.erpID) >= 0)) {
                    canAdd = false
                }
                if (includedItems.length > 0 && (includedItems.indexOf(value.id) < 0 && includedItems.indexOf(value.erpID) < 0)) {
                    canAdd = false
                }
                // Checking toppings
                /*if (value.hasOwnProperty('recipe') && Array.isArray(value.recipe) && value.recipe.length > 0) {
                    value.recipe.forEach(
                        v => {
                            // console.log(v)
                            // let topping: MenuFamilyToppingInterface = that.pgMenu.getFamilyTopping(value.familyGroupID, v.invSaleItemID)
                            // console.log(topping)
                            // if (topping && 'id' in topping && topping.grouppingID != 1) {
                            if (excludedToppings.length > 0 && excludedToppings.indexOf(v.invSaleItemID) >= 0) {
                                canAdd = false
                            }
                            if (includedToppings.length > 0 && includedToppings.indexOf(v.invSaleItemID) < 0) {
                                canAdd = false
                            }
                            // }
                        }
                    )
                }*/
                if (canAdd === true) {
                    result.push(this.copy(value))
                }
            }
        )
        return result
    }
    /**
     * @returns the list of groups with the products to choose
     */
    private getWizardLineItems(promoLine: PromoLineInterface): MenuGroupInterface[] {
        const items = this.getWizardSaleItems(promoLine)
        const lines: MenuGroupInterface[] = []
        const result: MenuGroupInterface[] = []
        items.forEach(
            value => {
                switch (promoLine.applicable) {
                    case PromoDetailApplicableTo.multiple:
                    case PromoDetailApplicableTo.family:
                        const family: MenuFamilyInterface = value as MenuFamilyInterface
                        const familyGroup: MenuGroupInterface = this.pgMenu.getGroup(family.webSalesGroup)
                        familyGroup.subGroups = this.filterInArray(familyGroup.subGroups, 'id', family.webSubsalesGroup)
                        familyGroup.subGroups.forEach(
                            val => {
                                val.families = this.filterInArray(val.families, 'id', family.id)
                                val.products = this.getWizardLineItemProducts(family.products, promoLine).sort((a, b) => a.order - b.order)
                            }
                        )
                        lines.push(familyGroup)
                        break
                    case PromoDetailApplicableTo.group:
                        const group: MenuGroupInterface = value as MenuGroupInterface
                        group.subGroups.forEach(
                            val => {
                                val.products = this.getWizardLineItemProducts(val.products, promoLine).sort((a, b) => a.order - b.order)
                            }
                        )
                        lines.push(group)
                        break
                }
            }
        )
        lines.forEach(
            value => {
                const groupPosition: number = this.findIndexInArray(result, 'id', value.id)
                if (groupPosition >= 0) {
                    result[groupPosition].subGroups = result[groupPosition].subGroups.concat(value.subGroups)
                } else {
                    result.push(value)
                }
            }
        )
        return result
    }
    /**
     * @returns the list of items to choose
     */
    private getWizardLines(promo: PromoInterface): WizardLineInterface[] {
        const result: WizardLineInterface[] = []
        if (promo.promoType !== PromoTypes.Descuento) {
            promo.lines.forEach(
                (value, index) => {
                    const wizard: WizardLineInterface = {
                        addedItems: [],
                        calculatedItems: [],
                        id: index,
                        items: [],
                        promoLine: value,
                        showInCart: true,
                        type: value.applicable
                    }
                    if (wizard.type === PromoDetailApplicableTo.saleItem) {
                        const wizardAddedLines = this.getWizardLineAddedItems(value)
                        if (wizardAddedLines.length > 0) {
                            wizardAddedLines.forEach(
                                v => {
                                    const family: MenuFamilyInterface = this.pgMenu.getFamily(v.familyID)
                                    if (family && !this.pgMenu.isFamilyVisibleAtWeb(family)) {
                                        wizard.showInCart = false
                                    }
                                }
                            )
                            wizard.addedItems = wizardAddedLines
                            result.push(wizard)
                        } else {
                            this.valid = false
                        }
                    } else {
                        const wizardLines = this.getWizardLineItems(value)
                        if (wizardLines.length > 0) {
                            wizard.items = wizardLines
                            result.push(wizard)
                        } else {
                            this.valid = false
                        }
                    }
                }
            )
        }
        return result
    }
    /**
     * @returns the list of sale items checking that they are not repeated or empty
     */
    private getWizardSaleItems(promoLine: PromoLineInterface): (MenuFamilyInterface | MenuGroupInterface | MenuFamilyProductInterface)[] {
        const saleItems: string[] = [
            promoLine.saleItemID,
            promoLine.saleItemID2,
            promoLine.saleItemID3
        ].filter(Boolean)
        const items: string[] = []/*[
            ...new Set([
                promoLine.saleItemID,
                promoLine.saleItemID2,
                promoLine.saleItemID3
            ])
        ].filter(Boolean)*/
        saleItems.forEach(
            value => {
                const itemsList: string[] = value.split(';').filter(Boolean)
                itemsList.forEach(
                    v => {
                        if (items.indexOf(v) < 0) {
                            items.push(v)
                        }
                    }
                )
            }
        )
        const result: (MenuFamilyInterface | MenuGroupInterface | MenuFamilyProductInterface)[] = []
        items.forEach(
            value => {
                switch (promoLine.applicable) {
                    case PromoDetailApplicableTo.multiple:
                    case PromoDetailApplicableTo.family:
                        const family = this.pgMenu.getFamily(parseInt(value))
                        if (family && family.hasOwnProperty('products') && family.products.length > 0) {
                            result.push(family)
                        } else {
                            // this.valid = false
                        }
                        break
                    case PromoDetailApplicableTo.group:
                        const group: MenuGroupInterface = this.pgMenu.getGroup(parseInt(value))
                        if (group && group.hasOwnProperty('subGroups') && group.subGroups.length > 0) {
                            result.push(group)
                        } else {
                            // this.valid = false
                        }
                        break
                    case PromoDetailApplicableTo.saleItem:
                        const product: MenuFamilyProductInterface = this.pgMenu.getProduct(value)
                        if (product) {
                            result.push(product)
                        } else {
                            // this.valid = false
                        }
                        break
                }
            }
        )
        if (result.length > 0) {
            result.sort((a, b) => a.order - b.order)
        } else {
            this.valid = false
        }
        return result
    }
    /**
     * Update the number of promo lines that already have a selected product
     */
    private updateCurrentStep(): void {
        this.currentStep = 0
        this.filterInArray(this.getLines(), 'showInCart', true).forEach(
            value => {
                this.currentStep += value.addedItems.length
            }
        )
    }
    // Public methods
    /**
     * @returns a boolean that indicates whether the product has been added or not
     */
    public addItemsToLine(items: CreateOrderLineItemsInterface, id: number): boolean {
        const position: number = this.findIndexInArray(this.getLines(), 'id', id)
        if (position >= 0) {
            this.lines[position].addedItems = [items]
            this.updateCurrentStep()
            return true
        }
        return false
    }
    public getCrossSaleCommentLines(): (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[] {
        return this.crossSaleCommentLines
    }
    /**
     * @returns the number of lines that already have a chosen product
     */
    public getCurrentStep(): number {
        return this.currentStep
    }
    /**
     * @returns the information to add a product to the wizard
     */
    public getItemToAddLineToWizard(product: MenuFamilyProductInterface, promoEngineResult: ProductCalculatedResponse = null): CreateOrderLineItemsInterface {
        const family: MenuFamilyInterface = this.pgMenu.getFamily(product.familyGroupID)
        const pgCustomizer: PgCustomizer = new PgCustomizer(family, product, this.pgMenu.getMenu(), '')
        if (promoEngineResult) {
            pgCustomizer.updateCustomizerFromPromoEngineResult(promoEngineResult)
        }
        const items: CreateOrderLineItemsInterface = pgCustomizer.getItemsToCreateOrderLine(1)
        if (promoEngineResult) {
            items.promoEngineResult = promoEngineResult
        } else {
            items.promoEngineResult = this.calculateProduct(this.promoEngineOptions, this.pgMenu.getMenu(), items.productID, items.toppings)
        }
        return items
    }
    /**
     * @returns the list of properties required to save it on the order lines
     */
    public getItemsToCreateOrderPromotionLine(): CreateOrderPromotionLineItemsInterface {
        return {
            currentStep: this.getCurrentStep(),
            lines: this.getLines().map(
                value => {
                    const line = this.copy(value)
                    return line
                }
            ),
            menu: this.pgMenu.getMenu(),
            promotionID: this.promotion.id,
            promotions: this.getPromotions(),
            promoUserID: this.getPromoUserID(),
            quantity: this.getQuantity(),
            totalSteps: this.getTotalSteps(),
            unitPrice: 0
        }
    }
    /**
     * @returns a line that matches the given id
     */
    public getLineById(id: number): WizardLineInterface {
        return this.findInArray(this.getLines(), 'id', id)
    }
    /**
     * @returns the promo lines from outside the class
     */
    public getLines(): WizardLineInterface[] {
        return this.copyArray(this.lines)
    }
    /**
     * 
     * @returns the current promotion
     */
    public getPromotionInfo(): PromoInterface {
        return this.copy(this.promotion)
    }
    /**
     * 
     * @returns the list of promotions
     */
    public getPromotions(): PromoInterface[] {
        return this.copyArray(this.promotions)
    }
    public getPromoUserID(): string {
        return this.promoUserID
    }
    /**
     * @returns the number of lines in a promo
     */
    public getTotalSteps(): number {
        return this.totalSteps
    }
    public getQuantity(): number {
        return this.quantity
    }
    public isCompleted(): boolean {
        return this.getTotalSteps() === this.getCurrentStep()
    }
    /**
     * 
     * @returns the wizard status
     */
    public isValid(): boolean {
        return this.valid
    }
    /**
     * Removes added items from a line
     */
    public removeItemsFromLine(id: number): void {
        const position: number = this.findIndexInArray(this.getLines(), 'id', id)
        if (position >= 0) {
            const line: WizardLineInterface = this.getLines()[position]
            if (line.type !== PromoDetailApplicableTo.saleItem) {
                line.addedItems = []
                this.updateLine(line)
            }
        }
    }
    public updateCrossSaleCommentLines(l: (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[]) {
        this.crossSaleCommentLines = l
    }
    /**
     * Update a line with a given id
     */
    public updateLine(line: WizardLineInterface): void {
        const position: number = this.findIndexInArray(this.getLines(), 'id', line.id)
        if (position >= 0) {
            this.lines[position].addedItems = line.addedItems
            this.lines[position].calculatedItems = line.calculatedItems
            this.updateCurrentStep()
        }
    }
    public updateLinesPromoDiscount(promoCalculated: PromotionCalculatedResponse): void {
        if (promoCalculated && promoCalculated.sublines && Array.isArray(promoCalculated.sublines) && promoCalculated.sublines.length > 0) {
            this.lines = promoCalculated.sublines.reduce(
                (result: WizardLineInterface[], value, index) => {
                    let productID: string = value.saleItemID
                    if (value.type === OrderLineTypes.composed) {
                        productID = value.sublines[0].saleItemID
                    }
                    let product: MenuFamilyProductInterface = this.pgMenu.getProduct(productID)
                    let items = this.getItemToAddLineToWizard(product, value)
                    items.comments = ('comments' in value) ? value.comments : null
                    result.push({
                        addedItems: [items],
                        calculatedItems: [],
                        id: index,
                        items: [],
                        promoLine: null,
                        showInCart: true,
                        type: null
                    })
                    return result
                }, []
            )
            this.totalSteps = this.lines.length
            this.updateCurrentStep()
        }
    }
    public updateQuantity(q: number): void {
        this.quantity = q
    }
}