import { Injectable } from '@angular/core';
interface Scripts {
  name: string;
  src: string;
}
interface ChatScript extends Scripts {
  async: string
  dataBotHash: string
  dataClient: string
  dataEnv: string
  dataInitType: string
  dataRegion: string
  dataSelfInit: string
  id: string
}
const ChatScriptStore: ChatScript[] = [
  {
    async: 'async',
    dataBotHash: '0f18aed3d29ac9d7127038977a7e57560422bcd5',
    dataClient: '0cf0c344c8a8dbbf83e79be120c110831d006a78',
    dataEnv: 'prod',
    dataInitType: 'opt',
    dataRegion: 'us',
    dataSelfInit: 'false',
    id: 'spd-busns-spt',
    name: 'chatBot',
    src: 'https://cdn.freshbots.ai/assets/share/js/freshbots.min.js'
  }
]
export const ScriptStore: Scripts[] = [
  { name: 'hereCore', src: 'https://js.api.here.com/v3/3.1/mapsjs-core.js' },
  { name: 'hereService', src: 'https://js.api.here.com/v3/3.1/mapsjs-service.js' },
  { name: 'hereUi', src: 'https://js.api.here.com/v3/3.1/mapsjs-ui.js' },
  { name: 'hereEvents', src: 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js' }
];

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  constructor() {
    ChatScriptStore.forEach(
      script => {
        this.scripts[script.name] = {
          loaded: false,
          ...script
        }
      }
    )
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
    //  this.loadScripstMaps();
  }

  // public load(...scripts: string[]) {
  //   var promises: any[] = [];
  //   scripts.forEach((script) => promises.push(this.loadScript(script)));
  //   return Promise.all(promises);
  // }

  public loadScriptsChatBot() {
    let scripts: string[] = ['chatBot']
    let promises: any[] = []
    scripts.forEach(
      value => {
        promises.push(this.loadScript(value))
      }
    )
    return Promise.all(promises)
  }

  public loadScripstMaps() {
    let scriptsMaps = ['hereCore','hereService','hereUi','hereEvents'];
    var promises: any[] = [];
    scriptsMaps.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        // console.log("already");
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (name === 'chatBot') {
          Object.keys(ChatScriptStore[0]).forEach(
            value => {
              if (['async', 'name'].indexOf(value) < 0) {
                let attributeKey: string = ''
                for (let i = 0; i < value.length; i++) {
                  let character: string = value.charAt(i)
                  if (character == character.toUpperCase()) {
                    attributeKey += `-${character.toLowerCase()}`
                  } else {
                    attributeKey += character
                  }
                }
                script.setAttribute(attributeKey, ChatScriptStore[0][value])
              }
            }
          )
          script.async = 'async'
        }
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            // console.log("Loaded");
              resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
