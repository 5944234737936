import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomService {
  private _window: any
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this._window = this.document.defaultView
  }
  get nativeWindow(): any {
    return this._window
  }
  setDirection(dir: string): void {
    this.document.dir = dir
  }
}
