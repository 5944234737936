import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { environment } from '@environments/environment';
import { HereService } from '@services/here.service';
import { FormComponent } from '@core/modules/angular-ionic-forms/form/form.component';
import { ApiService } from '@services/api.service';
import { DeliveryService } from '@services/delivery.service';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ModalService } from '@services/modal.service';
import { StorageService } from '@services/storage.service';
import { RxjsService } from '@services/rxjs.service';
import { SelectPromiseTimeService } from '@services/select-promise-time.service';
import { LangService } from '@services/lang.service';
import { PlatformService } from '@services/platform.service';
import { DatePipe } from '@angular/common';
import { DataService } from '@services/data.service';
import {
  CalculatedLinesResponse,
  MenuInterface,
  OrderInterface,
  OrderTypes,
  PromoInterface
} from '@lib/promo-engine';
import {
  CreateOrderInterface,
  PgMenu,
  PgOrder
} from '@lib/utils';
import { filter } from 'rxjs/operators';
import { PreviousRouteService } from '@services/previous-route.service';
import { BranchesService } from '@services/branches.service';
import { FaeventsService } from '@services/faevents.service';

class ScheduleTimer {

  private time: number;
  private callback;
  private ref: any;
  private data: any;

  constructor(time: number, data: any, callback) {
    this.time = time;
    this.data = data;
    this.callback = callback;
  }

  public start() {
    let that = this;
    this.ref = setTimeout(function () {
      that.callback(that.data);
    }, this.time)
  }

  public stop() {
    clearTimeout(this.ref)
  }

}

@Component({
  selector: 'app-delivery-details-form',
  templateUrl: './delivery-details-form.component.html',
  styleUrls: ['./delivery-details-form.component.scss'],
})
export class DeliveryDetailsFormComponent implements OnInit {


  formMetadata: FormMetadata
  formMetadataLocationName: FormMetadata
  formMetadataLocationInfo: FormMetadata
  formMetadataHouse: FormMetadata
  formMetadataApartment: FormMetadata
  formMetadataOffice: FormMetadata
  selectedBranch: any;
  type: any = false;
  showList: boolean;
  @Output() setValueToShow = new EventEmitter();
  assets: string = environment.assetsUrl;
  types: any[] = [];
  @Output() canShowMap = new EventEmitter();
  map: boolean = false;
  windowWidth: number;
  windowHeigth: number;
  subscription: any;
  subscriptionOld: any;
  AddresSelected : any | boolean;
  @Output() refreshDirec = new EventEmitter();
  @Input() wkngMethod: string = 'account';
  subscriptionDropDown: any;
  addressDisplay: any[];
  deliveryAddressSelected: any | boolean = false;
  addressesDelivery: any;
  pendingTimers = [];
  disablePlaceOrder: boolean = true;
  @Output() locate = new EventEmitter();
  requiredAddress : any = false;

  @Input() set closeMap(value) {
    if (value == false) {
      this.map = false;
    }
  }
  discover = {
    selected: {},
    discovered: []
  };
  @ViewChild('locationName',{static:false}) formName: FormComponent;
  @ViewChild('locationAddress',{static:false}) formAddress: FormComponent;
  @ViewChild('locationInfo',{static:false}) formInfo: FormComponent;

  isNewLocation: boolean = false;
  @Output() simulateScroll = new EventEmitter();
  route: string
  isDesktop : any = false;
  errorType : boolean =false;
  public errorCheckBranch = {
    text : '',
    show : false
  };
  deliveryTime_  : any = false;
  total: number
  pgMenu: PgMenu
  pgOrder: PgOrder
  order: OrderInterface = null
  lines: CalculatedLinesResponse[] = []
  enabledCallPromises : boolean = false;
  navSubscription: any
  currentRoute: string
  buttonSelect: boolean = false;
  oldData : any = false;
  onUpdateDeliveryTime: any
  @Input() set addresses(value: any[]) {
    console.log(value)
    this.addressList = value
  }
  addressList: any[]

  deliveryFee: number = 0
  promos:PromoInterface[]

  constructor(
    private readonly hereS: HereService,
    private pTimeService: SelectPromiseTimeService,
    private api:ApiService,
    private deliveryService:DeliveryService,
    private location: Location,    
    private readonly modalService: ModalService,
    private readonly storage: StorageService,
    private readonly rxJsService: RxjsService,
    private lngSer : LangService,
    private platformService : PlatformService,
    private datePipe: DatePipe,
    private readonly dataService: DataService,
    private ngZone: NgZone,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private readonly branchesService: BranchesService,
    private faevents : FaeventsService
    ) {

    this.isDesktop = this.platformService.isDesktop();
    this.AddresSelected = false;
    this.types = [{ id: 'H', alias: 'LANG_HOUSE', imgName: 'house' },
    { id: 'O', alias: 'LANG_OFFICE', imgName: 'office' },
    { id: 'A', alias: 'LANG_APARTMENT', imgName: 'apartment' },
    { id: 'C', alias: 'LANG_NAME_OWN_LOCATION', imgName: 'custom-location' }
    ]

    if (!this.platformService.isServer()) {
      this.navSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.fillCart()
      this.route = this.api.getStatusToGo()
    })

    this.onUpdateDeliveryTime = this.pTimeService.onrefreshDeliveryTime()
    .subscribe(time => {
      let zone = this.deliveryService.getZoneDelivery();
      if(zone){
        this.setDeliveryTime_(zone.branch);
      }
    });
  }


  }

  ngOnInit() {

    this.currentRoute = this.router.url;

    this.subscription = this.hereS.getLocationEmitterFromMap()
    .subscribe(newLocation => {
      this.clearErrorCheckBranch();
      this.showTextNewAddress(newLocation);
      if(newLocation){
        this.discoverFromPosition(newLocation);
      }

    });

    this.subscriptionOld = this.hereS.getLocationEmitterFromOldLocation()
      .subscribe(address => {
        if(address){
          this.formAddress.lock();
          this.hereS.mapInteractionLocked = true;
        }else{
          this.formAddress.unlock();
          this.hereS.mapInteractionLocked = false;
        }
        this.fillFieldsForm(address);
      });

    this.subscriptionDropDown = this.deliveryService.onUpdateDropDown()
      .subscribe(addresses => {
       this.fill_up_dropdown(addresses);
      });

      if (!this.platformService.isServer()) {
        this.windowWidth = window.innerWidth;
        this.windowHeigth  = window.innerHeight;
      }

    const fieldsLocationName: FormMetadataFieldInterface[] = [
      {
        group: "2",
        id: "locationName",
        isReadOnly: false,
        fieldType: 'text',
        name: 'locationName',
        placeholder: '',
        position: 5,
        class: "",
        disabled: false,
        label: "LANG_LOCATION_NAME",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_NAME',
            params: {},
            validatorType: 'required'
          },
        ],
        value: ''
      }
    ]

    const fieldsLocationInfo: FormMetadataFieldInterface[] = [
      {
        group: "2",
        id: "city",
        isReadOnly: true,
        fieldType: 'text',
        name: 'city',
        placeholder: '',
        position: 3,
        class: "",
        disabled: false,
        label: "LANG_CITY",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      },
      {
        group: "2",
        id: "instructions",
        isReadOnly: false,
        fieldType: 'textarea',
        name: 'instructions',
        placeholder: '',
        position: 4,
        class: "textarea",
        disabled: false,
        label: "LANG_INFO_FOR_DELIVERY",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        },
        {
          errorMsg: '',
          params: {
            length: 199
          },
          validatorType: 'maxLength'
        }],
        value: ''
      }
    ]

    const fields: FormMetadataFieldInterface[] = [
      {
        group: "1",
        id: "address",
        isReadOnly: false,
        fieldType: 'text',
        name: 'address',
        placeholder: '',
        position: 2,
        class: "",
        disabled: false,
        label: "LANG_ADDRESS",
        options: {
          icon: {'name': 'locate','color':'primary', 'class': 'circleButtonInput', 'position': 'start'},
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      }
    ]

    const fieldsTypeHouse: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "houseNumber",
        isReadOnly: false,
        fieldType: 'text',
        name: 'houseNumber',
        placeholder: '',
        position: 1,
        class: "",
        disabled: false,
        label: "LANG_HOUSE_NUMBER",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      }

    ]

    const fieldsTypeOffice: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "officeNumber",
        isReadOnly: false,
        fieldType: 'text',
        name: 'officeNumber',
        placeholder: '',
        position: 1,
        class: "",
        disabled: false,
        label: "LANG_OFFICE_NUMBER",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      },
      {
        group: "",
        id: "floorNumber",
        isReadOnly: false,
        fieldType: 'text',
        name: 'floorNumber',
        placeholder: '',
        position: 2,
        class: "",
        disabled: false,
        label: "LANG_FLOOR_NUMBER",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      },

    ]

    const fieldsTypeApartment: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "apartmentNumber",
        isReadOnly: false,
        fieldType: 'text',
        name: 'apartmentNumber',
        placeholder: '',
        position: 1,
        class: "",
        disabled: false,
        label: "LANG_APARTMENT_NUMBER",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      },
      {
        group: "",
        id: "floorNumber",
        isReadOnly: false,
        fieldType: 'text',
        name: 'floorNumber',
        placeholder: '',
        position: 2,
        class: "",
        disabled: false,
        label: "LANG_FLOOR_NUMBER",
        options: {
          label: {
            color: "secondary",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        }],
        value: ''
      }
    ]
    this.formMetadata = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fields,
      formName: 'deliveryForm1',
      groups: [],
      isReadOnly: false
    })
    this.formMetadataLocationName = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fieldsLocationName,
      formName: 'deliveryForm2',
      groups: [],
      isReadOnly: false
    })

    this.formMetadataLocationInfo = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fieldsLocationInfo,
      formName: 'deliveryForm3',
      groups: [],
      isReadOnly: false
    })

    this.formMetadataHouse = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fieldsTypeHouse,
      formName: 'deliveryForm3',
      groups: [],
      isReadOnly: false
    })
    this.formMetadataApartment = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fieldsTypeApartment,
      formName: 'deliveryForm4',
      groups: [],
      isReadOnly: false
    })
    this.formMetadataOffice = new FormMetadata({
      buttons: [],
      commitMode: 'change',
      fields: fieldsTypeOffice,
      formName: 'deliveryForm5',
      groups: [],
      isReadOnly: false
    })
  }


  fillCart(): void {
    this.rxJsService.createObservableFromMultipleRequests([
      this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
      this.rxJsService.createObservableFromPromise(this.dataService.getOrder()),
      this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
    ]).subscribe(
      (result: [MenuInterface, CreateOrderInterface, PromoInterface[]]) => {
        //   
        const menu: MenuInterface = result[0]
        const order: CreateOrderInterface = result[1]
        const promotions: PromoInterface[] = result[2]
        this.pgOrder = new PgOrder(order, menu, promotions)
        this.order = this.pgOrder.prepareOrderToSaveTicket(false)
        this.lines = (this.pgOrder.getExcludedOrderType() === OrderTypes.Domicilio) ? [] : this.order.lines
        this.recalculateCart()
        this.pgMenu = new PgMenu(menu, promotions, true)
        this.promos = promotions;
      },
      () => {
        this.pgOrder = new PgOrder(null, null, null)
        this.lines = []
      }
    )

  }

  recalculateCart(): void {
    this.total = 0
    if (this.order && 'lines' in this.order && this.order.lines.length > 0) {
      this.order.lines.forEach(
        value => {
          this.total += value.unitPrice
        }
      )
      const deliveryFeeLine = this.pgOrder.getDeliveryFeeLine()

      if (deliveryFeeLine && 'unitPrice' in deliveryFeeLine && deliveryFeeLine.unitPrice > 0) {
        this.total += deliveryFeeLine.unitPrice
        this.deliveryFee = deliveryFeeLine.unitPrice
      }
      this.total = this.pgOrder.parseToDecimals(this.total)
      console.log(this)
    }   
  }

  selectTypeBuilding(value) {
    this.type = value;
    if(this.type.id == 'C'){
      let that = this;
      setTimeout(function(){
        that.formName.setFocus();
      },300)
    }
    this.errorType = false;
    this.toogleAddresses()
  }


  save(event: any,remove : boolean = false) {
    let canSave = true

    if(!this.type){
      this.errorType = true;
      return;
    }
    let addSel = this.AddresSelected;
    let instructions = this.formInfo.getValue('instructions') || '';
    let position = this.hereS.getLocation();

    let address = {
      addressID: addSel &&  addSel.address.addressID >0 ? addSel.address.addressID : -1,
      addressType: this.type, // H. O. A. C.
      description : '', // location name
      additionalIndications: instructions, // info for delivery 'instructions'
      lat: position.lat,
      lng: position.lng,
      resolvedAddress: addSel ? addSel.address.street : '', // resolved or saved
    }

    if (this.type.id == 'C') {
        address.description = this.formName.getValue('locationName');
        if(address.description.length<=0){
          return;
        }
    }else{
        address.description = this.getDescriptionFromType(this.type.id);
    }

    if(this.discover.selected && this.discover.selected.hasOwnProperty('resultType') ){ // get the new resolved addres
      address.resolvedAddress = this.getAddressFromDicover(this.discover.selected);
    }

    let descriptionMatch = false;
    let resolvedAddressMatch = false;

    if(  !remove && ( (!(this.AddresSelected && this.AddresSelected.address.addressID >0)) || this.AddresSelected.address.addressID >0) ){
      this.addressList.forEach(
        addr => {
          if (addr.addressType == 'C' && addr.description == address.description ) {
            canSave = false
            descriptionMatch = true
          }
  
          if (addr.resolvedAddress == address.resolvedAddress) {
            canSave = false
            resolvedAddressMatch = true
          }
  
        })
    }

      if(canSave){
        this.updateAddress(address,remove);
      }else{
        let msg
        if(descriptionMatch && resolvedAddressMatch){
          msg = "LANG_NAME_AND_ADDRESS_ALREADY_USED"
          this.modalService.openModalWithCustomMsg(msg).then(
            () => { },
            () => { }
          )
        }else if(descriptionMatch){
          msg = "LANG_NAME_ALREADY_USED"
          this.modalService.openModalWithCustomMsg(msg).then(
            () => { },
            () => { }
          )
        }else if(resolvedAddressMatch){
          msg = "LANG_ADDRESS_ALREADY_USED"
          this.modalService.openModalWithCustomMsg(msg).then(
            () => { },
            () => { }
          )
        }
        
      }

  }

  close() {

    let actual = this.hereS.getLocation();
    let saved = this.oldData.position;
   
    // console.log("LOG ACTUAL ",actual);
    // console.log("LOG SAVED ",saved);
    // console.log("LOG OLD DATA",this.oldData.position);
   
    if(saved!=undefined && ( actual.lat != saved.lat || actual.lng != saved.lng) ){
        this.autoSelAddress(saved);
        this.hereS.setLocation(saved);
        // console.log("LOG SI HA CANVIADO");
      }
      // else{
      //   console.log("LOG NO HA CANVIADO");
      // }

    this.setValueToShow.emit('cart');
    this.canShowMap.emit(false);

    if(this.wkngMethod == 'delivery' && !this.map){
      this.location.back();
    }else if(this.wkngMethod == 'delivery' && this.map){

      this.map = !this.map;
      this.buttonSelect_call();
      this.canShowMap.emit({show:this.map,address:null});
      
    }
  }


  mergeInArray(olDD, newAddresess) {
    let res = [];
    let temp = [];
    olDD.forEach(element => {
      res.push(element);
    });
    newAddresess.forEach(newAddress => {
      let find = false;
      for (let index = 0; index < olDD.length; index++) {
        const old = olDD[index];
        if (old?.addressID && old.addressID == newAddress.addressID) {
          find = true;
          break;
        }
      }
      if (!find) {
        this.types = [{ id: 'H', alias: 'LANG_HOUSE', imgName: 'house' },
        { id: 'O', alias: 'LANG_OFFICE', imgName: 'office' },
        { id: 'A', alias: 'LANG_APARTMENT', imgName: 'apartment' },
        { id: 'C', alias: 'LANG_NAME_OWN_LOCATION', imgName: 'custom-location' }
        ]
        newAddress.alias =  newAddress.addressType== 'H' ? 'LANG_HOUSE' : (newAddress.addressType== 'O' ? 'LANG_OFFICE' : (newAddress.addressType== 'A') ? 'LANG_APARTMENT' : newAddress.description)
        newAddress.imgName = newAddress.addressType== 'H' ? 'house' : (newAddress.addressType== 'O' ? 'office' : (newAddress.addressType== 'A') ? 'apartment' : 'custom-location')
        temp.push(newAddress);
      }
    });

    let size = res.length > 0 ? res.length -1 : 0;
    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      res.splice(size + index, 0, element);
    }
    // console.log(res);
    return res;
  }

  async showAddresses(){

    let addresses = await this.deliveryService.getAddresses();
    this.addressDisplay = this.mergeInArray(this.addressDisplay,addresses);
    // console.log(this.addressDisplay );

    this.modalService.openModaAddresses("LANG_SELECT_DELIVERY_ADDRESS", this.addressDisplay).then(
      (res) => {
        // console.log('Success', res)
        // console.log('Success', res.data.result)
        if(res?.data?.result){
          this.selectAddress(res.data.result)
          this.clearErrorCheckBranch();
        }
      },
      () => {
        console.log('Error')
      }
    )

  }

  toogleAddresses() {
    this.showList = !this.showList;
  }

  toggleMap() {
    // console.log("draw on ",this.hereS.getLocation());
    this.map = !this.map;
    if(this.wkngMethod == 'account'){
      this.canShowMap.emit(this.map);
    }else{
     if(this.map){
      this.oldData = {};
      this.oldData.position = this.hereS.getLocation();
     }
    //  else{
    //    console.log("CLOSE");
    //  }
      this.buttonSelect_call();
      this.canShowMap.emit({show:this.map,address:this.hereS.getLocation()});
    }
  }

  discoverFromPosition(newLocation: any) {
    let Str = newLocation.lat + "," + newLocation.lng;
    let that = this;
    this.hereS.reveseGeoCode(Str).subscribe(
      (result) => {
        if (result.items.length > 0) {
          let newLocation = result.items[0];
          that.selectDiscovered(newLocation, false);
        }
      },
      (notFound) => {
        console.log(notFound);
      })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionOld.unsubscribe();
    this.subscriptionDropDown.unsubscribe();
    this.navSubscription.unsubscribe();
    this.hereS.mapInteractionLocked = false;
  }

  discoverAddress(event: any) {
    // console.log(event);
    let address = event.value.address;

    if (address != '') {
      this.hereS.discover(address).subscribe(
        (result) => {
          this.discover.discovered = this.filterDiscovered(result.items);
        },
        (notFound) => {
          console.log(notFound);
        })
    } else {
      // this.discover.selected = {};
      this.formAddress.updateForm('address', '');
      this.formInfo.updateForm('city', '');
      // this.hereS.newLocationEmmit(false);
      this.formAddress.setError('address','');
      this.requiredAddress = true;
    }

  }

  selectDiscovered(data: any, launchEmitter: boolean = true) {
    // console.log(data);
    this.requiredAddress = false;
    this.clearErrorCheckBranch();
    let display = '';
    let city = '';
    let instructions = '';
    let name = '';

    if (data?.resultType && data?.resultType == 'place' &&  data?.title) {
      display = data.title+", ";
    }

    if (data.address?.street) {
      display += data.address?.street
    }
    if (data.address?.houseNumber) {
      display += " "+data.address?.houseNumber
    }
    if(data.address?.district){
      display += "," +data.address?.district
    }
    if (data.address?.city) {
      city += data.address?.city
    }
    if (data.address?.instructions) {
      instructions = data.address?.instructions
    }
    if (data.address?.name) {
      name = data.address?.name
    }

    if (data.address?.type) {
      this.type = data.address?.type
      if (this.type.id == 'C') {
        let that = this;
        if(this.wkngMethod == 'account'){
        setTimeout(function(){
          if (name == 'LANG_LAST_ORDER') {
            that.lngSer.getTranslation(name).subscribe(tra => {
              that.formName.updateForm('locationName', tra);
            }, error => {
              that.formName.updateForm('locationName', name);
            });
          } else {
            that.formName.updateForm('locationName', name);
          }
        },0)
      }
      }
    }

    this.discover.selected = data;
    this.formAddress.updateForm('address', display);
    this.formInfo.updateForm('city', city);
    this.discover.discovered = [];

    if (launchEmitter) {
      this.showTextNewAddress(data.position);
      this.hereS.newLocationEmmit(data.position);
    } else {
      this.hereS.setLocation(data.position);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    this.windowHeigth  = window.innerHeight;
  }


  // this.simulateScroll.emit(test);



  onIonScrollEvent(event: any) {
    this.simulateScroll.emit(event.detail.scrollTop);
  }

  onIonScrollStartEvent(event: any) {

  }

  public fillFieldsForm(addSel: any) {
    if(!addSel){
      try{
        this.formName.updateForm('locationName', '');
      }catch(e){}
    }
    let street = addSel && addSel.resolvedAddress != '' ? addSel.resolvedAddress : null;
    let city = '';
    let instructions = addSel && addSel.additionalIndications != '' ? addSel.additionalIndications : null;
    let type = false;
    let name = addSel && addSel.description != '' ? addSel.description : null;
    let position = addSel ? {lat:addSel.lat,lng:addSel.lng} : false;

    if(addSel.addressType == 'H' || addSel.addressType == 'O' || addSel.addressType == 'A' ||  addSel.addressType == 'C'){
        type = this.getTypeByLetter(addSel.addressType);
    }

    if (street) {
      let tmp = street.split(",");
      let size = tmp.length;

      if (size > 1) {
        city = tmp[size - 1];
        street = street.replace(","+city,'');
      } else {
        city = null;
      }
    } else {
      city = null;
    }
    let add = {
      address:
      {
        "street": street,
        "city": city,
        "instructions":instructions,
        "type":type,
        "name":name,
        "addressID":addSel.addressID
      },
      position : position
    }
    // console.log(add);
    let launchEmitter = false;
    this.selectDiscovered(add, launchEmitter)
    this.AddresSelected = add;
    this.formInfo.updateForm('instructions', instructions);

  }

  private getTypeByLetter(letter: string) : any{
    let type = this.types[0];
    this.types.forEach(element => {
      if (element.id == letter) {
        type = element;
      }
    });
    return type;
  }

  private getAddressFromDicover(data: any): string {

    let str = '';

    if (data?.resultType && data?.resultType == 'place' &&  data?.title) {
      str = data.title+", ";
    }

    if (data.address?.street) {
      str += data.address?.street
    }
    if (data.address?.houseNumber) {
      str += " "+data.address?.houseNumber
    }
    if (data.address?.district) {
      str += ", " + data.address?.district
    }
    if (data.address?.city) {
      str += ", " + data.address?.city
    }

    return str;

  }


  private updateAddress(address: any, delete_: boolean = false) {
    // console.log(address);

    let t;

    if (!(address.lat || address.lng)) {
      this.setValueToShow.emit('cart');
      return
    }

    if (delete_) { // del
      t = 'd';
    } else if (address.addressID > 0) { // edit 
      t = 'u';
    } else { // new 
      t = 'n';
    }

    if(t == 'u'){
      let city = this.formInfo.getValue('city') || false;
      if(address.resolvedAddress && city){
        if(!address.resolvedAddress.includes(", "+city)){
          address.resolvedAddress += ","+city;
        }
      }
    }

    let Edit = {
      "lat": address.lat,
      "lng": address.lng,
      "resolvedAddress": address.resolvedAddress ? address.resolvedAddress : '',
      "description": address.description,
      "addressType": address.addressType.id,
      "addressID": address.addressID,
      "additionalIndications":address.additionalIndications
    }

    let New = {
      "streetID": 0,
      "stDirectoryID": 0,
      "stNumber": 0,
      "building": '',
      "stairNbr": '',
      "flatNbr": '',
      "doorNbr": '',
      "lat": address.lat,
      "lng": address.lng,
      "resolvedAddress": address.resolvedAddress ? address.resolvedAddress : '',
      "description": address.description,
      "addressType": address.addressType.id,
      "additionalIndications":address.additionalIndications

    };

    let Del = {
      "addressID": address.addressID
    }

    let send = t == 'd' ? Del : (t == 'u' ? Edit : New)

    this.api.updateAddress(t, send).subscribe(result => {
      // console.log(result);
      this.refreshDirec.emit('refresh');
      this.setValueToShow.emit('cart');
      this.canShowMap.emit(false);
    }, error => {
      this.refreshDirec.emit('refresh');
      this.setValueToShow.emit('cart');
      this.canShowMap.emit(false);
    });
  }

  private getDescriptionFromType(type): string {

    let str = '';

    if (type == 'H') {
      str = 'Home';
    }
    if (type == 'O') {
      str = 'Office';
    }
    if (type == 'A') {
      str = 'Apartment';
    }

    return str;
  }

  private filterDiscovered(items): any {
    let exclude = ['administrativeArea','intersection'];
    let filtered = [];

    items.forEach(x => {
      if (exclude.indexOf(x.resultType) == -1) {
        filtered.push(x);
      }
    });
    return filtered;
  }

  fill_up_dropdown_location(userLocation) {
    // console.log(userLocation);
    userLocation.alias = 'LANG_YOUR_LOCATION';
    userLocation.imgName = 'house';
    userLocation.description = 'LANG_YOUR_LOCATION';
    userLocation.addressType = 'H';
    userLocation.newAddress = false;
    // console.log(this.addressDisplay);
    let find = false;
    this.addressDisplay.forEach(element => {
      if (element.description == 'LANG_YOUR_LOCATION') {
        find = true;
      }
    });
    if (!find) {
      this.addressDisplay.unshift(userLocation);

    }
    this.selectAddress(userLocation);

  }

  fill_up_dropdown(addresses) {
    // console.log(addresses);
    let lastOrder, lastAddress, userLocation;
    let display = [];

    addresses.forEach(address => {
      if (address.hasOwnProperty('orderNumber')) {
        lastOrder = address;
      } else if (address.hasOwnProperty('addressID')) {
        lastAddress = address;
        lastAddress.alias = 'LANG_LAST_ADDRESS';
        lastAddress.imgName = lastAddress.addressType== 'H' ? 'house' : (lastAddress.addressType== 'O' ? 'office' : (lastAddress.addressType== 'A') ? 'apartment' : 'custom-location')
        lastAddress.newAddress = false;
        if (address.description !== null && address.description !== '') {
          lastAddress.alias = address.description;
           display.push(lastAddress);
        }
      } else {
        userLocation = address;
      }
    });

    if (userLocation) {
      userLocation.alias = 'LANG_YOUR_LOCATION';
      userLocation.imgName = 'house';
      userLocation.description = 'LANG_YOUR_LOCATION';
      userLocation.addressType = 'H';
      userLocation.newAddress = false;
      display.unshift(userLocation);
    }
      
    if (lastOrder) {
      lastOrder.alias = 'LANG_LAST_ORDER';
      lastOrder.description = 'LANG_LAST_ORDER';
      lastOrder.imgName = 'custom-location';
      lastOrder.addressType = 'C';
      lastOrder.newAddress = false;
      display.unshift(lastOrder);
    }

      display.push({
        alias: 'LANG_NEW_LOCATION',
        imgName: 'house',
        addressType: 'U',
        newAddress: true,
        lat: userLocation ? userLocation.lat :  false,
        lng: userLocation ? userLocation.lng :  false,
      });
    

    this.addressDisplay = display;
    // console.log(this.addressDisplay);
    if(this.addressDisplay.length>0){
      
      if(lastOrder){
        if(this.deliveryAddressSelected.lat != lastOrder.lat && this.deliveryAddressSelected.lng != lastOrder.lng){
          this.selectAddress(lastOrder);
        }
      }else if(userLocation){
        if(this.deliveryAddressSelected.lat != userLocation.lat && this.deliveryAddressSelected.lng != userLocation.lng){
          this.selectAddress(userLocation);
        }
      }else{
        if(this.deliveryAddressSelected.lat != this.addressDisplay[0].lat && this.deliveryAddressSelected.lng != this.addressDisplay[0].lng){
          this.selectAddress(this.addressDisplay[0]);
        }
      }
    }
  }

  selectAddress(value) {
    // console.log(value);
    if(!value){
      this.deliveryService.newAddressSelected(value);
      return;
    }
    if(value.alias == 'LANG_NEW_LOCATION'){
      let find = false;
      let pos = 0;
      for (let index = 0; index < this.addressDisplay.length; index++,pos++) {
        const element = this.addressDisplay[index];
        if(element.alias == 'LANG_YOUR_LOCATION'){
          find = element;
          break;
        }        
      }

      if(!find){
        this.locate.emit();
        return;
      }else{
        value = find;
      }
    }
    // console.log(value);
    this.addressDisplay.forEach(element => {
      if(element.lat == value.lat && element.lng == value.lng){
        element.selected = true;
      }else{
        element.selected = false;
      }
    });

    if (this.deliveryAddressSelected && this.deliveryAddressSelected.lat == value.lat && this.deliveryAddressSelected.lng == value.lng) {
      // this.deliveryService.setZoneDelivery(false);
      return;
    }

    this.deliveryAddressSelected = value;

    if (value.lat == false && value.lng == false) {
      this.deliveryService.setZoneDelivery(false);
      this.deliveryService.newAddressSelected(this.deliveryAddressSelected);
      return;
    }

    this.deliveryService.newAddressSelected(this.deliveryAddressSelected);
    this.findZoneDelivery(this.deliveryAddressSelected);
    if(value.hasOwnProperty('resolvedAddress')){
      this.fillFieldsForm(value);
    }else{
      let Str = value.lat + "," + value.lng;
      let that = this;
      this.hereS.reveseGeoCode(Str).subscribe(
        (result) => {
          if (result.items.length > 0) {
            let newLocation = result.items[0];

            let resolvedAddress = this.getAddressFromDicover(newLocation);
            this.addressDisplay.forEach(element => {
              if(element.lat == value.lat && element.lng == value.lng){
                element.resolvedAddress = resolvedAddress;
              }
            });

            that.selectDiscovered(newLocation, false);
          }
        },
        (notFound) => {
          console.log(notFound);
        })
    }
    // this.fillFieldsForm(
    //   {"addressID":1642,
    //   "additionalIndications":"",
    //   "stNumber":0,
    //   "building":"",
    //   "stairNbr":"",
    //   "flatNbr":"",
    //   "doorNbr":"",
    //   "lat":24.70565,
    //   "lng":46.69105,
    //   "resolvedAddress":"Abdullah Ibn Sulaiman Al Hamadan Street, Al Ulayah Al Janoubiyah, Riyadh",
    //   "description":"custom",
    //   "addressType":"C"
    // });

  }

  placeOrder(event) {

    if(this.requiredAddress){
      this.disablePlaceOrder = true;
      return
    }
    let sel: any;
    sel = this.discover.selected;

    let position = this.hereS.getLocation();
    let OldAdd = (sel.address && sel.address.addressID) ? sel.address.addressID : false;
    let OldType = (OldAdd && sel.address.type && sel.address.type.id) ? sel.address.type.id : false;

    let address = {
      addressID: OldAdd ? OldAdd : -1,
      addressType: OldType ? OldType : 'H',
      description: '',
      additionalIndications: this.formInfo.getValue('instructions') || '',
      lat: position.lat,
      lng: position.lng,
      resolvedAddress: ''
    }

    if (address.addressType == 'C') {
      address.description =  '';//this.formName.getValue('locationName');
    } else {
      address.description = this.getDescriptionFromType(address.addressType);
    }
    if (OldAdd) {
      address.resolvedAddress = sel.address.street || '';
      if(sel?.address?.houseNumber){
        address.resolvedAddress+= " "+sel?.address?.houseNumber;
      }
    }else if (sel.resultType) {
      address.resolvedAddress = this.getAddressFromDicover(sel);
    }else if(sel.address.street){
      address.resolvedAddress = sel.address.street || '';
    }

    // console.log("deliveryAddressSelected",this.deliveryAddressSelected);
    // console.log("discover",this.discover.selected);
    // console.log("data",address);
    // console.log(address.lat + "," + address.lng);
    // this._router.navigate(['/tracking'])

    let zone = this.deliveryService.getZoneDelivery()
    let promiseTime = this.pTimeService.getPromiseTimeSelected();
    // console.log(address.lat,address.lng);
    this.deliveryService.placeOrderEmmit({address:address,zone:zone,promiseTime:promiseTime});


  }

  showTextNewAddress(position){

    if(this.wkngMethod == 'delivery'){
      this.findZoneDelivery(position);
      // console.log(this.deliveryAddressSelected);

      this.addressDisplay.forEach(element => {

        if(element.newAddress){
            element.lat = position.lat;
            element.lng = position.lng;
            element.selected = true;
            this.deliveryAddressSelected = element;
        }else{
          element.selected = false;
        }

      });
    }
  }

  toogleButtonPlace(status:boolean){
    this.ngZone.run(() => {
      this.disablePlaceOrder = status;
    });
  }
  
  findZoneDelivery(position) {
    // console.log(position);
    this.toogleButtonPlace(true);
    // this.pTimeService.clearPromiseTimes();

    if (position.hasOwnProperty('zone')) {
      this.deliveryService.setZoneDelivery(position.zone);
      this.getPromiseTimes(this.isPromiseTimeSelected());
      if (position?.zone?.branch) {
        this.setDeliveryTime_(position.zone.branch)
      }
      this.toogleButtonPlace(false);
    } else {

    let that = this;
    this.pendingTimers.forEach(pending => {
      pending.stop();
    });

    let timer = new ScheduleTimer(1250, position, function (callbackData) {
      that.deliveryService.findZoneDelivery({ lat: callbackData.lat, lng: callbackData.lng }).subscribe(
        response => {
          that.getPromiseTimes(that.isPromiseTimeSelected());
          // that.pTimeService.newPromiseTimes(response.branch.promiseTimes);
          that.setDeliveryTime_(response.branch)
          that.toogleButtonPlace(false);
          that.sendEvt();

        },
        noZone => {
          that.getPromiseTimes(false);
          that.toogleButtonPlace(false);
        }
      )
    });
    timer.start();

    this.pendingTimers.push(timer);
  }
  }

  public setErrorCheckBranch(error) { // called from delivery component to set value on this component
    this.errorCheckBranch = error;
  }

  public clearErrorCheckBranch() {  // clear value on this component
    this.errorCheckBranch = { text: '', show: false };
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'm');
  }

  async setDeliveryTime_(branch) {

    // let today = new Date();
    // let deliveryTime = branch.deliveryTime.split(":");
    // let time = new Date(today.getFullYear(), today.getMonth(), today.getDate(), deliveryTime[0], deliveryTime[1], deliveryTime[2]);
    // let formated_time = this.transformDate(time)


    let timeOnBranch = new Date(branch.currentTime);
    let status = this.branchesService.getPickBrStatus({ branch: branch });
    let first = status == 2 ? this.branchesService.getOpeningTime(branch) : new Date(branch.currentTime);

    first.setHours(first.getHours() + Number(branch.deliveryTime.split(":")[0]));
    first.setMinutes(first.getMinutes() + Number(branch.deliveryTime.split(":")[1]));
    first.setSeconds(first.getSeconds() + Number(branch.deliveryTime.split(":")[2]));

    let timeSelected = this.pTimeService.getPromiseTimeSelected() ? new Date(this.pTimeService.getPromiseTimeSelected().value) : first;
   
    let formated_time = this.branchesService.calculateDiff(timeSelected, timeOnBranch);

    let res = await this.lngSer.getTranslation('LANG_DELIVERY_PIZZA_INTIME', {value:formated_time}).toPromise()

    this.deliveryTime_ = res;

  }

  getPromiseTimes(event){
    if(event){
      this.deliveryService.getPromiseTimes();
    }else{
      let zone = this.deliveryService.getZoneDelivery();
      if(zone){
        this.setDeliveryTime_(zone.branch);
      }
      this.pTimeService.clearPromisesNoDeliveryTime();
    }
  }

  isPromiseTimeSelected(){
    let res  = this.pTimeService.getPromiseTimeSelected();
    return res;
  }

  buttonSelect_call() {
    this.buttonSelect = !this.buttonSelect;
  }


  autoSelAddress(value) {
    this.deliveryService.newAddressSelected(value);
    this.findZoneDelivery(value);
    let Str = value.lat + "," + value.lng;
    let that = this;
    this.hereS.reveseGeoCode(Str).subscribe(
      (result) => {
        if (result.items.length > 0) {
          let newLocation = result.items[0];

          let resolvedAddress = this.getAddressFromDicover(newLocation);
          this.addressDisplay.forEach(element => {
            if (element.lat == value.lat && element.lng == value.lng) {
              element.resolvedAddress = resolvedAddress;
            }
          });

          that.selectDiscovered(newLocation, false);
        }
      },
      (notFound) => {
        console.log(notFound);
      })

  }

  onIconInputClicked(){
    console.log("entro")
    this.locate.emit()
  }

  sendEvt() {
    try {
      if (this.deliveryService.getZoneDelivery()) {
        let zone = this.deliveryService.getZoneDelivery();
        let branchName = zone.branch.name + " ," + zone.branch.streetName;
        this.faevents.preLogEvent("app_response_confirm", { confirm_message: "Delivery destination defined", deliveryLocation: true, branch_name: branchName })
      }
    } catch (e) {

    }
  }

  changeIconLoading(loading) {
    if (loading) {
      this.formMetadata.fields[0].options.icon = { 'name': 'locate', 'color': 'primary', 'class': 'spinner', 'position': 'start' };
    } else {
      this.formMetadata.fields[0].options.icon = { 'name': 'locate', 'color': 'primary', 'class': 'circleButtonInput', 'position': 'start' };
    }
  }
}


