import { AfterViewInit, Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControlOptions, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deepCopy, findIndexInArray } from '../common/common';
import {
  FormErrorInfoInterface,
  FormErrorsListInterface,
  FormFieldControInterface,
  FormMetadataButtonInterface,
  FormMetadataFieldInterface,
  FormMetadataFieldOptionDataInterface,
  FormMetadataGroupInterface,
  FormValidationInfoInterface
} from '../common/interfaces';
import { FormMetadata } from '../common/metadata';
import { FormCommitType, FormCustomValidatorType, FormFieldType, FormType, FormValidatorType } from '../common/types';
import { FormValidation } from '../validation/form-validation';
import { FormDatePickerDisplayFormat, FormDatePickerFormat, FormPasswordIcons } from '../common/constants';
import { IonInput } from '@ionic/angular';
import { CounterInterface } from '@lib/utils';
import { environment } from '@src/environments/environment';
import { countries } from '@core/constants';
import { DeliveryService } from '@services/delivery.service';
import { StorageService } from '@services/storage.service';
import { kiosk } from '@core/constants';
import Keyboard from "simple-keyboard";
import { LangService } from '@core/services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import layout from "simple-keyboard-layouts/build/layouts/arabic";
import { ApiService } from '@core/services/api.service';

@Component({
  selector: 'ng-ionic-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() metadata: FormMetadata
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter()
  @Output() onFormChange: EventEmitter<any> = new EventEmitter()
  @Output() onIconInputClick: EventEmitter<FormMetadataFieldInterface> = new EventEmitter()
  @Output() onFormResultsMatched: EventEmitter<FormMetadataFieldOptionDataInterface[]> = new EventEmitter()
  @ViewChildren(IonInput) inputList: QueryList<IonInput>
  @Input() set promoCodeErrorMessage(value) {

    const formValidation: FormValidation = new FormValidation()
    const currentErrors: FormErrorInfoInterface[] = []

    this.error = {
      conditionalParams: [],
      data: true,
      msg: value,
      params: []
    }
    if (value != undefined) {
      this.getErrorsList()
      this.updateForm("promocode", "")
    }

  }

  @Input() set searching(element) {
    if(element){
      this.searchingValue = { detail : { "value": element.detail.value }}
      this.searchingOptions = { options: { "data": element.options.data }}
       this.updateSearchBarMatches(this.searchingValue, this.searchingOptions)
    }
  }

  private unsubscribe = new Subject<void>()
  datePickerFormat: string = FormDatePickerFormat
  datePickerDisplayFormat: string = FormDatePickerDisplayFormat
  errorsList: FormErrorsListInterface
  fields: FormMetadataFieldInterface[]
  form: FormType
  isFormSubmitted: boolean = false
  showPasswordIcons: string[] = FormPasswordIcons
  error: any
  searchBarMatchedResults: CounterInterface<FormMetadataFieldOptionDataInterface[]> = {}
  @ViewChild('containerForm') containerForm
  containerFormWidth: number
  assets: string = environment.assetsUrl;
  phoneInputInForm: boolean = false
  showCountriesList: boolean = false
  selectedCountry = countries[0]
  countriesList = countries
  isFocusInsideComponent: boolean = false;
  isComponentClicked: boolean  = false;
  positionSelectedCountry:number = 0;
  isKiosk: boolean = kiosk
  kioskBranch: any

  value: string = "";
  keyboard: Keyboard;
  inputName = "firstName";
  showKeyboard: boolean
  currentLanguage: string
  inputs: any = {  };
  searchingValue: any;
  searchingOptions: any;
  

  constructor(
    private readonly ngZone: NgZone,
    private deliveryService : DeliveryService,
    private readonly storageService: StorageService,
    public langService: LangService,
    private readonly _api: ApiService
  ) { 


  }
  ngAfterViewInit(): void {
    if (this.metadata.focusOnFirstElement) {
      this.ngZone.run(
        () => {
          setTimeout(() => {
            this.inputList.first.setFocus()
          }, 400)
        }
      )
    }


    this.ngZone.run(
      () => {
        setTimeout(() => {
          this.fields.forEach(field => {
            this.inputs[field.id] = field.value
      })
      this._api.setDataForms(
        this.inputs
      )
        }, 400)
      }
    )




    // if(this.currentLanguage == 'ar'){
    //   this.keyboard = new Keyboard({
    //     debug: true,
    //     inputName: this.inputName,
    //     onChange: input => this.onChangeKeyboard(input),
    //     onKeyPress: button => this.onKeyPressKeyboard(button),
    //     rtl:  true,
    //     ...layout,
    //     preventMouseDownDefault: false,
    //     syncInstanceInputs: true
    //   });
    // }else{
    //   this.keyboard = new Keyboard({
    //     debug: true,
    //     inputName: this.inputName,
    //     onChange: input => this.onChangeKeyboard(input),
    //     onKeyPress: button => this.onKeyPressKeyboard(button),
    //     rtl:  false,
    //     layout: null,
    //     preventMouseDownDefault: false,
    //     syncInstanceInputs: true
    //   });
    // }

    //  this.keyboard.replaceInput(this.inputs);

  
  }

  onChangeKeyboard = (input: string) => {
    this.value = input;
    console.log("Input changed", input);
  };

  onKeyPressKeyboard = (button: string) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();

    if (button === "{enter}") {
      this.showKeyboard = false;
    }

  };



  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
  ngOnInit(): void {
    this.metadata.buttons.sort((a: FormMetadataButtonInterface, b: FormMetadataButtonInterface) =>
      a.position - b.position
    )
    this.metadata.fields.sort((a: FormMetadataFieldInterface, b: FormMetadataFieldInterface) =>
      a.position - b.position
    )
    this.fields = this.metadata.fields
    this.form = this.getForm()
    this.getErrorsList()
    this.form.valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((result: any) => {
      // console.log(result)
      // this.updateSearchBarMatches()
      // if (this.form.updateOn !== 'submit') {
      if (this.phoneInputInForm && this.form.value.phone != undefined) {
        this.form.value.country = this.selectedCountry.dial_code
      }
      this.onFormChange.emit(this.form)
      // }
      this.getErrorsList()
    })

    this._api.getNewDataForm.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (data: any) => {
        console.log(data)

        if(data["keyboard"]){
          this.keyboard = data["keyboard"]
          console.log(this.keyboard)
        }

        if(data["inputs"]){
          this.inputs = data["inputs"]
          console.log( this.inputs)
        }

        if(data["function"] && data["function"] == 'onChangeKeyboard'){
          this.onChange(data["event"])

        }

        if(data["function"] && data["function"] == 'onInputChange'){
          this.onInputChange(data["event"])
        }

        if(data["function"] && data["function"] == 'onKeyPressKeyboard'){
          this.onKeyPressKeyboard(data["event"])
        }


        if(data["function"] && data["function"] == 'onChange'){
          this.onChange(data["event"])
        }

        if(data["function"] && data["function"] == 'onChangeCountry'){
          this.selectCountry(data["params"]["item"], data["params"]["position"])
        }

      }
    )

this.currentLanguage = this.langService.getCurrentLang();

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {

        this.currentLanguage = result.lang


        // if(this.currentLanguage == 'ar'){
        //   this.keyboard.setOptions({
        //     rtl: true,
        //     ...layout
        //   })
        // }else{
        //   this.keyboard.setOptions({
        //     rtl: false,
        //     layout: null

        //   })
        // }

        // if(this.currentLanguage == 'ar'){
        //   this.keyboard = new Keyboard({
        //     onChange: input => this.onChangeKeyboard(input),
        //     onKeyPress: button => this.onKeyPressKeyboard(button),
        //     rtl:  true,
        //     ...layout
        //   });
        // }else{
        //   this.keyboard = new Keyboard({
        //     onChange: input => this.onChangeKeyboard(input),
        //     onKeyPress: button => this.onKeyPressKeyboard(button),
        //     rtl:  false,
    
        //   });
        // }

      }
    )

   
  }
  checkClearInput(field: FormMetadataFieldInterface): boolean {
    const list: FormFieldType[] = [
      'email',
      'password',
      'tel',
      'text'
    ]
    return list.indexOf(field.fieldType) >= 0
  }
  getErrorsList(): void {
    const formValidation: FormValidation = new FormValidation()
    this.errorsList = {}
    let flag = true;
    for (const field of this.fields) {
      const currentErrors: FormErrorInfoInterface[] = []
      const fieldErrors = this.form.get(field.name).errors
      // console.log(fieldErrors)
      const validators: FormValidationInfoInterface[] = formValidation.getValidators(field)
      validators.forEach((value: FormValidationInfoInterface, index: number) => {
        for (const validatorAlias of value.name) {
          if (this.form.get(field.name).hasError(validatorAlias)) {
            currentErrors.push({
              conditionalParams: value.conditionalParams,
              data: fieldErrors[validatorAlias],
              msg: field.validators[index].errorMsg,
              params: value.params
            })
          }
        }

      })

      if (this.error != undefined && flag) {
        currentErrors.push(this.error)
        flag = false
      }

      if (currentErrors.length > 0) {
        this.errorsList[field.name] = currentErrors
      }

      if (currentErrors.length > 1 && this.error != undefined && this.errorsList["promocode"][0].msg != '') { //Para poder borrar el error cuando se muestra otro error si no es el del required
        this.errorsList["promocode"].pop()
      }

    }



  }
  getForm(): FormType {
    const fields: FormFieldControInterface = {}
    const commitMode: FormCommitType = this.metadata.commitMode
    const formGroups: FormMetadataGroupInterface[] = []
    const formOptions: AbstractControlOptions = {
      updateOn: commitMode,
      validators: []
    }
    const formValidation: FormValidation = new FormValidation()
    const noGroup: FormMetadataGroupInterface = {
      fields: [],
      id: 'nogroup',
      name: 'nogroup'
    }
    let formCustomValidators: FormCustomValidatorType[] = []
    for (const field of this.metadata.fields) {
      const fieldValidatorsList: FormValidationInfoInterface[] = field.isReadOnly ? [] : formValidation.getValidators(field)
      let customValidators: FormCustomValidatorType[] = []
      let validators: FormValidatorType[] = []
      for (const fieldValidator of fieldValidatorsList) {
        if (fieldValidator.isCustom) {
          customValidators = customValidators.concat(fieldValidator.validators)
        } else {
          validators = validators.concat(fieldValidator.validators)
        }
      }
      if (field.group) {
        const groupPosition: number = findIndexInArray(this.metadata.groups, 'id', field.group)
        if (groupPosition >= 0) {
          const formGroupPosition: number = findIndexInArray(formGroups, 'id', field.group)
          if (formGroupPosition >= 0) {
            formGroups[formGroupPosition].fields.push(field)
          } else {
            const group: FormMetadataGroupInterface = deepCopy(this.metadata.groups[groupPosition])
            group.fields = [
              field
            ]
            formGroups.push(group)
          }
        } else {
          noGroup.fields.push(field)
        }
      } else {
        noGroup.fields.push(field)
      }
      if (customValidators.length > 0) {
        formCustomValidators = formCustomValidators.concat(customValidators)
      }
      // Search Bar
      if (field.fieldType === 'search-bar') {
        this.searchBarMatchedResults[field.name] = []
      }
      //
      fields[field.name] = new FormControl({
        disabled: field.disabled,
        value: field.value
      }, {
        validators: validators
      })
      console.log(field)

      if (field.inputMode == 'tel') {
        this.phoneInputInForm = true
      }


    }
    if (noGroup.fields.length > 0) {
      formGroups.push(noGroup)
    }


    this.metadata.groups = formGroups
    formOptions.validators = formCustomValidators
    return new FormGroup(fields, formOptions)
  }
  onClickIconInput(field: FormMetadataFieldInterface): void {
    if (this.containerForm) {
      this.containerFormWidth = this.containerForm.el.offsetWidth
    }

    if (field.fieldType === 'password') {
      field.options.showPassword = !field.options.showPassword
    }
    if (field.inputMode === 'tel') {
      this.showCountriesList = !this.showCountriesList;
      if (this.showCountriesList) {
        this.scroll(this.selectedCountry)
        this.isFocusInsideComponent = true;
        this.isComponentClicked = true;
      }
    }

    this.onIconInputClick.emit(field)
  }
  onKeyPress(event: KeyboardEvent, field: FormMetadataFieldInterface): void {
    if (field.pattern) {
      if (!field.pattern.test(event.key)) {
        event.preventDefault()
      }
    }
  }
  onPaste(event: ClipboardEvent, field: FormMetadataFieldInterface) {
    event.preventDefault()
    let clipboardData: DataTransfer = event.clipboardData
    let value: string = clipboardData.getData('text')
    this.form.get(field.name).setValue(value.trim())
  }
  onSubmitform(): void {
    this.isFormSubmitted = true
    // if (this.form.valid) {
    if (this.phoneInputInForm && this.form.value.phone != undefined) {
      this.form.value.country = this.selectedCountry.dial_code
    }
    this.onFormSubmit.emit(this.form)
    // } else { }
  }
  trackByFn(index: number, element: any): number {
    return index
  }
  public updateForm(id: string, value: string) {
    this.form.controls[id].setValue(value, { onlySelf: true });
  }
  public getValue(id: string) {
    return this.form.controls[id].value;
  }
  public setFocus() {
    this.inputList.first.setFocus()
  }
  public lock() {
    this.inputList.first.readonly = true;
  }
  public unlock() {
    this.inputList.first.readonly = false;
  }
  public setError(id: string, value: string) {
    this.form.controls[id].setErrors({ 'incorrect': true });
  }
  // Search Bar
  // selectSearchBarItem(item: FormMetadataFieldOptionDataInterface): void {
  //   console.log(item)
  //   this.onFormResultSelected.emit(item)
  // }
  updateSearchBarMatches(event: CustomEvent, field: FormMetadataFieldInterface): void {
    console.log(event)
    console.log(field)
    let searchTerm: string = event.detail.value.toLowerCase()
    let hasTheMinimum: boolean = searchTerm.length >= 3
    let userValues: string[] = searchTerm.split(' ').filter(Boolean)
    this.searchBarMatchedResults[field.name] = hasTheMinimum ? field.options.data.reduce(
      (result: FormMetadataFieldOptionDataInterface[], v) => {
        /*if (v.value.toLowerCase().indexOf(searchTerm) >= 0) {
          result.push(v)
        }*/
        let match: boolean = userValues.reduce(
          (resp: boolean, userValue) => {
            if (v.search.some(searchValue => searchValue.includes(userValue))) {
              resp = true
            }
            return resp
          }, false
        )
        if (match) {
          result.push(v)
        }
        return result
      }, []
    ) : null
    console.log(this.searchBarMatchedResults)
    if(this.searchBarMatchedResults.searchbar !== null && this.searchBarMatchedResults[field.name] != null){
      this.onFormResultsMatched.emit(this.searchBarMatchedResults[field.name])
    }else{
       this.onFormResultsMatched.emit(field.options.data)
    }
  }

  selectCountry(item, i) {
    console.log(item)
    this.selectedCountry = item;
    this.showCountriesList = false;
    this.positionSelectedCountry = i;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.containerForm) {
      this.containerFormWidth = this.containerForm.el.offsetWidth
    }
  }
  
  @HostListener('document:click')
  clickout() {
      if (!this.isFocusInsideComponent && this.isComponentClicked) {
          this.isComponentClicked = false;
          this.showCountriesList = false;
      }
      this.isFocusInsideComponent = false;

  }

  scroll(country) {
    setTimeout(() => {
      console.log("entro")
      let id = country.code;
      let el = document.getElementById(id);
      try {
        el.scrollIntoView({ block: "center", behavior: "auto" });
      } catch (e) { console.log(e) }
    }, 300);

  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if (this.showCountriesList) {
      if ((event.key === 'ArrowDown' || event.key === 'ArrowLeft') && this.positionSelectedCountry < countries.length - 1) {
        console.log("down")
        this.positionSelectedCountry = this.positionSelectedCountry + 1;
        this.selectedCountry = countries[this.positionSelectedCountry]

        this.scroll(this.selectedCountry)

      }
      else if ((event.key === 'ArrowUp' || event.key === 'ArrowRight') && this.positionSelectedCountry > 0) {
        this.positionSelectedCountry = this.positionSelectedCountry - 1;
        this.selectedCountry = countries[this.positionSelectedCountry]
        this.scroll(this.selectedCountry)
      }

      if (event.key === 'Enter') {
        this.showCountriesList = false;
      }

    }
    if (event.key === 'Enter') {
      if( this.form.status == "VALID"){
        this.onSubmitform()
      }
    }


  }


  onInputFocus = (event: any) => {

    this.inputName = event.target.id;
    this.showKeyboard = true;
    this._api.setDataForms({
      function: "onInputFocus", 
      event: event
    }
    )

    console.log("Focused input", this.inputName);
    console.log(this.keyboard)

    if(this.keyboard){
      this.keyboard.setOptions({
        inputName: event.target.id
      });
    }else{


    }


  };

  setInputCaretPosition = (elem: any, pos: number) => {
    if (elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
    }
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };

  onChange = (input: string) => {
    this.inputs[this.inputName] = input;
    console.log("Input changed", input);

    /**
     * Synchronizing input caret position
     * This part is optional and only relevant if using the option "preventMouseDownDefault: true"
     */
    let caretPosition = this.keyboard.caretPosition;

    if (caretPosition !== null){
      this.setInputCaretPosition(
        document.querySelector(`#${this.inputName}`),
        caretPosition
      );
    }


    console.log("caretPosition", caretPosition);
  };

  

}