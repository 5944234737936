import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { MenuFamilyInterface, MenuFamilyProductInterface, MenuInterface, PromoInterface, PromoLineInterface } from '@lib/promo-engine';
import {
  MenuGroupInterface,
  MenuSubGroupInterface,
  PgCustomizer,
  PgCommon
} from '@lib/utils';
import { CustomizerSelectedDataInterface } from '@core/interfaces/services';
import { PlatformService } from '@services/platform.service';
import { FaeventsService } from '@services/faevents.service'
import { kiosk } from '@core/constants';
import { isGroundr, isMaestro } from '../../functions';

@Component({
  selector: 'card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.groundr.component.scss','./card-product.component.scss']
})
export class CardProductComponent implements OnInit {
  @Input() id: number;
  @Input() set contador(value: number) {
    if (value !== null && !isNaN(value)) {
      let contadorProduct = value
      this.backgroundColorAssign(contadorProduct)
    }
  }
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  @Input() loyalty: boolean
  @Input() points: number
  @Input() lastBackground: string;
  @Input() firstBackground: string;
  @Input() product?: MenuFamilyProductInterface
  @Input() promotion?: PromoInterface
  @Input() promoLine?: PromoLineInterface
  @Input() group: MenuGroupInterface
  @Input() subgroup: MenuSubGroupInterface
  @Output() sendLast = new EventEmitter(); //Aqui mandamos al componente padre la clase que tiene la tarjeta anterior
  @Output() sendFirst = new EventEmitter(); //Aqui mandamos al componente padre la clase qie tiene la tarjeta de la izquierda de cada fila
  @Output() addPromotion = new EventEmitter<PromoInterface>()
  @Output() showCustomizerEvent = new EventEmitter();
  assets: string = environment.assetsUrl;
  backgroundClass: string;
  new: boolean = false
  piano: boolean = false
  veg: boolean = false
  hot: boolean = false
  bake: boolean = false
  topSales: boolean = false
  productsUrl: string = environment.productsUrl
  isEditable: boolean
  saleItemDescription: string
  saleItemName: string
  saleItemImageUrl: string
  pgCustomizer: PgCustomizer
  family: MenuFamilyInterface
  menu: MenuInterface
  public pgCommon: PgCommon = new PgCommon()
  isKiosk: boolean = kiosk
  kioskBranch: any
  constructor(
    private readonly _api: ApiService,
    private readonly platformService: PlatformService,
    private readonly apiService: ApiService,
    private faevents: FaeventsService
  ) { 
  }
  ngOnInit() {
    this.isEditable = this.group.isEditable
    this.saleItemImageUrl = this.assets
    if (this.product) {
      this.saleItemImageUrl += `products/${this.product.imageID}.png`
      this.saleItemDescription = this.pgCommon.cutText(this.product.webDescription,0, 170)
      this.saleItemName = this.product.shortName
      this.new = this.checkBadge(this.product.highlightWeb, 'N')
      this.veg = this.checkBadge(this.product.highlightWeb, 'V')
      this.hot = this.checkBadge(this.product.highlightWeb, 'H')
      this.bake = this.checkBadge(this.product.highlightWeb, 'B')
      this.topSales = this.checkBadge(this.product.highlightWeb, 'T')
      this.piano = this.checkBadge(this.product.highlightWeb, 'P')
    } else if (this.promotion) {
      this.saleItemDescription = this.promotion.webDescription
      this.saleItemImageUrl += `promos/${this.promotion.id}_menu.png`
      this.saleItemName = this.promotion.description
    }
  }

  callShowCustomizerEvent(data) {
    this.showCustomizerEvent.emit(data);
  }
  showCustomizer() {
    if (!this.loyalty || (this.points >= this.product.points && this.loyalty)) {
      if (this.product) {
        this.evt(this.group,this.product);
        const productData: CustomizerSelectedDataInterface = {
          product: this.product
        }
        if (this.promoLine) {
          productData.promoLine = this.promoLine
        }
        if (this.points > 0) {
          productData.points = this.points
        }
        if (this.promotion) {
          productData.promotion = this.promotion
        }
        this._api.setData(productData)
        this.callShowCustomizerEvent(true)
      } else if (this.promotion) {

        this.addPromotion.emit(this.promotion)

      }
    }
  }
  checkBadge(stringBadges, word) {
    if (stringBadges !== undefined) {
      if (stringBadges.indexOf(word) !== -1) {
        return true;
      }
    }
  }

  backgroundColorAssign(contadorProduct) {

    if (!this.platformService.isServer()) {
      if (window.innerWidth <= 991 || this.isKiosk) {

        let valor = contadorProduct % 12
        switch (valor) {
          case 1:
            this.backgroundClass = "secondaryBackground";
            break;
          case 2:
            this.backgroundClass = "primaryBackground";
            break;
          case 3:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 4:
            this.backgroundClass = "primaryBackground";
            break;
          case 5:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 6:
            this.backgroundClass = "secondaryBackground";
            break;
          case 7:
            this.backgroundClass = "primaryBackground";
            break;
          case 8:
            this.backgroundClass = "secondaryBackground";
            break;
          case 9:
            this.backgroundClass = "primaryBackground";
            break;
          case 10:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 11:
            this.backgroundClass = "secondaryBackground";
            break;
          case 0:
            this.backgroundClass = "tertiaryBackground";
            break;
        }
      }
      else {
        let valor = contadorProduct % 9
        switch (valor) {
          case 1:
            this.backgroundClass = "secondaryBackground";
            break;
          case 2:
            this.backgroundClass = "primaryBackground";
            break;
          case 3:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 4:
            this.backgroundClass = "primaryBackground";
            break;
          case 5:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 6:
            this.backgroundClass = "secondaryBackground";
            break;
          case 7:
            this.backgroundClass = "tertiaryBackground";
            break;
          case 8:
            this.backgroundClass = "secondaryBackground";
            break;
          case 0:
            this.backgroundClass = "primaryBackground";
            break;
        }
      }
    }
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.assets + "images/productnotfound.png") {
        event.target.src = this.assets + "images/productnotfound.png"
      }
    }

  }
  evt(group, product) {
    try {
      this.apiService.eventPg(this.product, "product_click");
      let evt = { item_name: product.sourceName, short_name: product.sourceName, item_category: "" };

      let familyGroupID = product.familyGroupID;
      let find = false;

      for (let z = 0; z < group.subGroups.length; z++) {
        const subG = group.subGroups[z];
        for (let x = 0; x < subG.families.length; x++) {
          const elSubG = subG.families[x];
          if (elSubG.id == familyGroupID) {
            evt.item_category = elSubG.sourceName;
            find = true;
            break
          }
          if (find) {
            break;
          }
        }
        if (find) {
          break;
        }
      }
      this.faevents.preLogEvent("view_item", evt)
    } catch (e) {
      this.apiService.logger("error","",e,"product_click")
    }
  }
}
