import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Platform } from '@ionic/angular';
import { LangService } from '@services/lang.service';
import { StorageService } from '@services/storage.service';
import { StorageVariables, orderExpiration } from '@core/constants';
import { environment } from '@environments/environment';
import { PlatformService } from '@services/platform.service';
import { MenuInterface, PromoInterface } from '@lib/promo-engine';
import { CreateOrderInterface } from '@lib/utils';
import { LastOrderInterface } from '@core/interfaces/services';
import { DataService } from '@services/data.service';

export interface CheckOrderResultInterface {
  branchID: number
  hasLastOrder: boolean
  hasMenu: boolean
  hasNearestBranch: boolean
  hasOrder: boolean
  hasPromotions: boolean
  hasUser: boolean
  lastOrder: LastOrderInterface
  menu: MenuInterface
  order: CreateOrderInterface
  promotions: PromoInterface[]
  updateMenuNdPromotions: boolean
  updateOrder: boolean
  user: string
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private webSocket: WebSocketSubject<any>
  constructor(
    private readonly langService: LangService,
    private readonly storageService: StorageService,
    private readonly platform: Platform,
    private readonly platformService: PlatformService,
    private readonly dataService: DataService
  ) { }
  getCurrentLanguage(): Promise<string> {
    return new Promise(
      resolve => {
        if (this.platformService.isServer()) {
          // To Do get language from url
          resolve(this.langService.getDefaultLang())
        } else {
          this.platform.ready().then(
            () => {
              this.langService.addLangs(['ar', 'en'])
              this.storageService.getItem(StorageVariables.lang).subscribe(
                (lang) => {
                  resolve(lang)
                },
                () => {
                  this.langService.getCurrentDeviceLang().subscribe(
                    (result: string) => {
                      const currentDefaultLang: string = this.langService.getDefaultLang()
                      const langList: string[] = this.langService.getLangs()
                      if (langList.indexOf(result) >= 0 && currentDefaultLang !== result) {
                        this.storageService.setItem(StorageVariables.lang, result).subscribe(
                          () => {
                            resolve(result)
                          },
                          () => {
                            resolve(result)
                          }
                        )
                      } else {
                        resolve(this.langService.getDefaultLang())
                      }
                    },
                    () => {
                      resolve(this.langService.getDefaultLang())
                    }
                  )
                }
              )
            }
          )
        }
      }
    )
  }
  // init order
  checkMenuDataByDefault(): Promise<[MenuInterface, PromoInterface[]]> {
    return new Promise(
      async resolve => {
        /*let items: [MenuInterface, PromoInterface[]] = await Promise.all([
          this.dataService.getMenu(),
          this.dataService.getPromotions()
        ])
        resolve(items)*/
        let items: [MenuInterface, PromoInterface[]] = null
        if (this.platformService.isServer()) {
          items = [
            null,
            null
          ]
          // items = [
          //   this.dataService.getStaticMenu(),
          //   this.dataService.getStaticPromotions()
          // ]
          resolve(items)
        } else {
          try {
            items = await Promise.all([
              this.storageService.getItem(StorageVariables.menu).toPromise(),
              this.storageService.getItem(StorageVariables.promos).toPromise()
            ])
          } catch (e) {
          }
          if (items && Array.isArray(items) && items.length > 1) {
            resolve(items)
          } else {
            items = [
              this.dataService.getStaticMenu(),
              this.dataService.getStaticPromotions()
            ]
            await Promise.all([
              this.storageService.setItem(StorageVariables.menu, items[0]).toPromise(),
              this.storageService.setItem(StorageVariables.promos, items[1]).toPromise()
            ])
            resolve(items)
          }
        }
      }
    )
  }
  checkOrder(): Promise<CreateOrderInterface> {
    return new Promise(
      async (resolve, reject) => {
        let isApp: boolean = (this.platformService.isAndroid() || this.platformService.isIos())
        if (isApp) {
          let emptyOrder: CreateOrderInterface = await this.dataService.getEmptyOrder()
          await this.storageService.setItem(StorageVariables.order, emptyOrder).toPromise()
          resolve(emptyOrder)
        } else {
          let order: CreateOrderInterface = await this.dataService.getOrder()
          if (order && order.ts) {
            let currentTime: string = new Date().toISOString()
            let currentTs: number = new Date(currentTime).getTime()
            let orderTs: number = new Date(order.ts).getTime()
            if ((currentTs - orderTs) > orderExpiration) {
              order = await this.dataService.getEmptyOrder()
              await this.storageService.setItem(StorageVariables.order, order).toPromise()
            }
          }
          resolve(order)
        }
      }
    )
  }
  initializeOrder(): Promise<boolean> {
    return new Promise(
      async resolve => {
        let lang: string = await this.getCurrentLanguage()
        this.langService.setLang(lang)
        await Promise.all([
          this.checkOrder()
        ])
        resolve(true)
      }
    )
  }
  // Web Socket
  connectNdGetWebSocketMessages(): WebSocketSubject<any> {
    if (!this.webSocket || this.webSocket.closed) {
      this.webSocket = webSocket(environment.socketUrl)
    }
    return this.webSocket
  }
  sendWebSocketMessages(msg: any): void {
    this.webSocket.next({
      action: 'sendmessage',
      data: JSON.stringify(msg)
    })
  }
}