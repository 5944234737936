import { Injectable, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { PlatformService } from '@services/platform.service';
import { NavigationEnd } from '@angular/router';
import { StorageService } from '@services/storage.service'
import { Appsflyer } from '@ionic-native/appsflyer/ngx';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
import { StorageVariables } from '@core/constants';
import { DeliveryService } from '@services/delivery.service';
import { ApiService } from '@services/api.service';
import { GeolocationService } from '@services/geolocation.service';
import { OrderTypes } from '@lib/promo-engine';
import { HereService } from '@services/here.service';

declare var AF: any;

@Injectable({
  providedIn: 'root'
})
export class FaeventsService {
  private last : any = false;
  private isWeb: boolean;
  private AFWeb: any;
  private status:  any = {
    analytics: {
      enabled: false,
      uidSetted: false
    },
    flyer: {
      enabled: false,
      uidSetted: false
    }
  };
  constructor(
    private analytics: AngularFireAnalytics,
    private firebaseAnalytics: FirebaseAnalytics,
    private platformService: PlatformService,
    private storage: StorageService,
    private appsflyer: Appsflyer,
    private deliveryService: DeliveryService,
    private readonly apiService: ApiService,
    private geolocation : GeolocationService,
    private hereS: HereService,

    @Inject(DOCUMENT) private doc: any

  ) {
    // console.log("FaeventsService");
    this.isWeb = this.platformService.isWeb();

  }

  private initAppsFlyer() {
    if (this.isWeb) {
      this.loadAppsFlyerWeb();
    } else {
      let config = this.platformService.isIos() ? environment.appsFlyer.iOS : environment.appsFlyer.android;
      this.appsflyer.initSdk(config);
      this.status.flyer.enabled = true;
    }
  }

  private checkUserId() {

    if (!this.status.analytics.uidSetted  || !this.status.flyer.uidSetted) {
      this.storage.getItem(StorageVariables.userID).subscribe(
        (data) => {
          this.setUserId(data.uid);
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }

  public pageView(event: NavigationEnd) {
  
    this.checkUserId();

    let location = event.url.replace("/", "");
        location = location.split("?")[0];
    if (this.isWeb) {
      if (this.status.analytics.enabled) {
        this.analytics.logEvent("page_view", { page_location: location });
      }
      if (this.status.flyer.enabled) {
        this.AFWeb('pba', 'event', { eventType: 'EVENT', eventName: 'page_view', page_location: location });
      }
    } else {
      if (this.status.analytics.enabled) {
        this.firebaseAnalytics.logEvent("screen_view", { screen_name: location, screen_class: location });
      }
      if (this.status.flyer.enabled) {
        this.appsflyer.logEvent("screen_view", { screen_name: location, screen_class: location });
      }
    }
  }

  private setUserId(id) {
    if (this.isWeb) {
      if (this.status.analytics.enabled) {
        this.analytics.setUserId(id);
        this.status.analytics.uidSetted = true;
      }

      if (this.status.flyer.enabled) {
        this.AFWeb('pba', 'setCustomerUserId', id)
        this.status.flyer.uidSetted = true;
      }

    } else {
      this.firebaseAnalytics.setUserId(id);
      this.appsflyer.setAppUserId(id);
      this.status.analytics.uidSetted = true;
      this.status.flyer.uidSetted = true;
    }
  }

  public enableAnalytics(status: boolean) {
    this.status.analytics.enabled = status;
    if (this.isWeb) {
      this.analytics.setAnalyticsCollectionEnabled(status);
    } else {
      this.firebaseAnalytics.setEnabled(status);
    }
  }

  public enableAppsFlyer(status: boolean) {
    if (this.isWeb) {
      this.initAppsFlyer();
      if(!status){
        this.status.flyer.enabled = false;
      }
    } else {
      if (status) {
        this.initAppsFlyer();
      } else {
        this.appsflyer.Stop(status);
        this.status.flyer.enabled = false;
      }
    }
  }
  public logEvent(eventName: string, data: any) {
    if (this.isWeb) {
      if (this.status.analytics.enabled) {
        this.analytics.logEvent(eventName, data);
      }
      if (this.status.flyer.enabled) {
        // require implementation here
      }
    } else {
      if (this.status.analytics.enabled) {
        this.firebaseAnalytics.logEvent(eventName, data);
      }
      if (this.status.flyer.enabled) {
        this.appsflyer.logEvent(eventName, data);
      }
    }
  }

  private loadAppsFlyerWeb() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = '!function (t, e, n, s, a, c, i, o, p) {'
      + 't.AppsFlyerSdkObject = a, t.AF = t.AF || function () {'
      + '(t.AF.q = t.AF.q || []).push([Date.now()].concat(Array.prototype.slice.call(arguments))) },'
      + 't.AF.id = t.AF.id || i, t.AF.plugins = {}, o = e.createElement(n), p = e.getElementsByTagName(n)[0], o.async = 1,'
      + 'o.src = "https://websdk.appsflyer.com?" + (c.length > 0 ? "st=" + c.split(",").sort().join(",") + "&" : "") + (i.length > 0 ? "af_id=" + i : ""),'
      + 'p.parentNode.insertBefore(o, p)'
      + '}(window, document, "script", 0, "AF", "pba", { pba: { webAppId: "' + environment.appsFlyer.web + '" } })';

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);

    let st_ = 0;
    let that = this;

    let int_ = setInterval(function () {

      if (st_ >= 5) { clearInterval(int_); }
      st_++;

      try {
        if (AF) {
          clearInterval(int_);
          that.AFWeb = AF;
          that.status.flyer.enabled = true;
        }
      } catch (e) { }
      
    }, 1000)

  }

  public preLogEvent(evtName, payload) {
    // console.warn(payload)
    try {
      let evt: any = {};

      let pos = this.geolocation.userPosition ? this.geolocation.userPosition : false;
      if (pos) {
        evt.lat = pos.lat;
        evt.lng = pos.lng;
      }

      let customerDetails = this.deliveryService.customerDetails ? this.deliveryService.customerDetails : false;
      if (customerDetails) {

        if (customerDetails.total_revenue && customerDetails.total_revenue >= 0) {
          evt.user_ltv = Number(customerDetails.total_revenue)
        }
        if (customerDetails.total_orders && customerDetails.total_orders >= 0) {
          evt.user_purchase_count = Number(customerDetails.total_orders)
        }
        if (customerDetails.daysSinceLastPurchase && payload.days_since_last_purchase >= 0) {
          evt.days_since_last_purchase = Number(payload.days_since_last_purchase)
        }
        if (customerDetails.loyaltyPoints) {
          evt.user_loyalty_status = customerDetails.loyaltyPoints;
        }
        evt.loged = 'true';
      } else {
        evt.loged = 'false';
      }

      let launch = false;

      if (evtName == 'view_item_list') {
        if (!this.last) {
          this.last = {};
          this.last.id = payload.id;
          launch = true;
        } else if (this.last.id != payload.id) {
          this.last.id = payload.id;
          launch = true;
        }
        let item: any = {};
        if (payload.item_category) {
          item.item_category = payload.item_category
        }
        if (payload.promotion_shortname) {
          evt.promotion_shortname = payload.promotion_shortname
        }
        evt.items = [item];
      } else if (evtName == 'view_item') {

        launch = true;
        let item: any = {};
        if (payload.item_name) {
          item.item_name = payload.item_name;
        }
        if (payload.short_name) {
          evt.short_name = payload.short_name;
        }
        if (payload.item_category) {
          item.item_category = payload.item_category
        }
        if (payload.promotion_shortname) {
          evt.promotion_shortname = payload.promotion_shortname
        }
        evt.items = [item];

      } else if (evtName == 'add_to_cart' || evtName == 'remove_from_cart' || evtName == 'begin_checkout' || evtName == 'add_shipping_info' || evtName == 'purchase' || evtName == 'add_payment_info') {
        evt.items = payload.items;
   
        if (payload.branch_name) {
          evt.branch_name = payload.branch_name;
        }
        if (payload.shipping) {
          evt.shipping = payload.shipping;
        }
        if (payload.coupon) {
          evt.coupon = payload.coupon;
        }
        if (payload.payment_type) {
          evt.payment_type = payload.payment_type;
        }
        if (payload.transaction_id) {
          evt.transaction_id = (payload.transaction_id).toString();
          // console.warn(evt)

        }

        if (payload.value) {
          evt.value = payload.value;
        }
        if (payload.currency) {
          evt.currency = payload.currency;
        }

        if (payload.short_name) {
          evt.short_name = payload.short_name;
        }
        if (payload.promotion_shortname) {
          evt.promotion_shortname = payload.promotion_shortname;
        }

        if(evt.user_purchase_count && evt.user_purchase_count >0){
           evt["purchase_record"] = 'Additional order';
        }else{
          evt["purchase_record"] = 'First order';
        }

        if(evtName == 'purchase'){
          this.storage.setItem(StorageVariables.last_order_evt,evt);
        }

        if(payload.shipping && payload.shipping == 'Delivery'){
          let pos = this.hereS.getLocation() ? this.hereS.getLocation() : false;
          if (pos) {
            evt.lat = pos.lat;
            evt.lng = pos.lng;
          }
        }

        launch = true;
      } else if (evtName == 'product_customization') {
        evt = { ...payload.item, ...evt };
        // console.warn(evt);
        launch = true;
      } else if (evtName == 'BeforeOpeningTime' || evtName == 'AfterClosingTime' || evtName == 'NoDelivery' || evtName == 'NoPickUp' || evtName == 'OutOfDeliveryArea' || evtName == 'outOfStock') {

        if (payload.short_name) {
          evt.short_name = payload.short_name;
        }
        if (payload.branch_name) {
          evt.branch_name = payload.branch_name;
        }

        if(payload.orderType && payload.orderType == OrderTypes.Domicilio){
          let pos = this.hereS.getLocation() ? this.hereS.getLocation() : false;
          if (pos) {
            evt.lat = pos.lat;
            evt.lng = pos.lng;
          }
        }

        launch = true;
      }else if(evtName == 'login' || evtName == 'sign_up' || evtName == 'loyalty_engagement' || evtName == 'app_response_error'){
        if(payload.error_message){
          evt.error_message = payload.error_message;
        }
        launch = true;
      }else if(evtName == 'app_response_confirm'){
        if(payload.confirm_message){
          evt.confirm_message = payload.confirm_message;
        }
        if(payload.branch_name){
          evt.branch_name = payload.branch_name;
        }

        if(payload.deliveryLocation){
          let pos = this.hereS.getLocation() ? this.hereS.getLocation() : false;
          if (pos) {
            evt.lat = pos.lat;
            evt.lng = pos.lng;
          }
        }

        launch = true;
      }

      if (launch) {
        // console.warn(evtName, evt)
        this.logEvent(evtName, evt);
      }
    } catch (e) {
      this.apiService.logger("error", "", e, evtName)

    }
  }

}

