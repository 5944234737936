import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, BehaviorSubject, Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Version } from '@core/constants';

@Injectable({
  providedIn: 'root'
})
export class SwupdateService {

    public hasUpdateAvailable = new BehaviorSubject(false);
    version = Version

    constructor(
      public updates: SwUpdate,
      public alertController: AlertController
      ) {

      if (updates.isEnabled) {
        updates.checkForUpdate()
        interval(6 * 60 * 60)
        .subscribe(() => 
            updates.checkForUpdate()
            .then(() =>{
              console.log('checking for updates')
            })
            .catch( e => console.log(e) )   
        );
      }
    }

    public checkForUpdates(): void {



      this.updates.available.subscribe(event =>{ 
        console.log(event)
        this.hasUpdateAvailable.next(true)
        this.update();
      });
    }
  
    private update(): void {
      this.updates.activateUpdate().then(() => document.location.reload())
    }


    public pendingUpdate(): Observable<boolean>{
      return this.hasUpdateAvailable;
    }

}