import {
    MenuInterface,
    OrderInterface,
    PromoInterface,
    MenuFamilyProductInterface,
    OrderTypes,
    OrderLineTypes,
    CartCalculatedResponse,
    PromotionCalculatedResponse,
    MenuFamilyInterface,
    LegacySources,
    ProductCalculatedResponse,
    PromoDetailApplicableTo,
    PromoOptions,
    CalculatedLinesResponse,
    CrossSalesType,
    ProductOptions,
    PromoTypes,
    PromoLineInterface
} from '../../../promo-engine/public-api';
import { CounterInterface, PgCommon } from './common';
import { CustomizerSides, PgCustomizer } from './customizer';
import { PgMenu } from './menu';
import { PgWizard, WizardLineInterface } from './wizard';

export interface PgOrderLineToppingsInterface {
    description: string
    grouppingID: number
    fromRecipe: boolean
    id: string
    quantity: number
    side: CustomizerSides
}

// Promotion

export interface AddPromotionResponseInterface {
    errors: any[]
    status: boolean
}

// Line
export interface CreateOrderLineItemsInterface {
    calories: number
    comments: string
    data: PgOrderLineToppingsInterface[]
    familyID: number
    groupID: number
    id?: number
    isHalfNdHalf: boolean
    leftToppings: string[]
    menu: MenuInterface
    order?: number
    productID: string
    promoEngineResult?: ProductCalculatedResponse
    quantity: number
    rightToppings: string[]
    selectedDoughs: CounterInterface<string>
    toppings: string[]
    toppingsNbr: number
    unitPrice: number
}

export interface CreateOrderSubLineInterface {
    halfNdHalf: boolean
    id: number
    productID: string
    side: CustomizerSides
    toppings: string
    toppingsNbr: number
}

export interface CreateOrderLineInterface {
    calories: number
    dough: string
    id: number
    quantity: number
    size: string
    sublines: CreateOrderSubLineInterface[]
    toppings: PgOrderLineToppingsInterface[]
    toppingsNbr: number
    unitPrice: number
}

// Promotion Line

export interface CreateOrderPromotionLineItemsInterface {
    currentStep: number
    id?: number
    lines: WizardLineInterface[]
    menu: MenuInterface
    order?: number
    promoCode?: string
    promoEngineResult?: PromotionCalculatedResponse
    promotionID: string
    promotions: PromoInterface[]
    promoUserID: string
    quantity: number
    totalSteps: number
    unitPrice: number
}

// Create Order

export interface CreateOrderPromoCodesInterface {
    isRedeemable: boolean
    promoID: string
    redeemableCodes: string[]
}

export interface CreateOrderInterface {
    addedCrossSales: string[]
    addressID: number
    branchPickUpID: number
    branchDeliveryID: number
    calculatedLines: CalculatedLinesResponse[]
    change: number
    clickNcar: string
    customerID: number
    device: string
    excludedOrderType: OrderTypes
    deliveryFeeLine: CalculatedLinesResponse
    deliveryFeePromoCode: string
    language: string
    lineItems: CreateOrderLineItemsInterface[]
    orderNumber: number
    orderType: OrderTypes
    paymentMethod: number
    promoCodes: CreateOrderPromoCodesInterface[]
    promoLineItems: CreateOrderPromotionLineItemsInterface[]
    promisedTime: string
    source: LegacySources
    status: string
    ts: string
}

// Summary
export interface CreateOrderSummaryLinesInterface {
    description: string
    id: string
    name: string
    quantity: number
    type: OrderLineTypes
    unitPrice: number
    imageID?: string
    size?: string
    sublines?: CreateOrderSummaryLinesInterface[]
    toppings?: string
}
export interface CreateOrderSummaryInterface {
    discount: number
    lines: CreateOrderSummaryLinesInterface[]
    orderType: OrderTypes
    subtotal: number
    total: number
}

// Type

export type PgOrderLinesType = (PgOrderLine | PgOrderPromoLine)

// Calculate Lines

export interface CalculateLineErrorInterface {
    errors: any[]
    line: PgOrderPromoLine
}

export interface CalculateLinesResponseInterface {
    errors: CalculateLineErrorInterface[]
    lines: PgOrderLinesType[]
}

// Pg Order

export class PgOrderSubline extends PgCommon {
    private halfNdHalf: boolean
    private id: number
    private productID: string
    private side: CustomizerSides
    private toppings: string
    private toppingsNbr: number
    constructor(items: CreateOrderLineItemsInterface, side: CustomizerSides, subLineID: number) {
        super()
        this.halfNdHalf = items.isHalfNdHalf
        this.id = subLineID
        this.productID = items.productID
        this.side = side
        this.toppings = this.getCurrentToppings(items)
        this.toppingsNbr = items.toppingsNbr
    }
    private getCurrentToppings(items: CreateOrderLineItemsInterface): string {
        let result: string[] = []
        switch (this.side) {
            case CustomizerSides.whole:
                result = items.toppings
                break
            case CustomizerSides.left:
                result = items.leftToppings
                break
            case CustomizerSides.right:
                result = items.rightToppings
                break
        }
        return result.join(';')
    }
    // Public methods
    public getSublineToppings(): string {
        return this.toppings
    }
    // public prepareOrderSublinesToSaveTicket(): OrderSubLineInterface {
    //     return {
    //         saleItemID: this.productID,
    //         toppings: this.toppings,
    //         toppingsNbr: this.toppingsNbr,
    //         unitPrice: 0
    //     }
    // }
}

export class PgOrderLine extends PgCommon {
    private comments: string = null
    private id: number
    private order: number
    private promoEngineOptions: ProductOptions = null
    private promoEngineResult: ProductCalculatedResponse = null
    private promoLine: PromoLineInterface
    private quantity: number
    private size: string
    private sublines: PgOrderSubline[]
    private toppings: PgOrderLineToppingsInterface[]
    public dough: string
    public pgCustomizer: PgCustomizer
    public pgMenu: PgMenu
    constructor(
        items: CreateOrderLineItemsInterface,
        id: number,
        menu: MenuInterface,
        promoEngineOptions: ProductOptions,
        promoLine: PromoLineInterface
    ) {
        super()
        this.pgMenu = new PgMenu(menu, [])
        this.pgCustomizer = new PgCustomizer(
            this.pgMenu.getFamily(items.familyID),
            this.pgMenu.getProduct(items.productID),
            menu,
            ''
        )
        this.promoEngineOptions = promoEngineOptions
        this.promoLine = promoLine
        this.updateCustomizer(items)
        this.id = id
        this.order = 0
        this.quantity = items.quantity
        this.dough = this.getDough()
        this.size = this.pgCustomizer.getSize()
        this.sublines = this.getCurrentSublines(items)
        this.toppings = this.getToppings(items)
    }
    public getComments(): string {
        return this.comments
    }
    private getDough(): string {
        let result: string = ''
        this.pgCustomizer.getDoughs().forEach(
            value => {
                if (value.quantity > 0) {
                    result = value.id
                }
            }
        )
        return result
    }
    private getCurrentSublines(items: CreateOrderLineItemsInterface): PgOrderSubline[] {
        const result: PgOrderSubline[] = []
        let sides: CustomizerSides[] = []
        if (items.isHalfNdHalf === true) {
            sides = [
                CustomizerSides.left,
                CustomizerSides.right
            ]
        } else {
            sides = [
                CustomizerSides.whole
            ]
        }
        sides.forEach(
            (value, index) => {
                result.push(
                    new PgOrderSubline(
                        items,
                        value,
                        index
                    )
                )
            }
        )
        return result
    }
    private getToppings(items: CreateOrderLineItemsInterface): PgOrderLineToppingsInterface[] {
        return this.filterIfNotInArray(items.data, 'grouppingID', this.pgCustomizer.getDoughGroupID())
    }
    // Public methods
    public getAllergens(): string {
        return (this.promoEngineResult && 'allergens' in this.promoEngineResult) ? this.promoEngineResult.allergens : ''
    }
    public getCalories(): number {
        return (this.promoEngineResult && 'calories' in this.promoEngineResult) ? this.promoEngineResult.calories : 0
    }
    public getID(): number {
        return this.id
    }
    public getItemsFromCustomizer(qty: number): CreateOrderLineItemsInterface {
        let items: CreateOrderLineItemsInterface = this.pgCustomizer.getItemsToCreateOrderLine(qty)
        items.comments = this.getComments()
        return items
    }
    public getOrder(): number {
        return this.order
    }
    public getOrderToppings(): PgOrderLineToppingsInterface[] {
        let orderToppings: PgOrderLineToppingsInterface[] = this.copyArray(this.toppings)
        orderToppings.sort((a, b) => {
            if (a.quantity === -1 && b.quantity !== -1) {
                return -1
            } else if (a.quantity !== -1 && b.quantity === -1) {
                return 1
            } else {
                return 0
            }
        })
        return orderToppings
    }
    public getPortions(): number {
        return (this.promoEngineResult && 'portions' in this.promoEngineResult) ? this.promoEngineResult.portions : 1
    }
    public getPromoEngineResult(): ProductCalculatedResponse {
        return this.promoEngineResult
    }
    public getQuantity(): number {
        return this.quantity
    }
    public getSize(): string {
        return this.size
    }
    public getSublines(): PgOrderSubline[] {
        return this.deepCopy(this.sublines)
    }
    public getToppingsNbr(): number {
        return (this.promoEngineResult && 'total_num_ingredientes' in this.promoEngineResult) ? this.promoEngineResult.total_num_ingredientes : 0
    }
    public getUnitPrice(): number {
        return (this.promoEngineResult && 'unitPrice' in this.promoEngineResult) ? this.parseToDecimals(this.promoEngineResult.unitPrice) : 0
    }
    public getPromoLine(): PromoLineInterface {
        return this.promoLine
    }
    // public prepareOrderLinesToSaveTicket(): OrderLineInterface {
    //     return {
    //         id: this.pgCustomizer.checkProductCustom() ? '' : this.pgCustomizer.getCurrentProduct().id,
    //         quantity: this.getQuantity(),
    //         sublines: this.getSublines().map(
    //             value => {
    //                 const subline: OrderSubLineInterface = value.prepareOrderSublinesToSaveTicket()
    //                 subline.unitPrice = this.pgCustomizer.checkProductCustom() ? this.parseToDecimals(this.getUnitPrice() / 2) : this.getUnitPrice()
    //                 return subline
    //             }
    //         ),
    //         type: this.pgCustomizer.checkProductCustom() ? OrderLineTypes.composed : OrderLineTypes.simple,
    //         unitPrice: this.getUnitPrice()
    //     }
    // }
    public prepareOrderLinesToSaveTicket(): ProductCalculatedResponse {
        return this.getPromoEngineResult()
    }
    public updateCustomizer(items: CreateOrderLineItemsInterface): void {
        this.pgCustomizer.updateCustomizerFromItems(
            items,
            this.promoEngineOptions,
            this.promoLine
        )
        /*items.data.forEach(
            value => {
                const toppingID: string = value.id
                const topping: MenuFamilyToppingInterface = this.pgMenu.getFamilyTopping(items.familyID, toppingID)
                if (topping) {
                    if (value.quantity > 0) {
                        const qty: number = (value.fromRecipe === true && value.grouppingID !== this.pgCustomizer.getDoughGroupID()) ? (value.quantity + 1) : value.quantity
                        this.pgCustomizer.modifyTopping(topping, value.side, qty)
                    } else {
                        this.pgCustomizer.removeTopping(topping)
                    }
                }
            }
        )*/
    }
    public updateID(id: number, updateCustomID: boolean = false): void {
        this.id = id
        if (this.promoEngineResult && updateCustomID) {
            this.promoEngineResult.customID = this.id
        }
    }
    public updateOrder(o: number): void {
        this.order = o
    }
    public updatePromoEngineResult(r: ProductCalculatedResponse): void {
        this.promoEngineResult = r ? this.copy(r) : r
        if (this.promoEngineResult) {
            this.promoEngineResult.quantity = this.getQuantity()
            this.promoEngineResult.comments = this.getComments()
            if ('full_toppings' in this.promoEngineResult) {
                delete this.promoEngineResult.full_toppings
            }
            if ('sublines' in this.promoEngineResult && Array.isArray(this.promoEngineResult.sublines) && this.promoEngineResult.sublines.length > 0) {
                this.promoEngineResult.sublines.forEach(
                    value => {
                        if (value && 'full_toppings' in value) {
                            delete value.full_toppings
                        }
                    }
                )
            }
        }
    }
    public updateQuantity(q: number): void {
        this.quantity = q
        if (this.promoEngineResult) {
            this.promoEngineResult.quantity = q
        }
    }
    public updateComments(comment: string) {
        this.comments = comment
        if (this.promoEngineResult) {
            this.promoEngineResult.comments = this.getComments()
        }
    }
}

export class PgOrderPromoLine extends PgCommon {
    private id: number
    private isManuallyAdded: boolean
    private order: number
    private pgWizard: PgWizard
    private promoEngineResult: PromotionCalculatedResponse = null
    private promotion: PromoInterface
    private promoUserID: string
    private quantity: number
    private unitPrice: number
    public requiresManualUpdate: boolean
    constructor(items: CreateOrderPromotionLineItemsInterface, id: number, menu: MenuInterface) {
        super()
        this.id = id
        this.isManuallyAdded = true
        this.order = 0
        this.promotion = this.getPromotion(items)
        this.pgWizard = new PgWizard(menu, this.promotion, items.promotions, null)
        this.promoUserID = this.pgWizard.getPromoUserID()
        this.quantity = items.quantity
        this.requiresManualUpdate = this.checkIfPromotionRequiresManualUpdate()
        if (!this.requiresManualUpdate) {
            this.unitPrice = items.lines.map(
                value => value.addedItems.map(
                    v => v.unitPrice
                ).reduce((a, b) => a + b, 0)

            ).reduce((a, b) => a + b, 0)
            this.updateWizardLines(items)
        }
    }
    private getPromotion(items: CreateOrderPromotionLineItemsInterface): PromoInterface {
        let promotion: PromoInterface = this.findInArray(items.promotions, 'id', items.promotionID)
        if (promotion && 'id' in promotion && 'promoUserID' in items) {
            promotion.promoUserID = items.promoUserID
        }
        return promotion
    }
    public addProductToLine(items: CreateOrderLineItemsInterface, lineID: number): boolean {
        return this.pgWizard.addItemsToLine(items, lineID)
    }
    public checkIfPromotionRequiresManualUpdate(): boolean {
        const list: string[] = [
            PromoTypes.Descuento,
            PromoTypes.Loyalty,
            PromoTypes.Delivery
        ]
        return this.promotion ? list.indexOf(this.promotion.promoType) >= 0 : false
    }
    public getCurrentPromotion(): PromoInterface {
        return this.promotion
    }
    public getDiscount(): number {
        return (this.promoEngineResult && 'discount' in this.promoEngineResult) ? this.parseToDecimals(this.promoEngineResult.discount) : 0
    }
    public getID(): number {
        return this.id
    }
    public getIsManuallyAdded(): boolean {
        return this.isManuallyAdded
    }
    public getItemsFromWizard(): CreateOrderPromotionLineItemsInterface {
        return this.pgWizard.getItemsToCreateOrderPromotionLine()
    }
    public getOrder(): number {
        return this.order
    }
    public prepareOrderPromoLinesToSaveTicket(): PromotionCalculatedResponse {
        return this.getPromoEngineResult()
    }
    public getPromoCode(): string {
        return (this.promoEngineResult && 'code' in this.promoEngineResult)
            ? this.promoEngineResult.code
            : null
    }
    public getPromoEngineResult(): PromotionCalculatedResponse {
        return this.promoEngineResult
    }
    public getPromotionID(): string {
        return this.promotion.id
    }
    public getPromoUserID(): string {
        return this.promoUserID
    }
    public getQuantity(): number {
        return this.quantity
    }
    public getUnitPrice(): number {
        return (this.promoEngineResult && 'unitPrice' in this.promoEngineResult)
            ? this.parseToDecimals(this.promoEngineResult.unitPrice)
            : this.parseToDecimals(this.unitPrice)
    }
    public getWizard(): PgWizard {
        return this.pgWizard
    }
    public getWizardCurrentStep(): number {
        return this.pgWizard.getCurrentStep()
    }
    public getWizardItemsFromLine(product: MenuFamilyProductInterface): CreateOrderLineItemsInterface {
        return this.pgWizard.getItemToAddLineToWizard(product)
    }
    public getWizardLines(): WizardLineInterface[] {
        return this.pgWizard.getLines()
    }
    public getWizardTotalSteps(): number {
        return this.pgWizard.getTotalSteps()
    }
    public isCompleted(): boolean {
        return this.pgWizard.isCompleted()
    }
    public removeProductsFromLine(id: number): void {
        this.pgWizard.removeItemsFromLine(id)
    }
    public updateID(id: number): void {
        this.id = id
    }
    public updateIsManuallyAdded(status: boolean): void {
        this.isManuallyAdded = status
    }
    public updateOrder(o: number): void {
        this.order = o
    }
    public updatePromoEngineResult(r: PromotionCalculatedResponse): void {
        this.promoEngineResult = r ? this.copy(r) : r
        if (this.promoEngineResult) {
            if ('sublines' in this.promoEngineResult && Array.isArray(this.promoEngineResult.sublines) && this.promoEngineResult.sublines.length > 0) {
                this.promoEngineResult.sublines.forEach(
                    value => {
                        if (value && 'full_toppings' in value) {
                            delete value.full_toppings
                        }
                    }
                )
            }
            /*let someComponsedLine: boolean = this.someInArray(this.promoEngineResult.sublines, 'type', OrderLineTypes.composed)
            if (someComponsedLine) {
                let items = this.getItemsFromWizard()
                let pgMenu: PgMenu = new PgMenu(items.menu, items.promotions)
                this.promoEngineResult.sublines.forEach(
                    value => {
                        if (value.type === OrderLineTypes.composed) {
                            let product = pgMenu.getProduct(value.sublines[0].saleItemID)
                            let family = pgMenu.getFamily(product.familyGroupID)
                            let pgCustomizer: PgCustomizer = new PgCustomizer(family, product, items.menu, '')
                            pgCustomizer.updateCustomizerFromPromoEngineResult(value)
                            let sublineItems: CreateOrderLineItemsInterface = pgCustomizer.getItemsToCreateOrderLine(1)
                            value.toppings = sublineItems.toppings
                            // delete value.toppings
                        }
                    }
                )
            }
            console.log(this.promoEngineResult)*/
            if (this.requiresManualUpdate) {
                this.pgWizard.updateLinesPromoDiscount(this.promoEngineResult)
            }
        }
    }
    public updateQuantity(q: number): void {
        this.quantity = q
        this.pgWizard.updateQuantity(q)
        if (this.promoEngineResult) {
            this.promoEngineResult.quantity = q
        }
    }
    public updateWizardLine(line: WizardLineInterface): void {
        this.pgWizard.updateLine(line)
    }
    public updateWizardLineComments(id: number, comment: string): void {
        let position: number = this.getWizardLines().findIndex(l => l.id === id)
        if (position >= 0) {
            let wizardLine: WizardLineInterface = this.getWizardLines()[position]
            if (wizardLine.addedItems.length > 0) {
                wizardLine.addedItems[0].comments = comment
                if (wizardLine.addedItems[0].promoEngineResult) {
                    wizardLine.addedItems[0].promoEngineResult.comments = comment
                }
                this.updateWizardLine(wizardLine)
            }
        }
    }
    public updateWizardLines(items: CreateOrderPromotionLineItemsInterface): void {
        if (items.lines.length > 0) {
            items.lines.forEach(
                value => {
                    this.updateWizardLine(value)
                }
            )
        }
    }
}

export class PgOrder extends PgCommon {
    private addedCrossSales: string[]
    private addressID: number
    private branchPickUpID: number
    private branchDeliveryID: number
    /**
     * @description Promo-Engine auto
     */
    private calculatedLines: CalculatedLinesResponse[]
    private change: number
    private clickNcar: string
    private customerID: number
    private deliveryFeeLine: CalculatedLinesResponse
    private deliveryFeePromoCode: string = null
    private device: string
    private excludedOrderType: OrderTypes
    private language: string
    private lines: PgOrderLine[]
    private menu: MenuInterface
    private orderNumber: number
    private orderType: OrderTypes
    private paymentMethod: number
    private promoCodes: CreateOrderPromoCodesInterface[]
    private promisedTime: string
    private promotionLines: PgOrderPromoLine[]
    private promotions: PromoInterface[]
    private source: LegacySources
    private status: string
    private ts: string
    constructor(order: CreateOrderInterface, menu: MenuInterface, promotions: PromoInterface[]) {
        super()
        this.lines = []
        this.promotionLines = []
        if (order && menu && promotions) {
            this.updateOrder(order, menu, promotions)
        }
    }
    // Loyalty
    public addLoyaltyPromo(loyaltyPromo: PromoInterface, points: number, extraLines: WizardLineInterface[] = [], singleExtraLine: CreateOrderLineItemsInterface = null, filterID: number = null): AddPromotionResponseInterface {
        let calculatedLines: CartCalculatedResponse = this.calculatePromotion(
            this.getPromoEngineOptions(),
            this.menu,
            this.promotions,
            loyaltyPromo.id,
            this.getLinesToCalculatePromotion(filterID, extraLines, singleExtraLine),
            null,
            points
        )
        let response: AddPromotionResponseInterface = {
            errors: [],
            status: false
        }
        if (calculatedLines && calculatedLines.errors && Array.isArray(calculatedLines.errors)) {
            response.errors = calculatedLines.errors
            if (response.errors.length === 0) {
                let oldLines = this.disengageLines(this.getLinesToCalculatePromotion(filterID))
                let linesToCheck: OrderLineTypes[] = [
                    OrderLineTypes.composed,
                    OrderLineTypes.simple
                ]
                let customIDalreadyUsed: boolean = false
                calculatedLines.complete = this.disengageLines(calculatedLines.complete)
                calculatedLines.complete.forEach(
                    value => {
                        if (filterID && this.comparePromoLines(value, calculatedLines.promo) && !customIDalreadyUsed) {
                            customIDalreadyUsed = true
                            value.customID = filterID
                        } else {
                            oldLines.forEach(
                                v => {
                                    if (value.type === v.type) {
                                        if (linesToCheck.indexOf(value.type) >= 0) {
                                            if (this.compareLines(value, v)) {
                                                value.customID = v.customID
                                            }
                                        } else {
                                            if (this.comparePromoLines(value, v)) {
                                                value.customID = v.customID
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
                let pgWizard: PgWizard = new PgWizard(this.menu, loyaltyPromo, this.promotions, this.getPromoEngineOptions())
                pgWizard.updateLinesPromoDiscount(calculatedLines.promo)
                response = this.addPromotion(pgWizard.getItemsToCreateOrderPromotionLine(), pgWizard.isCompleted(), null, calculatedLines)
            }
        }
        return response
    }
    // Add Lines
    public addLine(line: PgOrderLine): boolean {
        if (line.getUnitPrice() > 0) {
            this.lines.push(line)
            return true
            /*const position: number = this.getLines().reduce(
                (result: number, value) => {
                    return result
                }, -1
            )*/
        }
        return false
    }
    public addPromotion(items: CreateOrderPromotionLineItemsInterface, isCompleted: boolean, promoLine: PgOrderPromoLine = null, cartCalculated: CartCalculatedResponse = null): AddPromotionResponseInterface {
        let response: AddPromotionResponseInterface = {
            errors: [],
            status: false
        }
        if (isCompleted) {
            if (promoLine) {
                items.id = promoLine.getID()
                items.quantity = promoLine.getQuantity()
            }
            if (cartCalculated) {
                cartCalculated.promo.quantity = promoLine.getQuantity()
            }
            response.errors = this.updateEntireOrder(items, cartCalculated)
            if (response.errors && Array.isArray(response.errors) && response.errors.length === 0) {
                response.status = true
            }
            return response
        } else {
            response.status = true
            if (promoLine) {
                this.updateOrderLine(promoLine)
            } else {
                this.addPromotionLine(this.createPromotionLine(items))
            }
        }
        return response
    }
    public addPromotionLine(line: PgOrderPromoLine, prepend: boolean = false) {
        if (prepend) {
            this.promotionLines.unshift(line)
        } else {
            this.promotionLines.push(line)
        }
        return true
    }
    // Check CrossSales
    public checkCommentCrossSales(cross: PromoInterface, items: CreateOrderLineItemsInterface = null): (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[] {
        let findType: PromoDetailApplicableTo = cross.crossFindType
        let saleItems: string[] = []
        try {
            saleItems = cross.crossFindCode.split(';').filter(Boolean)
        } catch (e) { }
        let response = []
        if (items) {
            let findID: string = null
            let isAdded: boolean = false
            switch (findType) {
                case PromoDetailApplicableTo.group:
                    findID = items.groupID.toString()
                    break
                case PromoDetailApplicableTo.saleItem:
                    findID = items.productID
                    break
                default:
                    findID = items.familyID.toString()
                    break
            }
            isAdded = (saleItems.indexOf(findID) >= 0 && items.comments === null)
            if (isAdded) {
                response.push(items)
            }
        } else {
            response = this.getFullLines().reduce(
                (result: (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[], value) => {
                    let isAdded: boolean = false
                    if (value instanceof PgOrderLine) {
                        let findID: string = null
                        switch (findType) {
                            case PromoDetailApplicableTo.group:
                                findID = value.pgCustomizer.getCurrentFamily().webSalesGroup.toString()
                                break
                            case PromoDetailApplicableTo.saleItem:
                                findID = value.pgCustomizer.getCurrentProduct().id
                                break
                            default:
                                findID = value.pgCustomizer.getCurrentFamily().id.toString()
                                break
                        }
                        isAdded = (saleItems.indexOf(findID) >= 0 && value.getComments() === null)
                        if (isAdded) {
                            let items: CreateOrderLineItemsInterface = value.getItemsFromCustomizer(value.getQuantity())
                            items.id = value.getID()
                            items.promoEngineResult = value.getPromoEngineResult()
                            result.push(items)
                        }
                    } else if (value instanceof PgOrderPromoLine) {
                        let wizardLines: WizardLineInterface[] = value.getWizardLines().reduce(
                            (resp: WizardLineInterface[], v) => {
                                if (v.addedItems.length > 0) {
                                    let items: CreateOrderLineItemsInterface = v.addedItems[0]
                                    let findID: string = null
                                    switch (findType) {
                                        case PromoDetailApplicableTo.group:
                                            findID = items.groupID.toString()
                                            break
                                        case PromoDetailApplicableTo.saleItem:
                                            findID = items.productID
                                            break
                                        default:
                                            findID = items.familyID.toString()
                                            break
                                    }
                                    if (saleItems.indexOf(findID) >= 0 && items.promoEngineResult.comments === null) {
                                        resp.push(v)
                                    }
                                }
                                return resp
                            }, []
                        )
                        if (wizardLines.length > 0) {
                            let promoItems: CreateOrderPromotionLineItemsInterface = value.getItemsFromWizard()
                            promoItems.id = value.getID()
                            promoItems.lines = wizardLines
                            promoItems.promoEngineResult = value.getPromoEngineResult()
                            result.push(promoItems)
                        }
                    }
                    return result
                }, []
            )
        }
        return response
    }
    public checkCrossSales(cross: PromoInterface): boolean {
        let mustBeAdded: boolean = cross.crossType === CrossSalesType.Xcross
        let orderLines: PgOrderLine[] = this.getLines()
        let result: boolean = true
        let saleItems: string[] = []
        try {
            saleItems = cross.crossFindCode.split(';').filter(Boolean)
        } catch (e) { }
        if (saleItems.length > 0) {
            let isAdded: boolean = orderLines.some(
                l => {
                    let findType: PromoDetailApplicableTo = cross.crossFindType
                    let findID: string = (findType === PromoDetailApplicableTo.group ? l.pgCustomizer.getCurrentFamily().webSalesGroup.toString() : (findType === PromoDetailApplicableTo.saleItem ? l.pgCustomizer.getCurrentProduct().id : l.pgCustomizer.getCurrentFamily().id.toString()))
                    return saleItems.indexOf(findID) >= 0
                }
            )
            result = mustBeAdded ? isAdded : !isAdded
            // const isCrossAdded: boolean = this.getPromotionLines().some(p => p.getPromotionID() === cross.id)
            // if (isCrossAdded) {
            //     result = !isCrossAdded
            // } else {
            /*saleItems.forEach(
                value => {
                    switch (cross.crossFindType) {
                        case PromoDetailApplicableTo.multiple:
                        case PromoDetailApplicableTo.family:
                            const isFamilyAdded: boolean = orderLines.some(l => {
                                return l.pgCustomizer.getCurrentFamily().id === parseInt(value)
                            })
                            result = mustBeAdded ? isFamilyAdded : !isFamilyAdded
                            break
                        case PromoDetailApplicableTo.group:
                            const isGroupAdded: boolean = orderLines.some(l => {
                                return l.pgCustomizer.getCurrentFamily().webSalesGroup === parseInt(value)
                            })
                            result = mustBeAdded ? isGroupAdded : !isGroupAdded
                            break
                        case PromoDetailApplicableTo.saleItem:
                            const isProductAdded: boolean = orderLines.some(l => {
                                return l.pgCustomizer.getCurrentProduct().id === value
                            })
                            result = mustBeAdded ? isProductAdded : !isProductAdded
                            break
                    }
                }
            )*/
            // }
        }
        return result
    }
    // Check Promo User ID
    public checkPromoUserID(id: string): boolean {
        return this.getPromotionLines().some(p => p.getPromoUserID() === id)
    }
    // Promo Engine
    public getPromoEngineOptions(): (ProductOptions | PromoOptions) {
        return {
            orderType: this.getOrderType(),
            source: this.getOrderSource()
        }
    }
    public getDeliveryPromoEngineOptions(): ProductOptions | PromoOptions {
        return {
            orderType: OrderTypes.Domicilio,
            source: this.getOrderSource()
        }
    }
    public getPickUpPromoEngineOptions(): ProductOptions | PromoOptions {
        return {
            orderType: OrderTypes.Recoger,
            source: this.getOrderSource()
        }
    }
    public getCalculatedLines(): (PromotionCalculatedResponse | ProductCalculatedResponse)[] {
        return this.copyArray(this.calculatedLines)
    }
    public setCalculatedLines(l: CalculatedLinesResponse[]): void {
        this.calculatedLines = l
    }
    public updateCalculatedLines(excludedPromotionsFromUser: string[] = [], promoEngineOptions = this.getPromoEngineOptions(), loyaltyPoints: number, updateCrossSalesSublines: boolean = false): CalculateLinesResponseInterface {
        let calculatedCrossSalesLines: PgOrderPromoLine[] = []
        let currentPromotions: PromoInterface[] = this.promotions.reduce(
            (result: PromoInterface[], value) => {
                if (excludedPromotionsFromUser.indexOf(value.id) < 0) {
                    result.push(this.copy(value))
                }
                return result
            }, []
        )
        let errorsCrossSalesLines: CalculateLineErrorInterface[] = []
        let extraPromotions: PromoInterface[] = (this.promoCodes && Array.isArray(this.promoCodes) && this.promoCodes.length > 0) ? this.promoCodes.reduce(
            (result: PromoInterface[], value) => {
                let promotion: PromoInterface = this.findInArray(this.promotions, 'id', value.promoID)
                if (promotion && 'manuallyAdded' in promotion && promotion.manuallyAdded === 'S' && promotion.orderType.includes(promoEngineOptions.orderType)) {
                    result.push(promotion)
                }
                return result
            }, []
        ) : []
        let linesToCheck: OrderLineTypes[] = [
            OrderLineTypes.composed,
            OrderLineTypes.simple
        ]
        let orderLines: CalculatedLinesResponse[] = this.getLinesToCalculatePromotion(null, [], null, true)
        let orderPromoLines: PgOrderPromoLine[] = this.getPromotionLines().reduce(
            (result: PgOrderPromoLine[], value) => {
                if (value.isCompleted() && value.getCurrentPromotion().promoType !== PromoTypes.CrossSales) {
                    result.push(value)
                }
                return result
            }, []
        )
        let that: this = this
        let orderCrossSalesLines: PgOrderPromoLine[] = this.getPromotionLines()
            .sort(
                (a, b) => a.getCurrentPromotion().minimumOrderAmount - b.getCurrentPromotion().minimumOrderAmount
            )
            .reduce(
                (result: PgOrderPromoLine[], value) => {
                    if (value.isCompleted() && value.getCurrentPromotion().promoType === PromoTypes.CrossSales) {
                        let line: PgOrderPromoLine = that.deepCopy(value)
                        line.updateQuantity(1)
                        result = that.pushToArrayNtimes(result, value.getQuantity(), line)
                    }
                    return result
                }, []
            )
        let redeemablePromoCodes: CreateOrderPromoCodesInterface[] = this.deepCopy(this.promoCodes)
        this.calculatedLines = (orderLines.length > 0) ? this.calculateAutoBasket(promoEngineOptions, this.menu, currentPromotions, extraPromotions, this.deepCopy(orderLines), loyaltyPoints) : []
        if (orderCrossSalesLines.length > 0) {
            let promoLines: PromotionCalculatedResponse[] = orderPromoLines.reduce(
                (result: PromotionCalculatedResponse[], value) => {
                    let line: PgOrderPromoLine = that.deepCopy(value)
                    line.updateQuantity(1)
                    result = that.pushToArrayNtimes(result, value.getQuantity(), line.getPromoEngineResult())
                    return result
                }, []
            )
            orderCrossSalesLines.forEach(
                l => {
                    let sublines: ProductCalculatedResponse[] = this.deepCopy(l.getPromoEngineResult().sublines)
                    if (updateCrossSalesSublines) {
                        sublines = l.getPromoEngineResult().sublines.map(
                            subline => {
                                let calculatedSubline: ProductCalculatedResponse = null
                                if (subline.type === OrderLineTypes.composed) {
                                    calculatedSubline = this.calculateComposedProduct(promoEngineOptions, this.menu, subline.sublines)
                                } else if (subline.type === OrderLineTypes.simple) {
                                    calculatedSubline = this.calculateProduct(promoEngineOptions, this.menu, subline.saleItemID, subline.toppings as string[])
                                }
                                if (calculatedSubline && 'saleItemID' in calculatedSubline && 'unitPrice' in calculatedSubline) {
                                    try {
                                        delete calculatedSubline['full_toppings']
                                    } catch (e) { }
                                    return calculatedSubline
                                }
                                return subline
                            }
                        )
                    }
                    let currentLines: CalculatedLinesResponse[] = that.disengageLines(
                        [
                            ...sublines,
                            ...that.disengageLines(that.calculatedLines),
                            ...calculatedCrossSalesLines.map(c => c.getPromoEngineResult()),
                            ...promoLines
                        ]
                    )
                    let result: CartCalculatedResponse = that.calculatePromotion(
                        promoEngineOptions,
                        this.menu,
                        [
                            ...currentPromotions,
                            ...extraPromotions
                        ],
                        l.getCurrentPromotion().id,
                        that.deepCopy(currentLines),
                        null,
                        loyaltyPoints
                    )
                    if (result.errors.length === 0) {
                        let position: number = calculatedCrossSalesLines.findIndex(v => v.getID() === l.getID())
                        if (position >= 0) {
                            if (updateCrossSalesSublines) {
                                calculatedCrossSalesLines[position].updatePromoEngineResult(result.promo)
                            }
                            calculatedCrossSalesLines[position].updateQuantity(calculatedCrossSalesLines[position].getQuantity() + 1)
                        } else {
                            if (updateCrossSalesSublines) {
                                l.updatePromoEngineResult(result.promo)
                            }
                            calculatedCrossSalesLines.push(that.deepCopy(l))
                        }
                    } else {
                        errorsCrossSalesLines.push(
                            {
                                errors: result.errors,
                                line: l
                            }
                        )
                    }
                }
            )
        }
        orderLines = this.engageLines(orderLines as any[], false)
        this.calculatedLines.forEach(
            value => {
                if (linesToCheck.indexOf(value.type) >= 0) {
                    orderLines.forEach(
                        v => {
                            if (value.type === v.type) {
                                if (this.compareLines(value as ProductCalculatedResponse, v as ProductCalculatedResponse)) {
                                    value.customID = v.customID
                                }
                            }
                        }
                    )
                } else {
                    if ('sublines' in value) {
                        value.sublines.forEach(
                            val => {
                                orderLines.forEach(
                                    v => {
                                        if (val.type === v.type) {
                                            if (this.compareLines(val as ProductCalculatedResponse, v as ProductCalculatedResponse)) {
                                                val.customID = v.customID
                                            }
                                        }
                                    }
                                )
                            }
                        )
                    }
                    if (value.type === OrderLineTypes.promotion) {
                        let redeemablePromoCodePosition: number = redeemablePromoCodes.findIndex(r => r.promoID === value.saleItemID);
                        if (redeemablePromoCodePosition >= 0 && Array.isArray(redeemablePromoCodes[redeemablePromoCodePosition].redeemableCodes) && redeemablePromoCodes[redeemablePromoCodePosition].redeemableCodes.length > 0) {
                            let redeemablePromoCode: CreateOrderPromoCodesInterface = this.deepCopy(redeemablePromoCodes[redeemablePromoCodePosition]);
                            let codeToRedeem: string = this.deepCopy(redeemablePromoCode.redeemableCodes[0]);
                            (value as PromotionCalculatedResponse).code = codeToRedeem;
                            if ("isRedeemable" in redeemablePromoCode && redeemablePromoCode.isRedeemable) {
                                redeemablePromoCodes[redeemablePromoCodePosition].redeemableCodes = redeemablePromoCodes[redeemablePromoCodePosition].redeemableCodes.filter(c => c !== codeToRedeem);
                            }
                        }
                    }
                }
            }
        )
        this.calculatedLines = this.engageLines(this.disengageLines(this.calculatedLines), true)
        let lines: PgOrderLinesType[] = this.getOrderLinesFromCartCalculated({
            complete: this.copyArray(this.calculatedLines),
            discarded: [],
            errors: [],
            promo: null
        }, null).concat(
            [
                ...orderPromoLines,
                ...calculatedCrossSalesLines
            ]
        )
        lines.forEach(
            value => {
                if (value instanceof PgOrderPromoLine) {
                    if (!this.someInArray(this.getPromotionLines(), 'id', value.getID())) {
                        value.updateIsManuallyAdded(false)
                    }
                }
            }
        )
        return { errors: errorsCrossSalesLines, lines: this.sortPgLines(lines) }
    }
    // Summary
    public getProductSummary(line: ProductCalculatedResponse): CreateOrderSummaryLinesInterface {
        let pgCustomizer: PgCustomizer = new PgCustomizer(null, null, this.menu, '')
        let productID: string = (line.type === OrderLineTypes.composed) ? line.sublines[0].saleItemID : line.saleItemID
        let product: MenuFamilyProductInterface = pgCustomizer.getProduct(productID)
        let family = pgCustomizer.getFamily(product.familyGroupID)
        let summary = <CreateOrderSummaryLinesInterface>{}
        pgCustomizer.updateFamily(family)
        pgCustomizer.updateProduct(product)
        pgCustomizer.updateCustomizerFromPromoEngineResult(line)
        summary.description = product.description
        summary.id = productID
        summary.imageID = product.imageID
        summary.name = product.shortName
        summary.quantity = line.quantity || 1
        summary.size = pgCustomizer.getSize()
        summary.type = line.type
        summary.toppings = pgCustomizer.getToppingsDescription()
        summary.unitPrice = line.unitPrice
        return summary
    }
    public getSummary(): CreateOrderSummaryInterface {
        let order: OrderInterface = this.prepareOrderToSaveTicket(false)
        let orderLines: CalculatedLinesResponse[] = this.engageLines(this.disengageLines(order.lines), true)
        let composedTypes: OrderLineTypes[] = [
            OrderLineTypes.composed
        ]
        let promotionTypes: OrderLineTypes[] = [
            OrderLineTypes.crossSales,
            OrderLineTypes.promotion
        ]
        let that: this = this
        let summary: CreateOrderSummaryInterface = {
            discount: 0,
            lines: [],
            orderType: order.orderType,
            subtotal: 0,
            total: order.total
        }
        orderLines.forEach(
            value => {
                if (promotionTypes.indexOf(value.type) >= 0) {
                    let line: PromotionCalculatedResponse = value as PromotionCalculatedResponse
                    let lineQuantity: number = line.quantity || 1
                    let lineDiscount: number = (line.discount * lineQuantity)
                    let promotion: PromoInterface = this.promotions.find(p => p.id === line.saleItemID)
                    let sublines: CreateOrderSummaryLinesInterface[] = line.sublines.reduce(
                        (result: CreateOrderSummaryLinesInterface[], v) => {
                            let sublineSummary: CreateOrderSummaryLinesInterface = that.getProductSummary(v)
                            result.push(sublineSummary)
                            return result
                        }, []
                    )
                    summary.discount += lineDiscount
                    summary.lines.push({
                        description: promotion.webDescription,
                        id: promotion.id,
                        imageID: promotion.id,
                        name: promotion.description,
                        quantity: lineQuantity,
                        sublines: sublines,
                        type: line.type,
                        unitPrice: line.unitPrice
                    })
                    summary.subtotal += (lineDiscount + (line.unitPrice * lineQuantity))
                } else {
                    let line: ProductCalculatedResponse = value as ProductCalculatedResponse
                    let productSummary: CreateOrderSummaryLinesInterface = this.getProductSummary(line)
                    if (composedTypes.indexOf(line.type) >= 0 && 'sublines' in line) {
                        productSummary.sublines = line.sublines.reduce(
                            (result: CreateOrderSummaryLinesInterface[], v) => {
                                let sublineSummary: CreateOrderSummaryLinesInterface = that.getProductSummary(v)
                                result.push(sublineSummary)
                                return result
                            }, []
                        )
                    }
                    summary.lines.push(productSummary)
                    summary.subtotal += (productSummary.unitPrice * productSummary.quantity)
                }
            }
        )
        summary.discount = this.parseToDecimals(summary.discount)
        summary.subtotal = this.parseToDecimals(summary.subtotal)
        summary.total = this.parseToDecimals(summary.total)
        return summary
    }
    //
    public createLine(items: CreateOrderLineItemsInterface, productCalculated: ProductCalculatedResponse = null): PgOrderLine {
        const comments: string = ('comments' in items) ? items.comments : null
        const lineID: number = ('id' in items) ? items.id : this.createOrderLineID()
        const order: number = ('order' in items) ? items.order : (this.getLinesLength() + 1)
        const result: PgOrderLine = new PgOrderLine(
            items,
            lineID,
            items.menu,
            this.getPromoEngineOptions(),
            null
        )
        result.updateOrder(order)
        if (!productCalculated) {
            productCalculated = items.isHalfNdHalf ?
                this.calculateComposedProduct(
                    this.getPromoEngineOptions(),
                    items.menu,
                    [
                        {
                            saleItemID: items.productID,
                            toppings: items.leftToppings,
                            type: OrderLineTypes.composed,
                            unitPrice: items.unitPrice
                        },
                        {
                            saleItemID: items.productID,
                            toppings: items.rightToppings,
                            type: OrderLineTypes.composed,
                            unitPrice: items.unitPrice
                        }
                    ]
                ) :
                this.calculateProduct(
                    this.getPromoEngineOptions(),
                    items.menu,
                    items.productID,
                    items.toppings
                )
        }
        if (productCalculated) {
            /*if (items.isHalfNdHalf) {
                productCalculated.toppings = items.toppings
            }*/
            result.updatePromoEngineResult(productCalculated)
        }
        if (comments !== null) {
            result.updateComments(comments)
        }
        return result
    }
    public createPromotionLine(items: CreateOrderPromotionLineItemsInterface, promotionCalculated: PromotionCalculatedResponse = null): PgOrderPromoLine {
        const lineID: number = ('id' in items) ? items.id : (new Date()).getTime()
        const order: number = ('order' in items) ? items.order : (this.getLinesLength() + 1)
        let result: PgOrderPromoLine = new PgOrderPromoLine(
            items,
            lineID,
            items.menu
        )
        result.updateOrder(order)
        if (items.totalSteps === items.currentStep) {
            if (promotionCalculated) {
                result.updatePromoEngineResult(promotionCalculated)
            } else {
                const orderLines: CalculatedLinesResponse[] = items.lines.reduce(
                    (result: CalculatedLinesResponse[], value) => {
                        result.push(
                            ...value.addedItems.reduce(
                                (resp: CalculatedLinesResponse[], v) => {
                                    resp.push(v.promoEngineResult)
                                    return resp
                                }, []
                            )
                        )
                        return result
                    }, []
                )
                const cartCalculated: CartCalculatedResponse = this.calculatePromotion(
                    this.getPromoEngineOptions(),
                    this.menu,
                    this.promotions,
                    items.promotionID,
                    orderLines
                )
                if (cartCalculated && cartCalculated.promo && cartCalculated.errors.length === 0) {
                    const wizardItems = this.updateWizardLinesFromOrderPromoLine(cartCalculated.promo, result.getItemsFromWizard())
                    result.updateWizardLines(wizardItems)
                    result.updatePromoEngineResult(cartCalculated.promo)
                }
            }
        }
        return result
    }
    public emptyLines(): void {
        this.lines = []
        this.promotionLines = []
    }
    public getAddedCrossSales(): string[] {
        return (this.addedCrossSales && Array.isArray(this.addedCrossSales)) ? this.addedCrossSales : []
    }
    public getBranchDeliveryID(): number {
        return this.branchDeliveryID
    }
    public getDeliveryFeeLine(): CalculatedLinesResponse {
        return this.copy(this.deliveryFeeLine)
    }
    public getDeliveryFeePromoCode(): string {
        return this.deliveryFeePromoCode
    }
    public getExcludedOrderType(): OrderTypes {
        return this.excludedOrderType
    }
    public getFullLines(): PgOrderLinesType[] {
        const lines = [
            ...this.getLines(),
            ...this.getPromotionLines()
        ]
        return this.sortPgLines(lines)
    }
    public getLineById(id: number): PgOrderLine {
        return this.findInArray(this.getLines(), 'id', id)
    }
    public getPromotionLineById(id: number): PgOrderPromoLine {
        return this.findInArray(this.getPromotionLines(), 'id', id)
    }
    public getLines(): PgOrderLine[] {
        return this.lines
    }
    public getLinesLength(): number {
        return this.lines.length + this.promotionLines.length
    }
    public getLinesToCalculatePromotion(filterID: number = null, extraLines: WizardLineInterface[] = [], singleExtraLine: CreateOrderLineItemsInterface = null, ignorePromotions: boolean = false): CalculatedLinesResponse[] {
        let lines: PgOrderLinesType[] = this.getFullLines()
        lines.sort((a, b) => a.getOrder() - b.getOrder())
        if (filterID) {
            lines = this.filterIfNotInArray(lines, 'id', filterID)
        }
        let calculatedLines: CalculatedLinesResponse[] = lines.reduce(
            (result: CalculatedLinesResponse[], value) => {
                if (value instanceof PgOrderPromoLine) {
                    if (!ignorePromotions) {
                        const promoEngineResult = value.getPromoEngineResult()
                        if (value.isCompleted()) {
                            promoEngineResult.customID = value.getID()
                            promoEngineResult.quantity = 1
                            result = this.pushToArrayNtimes(
                                result,
                                value.getQuantity(),
                                this.copy(promoEngineResult)
                            )
                        }
                    }
                } else {
                    const promoEngineResult = value.getPromoEngineResult()
                    if (promoEngineResult) {
                        promoEngineResult.customID = value.getID()
                        promoEngineResult.quantity = 1
                        result = this.pushToArrayNtimes(
                            result,
                            value.getQuantity(),
                            this.copy(promoEngineResult)
                        )
                    }
                }
                return result
            }, [])
        if (extraLines.length > 0) {
            calculatedLines.unshift(
                ...extraLines.reduce(
                    (result: CalculatedLinesResponse[], value) => {
                        result.push(
                            ...value.addedItems.reduce(
                                (resp: CalculatedLinesResponse[], v) => {
                                    resp.push(this.copy(v.promoEngineResult))
                                    return resp
                                }, []
                            )
                        )
                        return result
                    }, []
                )
            )
        }
        if (singleExtraLine) {
            calculatedLines.unshift(singleExtraLine.promoEngineResult)
        }
        return calculatedLines
    }
    public getPromotionLines(): PgOrderPromoLine[] {
        return this.promotionLines
    }
    public getOrder(): CreateOrderInterface {
        const order: CreateOrderInterface = {
            addedCrossSales: this.addedCrossSales,
            addressID: this.addressID,
            branchDeliveryID: this.branchDeliveryID,
            branchPickUpID: this.branchPickUpID,
            calculatedLines: this.calculatedLines,
            change: this.change,
            clickNcar: this.clickNcar,
            customerID: this.customerID,
            excludedOrderType: this.excludedOrderType,
            device: this.device,
            deliveryFeeLine: this.deliveryFeeLine,
            deliveryFeePromoCode: this.deliveryFeePromoCode,
            language: this.language,
            lineItems: [],
            orderNumber: this.orderNumber,
            orderType: this.orderType,
            paymentMethod: this.paymentMethod,
            promoCodes: this.promoCodes,
            promisedTime: this.promisedTime,
            promoLineItems: [],
            source: this.source,
            status: this.status,
            ts: this.ts
        }
        this.getFullLines().forEach(
            value => {
                if (value instanceof PgOrderLine) {
                    order.lineItems.push(this.optimizeLineItems(value))
                } else if (value instanceof PgOrderPromoLine) {
                    order.promoLineItems.push(this.optimizePromotionLineItems(value))
                }
            }
        )
        return order
    }
    public getOrderLineItemsFromPromoEngineResult(result: ProductCalculatedResponse): CreateOrderLineItemsInterface {
        const pgMenu: PgMenu = new PgMenu(this.menu, [])
        // ToDo CANTIDAD
        const qty: number = result.quantity || 1
        let productID: string = result.saleItemID
        if (result.type === OrderLineTypes.composed) {
            productID = result.sublines[0].saleItemID
        }
        const product: MenuFamilyProductInterface = pgMenu.getProduct(productID)
        const family: MenuFamilyInterface = pgMenu.getFamily(product.familyGroupID)
        const pgCustomizer: PgCustomizer = new PgCustomizer(family, product, this.menu, '')
        pgCustomizer.updateCustomizerFromPromoEngineResult(result)
        const items: CreateOrderLineItemsInterface = pgCustomizer.getItemsToCreateOrderLine(qty)
        items.comments = result.comments
        return items
    }
    public getOrderSource(): LegacySources {
        return this.source
    }
    public getOrderType(): OrderTypes {
        return this.orderType
    }
    public getPgOrderLinesFromPgOrderPromoLine(wizardLines: WizardLineInterface[]): PgOrderLine[] {
        const result: PgOrderLine[] = []
        wizardLines.forEach(
            val => {
                val.addedItems.forEach(
                    v => {
                        result.push(this.createLine({
                            ...v,
                            menu: this.menu
                        }, v.promoEngineResult))
                    }
                )
            }
        )
        return result
    }
    public optimizeLineItems(line: PgOrderLine): CreateOrderLineItemsInterface {
        const items: CreateOrderLineItemsInterface = line.getItemsFromCustomizer(line.getQuantity())
        items.calories = line.getCalories()
        items.id = line.getID()
        items.order = line.getOrder()
        items.promoEngineResult = line.getPromoEngineResult()
        items.toppingsNbr = line.getToppingsNbr()
        items.unitPrice = line.getUnitPrice()
        delete items.menu
        return items
    }
    public optimizePromotionLineItems(line: PgOrderPromoLine): CreateOrderPromotionLineItemsInterface {
        const items: CreateOrderPromotionLineItemsInterface = line.getItemsFromWizard()
        items.id = line.getID()
        items.order = line.getOrder()
        items.quantity = line.getQuantity()
        items.lines.forEach(
            value => {
                value.addedItems.forEach(
                    v => {
                        delete v.menu
                    }
                )
            }
        )
        items.promoEngineResult = line.getPromoEngineResult()
        if (items.promoEngineResult) {
            items.unitPrice = items.promoEngineResult.unitPrice
        }
        delete items.menu
        delete items.promotions
        return items
    }
    public removeLineById(id: number, quantityToRemove: number = 1): void {
        const order: CreateOrderInterface = this.getOrder()
        const linePosition: number = this.findIndexInArray(order.lineItems, 'id', id)
        const promoLinePosition: number = this.findIndexInArray(order.promoLineItems, 'id', id)
        if (linePosition >= 0) {
            order.lineItems[linePosition].quantity -= quantityToRemove
            if (order.lineItems[linePosition].quantity < 1) {
                order.lineItems = this.filterIfNotInArray(order.lineItems, 'id', id)
            }
            // if (order.lineItems[linePosition].quantity > 1 && !forceRemove) {
            //     order.lineItems[linePosition].quantity -= 1
            // } else {
            //     order.lineItems = this.filterIfNotInArray(order.lineItems, 'id', id)
            // }
            this.updateOrder(order)
        } else if (promoLinePosition >= 0) {
            order.promoLineItems = this.filterIfNotInArray(order.promoLineItems, 'id', id)
            this.updateOrder(order)
        }
    }
    public prepareOrderToSaveTicket(cleanLines: boolean = true): OrderInterface {
        const o: CreateOrderInterface = this.getOrder()
        const order: OrderInterface = {
            addressID: o.addressID,
            branchID: (o.orderType === OrderTypes.Domicilio) ? o.branchDeliveryID : o.branchPickUpID,
            change: o.change,
            clickNcar: o.clickNcar,
            customerID: o.customerID,
            device: o.device,
            fee: null,
            feePromoCode: o.deliveryFeePromoCode,
            language: o.language,
            lines: [],
            orderNumber: o.orderNumber,
            orderType: o.orderType,
            paymentMethod: o.paymentMethod,
            promisedTime: o.promisedTime,
            source: o.source,
            status: o.status,
            total: 0
        }
        order.lines = this.disengageLines(this.getCalculatedLines())
        order.total = order.lines.reduce(
            (result: number, value) => {
                result += value.unitPrice
                return result
            }, 0
        )
        if (cleanLines) {
            if (this.orderType === OrderTypes.Domicilio) {
                if (this.deliveryFeeLine && 'saleItemID' in this.deliveryFeeLine) {
                    order.fee = this.cleanLines([this.getDeliveryFeeLine()])[0]
                }
            }
            order.lines = this.cleanLines(order.lines)
        } else {
            if (this.orderType === OrderTypes.Domicilio) {
                if (this.deliveryFeeLine && 'saleItemID' in this.deliveryFeeLine) {
                    order.fee = this.getDeliveryFeeLine()
                }
            }
        }
        return order
    }
    public sortPgLines(lines: PgOrderLinesType[]): PgOrderLinesType[] {
        lines.sort((a, b) => {
            // Ambos objetos son promociones
            if (a instanceof PgOrderPromoLine && b instanceof PgOrderPromoLine) {
                if (a.isCompleted() && b.isCompleted()) {
                    return a.getOrder() - b.getOrder()
                } else if (a.isCompleted() && !b.isCompleted()) {
                    return 1
                } else if (!a.isCompleted() && b.isCompleted()) {
                    return -1
                } else {
                    return a.getOrder() - b.getOrder()
                }
            }
            // Uno de los objetos es una promoción y el otro es un producto simple
            else if (a instanceof PgOrderPromoLine && b instanceof PgOrderLine) {
                return a.isCompleted() ? a.getOrder() - b.getOrder() : -1
            } else if (a instanceof PgOrderLine && b instanceof PgOrderPromoLine) {
                return b.isCompleted() ? a.getOrder() - b.getOrder() : 1
            }
            // Ambos objetos son productos sueltos
            else {
                return a.getOrder() - b.getOrder()
            }
        })
        return lines
    }
    public updateAddedCrossSales(crossID: string): void {
        if (!this.addedCrossSales) {
            this.addedCrossSales = []
        }
        if (this.addedCrossSales.indexOf(crossID) < 0) {
            this.addedCrossSales.push(crossID)
        }
    }
    public getOrderLinesFromCartCalculated(cartCalculated: CartCalculatedResponse, items: CreateOrderPromotionLineItemsInterface): PgOrderLinesType[] {
        let result: PgOrderLinesType[] = []
        let unfinishedLines: PgOrderPromoLine[] = this.filterIfNotInArray(this.getPromotionLines(), 'id', (items && 'id' in items) ? items.id : null).reduce(
            (result: PgOrderPromoLine[], value) => {
                if (!value.isCompleted()) {
                    result.push(value)
                }
                return result
            }, []
        )
        cartCalculated.complete = this.engageLines(this.disengageLines(cartCalculated.complete), true)
        cartCalculated.complete.forEach(
            (value, index) => {
                switch (value.type) {
                    case OrderLineTypes.composed:
                        const composedOrderItems: CreateOrderLineItemsInterface = this.getOrderLineItemsFromPromoEngineResult(value)
                        if ('customID' in value) {
                            let oldLine: PgOrderLine = this.getLineById(value.customID)
                            if (oldLine && oldLine instanceof PgOrderLine) {
                                composedOrderItems.comments = oldLine.getComments()
                                composedOrderItems.id = oldLine.getID()
                                composedOrderItems.order = oldLine.getOrder()
                            } else {
                                composedOrderItems.order = index
                            }
                        } else {
                            composedOrderItems.order = index
                        }
                        const composedOrderLine: PgOrderLine = this.createLine(composedOrderItems, value)
                        composedOrderLine.pgCustomizer.updateSelectedDough()
                        result.push(composedOrderLine)
                        break
                    case OrderLineTypes.crossSales:
                    case OrderLineTypes.promotion:
                        const promotion: PromoInterface = this.findInArray(this.promotions, 'id', value.saleItemID)
                        const pgWizard: PgWizard = new PgWizard(
                            this.menu,
                            promotion,
                            this.promotions,
                            this.getPromoEngineOptions()
                        )
                        const wizardItems = this.updateWizardLinesFromOrderPromoLine(value, pgWizard.getItemsToCreateOrderPromotionLine())
                        wizardItems.quantity = value.quantity
                        if ('customID' in value) {
                            let oldPromoLine: PgOrderPromoLine = this.getPromotionLineById(value.customID)
                            if (oldPromoLine && oldPromoLine instanceof PgOrderPromoLine) {
                                wizardItems.id = oldPromoLine.getID()
                                wizardItems.order = oldPromoLine.getOrder()
                            } else {
                                wizardItems.order = index
                            }
                        } else {
                            wizardItems.order = index
                        }
                        const orderPromoLine: PgOrderPromoLine = this.createPromotionLine(wizardItems, value)
                        if (orderPromoLine.requiresManualUpdate) {
                            orderPromoLine.updatePromoEngineResult(value)
                        }
                        result.push(orderPromoLine)
                        break
                    case OrderLineTypes.simple:
                        const orderItems: CreateOrderLineItemsInterface = this.getOrderLineItemsFromPromoEngineResult(value)
                        if ('customID' in value) {
                            let oldLine: PgOrderLine = this.getLineById(value.customID)
                            if (oldLine && oldLine instanceof PgOrderLine) {
                                orderItems.comments = oldLine.getComments()
                                orderItems.id = oldLine.getID()
                                orderItems.order = oldLine.getOrder()
                            } else {
                                orderItems.order = index
                            }
                        } else {
                            orderItems.order = index
                        }
                        const orderLine: PgOrderLine = this.createLine(orderItems, value)
                        orderLine.pgCustomizer.updateSelectedDough()
                        result.push(orderLine)
                        break
                }
            }
        )
        result.push(...unfinishedLines)
        return this.sortPgLines(result)
    }
    public updateEntireOrder(items: CreateOrderPromotionLineItemsInterface, cartCalculated: CartCalculatedResponse = null): any[] {
        let customID: number = (items && 'id' in items) ? items.id : null
        // Las promociones que no estén completas, no se envían al calculador y se añaden luego
        let unfinishedLines: PgOrderPromoLine[] = this.filterIfNotInArray(this.getPromotionLines(), 'id', customID).reduce(
            (result: PgOrderPromoLine[], value) => {
                if (!value.isCompleted()) {
                    result.push(value)
                }
                return result
            }, []
        )
        if (!cartCalculated) {
            let isCrossSales: boolean = false
            try {
                if (items && 'promotionID' in items) {
                    let promo: PromoInterface = items.promotions.find(p => p.id === items.promotionID)
                    if (promo) {
                        isCrossSales = (promo.promoType === PromoTypes.CrossSales)
                    }
                }
            } catch (e) { }
            let orderLines: CalculatedLinesResponse[] = []
            /**
             * @description Si es CrossSales, se cogen las líneas de la función 'auto' del promo-engine
             */
            if (isCrossSales) {
                orderLines = this.disengageLines(this.getCalculatedLines())
                orderLines.unshift(
                    ...items.lines.reduce(
                        (result: CalculatedLinesResponse[], value) => {
                            result.push(
                                ...value.addedItems.reduce(
                                    (resp: CalculatedLinesResponse[], v) => {
                                        resp.push(this.copy(v.promoEngineResult))
                                        return resp
                                    }, []
                                )
                            )
                            return result
                        }, []
                    )
                )
            } else {
                orderLines = this.disengageLines(this.getLinesToCalculatePromotion(items.id, items.lines))
            }
            let promoCode: string = ('promoCode' in items) ? items.promoCode : null
            cartCalculated = this.calculatePromotion(
                this.getPromoEngineOptions(),
                this.menu,
                this.promotions,
                items.promotionID,
                orderLines,
                promoCode
            )
            if (cartCalculated && cartCalculated.complete && Array.isArray(cartCalculated.complete) && cartCalculated.complete.length > 0 && cartCalculated.errors.length === 0) {
                if (isCrossSales && cartCalculated.promo) {
                    cartCalculated.complete = this.getLinesToCalculatePromotion(items.id)
                    cartCalculated.complete.push(cartCalculated.promo)
                }
                let oldLines = this.disengageLines(this.getLinesToCalculatePromotion(items.id))
                let linesToCheck: OrderLineTypes[] = [
                    OrderLineTypes.composed,
                    OrderLineTypes.simple
                ]
                let customIDalreadyUsed: boolean = false
                cartCalculated.complete = this.disengageLines(cartCalculated.complete)
                cartCalculated.complete.forEach(
                    value => {
                        if (customID && this.comparePromoLines(value, cartCalculated.promo) && !customIDalreadyUsed) {
                            customIDalreadyUsed = true
                            value.customID = customID
                            value.quantity = items.quantity
                        } else {
                            oldLines.forEach(
                                v => {
                                    if (value.type === v.type) {
                                        if (linesToCheck.indexOf(value.type) >= 0) {
                                            if (this.compareLines(value, v)) {
                                                value.customID = v.customID
                                            }
                                        } else {
                                            if (this.comparePromoLines(value, v)) {
                                                value.customID = v.customID
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
            }
        }
        if (cartCalculated && cartCalculated.complete && Array.isArray(cartCalculated.complete) && cartCalculated.complete.length > 0 && cartCalculated.errors.length === 0) {
            this.emptyLines()
            this.getOrderLinesFromCartCalculated(cartCalculated, items).forEach(
                value => {
                    if (value instanceof PgOrderLine) {
                        this.addLine(value)
                    } else if (value instanceof PgOrderPromoLine) {
                        this.addPromotionLine(value)
                    }
                }
            )
            this.promotionLines = this.promotionLines.concat(unfinishedLines)
        }
        return cartCalculated && 'errors' in cartCalculated ? cartCalculated.errors : null
    }
    public updateOrder(o: CreateOrderInterface, menu?: MenuInterface, p?: PromoInterface[]): void {
        if (menu) {
            this.menu = menu
        }
        if (p) {
            this.promotions = p
        }
        this.lines = []
        this.promotionLines = []
        const exceptions: string[] = [
            'lineItems',
            'menu',
            'promoLineItems',
            'promotions'
        ]
        Object.keys(o).forEach(
            value => {
                if (exceptions.indexOf(value) < 0) {
                    this[value] = o[value]
                }
            }
        )
        if (o.lineItems.length > 0) {
            o.lineItems.forEach(
                value => {
                    const line: PgOrderLine = this.createLine({
                        ...value,
                        menu: this.menu
                    }, value.promoEngineResult)
                    this.lines.push(line)
                }
            )
        }
        if (o.promoLineItems.length > 0) {
            o.promoLineItems.forEach(
                value => {
                    const promotionExists: boolean = this.someInArray(this.promotions, 'id', value.promotionID)
                    if (promotionExists) {
                        const line: PgOrderPromoLine = this.createPromotionLine({
                            ...value,
                            menu: this.menu,
                            promotions: this.promotions
                        }, value.promoEngineResult)
                        this.promotionLines.push(line)
                    }
                }
            )
        }
    }
    public updateOrderLine(line: PgOrderLinesType): void {
        if (line instanceof PgOrderLine) {
            const position: number = this.findIndexInArray(this.getLines(), 'id', line.getID())
            if (position >= 0) {
                const originalLine: PgOrderLine = this.getLines()[position]
                this.lines[position] = this.createLine(line.getItemsFromCustomizer(line.getQuantity()), line.getPromoEngineResult())
                this.lines[position].updateID(originalLine.getID())
                this.lines[position].updateOrder(originalLine.getOrder())
            }
        } else if (line instanceof PgOrderPromoLine) {
            const position: number = this.findIndexInArray(this.getPromotionLines(), 'id', line.getID())
            if (position >= 0) {
                const originalLine: PgOrderPromoLine = this.getPromotionLines()[position]
                this.promotionLines[position] = this.createPromotionLine(line.getItemsFromWizard(), line.getPromoEngineResult())
                this.promotionLines[position].updateID(originalLine.getID())
                this.promotionLines[position].updateOrder(originalLine.getOrder())
                this.promotionLines[position].updateQuantity(line.getQuantity())
            }
        }
    }
    public updateWizardLinesFromOrderPromoLine(promoEngineResult: PromotionCalculatedResponse, wizardItems: CreateOrderPromotionLineItemsInterface): CreateOrderPromotionLineItemsInterface {
        const indexesAlreadyUsed: number[] = []
        promoEngineResult.sublines.forEach(
            value => {
                const orderItems: CreateOrderLineItemsInterface = this.getOrderLineItemsFromPromoEngineResult(value)
                let wizardLinePosition: number = -1
                for (const index in wizardItems.lines) {
                    const lineIndex: number = parseInt(index)
                    const val: WizardLineInterface = wizardItems.lines[lineIndex]
                    if (indexesAlreadyUsed.indexOf(lineIndex) < 0) {
                        if (val.addedItems.length > 0) {
                            const position: number = this.findIndexInArray(val.addedItems, 'productID', orderItems.productID)
                            if (position >= 0) {
                                wizardLinePosition = lineIndex
                            }
                        } else {
                            val.items.forEach(
                                group => {
                                    group.subGroups.forEach(
                                        subgroup => {
                                            const position: number = (orderItems.isHalfNdHalf) ? Object.values(subgroup.customProducts).indexOf(orderItems.productID) : this.findIndexInArray(subgroup.products, 'id', orderItems.productID)
                                            if (position >= 0) {
                                                wizardLinePosition = lineIndex
                                            }
                                        }
                                    )
                                }
                            )
                        }
                        if (wizardLinePosition >= 0) {
                            indexesAlreadyUsed.push(wizardLinePosition)
                            break
                        }
                    }
                }
                if (wizardLinePosition >= 0) {
                    const wizardLine = wizardItems.lines[wizardLinePosition]
                    if (wizardLine.addedItems.length === 0) {
                        const orderItems: CreateOrderLineItemsInterface = this.getOrderLineItemsFromPromoEngineResult(value)
                        orderItems.promoEngineResult = value
                        wizardLine.addedItems.push(orderItems)
                    } else {
                        wizardLine.addedItems[0].promoEngineResult = value
                    }
                }
            }
        )
        wizardItems.currentStep = this.filterInArray(wizardItems.lines, 'showInCart', true).reduce(
            (result: number, value) => {
                result += value.addedItems.length
                return result
            }, 0
        )
        return wizardItems
    }
    public updateExcludedOrderType(value: OrderTypes) {
        this.excludedOrderType = value
    }
    public updateOrderType(value: OrderTypes) {
        this.orderType = value
    }
    public updateBranchCode(branchDeliveryID: number, branchPickUpID: number) {
        if (branchDeliveryID !== null) {
            this.branchDeliveryID = branchDeliveryID;
        }
        if (branchPickUpID !== null) {
            this.branchPickUpID = branchPickUpID;
        }
    }
    public updateSource(source: LegacySources) {
        this.source = source;
    }
    public updateDevice(device: string) {
        this.device = device;
    }
    public updateDeliveryFeeLine(r: CalculatedLinesResponse): void {
        this.deliveryFeeLine = r
    }
    public updateDeliveryFeePromoCode(c: string): void {
        this.deliveryFeePromoCode = c
    }
    public checkPromoCodeInPromoCodes(code: string, promoID: string): boolean {
        if (Array.isArray(this.promoCodes)) {
            return (code !== null) ? this.promoCodes.some(p => (p.promoID === promoID && p.redeemableCodes.some(c => c === code))) : this.promoCodes.some(p => p.promoID === promoID)
        }
        return false
    }
    public updatePromoCodes(code: string, promo: PromoInterface): void {
        if (!Array.isArray(this.promoCodes)) {
            this.promoCodes = []
        }
        let promoID: string = promo.id
        let position: number = this.promoCodes.findIndex(p => p.promoID === promoID)
        if (position >= 0) {
            if (code !== null) {
                this.promoCodes[position].redeemableCodes.push(code)
            }
        } else {
            let isRedeemable: boolean = true
            if (code !== null && (typeof promo.promoCodes === 'string') && promo.promoCodes.length > 0 && promo.promoCodes !== '*') {
                let promoPromoCodes: string[] = promo.promoCodes.split(';')
                if (promoPromoCodes.length > 0 && promoPromoCodes.includes(code)) {
                    isRedeemable = false
                }
            }
            this.promoCodes.push({ isRedeemable, promoID, "redeemableCodes": ((code !== null) ? [code] : []) })
        }
    }
    public isStartNewOrder(): boolean {
        let oneHour = 3600000;
        let currentTime: string = new Date().toISOString()
        let currentTs: number = new Date(currentTime).getTime()
        let orderTs: number = new Date(this.ts).getTime()

        if (this.getLinesLength() - 1 == 0 && (currentTs - orderTs) > oneHour) {
            this.ts = (new Date()).toISOString()
            return true;
        } else {
            this.ts = (new Date()).toISOString()
            return false;
        }
    }
}