import { FormMetadataButtonInterface, FormMetadataFieldInterface } from "@core/modules/angular-ionic-forms/common/interfaces";

export const buttonLogin: FormMetadataButtonInterface[] = [
    {
        buttonType: 'S',
        class: '',
        color: 'secondary',
        expand: 'block',
        fill: 'solid',
        label: 'LANG_SEND',
        position: 4,
        size: 'default'
    }
]
export const buttonRegisterRegister: FormMetadataButtonInterface[] = [
    {
        buttonType: 'S',
        class: '',
        color: 'primary',
        expand: 'block',
        fill: 'solid',
        label: 'LANG_REGISTER',
        position: 4,
        size: 'default'
    }
]
export const buttonConfirmRegister: FormMetadataButtonInterface[] = [
    {
        buttonType: 'S',
        class: '',
        color: 'primary',
        expand: 'block',
        fill: 'solid',
        label: 'LANG_CONFIRM',
        position: 4,
        size: 'default'
    }
]
export const buttonReset: FormMetadataButtonInterface[] = [
    {
        buttonType: 'S',
        class: '',
        color: 'secondary',
        expand: 'block',
        fill: 'solid',
        label: 'LANG_SEND',
        position: 4,
        size: 'default'
    }
]
export const fieldsFullRegister: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "phone",
        isReadOnly: false,
        fieldType: 'tel',
        label: '',
        name: 'phone',
        placeholder: 'LANG_PHONE_NUMBER',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            checkArabic: true
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PHONE',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_PHONE',
                params: {
                            length: 10
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    },
    {
        class: '',
        disabled: false,
        group: "",
        id: "email",
        isReadOnly: false,
        fieldType: 'email',
        name: 'email',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            }
        },
        label: '',
        placeholder: 'LANG_EMAIL',
        position: 2,
        validators: [
            {
                errorMsg: 'LANG_INVALID_EMAIL',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_EMAIL',
                params: {},
                validatorType: 'email'
            },
            {
                errorMsg: '',
                params: {
                    length: 100
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    },
    {
        class: '',
        disabled: false,
        group: '',
        id: "password",
        isReadOnly: false,
        fieldType: 'password',
        label: '',
        name: 'password',
        options: {
            icon: { name: 'eye', color: 'medium' },
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            showPassword: false
        },
        placeholder: 'LANG_PASSWORD',
        position: 3,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PASSWORD',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 5
                },
                validatorType: 'minLength'
            },
            {
                errorMsg: '',
                params: {
                    length: 250
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]
export const fieldsLoginPhone: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "phone",
        isReadOnly: false,
        fieldType: 'tel',
        label: '',
        name: 'phone',
        placeholder: 'LANG_TYPE_PHONE_NUMBER',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            checkArabic: true
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PHONE',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_PHONE',
                params: {
                            length: 10
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
    // {
    //     class: '',
    //     defaultValue: "",
    //     disabled: false,
    //     group: "",
    //     id: "password",
    //     isReadOnly: false,
    //     fieldType: 'password',
    //     label: '',
    //     name: 'password',
    //     options: {
    //         label: {
    //             color: "success",
    //             position: "stacked",
    //         },
    //         showPassword: false,
    //         size: {
    //             lg: '12',
    //             md: '12',
    //             sm: '12',
    //             xl: '12',
    //             xs: '12'
    //         }
    //     },
    //     placeholder: 'LANG_PASSWORD',
    //     position: 1,
    //     validators: [{
    //         errorMsg: 'LANG_REQUIRED_PASSWORD',
    //         validatorType: 'required'
    //         },
    //         {
    //         errorMsg: '',
    //         params: {
    //             length: 5
    //         },
    //         validatorType: 'minLength'
    //     },
    //     {
    //         errorMsg: '',
    //         params: {
    //             length: 250
    //         },
    //         validatorType: 'maxLength'
    //     }]
    // }
]
export const fieldsLoginEmail: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "email",
        isReadOnly: false,
        fieldType: 'email',
        label: '',
        name: 'email',
        placeholder: 'LANG_EMAIL_ADDRESS',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            }
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_EMAIL',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_EMAIL',
                params: {},
                validatorType: 'email'
            },
            {
                errorMsg: '',
                params: {
                    length: 100
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    },
    {
        class: '',
        disabled: false,
        group: "",
        id: "password",
        isReadOnly: false,
        fieldType: 'password',
        label: '',
        name: 'password',
        options: {
            icon: { name: 'eye', color: 'medium' },
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            showPassword: false
        },
        placeholder: 'LANG_PASSWORD',
        position: 3,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PASSWORD',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 5
                },
                validatorType: 'minLength'
            },
            {
                errorMsg: '',
                params: {
                    length: 250
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]

export const fieldsLoginKiosk: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "user",
        isReadOnly: false,
        fieldType: 'text',
        label: '',
        name: 'user',
        placeholder: 'LANG_USER',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            }
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_USER',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 100
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    },
    {
        class: '',
        disabled: false,
        group: "",
        id: "password",
        isReadOnly: false,
        fieldType: 'password',
        label: '',
        name: 'password',
        options: {
            icon: { name: 'eye', color: 'medium' },
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            showPassword: false
        },
        placeholder: 'LANG_PASSWORD',
        position: 3,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PASSWORD',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 5
                },
                validatorType: 'minLength'
            },
            {
                errorMsg: '',
                params: {
                    length: 250
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]
export const fielResetByPhone: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "phone",
        isReadOnly: false,
        fieldType: 'tel',
        label: '',
        name: 'phone',
        placeholder: 'LANG_PHONE_NUMBER',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            checkArabic: true
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PHONE',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_PHONE',
                params: {
                            length: 10
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]
export const fieldResetByEmail: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "email",
        isReadOnly: false,
        fieldType: 'email',
        label: '',
        name: 'email',
        placeholder: 'LANG_EMAIL_ADDRESS',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            }
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_EMAIL',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_EMAIL',
                params: {},
                validatorType: 'email'
            },
            {
                errorMsg: '',
                params: {
                    length: 100
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]
export const onlyPassRegister: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "password",
        isReadOnly: false,
        fieldType: 'password',
        label: '',
        name: 'password',
        options: {
            icon: { name: 'eye', color: 'medium' },
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            showPassword: false
        },
        placeholder: 'LANG_NEW_PASSWORD',
        position: 3,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PASSWORD',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 5
                },
                validatorType: 'minLength'
            },
            {
                errorMsg: '',
                params: {
                    length: 250
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    },
    {
        class: '',
        disabled: false,
        group: "",
        id: "password",
        isReadOnly: false,
        fieldType: 'password',
        label: '',
        name: 'password',
        options: {
            icon: { name: 'eye', color: 'medium' },
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            showPassword: false
        },
        placeholder: 'LANG_REPEAT_PASSWORD',
        position: 3,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PASSWORD',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: '',
                params: {
                    length: 5
                },
                validatorType: 'minLength'
            },
            {
                errorMsg: '',
                params: {
                    length: 250
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]
export const fieldsOnlyPhone: FormMetadataFieldInterface[] = [
    {
        class: '',
        disabled: false,
        group: "",
        id: "phone",
        isReadOnly: false,
        fieldType: 'tel',
        label: '',
        name: 'phone',
        placeholder: 'LANG_PHONE_NUMBER',
        options: {
            label: {
                color: "success",
                position: "stacked"
            },
            size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
            },
            checkArabic: true
        },
        position: 1,
        validators: [
            {
                errorMsg: 'LANG_REQUIRED_PHONE',
                params: {},
                validatorType: 'required'
            },
            {
                errorMsg: 'LANG_INVALID_PHONE',
                params: {
                            length: 10
                },
                validatorType: 'maxLength'
            }
        ],
        value: ''
    }
]

export const buttonPinQitaf: FormMetadataButtonInterface[] = [
    {
      buttonType: 'S',
      color: 'primary',
      label: 'LANG_CONTINUE_PIN',
      position: 4,
      class: "",
      expand: 'block',
      fill: 'solid',
      size: "default"
    }
  ]


  export const fieldsPinQitaf: FormMetadataFieldInterface[] = [
    {
      group: "",
      id: "pin",
      isReadOnly: false,
      fieldType: 'text',
      name: 'pin',
      placeholder: 'LANG_YOU_SHOULD_RECEIVE_SMS_PIN',
      position: 1,
      class: "",
      disabled: false,
      label: "",
      options: {
        label: {
          color: "success",
          position: "stacked"
        },
        size: {
          lg: '12',
          md: '12',
          sm: '12',
          xl: '12',
          xs: '12'
        },
        checkArabic: true
      },
      validators: [
        {
            errorMsg: 'LANG_REQUIRED_PIN',
            params: {},
            validatorType: 'required'
        },
        {
            errorMsg: 'LANG_INVALID_PIN',
            params: {
                        length: 4
            },
            validatorType: 'minLength'
        }
    ],
      value: ''
    }
  ]