import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { environment } from '@environments/environment';
import { RoutesMenu, kiosk, RoutesMenuKiosk, Version} from '@core/constants';
import { LangService } from '@services/lang.service';
import { UserDataService } from '@services/user-data.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { PreviousRouteService } from '@services/previous-route.service';
import { PlatformService } from '@services/platform.service';
import { DeliveryService } from '@services/delivery.service';
import { isGroundr, isMaestro } from '@core/functions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  assets: string = environment.assetsUrl;
  menuList = kiosk ? RoutesMenuKiosk : RoutesMenu
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  @ViewChild('header', { read: ElementRef }) element: ElementRef;
  contentHeight: number;
  @Output() height = new EventEmitter();
  @Input() showToolbar: boolean = true;
  windowWidth: number;
  showLogin: boolean;
  showBack: boolean = false;
  showMyAccount: boolean = false;
  showWallet: boolean = false;
  showPromos: boolean = false;
  private unsubscribe = new Subject<void>();
  @Input() title: string;
  @Output() titleHeader = new EventEmitter();
  currentLanguage: any
  previusRoute: string
  currentPlatform: string
  isKiosk: boolean = kiosk
  kioskBranch: any
  version = Version
  constructor(
    public langService: LangService,
    private userDataService: UserDataService,
    private location: Location,
    private router: Router,
    private readonly apiService: ApiService,
    private previousRouteService: PreviousRouteService,
    private readonly platformService: PlatformService,
    private readonly deliveryService: DeliveryService
  ) { 

    if(this.kioskBranch){
      this.isKiosk = true;
    }
  }

  ngOnDestroy(): void {
    console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  ngOnInit() {
    if(!this.platformService.isServer()){
    this.setIsLoged();
    this.currentLanguage = this.langService.getCurrentLang();

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
          ;
        this.currentLanguage = result.lang
      }
    )
        this.currentPlatform = this.platformService.getDevice();
    }

  }

  changeLang() {
    this.langService.changeLanguage()
    this.triggerPageResize();
  }

  triggerPageResize() {
    // Crear un nuevo evento de tipo 'resize'
    window.dispatchEvent(new Event('resize'));
  }


  ngAfterViewChecked(): void {
    this.contentHeight = this.element.nativeElement.offsetHeight;
    this.height.emit(this.contentHeight);
    try{
      if (!this.platformService.isServer()) {
        this.windowWidth = window.innerWidth;
      }
    }catch(e){

    }
  }

  ngAfterContentInit() {
    if(!this.platformService.isServer()){
      if (this.router.url == '/cart' || this.router.url == '/account' || this.router.url == '/wallet') {
        this.showBack = true;
      } else {
        this.showBack = false;
      }
    if (this.router.url == '/account') {
      this.showMyAccount = true;
    }
    if (this.router.url == '/wallet') {
      this.showWallet = true;
    }

    if (this.router.url == '/promotions') {
      this.showPromos = true;
    }

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    try{
      this.windowWidth = window.innerWidth;
    }catch(e){

    }
  }

  canShowHeader() {

    if (this.showToolbar && this.windowWidth > 575) { // mostramos header
      return true
    } else if (this.showToolbar && this.windowWidth <= 575) {
      return false
    } else if (!this.showToolbar) {
      return true
    }


  }

  goBack() {

    this.previusRoute = this.previousRouteService.getPreviousUrl();
    console.log(this.previusRoute)

    if (this.previusRoute != "/wizard") {

    if (this.title == 'History') {
      this.titleHeader.emit("Wallets");
    } else if (this.title == 'Top-up') {
      this.titleHeader.emit("Wallets");
    } else {
      if (this.router.url == '/cart') {
        this.apiService.setData({})
      }
      this.location.back();
    }
  }else{
    this.router.navigate(['/menu'])
  }

  }

  setIsLoged(): void {
    this.userDataService.getUser().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (user) => {
        this.showLogin = false;
      }, error => {
        this.showLogin = true;
      });
  }

  logout() {
    this.userDataService.logout();
    this.router.navigate(['/login'])
    this.showLogin = true;
  }

  goToMenu() {
    this.router.navigate(['/menu'])
  }

}
