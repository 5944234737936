import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-loyalty-success',
  templateUrl: './loyalty-success.component.html',
  styleUrls: ['./loyalty-success.component.scss'],
})
export class LoyaltySuccessComponent implements OnInit {

  assets: string = environment.assetsUrl;
  card: any = { title: "LANG_SILVER", class: "silverCard", advantages: ["LANG_SILVER1", "LANG_SILVER2", "LANG_SILVER3"] }
   
  @Output() setValueToShow = new EventEmitter();
  @Output() showLoyaltySuccess = new EventEmitter();
  
  constructor() { }

  ngOnInit() {

  }

  close(){
    this.setValueToShow.emit('cart');
    this.showLoyaltySuccess.emit(false);
  }

}
