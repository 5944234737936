import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

import { environment } from '@environments/environment';
import { CurrentPositionCoords, GeolocationService } from '@services/geolocation.service';
import { StorageService } from '@services/storage.service';
import { StorageVariables } from '@core/constants';
import {DeliveryService} from '@services/delivery.service';

@Component({
  selector: 'app-alert-full-screen',
  templateUrl: './alert-full-screen.component.html',
  styleUrls: ['./alert-full-screen.component.scss'],
})
export class AlertFullScreenComponent implements OnInit {

  @Output() clickButton = new EventEmitter();
  assets: string = environment.assetsUrl;
  height: number;

  constructor(private geoLocationService: GeolocationService, private readonly storageService: StorageService,    private nds : DeliveryService
    ) {
  }

  ngOnInit() {
  }


  data = { title: "LANG_ALLOW_MAESTRO_APP", subtitle: "LANG_THANKS_TO_ACCESS", image: "allowLocation" }

  click() {
    this.nds.init_B_PDZ(true, true);
    this.clickButton.emit(true);
  }

  skip() {
    this.nds.init_B_PDZ(true, false);
    this.storageService.setItem(StorageVariables.showedOpeningScreen, true)
  }




}
