import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { HereService } from '@services/here.service';
import { DeliveryService } from '@services/delivery.service';
import { OrderTypes } from '@lib/promo-engine';
import { LangService } from '@services/lang.service';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss'],
})
export class DeliveryDetailsComponent implements OnInit {

  assets: string = environment.assetsUrl;
  addresses: any[] = []
  orders: any[] = []
  detailIcon: any;

  @Output() setValueToShow = new EventEmitter();
  @Output() addressList = new EventEmitter();

  constructor(private readonly _api: ApiService,  private readonly hereS: HereService , private deliveryService: DeliveryService, public langService: LangService){ }

  ngOnInit() {
    this.loadData();
    this.detailIcon = this.langService.isRtl() ? 'chevron-back' : 'chevron-forward';
  }

  public loadData() {
    this.addresses = [];
    this.orders = [];

    this._api.getCustomerAddresses().subscribe(
      (addresses: any) => {
        addresses.forEach(
          address => {
            if (address.description !== null && address.description !== '') {
              this.addresses.push(address)
            }
          })
          this.addressList.emit(this.addresses)
          this.deliveryService.addresses = this.addresses;
      })

      this._api.getCustomerOrders(5,OrderTypes.Domicilio).subscribe(
        (orders: any) => {
          if(orders && orders.length > 0){

          orders.forEach(
             order => {
              if (order.description == null || order.description == '') {
                this.orders.push(order)
              }
            })
          }
          // console.log(this.orders);
        })

  }

  ngOnDestroy(): void {
    // console.log('Destroyed')
  }

  edit(address:any|boolean = false) {
    if(address){
      // console.log(address);
      // return;
      this.setValueToShow.emit({whatShow:'editDeliveryDetails',address:address});
    }else{
      this.setValueToShow.emit({whatShow:'editDeliveryDetails'});
    }
  }



}
