export const staticMenu: any = {
    "majorGroups": [
      {
        "id": 1,
        "description": "Pizza",
        "shortName": "Pizza",
        "toppingsAllowed": "S",
        "halfNdHalfAllowed": "S",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 1,
        "imageID": null,
        "webDescription": "Pizzas"
      },
      {
        "id": 6,
        "description": "Side Dishes",
        "shortName": "Side Dishes",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 2,
        "imageID": null,
        "webDescription": "Appetizers"
      },
      {
        "id": 7,
        "description": "Drinks",
        "shortName": "Drinks & Dips",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 4,
        "imageID": null,
        "webDescription": "Drinks & Dips"
      },
      {
        "id": 9,
        "description": "Desserts",
        "shortName": "Desserts",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 3,
        "imageID": null,
        "webDescription": "Desserts"
      },
      {
        "id": 10,
        "description": "Garlic Bread",
        "shortName": "Garlic Bread",
        "toppingsAllowed": "S",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 11,
        "description": "Sauces",
        "shortName": "Sauces",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 12,
        "description": "Delivery",
        "shortName": "Delivery",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": null,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 13,
        "description": "Salads",
        "shortName": "Salads",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 14,
        "description": "Marketing",
        "shortName": "Marketing",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 15,
        "description": "Combos",
        "shortName": "Combos",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 18,
        "description": "Take & Bake",
        "shortName": "Take & Bake",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": ""
      },
      {
        "id": 19,
        "description": "Kids Pizza",
        "shortName": "Kid's Meal",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 6,
        "imageID": null,
        "webDescription": "Kids Small Pizza"
      },
      {
        "id": 20,
        "description": "Delivery50",
        "shortName": "Delivery50",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": "Delivery foodtruck"
      },
      {
        "id": 21,
        "description": "Oven",
        "shortName": "Oven",
        "toppingsAllowed": "N",
        "halfNdHalfAllowed": "N",
        "taxDinein": 15,
        "taxDelivery": 15,
        "chargeByWeight": "N",
        "alcohol": "N",
        "loyaltyPoints": 0,
        "order": 50,
        "imageID": null,
        "webDescription": "Bread Maker"
      }
    ],
    "families": [
      {
        "id": 2,
        "description": "Medium Pizza",
        "webDescription": "",
        "shortName": "The originals",
        "majorGroupID": 1,
        "hNDhGroupping": "",
        "hNDnType": "C",
        "freeToppingsNbr": 0,
        "order": 6,
        "baseProduct": "FGPZT0002",
        "showAtWeb": "S",
        "size": "M",
        "familyGroupPrefix": "PM",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 1,
        "products": [
          {
            "id": "FGPZA0047",
            "description": "Fresh Italian Rocca Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Rocca, Parmesan Cheese, Balsamic Vinegar, Maestro Cheese, Tomato ",
            "order": 13,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMFIR",
            "shortName": "Fresh Italian Rocca",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZA0047",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZA0047",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZA0047",
                "invSaleItemID": "SFCRF0009",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT0002",
            "description": "Create Your Own Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Compose your own pizza.",
            "order": 23,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 15,
            "promotedToppingsQtty": 3,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCRO",
            "shortName": "Create Your Own",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT0002",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT0002",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT20017",
            "description": "Arabesque Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Chicken, Pickles, Sumaq, Arabic Sauce ",
            "order": 16,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMARB",
            "shortName": "Arabesque Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "RMSEA0027",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.006,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "SFTOC0013",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20017",
                "invSaleItemID": "SWSFSAC0007",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT20019",
            "description": "Vegetarian Slim Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Tomato, Olives, Onion, Green Pepper, Mushroom, 50% Thinner",
            "order": 19,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMVES",
            "shortName": "Vegetarian Slim",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.042,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20019",
                "invSaleItemID": "SFCRF0009",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT20020",
            "description": "Dynamite Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "DRVC",
            "noHalfNDHalf": "N",
            "webDescription": "onion, green pepper, sliced cheese, buffalo chicken topping, maestro cheese, dynamite sauce",
            "order": 22,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMDCH",
            "shortName": "Dynamite Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "175",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000110000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 4,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT20020",
                "invSaleItemID": "SWSFSAC0009",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.017,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2023",
            "description": "El Taco Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Onion, Tomato, Green Pepper, Black Olives, Jalapeno, Chicken Topping, Maestro Cheese, Cheddar Cheese, Nachos",
            "order": 21,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMTCH",
            "shortName": "El Taco Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.022,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "178",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.014,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2023",
                "invSaleItemID": "SWRMTOA0016",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2055",
            "description": "Chipotle Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Cheese Mix, Yellow Cheese Slices, Green Pepper, Red Pepper, Onion, Grilled Chicken, Chipotle Sauce",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHTRU9JGSYMEP",
            "points": 0,
            "erpID": "PM055",
            "shortName": "Chipotle Chicken",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2055",
                "invSaleItemID": "RMTOC0018",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2056",
            "description": "Sweet and Sour Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Sweet & Sour Sauce, Cheese Mix, Green Pepper, Onion, Spicy Chicken, Swirl Sweet & Sour Sauce, Large Pizza",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHTRU9JGSYMEP",
            "points": 0,
            "erpID": "PM056",
            "shortName": "Sweet and Sour Chicken",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "SFSAC0026",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.044,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "SFSDF0026",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.075,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2056",
                "invSaleItemID": "SWSFSAC0126",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.011,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2058",
            "description": "Fresh Rocca-Cauliflower Dough",
            "salePrice": 35,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 35,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Rocca, Parmesan Cheese, Balsamic Vinegar, Maestro Cheese, Tomato ",
            "order": 6,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 35,
            "salePriceTerrace": 35,
            "salePriceTable": 35,
            "salePricePUOnline": 35,
            "salePriceDEOnline": 35,
            "source": "LWCHARU9JGP",
            "points": 0,
            "erpID": "PMCAU",
            "shortName": "Fresh Rocca-Cauliflower Dough",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2058",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2058",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2058",
                "invSaleItemID": "RMPDF0001",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMCB",
            "description": "BBQ Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Grilled Chicken, BBQ  Sauce, Onion ",
            "order": 10,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCB",
            "shortName": "BBQ Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCB",
                "invSaleItemID": "SW307",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMCBF",
            "description": "Buffalo Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Cheese Mix, Buffalo Chicken, Hot Sauce, White Pizza Sauce ",
            "order": 15,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCBF",
            "shortName": "Buffalo Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMCBF",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCBF",
                "invSaleItemID": "175",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000110000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCBF",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCBF",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCBF",
                "invSaleItemID": "SW308",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.015,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMCH",
            "description": "Grilled Chicken Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Grilled Chicken, Tomato, Olives, Onion, Mushroom ",
            "order": 6,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCH",
            "shortName": "Grilled Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.042,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCH",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMCHR",
            "description": "Jalapeno Ranchy Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "White Pizza Sauce, Cheese Mix, Grilled Chicken, Ranch Sauce, Jalapeno ",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCHR",
            "shortName": "Jalapeno Ranchy",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.022,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHR",
                "invSaleItemID": "SW101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.018,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMCHRJ",
            "description": "Original Ranchy Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "White Pizza Sauce, Cheese Mix, Grilled Chicken, Ranch Sauce",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMCHRJ",
            "shortName": "Original Ranchy",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMCHRJ",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHRJ",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHRJ",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHRJ",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMCHRJ",
                "invSaleItemID": "SW101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.018,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMDE",
            "description": "Deluxe Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Beef, Pepperoni, Olives, Onion, Green Pepper, Mushroom",
            "order": 7,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMDE",
            "shortName": "Deluxe",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.038,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "024",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.026,
                "allergens": "00000000010100",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.042,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMDE",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMMA",
            "description": "Margarita Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Maestro Secret Cheese Mix",
            "order": 13,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMMA",
            "shortName": "Margarita",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMMA",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMMA",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.125,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMMA",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMML",
            "description": "Meat Lovers Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Beef, Pepperoni\r\n",
            "order": 11,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMML",
            "shortName": "Meat Lovers",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMML",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.038,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMML",
                "invSaleItemID": "024",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.026,
                "allergens": "00000000010100",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMML",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMML",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMML",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMPE",
            "description": "Pepperoni Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Pepperoni",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMPE",
            "shortName": "Pepperoni",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMPE",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMPE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMPE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMPE",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMRBQ",
            "description": "Ranchy BBQ Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce, BBQ Sauce, Cheese Mix, Grilled Chicken, Onion",
            "order": 8,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMRBQ",
            "shortName": "Ranchy BBQ",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.078,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMRBQ",
                "invSaleItemID": "SW307",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMSC",
            "description": "Steak & Cheese Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Cheese Mix, Philly Steak, Green bell Pepper, Mushroom, Onion, Secret Cheese Mix ",
            "order": 9,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMSC",
            "shortName": "Steak & Cheese",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.048,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "134",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "01000000011010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 4,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMSC",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMTA",
            "description": "Lahapeno Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Jalapeno Pepper, Red and Green Tabasco Sauce, Green and Red Bell Pepper ",
            "order": 14,
            "allergens": "00000000000000",
            "highlightWeb": "HV",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMTA",
            "shortName": "Lahapeno",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.022,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "097",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.002,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "098",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.003,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMTA",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PMVE",
            "description": "Vegetarian Medium",
            "salePrice": 25,
            "familyGroupID": 2,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Tomato, Olives, Onion, Green Pepper, Mushroom",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "PMVE",
            "shortName": "Vegetarian",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.042,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PMVE",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": [
          {
            "id": "023",
            "familyGroupID": 2,
            "description": "Pepperoni",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "024",
            "familyGroupID": 2,
            "description": "Beef",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000010100",
            "grouppingID": 3,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "033",
            "familyGroupID": 2,
            "description": "Spanish Mushroom",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 18,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "043",
            "familyGroupID": 2,
            "description": "Black Olives",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 16,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "047",
            "familyGroupID": 2,
            "description": "Jalapeno",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 13,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "059",
            "familyGroupID": 2,
            "description": "Onion",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 12,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "060",
            "familyGroupID": 2,
            "description": "Tomato Topping",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 17,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "061",
            "familyGroupID": 2,
            "description": "Green Pepper",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 15,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "071",
            "familyGroupID": 2,
            "description": "Red Pepper",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 14,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "097",
            "familyGroupID": 2,
            "description": "Tabasco Red",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 22,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "098",
            "familyGroupID": 2,
            "description": "Tabasco Green",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 23,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "101",
            "familyGroupID": 2,
            "description": "Ranch Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000011000010",
            "grouppingID": 2,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "134",
            "familyGroupID": 2,
            "description": "Steak Philly",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000011010",
            "grouppingID": 3,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "175",
            "familyGroupID": 2,
            "description": "Buffalo Chicken",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000110000010",
            "grouppingID": 3,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "178",
            "familyGroupID": 2,
            "description": "Nachos",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 21,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "179",
            "familyGroupID": 2,
            "description": "Sliced Cheese",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000010",
            "grouppingID": 2,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "203",
            "familyGroupID": 2,
            "description": "Thin Brown ",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000010",
            "grouppingID": 1,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "307",
            "familyGroupID": 2,
            "description": "BBQ Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000010",
            "grouppingID": 2,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "308",
            "familyGroupID": 2,
            "description": "Spicy Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "601",
            "familyGroupID": 2,
            "description": "Tomato Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "635",
            "familyGroupID": 2,
            "description": "Thin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "664",
            "familyGroupID": 2,
            "description": "Grilled Chicken",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000000000",
            "grouppingID": 3,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671",
            "familyGroupID": 2,
            "description": "Maestro Cheese",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671LESS",
            "familyGroupID": 2,
            "description": "Less Cheese***",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "N",
            "points": null
          },
          {
            "id": "DO003",
            "familyGroupID": 2,
            "description": "Double Cheddar",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 1,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "FGPZT2042",
            "familyGroupID": 2,
            "description": "Thick",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMPDF0001",
            "familyGroupID": 2,
            "description": "Cauliflower Crust",
            "salePrice": 10,
            "salePriceWithout": 0,
            "salePriceDel": 10,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 1,
            "calories": 0,
            "promotedPrice": 10,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMSEA0027",
            "familyGroupID": 2,
            "description": "Sumac Powder",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 14,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 19,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOA0016",
            "familyGroupID": 2,
            "description": "Cheddar Cheese Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOC0018",
            "familyGroupID": 2,
            "description": "Cheddar Cheese Sliced",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0009",
            "familyGroupID": 2,
            "description": "Ultrathin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0013",
            "familyGroupID": 2,
            "description": "Classic ",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0007",
            "familyGroupID": 2,
            "description": "Arabic Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000100",
            "grouppingID": 2,
            "order": 12,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0009",
            "familyGroupID": 2,
            "description": "Dynamite Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000010000010",
            "grouppingID": 2,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0014",
            "familyGroupID": 2,
            "description": "Chef's Special Tomato Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0015",
            "familyGroupID": 2,
            "description": "Chef's Special Alfredo Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0016",
            "familyGroupID": 2,
            "description": "Pesto Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0026",
            "familyGroupID": 2,
            "description": "Sweet Sour Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFT0F0005",
            "familyGroupID": 2,
            "description": "Shredded Parmesan",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0013",
            "familyGroupID": 2,
            "description": "Cucumber Pickled Slices",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 24,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0015",
            "familyGroupID": 2,
            "description": "Fresh Cherry Tomatoes",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOT0017",
            "familyGroupID": 2,
            "description": "Fajita Seasoning",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 14,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 20,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW101",
            "familyGroupID": 2,
            "description": "Ranch Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW307",
            "familyGroupID": 2,
            "description": "BBQ Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW308",
            "familyGroupID": 2,
            "description": "Spicy Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWRMTOA0016",
            "familyGroupID": 2,
            "description": "Chedder Cheese Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0007",
            "familyGroupID": 2,
            "description": "Arabic Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0009",
            "familyGroupID": 2,
            "description": "Dynamite Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0016",
            "familyGroupID": 2,
            "description": "Pesto Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0021",
            "familyGroupID": 2,
            "description": "Chilly Lemon Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0126",
            "familyGroupID": 2,
            "description": "Sweet & Sour Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          }
        ]
      },
      {
        "id": 3,
        "description": "Large Pizza",
        "webDescription": "",
        "shortName": "The originals",
        "majorGroupID": 1,
        "hNDhGroupping": "",
        "hNDnType": "C",
        "freeToppingsNbr": 0,
        "order": 1,
        "baseProduct": "FGPZT0003",
        "showAtWeb": "S",
        "size": "L",
        "familyGroupPrefix": "PL",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 1,
        "products": [
          {
            "id": "FGPZA0046",
            "description": "Fresh Italian Rocca Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Cheese Mix, Rocca, Parmesan Cheese, Tomato, Balsamic Vinegar",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLFIR",
            "shortName": "Fresh Italian Rocca",
            "calories": 0,
            "portions": 21,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZA0046",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.115,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZA0046",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZA0046",
                "invSaleItemID": "SFCRF0010",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT0003",
            "description": "Create Your Own Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Compose your own Pizza.",
            "order": 23,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 15,
            "promotedToppingsQtty": 3,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLCRO",
            "shortName": "Create Your Own",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT0003",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT0003",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT30017",
            "description": "Arabesque Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Chesse Mix, Chicken, Pickles, Sumaq, Arabic Sauce\r\n",
            "order": 25,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLARB",
            "shortName": "Arabesque Chicken",
            "calories": 0,
            "portions": 23,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "RMSEA0027",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.01,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "SFTOC0013",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.045,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30017",
                "invSaleItemID": "SWSFSAC0007",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT30019",
            "description": "Vegetarian Slim Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Tomato, Olives, Onion, Green Pepper, Mushroom, 50% Thinner",
            "order": 2,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLVES",
            "shortName": "Vegetarian Slim",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.056,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30019",
                "invSaleItemID": "SFCRF0010",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT30020",
            "description": "Dynamite Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "DRVC",
            "noHalfNDHalf": "N",
            "webDescription": "Cheese Mix, Chicken, Onion, Green Bell Pepper, Dynamite Sauce\r\n",
            "order": 17,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLDCH",
            "shortName": "Dynamite Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "175",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000110000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 6,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT30020",
                "invSaleItemID": "SWSFSAC0009",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3023",
            "description": "El Taco Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Chicken, Green Bell Pepper, Onion, Olives, Tomatoes, Crushed Nachos, Cheddar Cheese Swirl, Jalapeno",
            "order": 20,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLTCH",
            "shortName": "El Taco Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.035,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.033,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "178",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.018,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3023",
                "invSaleItemID": "SWRMTOA0016",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.022,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3055",
            "description": "Chipotle Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Cheese Mix, Yellow Cheese Slices, Green Pepper, Red Pepper, Onion, Grilled Chicken, Chipotle Sauce",
            "order": 10,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTRU9JGSYMEP",
            "points": 3600,
            "erpID": "PL055",
            "shortName": "Chipotle Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.045,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3055",
                "invSaleItemID": "RMTOC0018",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3056",
            "description": "Sweet and Sour Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Sweet & Sour Sauce, Cheese Mix, Green Pepper, Onion, Spicy Chicken, Swirl Sweet & Sour Sauce, Large Pizza",
            "order": 9,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3600,
            "erpID": "PL056",
            "shortName": "Sweet and Sour Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "SFSAC0026",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.065,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "SFSDF0026",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 4,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3056",
                "invSaleItemID": "SWSFSAC0126",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.015,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLCB",
            "description": "BBQ Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Grilled Chicken, Onion, BBQ Swirl",
            "order": 28,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLCB",
            "shortName": "BBQ Chicken",
            "calories": 0,
            "portions": 18,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCB",
                "invSaleItemID": "SW307",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.028,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLCBF",
            "description": "Buffalo Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce, Cheese Mix, Buffalo Chicken, Buffalo Swirl ",
            "order": 10,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLCBF",
            "shortName": "Buffalo Chicken",
            "calories": 0,
            "portions": 22,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLCBF",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCBF",
                "invSaleItemID": "175",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000110000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCBF",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCBF",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCBF",
                "invSaleItemID": "SW308",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.019,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLCH",
            "description": "Grilled Chicken Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Grilled Chicken, Onion, Olive, Tomato, Mushroom",
            "order": 14,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLCH",
            "shortName": "Grilled Chicken",
            "calories": 0,
            "portions": 13,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.056,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCH",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLCHR",
            "description": "Jalapeno Ranchy Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce, Cheese Mix, Jalapeno, Grilled Chicken, Ranch Swirl",
            "order": 15,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLCHR",
            "shortName": "Jalapeno Ranchy",
            "calories": 0,
            "portions": 9,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.033,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHR",
                "invSaleItemID": "SW101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.026,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLCHRJ",
            "description": "Original Ranchy Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce, Cheese Mix, Grilled Chicken, Ranch Swirl",
            "order": 24,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLCHRJ",
            "shortName": "Original Ranchy",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLCHRJ",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHRJ",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHRJ",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHRJ",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLCHRJ",
                "invSaleItemID": "SW101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.026,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLDE",
            "description": "Deluxe Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Beef, Pepperoni, Olive, Onion, Green Pepper, Mushroom",
            "order": 27,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLDE",
            "shortName": "Deluxe",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "024",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000010100",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.056,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLDE",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLMA",
            "description": "Margarita Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Maestro Secret Cheese Mix",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLMA",
            "shortName": "Margarita",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLMA",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLMA",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.2,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLMA",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLML",
            "description": "Meat Lovers Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Beef, Pepperoni",
            "order": 18,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLML",
            "shortName": "Meat Lovers",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLML",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLML",
                "invSaleItemID": "024",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000010100",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLML",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLML",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLML",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLPE",
            "description": "Pepperoni Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Pepperoni",
            "order": 16,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLPE",
            "shortName": "Pepperoni",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLPE",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.075,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLPE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLPE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLPE",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLRBQ",
            "description": "Ranchy BBQ Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce, Cheese Mix, Grilled Chicken, Onion, BBQ Swirl",
            "order": 26,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3600,
            "erpID": "PLRBQ",
            "shortName": "Ranchy BBQ",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.117,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLRBQ",
                "invSaleItemID": "SW307",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.028,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLSC",
            "description": "Steak & Cheese Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Cheese Mix, Philly Steak, Green bell Pepper, Mushroom, Onion, Secret Cheese Mix",
            "order": 29,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLSC",
            "shortName": "Steak & Cheese",
            "calories": 0,
            "portions": 16,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.064,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "134",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.056,
                "allergens": "01000000011010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 6,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLSC",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLTA",
            "description": "Lahapeno Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Jalapeno Pepper,Red and Green Tabasco Sauce, Red and Green Bell Pepper",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "HV",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLTA",
            "shortName": "Lahapeno",
            "calories": 0,
            "portions": 21,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.033,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.045,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "097",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.002,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "098",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.005,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLTA",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PLVE",
            "description": "Vegetarian Large",
            "salePrice": 36,
            "familyGroupID": 3,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 36,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Maestro Sauce, Cheese Mix, Tomato, Olive, Onion,Green Pepper, Mushroom",
            "order": 3,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 36,
            "salePriceTerrace": 36,
            "salePriceTable": 36,
            "salePricePUOnline": 36,
            "salePriceDEOnline": 36,
            "source": "LWCKHTARU9JGSYMDEP",
            "points": 3600,
            "erpID": "PLVE",
            "shortName": "Vegetarian",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.056,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.032,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PLVE",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": [
          {
            "id": "023",
            "familyGroupID": 3,
            "description": "Pepperoni",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "024",
            "familyGroupID": 3,
            "description": "Beef",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000010100",
            "grouppingID": 3,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "033",
            "familyGroupID": 3,
            "description": "Spanish Mushroom",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 18,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "043",
            "familyGroupID": 3,
            "description": "Black Olives",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 16,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "047",
            "familyGroupID": 3,
            "description": "Jalapeno",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 13,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "059",
            "familyGroupID": 3,
            "description": "Onion",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 12,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "060",
            "familyGroupID": 3,
            "description": "Tomato Topping",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 17,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "061",
            "familyGroupID": 3,
            "description": "Green Pepper",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 15,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "071",
            "familyGroupID": 3,
            "description": "Red Pepper",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 14,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "097",
            "familyGroupID": 3,
            "description": "Tabasco Red",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 22,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "098",
            "familyGroupID": 3,
            "description": "Tabasco Green",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 23,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "101",
            "familyGroupID": 3,
            "description": "Ranch Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000011000010",
            "grouppingID": 2,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "134",
            "familyGroupID": 3,
            "description": "Steak Philly",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000011010",
            "grouppingID": 3,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "175",
            "familyGroupID": 3,
            "description": "Buffalo Chicken",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000110000010",
            "grouppingID": 3,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "178",
            "familyGroupID": 3,
            "description": "Nachos",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 21,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "179",
            "familyGroupID": 3,
            "description": "Sliced Cheese",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000010",
            "grouppingID": 2,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "204",
            "familyGroupID": 3,
            "description": "Thin Brown",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000010",
            "grouppingID": 1,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "307",
            "familyGroupID": 3,
            "description": "BBQ Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000010",
            "grouppingID": 2,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "308",
            "familyGroupID": 3,
            "description": "Spicy Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "601",
            "familyGroupID": 3,
            "description": "Tomato Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "636",
            "familyGroupID": 3,
            "description": "Thin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "664",
            "familyGroupID": 3,
            "description": "Grilled Chicken",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000000000",
            "grouppingID": 3,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671",
            "familyGroupID": 3,
            "description": "Maestro Cheese",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671LESS",
            "familyGroupID": 3,
            "description": "Less Cheese***",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "N",
            "points": null
          },
          {
            "id": "DO001",
            "familyGroupID": 3,
            "description": "Cheesy Crust",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "DO002",
            "familyGroupID": 3,
            "description": "Double Cheddar",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 1,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "FGPZT3042",
            "familyGroupID": 3,
            "description": "Thick",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMSEA0027",
            "familyGroupID": 3,
            "description": "Sumac Powder",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 14,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 19,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOA0016",
            "familyGroupID": 3,
            "description": "Cheddar Cheese Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOC0018",
            "familyGroupID": 3,
            "description": "Cheddar Cheese Sliced",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0010",
            "familyGroupID": 3,
            "description": "Ultrathin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0012",
            "familyGroupID": 3,
            "description": "Classic",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0009",
            "familyGroupID": 3,
            "description": "Dynamite Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000010000010",
            "grouppingID": 2,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0014",
            "familyGroupID": 3,
            "description": "Chef's Special Tomato Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0015",
            "familyGroupID": 3,
            "description": "Chef's Special Alfredo Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0016",
            "familyGroupID": 3,
            "description": "Pesto Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0026",
            "familyGroupID": 3,
            "description": "Sweet Sour Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSDF0026",
            "familyGroupID": 3,
            "description": "Kickers",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFT0F0005",
            "familyGroupID": 3,
            "description": "Shredded Parmesan",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0013",
            "familyGroupID": 3,
            "description": "Cucumber Pickled Slices",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 24,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0015",
            "familyGroupID": 3,
            "description": "Fresh Cherry Tomatoes",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOT0017",
            "familyGroupID": 3,
            "description": "Fajita Seasoning",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 14,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 20,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW101",
            "familyGroupID": 3,
            "description": "Ranch Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW307",
            "familyGroupID": 3,
            "description": "BBQ Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW308",
            "familyGroupID": 3,
            "description": "Spicy Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWRMTOA0016",
            "familyGroupID": 3,
            "description": "Chedder Cheese Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0007",
            "familyGroupID": 3,
            "description": "Arabic Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0009",
            "familyGroupID": 3,
            "description": "Dynamite Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0016",
            "familyGroupID": 3,
            "description": "Pesto Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0021",
            "familyGroupID": 3,
            "description": "Chilly Lemon Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0126",
            "familyGroupID": 3,
            "description": "Sweet & Sour Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          }
        ]
      },
      {
        "id": 8,
        "description": "Appetizers",
        "webDescription": "",
        "shortName": "Appetizers",
        "majorGroupID": 6,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 4,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "SD",
        "saleGrouppingWeb": 6,
        "hNdhFee": 0,
        "webSalesGroup": 6,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "FGAPA0009",
            "description": "Plain Chicken Wings",
            "salePrice": 24,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 24,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Flavorful, Oven-Baked Chicken Wings. Come with Ranch Sauce",
            "order": 6,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 24,
            "salePriceTerrace": 24,
            "salePriceTable": 24,
            "salePricePUOnline": 24,
            "salePriceDEOnline": 24,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2400,
            "erpID": "FGAPA0009",
            "shortName": "Plain Chicken Wings",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGAPT0001",
            "description": "Cheesy Wedges",
            "salePrice": 16,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 16,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Golden Slices of Potato Topped with Melted Chedder Cheese\r\n",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 16,
            "salePriceTerrace": 16,
            "salePriceTable": 16,
            "salePricePUOnline": 16,
            "salePriceDEOnline": 16,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1500,
            "erpID": "FGAPT0001",
            "shortName": "Cheesy Wedges",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT0001",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.01,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0001",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0001",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGAPT0009",
            "description": "Quesadilla Cheddar",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken Blended with Cheddar Cheese Mix Folded into Flour Tortilla ",
            "order": 2,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "FGAPT0009",
            "shortName": "Quesadilla Cheddar",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.07,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0009",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 2,
                "required": "S",
                "orderType": "T",
                "quantity": 0.015,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGAPT0010",
            "description": "Cheesy Wedges No Jalapeno",
            "salePrice": 16,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 16,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Golden Slices of Potato Topped with Melted Chedder Cheese and Chopped Jalapeno Pieces",
            "order": 9,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 16,
            "salePriceTerrace": 16,
            "salePriceTable": 16,
            "salePricePUOnline": 16,
            "salePriceDEOnline": 16,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1500,
            "erpID": "FGAPT0010",
            "shortName": "Cheesy Wedges No Jalapeno",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT0010",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT0010",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGAPT0016",
            "description": "Spicy Chicken Wings",
            "salePrice": 24,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 24,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Flavorful, Oven-Baked Chicken Wings Coated with Buffalo Sauce, Come with Ranch Sauce",
            "order": 8,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 24,
            "salePriceTerrace": 24,
            "salePriceTable": 24,
            "salePricePUOnline": 24,
            "salePriceDEOnline": 24,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2400,
            "erpID": "FGAPT0016",
            "shortName": "Spicy Chicken Wings",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGAPT0017",
            "description": "BBQ Chicken Wings",
            "salePrice": 24,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 24,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Flavorful, Oven-Baked Chicken Wings Coated with BBQ. Comes with Ranch Sauce",
            "order": 7,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 24,
            "salePriceTerrace": 24,
            "salePriceTable": 24,
            "salePricePUOnline": 24,
            "salePriceDEOnline": 24,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2400,
            "erpID": "FGAPT0017",
            "shortName": "BBQ Chicken Wings",
            "calories": 560,
            "portions": 10,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT0017",
                "invSaleItemID": "307",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.035,
                "allergens": "00000000000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGAPT2015",
            "description": "Nachos Volcano",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Melted Chedder Cheese with Crispy Tortilla Chips, Perfect for Sharing",
            "order": 10,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "FGAPT2015",
            "shortName": "Nachos Volcano",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT2015",
                "invSaleItemID": "178",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.07,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT2015",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGAPT2015",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.195,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGAPT2023",
            "description": "Mozarella Sticks",
            "salePrice": 18,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 18,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "Italian Style Breaded Mozzarella Sticks Infused with Herbs Comes with Tomato Sauce",
            "order": 12,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 18,
            "salePriceTerrace": 18,
            "salePriceTable": 18,
            "salePricePUOnline": 18,
            "salePriceDEOnline": 18,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1800,
            "erpID": "FGAPT2023",
            "shortName": "Mozarella Sticks",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGAPT2027",
            "description": "Smiley Potato",
            "salePrice": 10,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 10,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Golden Smiley Potato",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 10,
            "salePriceTerrace": 10,
            "salePriceTable": 10,
            "salePricePUOnline": 10,
            "salePriceDEOnline": 10,
            "source": "LWCKHARU9JGSYMEP",
            "points": 900,
            "erpID": "FGAPT2027",
            "shortName": "Smiley Potato",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGAPT2028",
            "description": "Chicken Bites Kids",
            "salePrice": 15,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 15,
            "orderType": "LDRV",
            "noHalfNDHalf": "N",
            "webDescription": "Kids Chicken Bites",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 15,
            "salePriceTerrace": 15,
            "salePriceTable": 15,
            "salePricePUOnline": 15,
            "salePriceDEOnline": 15,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1500,
            "erpID": "FGAPT2028",
            "shortName": "Chicken Bites Kids",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGAPT2030",
            "description": "Spicy Chicken Bites",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Delicious Spicy Chicken Bites Served With Ranch Sauce",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHTRU9JGSYMEP",
            "points": 0,
            "erpID": "FGAPT2030",
            "shortName": "Spicy Chicken Bites",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGAPT2030",
                "invSaleItemID": "SFSDF0026",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 8,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGCMA8008",
            "description": "Chicken Party Box",
            "salePrice": 25,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Four Delicious Flavors of Chicken Bites. Spicy Bites, Cajun Bites, Waffle Bites, Korean Bites with Sticky Sauce. Served with Ranch and Dynamite Sauce ( Dips)",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 2500,
            "erpID": "FGCMA8008",
            "shortName": "Chicken Party Box",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGCMA8008",
                "invSaleItemID": "SFSDF0026",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGMAF7011",
            "description": "Mac & Cheese",
            "salePrice": 13,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 13,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Cheddar Cheesy, Buttery Goodness Blend Mixed with Macaroni Pasta",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 13,
            "salePriceTerrace": 13,
            "salePriceTable": 13,
            "salePricePUOnline": 13,
            "salePriceDEOnline": 13,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 1200,
            "erpID": "FGMAF7011",
            "shortName": "Mac & Cheese",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "QUEBBQ",
            "description": "Quesadilla BBQ",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken Blended with Cheese and BBQ Sauce Folded into Flour Tortilla",
            "order": 3,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "QUEBBQ",
            "shortName": "Quesadilla BBQ",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.015,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "307",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.035,
                "allergens": "00000000000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.075,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUEBBQ",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "QUESPC",
            "description": "Quesadilla Spicy",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken Blended with Cheese and Spicy Sauce Folded into Flour Tortilla",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "QUESPC",
            "shortName": "Quesadilla Spicy",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.015,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "179",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 3,
                "allergens": "00000001000010",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "308",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "S",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.075,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "QUESPC",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "SDBC",
            "description": "Buffalo Chicken Bites",
            "salePrice": 23,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Lightly Breaded Buffalo Chicken Breast Come with Ranch Sauce\r\n",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "SDBC",
            "shortName": "Buffalo Chicken Bites",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "SDGBC",
            "description": "Garlic Bread with Cheese",
            "salePrice": 18,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 18,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Freshly Baked in Our Wood Fire Oven, Topped with Parmesan Cheese, Garlic, Mixed Herbs and Olive Oil",
            "order": 11,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 18,
            "salePriceTerrace": 18,
            "salePriceTable": 18,
            "salePricePUOnline": 18,
            "salePriceDEOnline": 18,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1800,
            "erpID": "SDGBC",
            "shortName": "Garlic Bread with Cheese",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "SDGBC",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "SDPW",
            "description": "Spicy Potato Wedges",
            "salePrice": 11,
            "familyGroupID": 8,
            "majorGroupID": 6,
            "toppingsEq": 0,
            "salePriceDelivery": 11,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Gloden Slices of Potato Infused with Herbs",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 11,
            "salePriceTerrace": 11,
            "salePriceTable": 11,
            "salePricePUOnline": 11,
            "salePriceDEOnline": 11,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1000,
            "erpID": "SDPW",
            "shortName": "Spicy Potato Wedges",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 10,
        "description": "Drinks Can",
        "webDescription": "",
        "shortName": "Drinks Can",
        "majorGroupID": 7,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 5,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "DC",
        "saleGrouppingWeb": 7,
        "hNdhFee": 0,
        "webSalesGroup": 7,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "FGBET1021",
            "description": "Coke - 330 ml",
            "salePrice": 4,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "330 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 400,
            "erpID": "FGBET1021",
            "shortName": "Coke",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET1022",
            "description": "Coke Light - 330 ml",
            "salePrice": 4,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "330 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 400,
            "erpID": "FGBET1022",
            "shortName": "Coke Light",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET1023",
            "description": "Sprite - 330 ml",
            "salePrice": 4,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "330 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 400,
            "erpID": "FGBET1023",
            "shortName": "Sprite",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET1025",
            "description": "Fanta Orange - 330 ml",
            "salePrice": 4,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "330 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 400,
            "erpID": "FGBET1025",
            "shortName": "Fanta Orange",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET1026",
            "description": "Arwa water - 500 ml",
            "salePrice": 2,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 2,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "500 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 2,
            "salePriceTerrace": 2,
            "salePriceTable": 2,
            "salePricePUOnline": 2,
            "salePriceDEOnline": 2,
            "source": "LWCKHARU9JGSYMEP",
            "points": 200,
            "erpID": "FGBET1026",
            "shortName": "Arwa water",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET1028",
            "description": "Fanta-Citrus - 330 ml",
            "salePrice": 4,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "330 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 400,
            "erpID": "FGBET1028",
            "shortName": "Fanta-Citrus",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBVC0011",
            "description": "Al Rabie Orange Juice",
            "salePrice": 3,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 3,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "200 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 3,
            "salePriceTerrace": 3,
            "salePriceTable": 3,
            "salePricePUOnline": 3,
            "salePriceDEOnline": 3,
            "source": "LWCKHTRU9JGSYMEP",
            "points": 0,
            "erpID": "FGBVC0011",
            "shortName": "Al Rabie Orange Juice",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBVT0008",
            "description": "Chocolate Milk 150ml",
            "salePrice": 3,
            "familyGroupID": 10,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 3,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "150 ml",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 3,
            "salePriceTerrace": 3,
            "salePriceTable": 3,
            "salePricePUOnline": 3,
            "salePriceDEOnline": 3,
            "source": "LWCKHARU9JGSYMEP",
            "points": 300,
            "erpID": "FGBVT0008",
            "shortName": "Chocolate Milk",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 13,
        "description": "Desserts",
        "webDescription": "",
        "shortName": "Desserts",
        "majorGroupID": 9,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 5,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "DS",
        "saleGrouppingWeb": 9,
        "hNdhFee": 0,
        "webSalesGroup": 9,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "DENC",
            "description": "Chocolate Calzone",
            "salePrice": 19,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 19,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Italian Oven-Made Pastry Goodness Filled with Rich Chocolate",
            "order": 11,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 19,
            "salePriceTerrace": 19,
            "salePriceTable": 19,
            "salePricePUOnline": 19,
            "salePriceDEOnline": 19,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1800,
            "erpID": "DENC",
            "shortName": "Chocolate Calzone",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGDST1003",
            "description": "Churros",
            "salePrice": 16,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 16,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Famous spanish churros sprinkled with sugar. Comes with the delicious chocolate dip\r\n",
            "order": 9,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 16,
            "salePriceTerrace": 16,
            "salePriceTable": 16,
            "salePricePUOnline": 16,
            "salePriceDEOnline": 16,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1500,
            "erpID": "FGDST1003",
            "shortName": "Churros",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGDST2006",
            "description": "Lava Twins",
            "salePrice": 19,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 19,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Two pieces of fluffy cake filled with warm chocolate goodness",
            "order": 10,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 19,
            "salePriceTerrace": 19,
            "salePriceTable": 19,
            "salePricePUOnline": 19,
            "salePriceDEOnline": 19,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1800,
            "erpID": "FGDST2006",
            "shortName": "Lava Twins",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGDST2013",
            "description": "Lava Single",
            "salePrice": 10,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 10,
            "orderType": "DRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Fluffy Cake Filled with Warm Chocolate Goodness",
            "order": 12,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 10,
            "salePriceTerrace": 10,
            "salePriceTable": 10,
            "salePricePUOnline": 10,
            "salePriceDEOnline": 10,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1000,
            "erpID": "FGDST2013",
            "shortName": "Lava Single",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGDZF001",
            "description": "Raspberry Cheesecake",
            "salePrice": 16,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 16,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Creamy Rich Cheese Topped with Our Sweet Raspberry Sauce\r\n",
            "order": 8,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 16,
            "salePriceTerrace": 16,
            "salePriceTable": 16,
            "salePricePUOnline": 16,
            "salePriceDEOnline": 16,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1500,
            "erpID": "FGDZF001",
            "shortName": "Raspberry Cheesecake",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGPZT2044",
            "description": "Choco Pizza",
            "salePrice": 23,
            "familyGroupID": 13,
            "majorGroupID": 9,
            "toppingsEq": 0,
            "salePriceDelivery": 23,
            "orderType": "LDRVE",
            "noHalfNDHalf": "N",
            "webDescription": "What's Better than Pizza is Chocolate Pizza. Topped with Chocolate Crunchies",
            "order": 7,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 23,
            "salePriceTerrace": 23,
            "salePriceTable": 23,
            "salePricePUOnline": 23,
            "salePriceDEOnline": 23,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2200,
            "erpID": "FGPZT2044",
            "shortName": "Choco Pizza",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 15,
        "description": "Dips",
        "webDescription": null,
        "shortName": "Dips",
        "majorGroupID": 11,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 7,
        "baseProduct": null,
        "showAtWeb": "S",
        "size": null,
        "familyGroupPrefix": null,
        "saleGrouppingWeb": 7,
        "hNdhFee": 0,
        "webSalesGroup": 7,
        "webSubsalesGroup": 1,
        "products": [
          {
            "id": "FGAPT2026",
            "description": "Rocca, Parmesan & Balsamic",
            "salePrice": 10,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 10,
            "orderType": "DRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Fresh Rocca, Parmessan, and Balsamic Vinegar",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 10,
            "salePriceTerrace": 10,
            "salePriceTable": 10,
            "salePricePUOnline": 10,
            "salePriceDEOnline": 10,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 0,
            "erpID": "FGAPT2026",
            "shortName": "Rocca, Parmesan & Balsamic",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0001",
            "description": "Ranch Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0001",
            "shortName": "Ranch Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0002",
            "description": "Chili Sauce Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0002",
            "shortName": "Chili Sauce Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0003",
            "description": "BBQ Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0003",
            "shortName": "BBQ Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0005",
            "description": "Tomato Sauce Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0005",
            "shortName": "Tomato Sauce Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0010",
            "description": "Chocolate Sauce Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0010",
            "shortName": "Chocolate Sauce Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0011",
            "description": "Shredded Parmesan",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0011",
            "shortName": "Shredded Parmesan",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGSAT0011",
                "invSaleItemID": "SFT0F0005",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGSAT0013",
            "description": "Dynamite Sauce Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "H",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0013",
            "shortName": "Dynamite Sauce Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0019",
            "description": "Balsamic Vinegar Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0019",
            "shortName": "Balsamic Vinegar Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGSAT0025",
            "description": "Cheddar Cheese Dip",
            "salePrice": 4,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 4,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 4,
            "salePriceTerrace": 4,
            "salePriceTable": 4,
            "salePricePUOnline": 4,
            "salePriceDEOnline": 4,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSAT0025",
            "shortName": "Cheddar Cheese Dip",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGSAT0025",
                "invSaleItemID": "RMTOA0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGSBC0001",
            "description": "Ranch Sauce Bottle",
            "salePrice": 5,
            "familyGroupID": 15,
            "majorGroupID": 11,
            "toppingsEq": 0,
            "salePriceDelivery": 5,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Ranch Sauce Bottle",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 5,
            "salePriceTerrace": 5,
            "salePriceTable": 5,
            "salePricePUOnline": 5,
            "salePriceDEOnline": 5,
            "source": "LWCKHARU9JGSYMEP",
            "points": 0,
            "erpID": "FGSBC0001",
            "shortName": "Ranch Sauce Bottle",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGSBC0001",
                "invSaleItemID": "101",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.135,
                "allergens": "00000011000010",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": []
      },
      {
        "id": 16,
        "description": "Delivery",
        "webDescription": null,
        "shortName": "Delivery",
        "majorGroupID": 12,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 9,
        "baseProduct": "",
        "showAtWeb": "N",
        "size": "N",
        "familyGroupPrefix": "DE",
        "saleGrouppingWeb": null,
        "hNdhFee": 0,
        "webSalesGroup": null,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "DELIVERY",
            "description": "Delivery charge",
            "salePrice": 18,
            "familyGroupID": 16,
            "majorGroupID": 12,
            "toppingsEq": 0,
            "salePriceDelivery": 18,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 18,
            "salePriceTerrace": 18,
            "salePriceTable": 18,
            "salePricePUOnline": 18,
            "salePriceDEOnline": 18,
            "source": "LWCHARU9JGP",
            "points": 0,
            "erpID": "DELIVERY",
            "shortName": "Delivery charge",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "DELIVERY20",
            "description": "Delivery Charges 20",
            "salePrice": 20,
            "familyGroupID": 16,
            "majorGroupID": 12,
            "toppingsEq": 0,
            "salePriceDelivery": 20,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 0,
            "salePriceTerrace": 0,
            "salePriceTable": 0,
            "salePricePUOnline": 20,
            "salePriceDEOnline": 20,
            "source": "LWCHA9JGSYMEP",
            "points": 0,
            "erpID": "DELIVERY20",
            "shortName": "Delivery Charges 20",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 19,
        "description": "Combos",
        "webDescription": "",
        "shortName": "Combos",
        "majorGroupID": 15,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 49,
        "baseProduct": "",
        "showAtWeb": "N",
        "size": "N",
        "familyGroupPrefix": "CB",
        "saleGrouppingWeb": null,
        "hNdhFee": 0,
        "webSalesGroup": null,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "CBMOVIE",
            "description": "Movie Combo Box",
            "salePrice": 10,
            "familyGroupID": 19,
            "majorGroupID": 15,
            "toppingsEq": 0,
            "salePriceDelivery": 10,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Two medium pizzas, potato wedges, nachos volcano, churros and 1 liter pepsi.",
            "order": 2,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 10,
            "salePriceTerrace": 10,
            "salePriceTable": 10,
            "salePricePUOnline": 10,
            "salePriceDEOnline": 10,
            "source": "LWPCKHARU9GSYMEP",
            "points": 0,
            "erpID": "CBMOVIE",
            "shortName": "Movie Combo Box",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "CBPARTY",
            "description": "Party Combo Box",
            "salePrice": 10,
            "familyGroupID": 19,
            "majorGroupID": 15,
            "toppingsEq": 0,
            "salePriceDelivery": 10,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Two large pizzas, potato wedges, mozzarella sticks, crispy cheesecake bites, churros and 2 1 liter Pepsi",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 10,
            "salePriceTerrace": 10,
            "salePriceTable": 10,
            "salePricePUOnline": 10,
            "salePriceDEOnline": 10,
            "source": "LWPCKHARU9GSYMEP",
            "points": 0,
            "erpID": "CBPARTY",
            "shortName": "Party Combo Box",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "CBSNT",
            "description": "SNT Combo",
            "salePrice": 6,
            "familyGroupID": 19,
            "majorGroupID": 15,
            "toppingsEq": 0,
            "salePriceDelivery": 6,
            "orderType": "DRV",
            "noHalfNDHalf": "N",
            "webDescription": "3 Large pizza, potato wedges, mozzarella stick, chicken bites, churros, chocolate calzone, 2 pepsi liter, t-shirt",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "N",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 6,
            "salePriceTerrace": 6,
            "salePriceTable": 6,
            "salePricePUOnline": 6,
            "salePriceDEOnline": 6,
            "source": "LWPCKHARU9GSYMEP",
            "points": 0,
            "erpID": "CBSNT",
            "shortName": "SNT Combo",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGCMA8001",
            "description": "WOW Box Pizza Meal",
            "salePrice": 18,
            "familyGroupID": 19,
            "majorGroupID": 15,
            "toppingsEq": 0,
            "salePriceDelivery": 18,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "",
            "order": 1,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 18,
            "salePriceTerrace": 18,
            "salePriceTable": 18,
            "salePricePUOnline": 18,
            "salePriceDEOnline": 18,
            "source": "LWCKARU9GSP",
            "points": 0,
            "erpID": "FGCMA8001",
            "shortName": "WOW Box Pizza Meal",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGCMA8002",
            "description": "WOW Box Chicken Bites Meal",
            "salePrice": 18,
            "familyGroupID": 19,
            "majorGroupID": 15,
            "toppingsEq": 0,
            "salePriceDelivery": 18,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 18,
            "salePriceTerrace": 18,
            "salePriceTable": 18,
            "salePricePUOnline": 18,
            "salePriceDEOnline": 18,
            "source": "LWCKAR9YMEP",
            "points": 0,
            "erpID": "FGCMA8002",
            "shortName": "WOW Box Chicken Bites Meal",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 21,
        "description": "Drinks 1 L",
        "webDescription": "",
        "shortName": "Drinks 1 L",
        "majorGroupID": 7,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 8,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "DL",
        "saleGrouppingWeb": 7,
        "hNdhFee": 0,
        "webSalesGroup": 7,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "FGBET2021",
            "description": "Coke -  1 Ltr",
            "salePrice": 8,
            "familyGroupID": 21,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 8,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "1 Ltr",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 8,
            "salePriceTerrace": 8,
            "salePriceTable": 8,
            "salePricePUOnline": 8,
            "salePriceDEOnline": 8,
            "source": "LWCKHARU9JGSYMDP",
            "points": 800,
            "erpID": "FGBET2021",
            "shortName": "Coke - 1 Ltr",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET2023",
            "description": "Sprite -  1 Ltr",
            "salePrice": 8,
            "familyGroupID": 21,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 8,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "1 Ltr",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 8,
            "salePriceTerrace": 8,
            "salePriceTable": 8,
            "salePricePUOnline": 8,
            "salePriceDEOnline": 8,
            "source": "LWCKHARU9JGSYMDP",
            "points": 800,
            "erpID": "FGBET2023",
            "shortName": "Sprite - 1 Ltr",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 22,
        "description": "Drinks 2.25 L",
        "webDescription": "",
        "shortName": "Drinks 2.25 L",
        "majorGroupID": 7,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 9,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "D2",
        "saleGrouppingWeb": 7,
        "hNdhFee": 0,
        "webSalesGroup": 7,
        "webSubsalesGroup": 0,
        "products": [
          {
            "id": "FGBET3021",
            "description": "Coke -  2.25 Ltr",
            "salePrice": 12,
            "familyGroupID": 22,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 12,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "2.25 Ltr",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 10,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 12,
            "salePriceTerrace": 12,
            "salePriceTable": 12,
            "salePricePUOnline": 12,
            "salePriceDEOnline": 12,
            "source": "LWCKHARU9JSYMEP",
            "points": 1200,
            "erpID": "FGBET3021",
            "shortName": "Coke - 2.25 Ltr",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          },
          {
            "id": "FGBET3023",
            "description": "Sprite -  2.25 Ltr",
            "salePrice": 12,
            "familyGroupID": 22,
            "majorGroupID": 7,
            "toppingsEq": 0,
            "salePriceDelivery": 12,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "2.25 Ltr",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 10,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 12,
            "salePriceTerrace": 12,
            "salePriceTable": 12,
            "salePricePUOnline": 12,
            "salePriceDEOnline": 12,
            "source": "LWCKHARU9JGSYMEP",
            "points": 1200,
            "erpID": "FGBET3023",
            "shortName": "Sprite - 2.25 Ltr",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": []
          }
        ],
        "toppings": []
      },
      {
        "id": 26,
        "description": "Chef´s Selection Large",
        "webDescription": "",
        "shortName": "Chef´s Selection",
        "majorGroupID": 1,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 2,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "L",
        "familyGroupPrefix": "CS",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 2,
        "products": [
          {
            "id": "FGPZT3025",
            "description": "Olive Oil Pesto Large",
            "salePrice": 42,
            "familyGroupID": 26,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 42,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken, Fresh Cherry Tomatoes, Cheese Mix, Pesto Sauce ",
            "order": 6,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 42,
            "salePriceTerrace": 42,
            "salePriceTable": 42,
            "salePricePUOnline": 42,
            "salePriceDEOnline": 42,
            "source": "LWCKHARU9JGSYMEP",
            "points": 4200,
            "erpID": "CSOOP",
            "shortName": "Olive Oil Pesto",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "SFSAC0014",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.105,
                "allergens": "00001000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "SFT0F0005",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "SFTOC0015",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 7.5,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3025",
                "invSaleItemID": "SWSFSAC0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3026",
            "description": "Alfredo Chicken Large",
            "salePrice": 42,
            "familyGroupID": 26,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 42,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken, Spanish Mushroom, Alfredo Sauce, Cheese Mix ",
            "order": 7,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 42,
            "salePriceTerrace": 42,
            "salePriceTable": 42,
            "salePricePUOnline": 42,
            "salePriceDEOnline": 42,
            "source": "LWCKHARU9JGSYMEP",
            "points": 4200,
            "erpID": "CSACH",
            "shortName": "Alfredo Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3026",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.064,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3026",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3026",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3026",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3026",
                "invSaleItemID": "SFSAC0015",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.09,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3027",
            "description": "Pomodoro Large",
            "salePrice": 42,
            "familyGroupID": 26,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 42,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Italian Rocca, Cherry Tomatoes, Cheese Mix, Italian Balsamic Vinegar, Tomato Sauce",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 42,
            "salePriceTerrace": 42,
            "salePriceTable": 42,
            "salePricePUOnline": 42,
            "salePriceDEOnline": 42,
            "source": "LWCKHARU9JGSYMEP",
            "points": 4200,
            "erpID": "CSPOM",
            "shortName": "Pomodoro",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3027",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3027",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3027",
                "invSaleItemID": "SFSAC0014",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.105,
                "allergens": "00001000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3027",
                "invSaleItemID": "SFTOC0015",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 7.5,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT3035",
            "description": "Chicken Fajita Large",
            "salePrice": 42,
            "familyGroupID": 26,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 42,
            "orderType": "LDRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Spiced Chicken, Premium Cheese Mix, Green Pepper, Red Pepper, Onion, Jalapeno, Fajita Sauce ",
            "order": 8,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 42,
            "salePriceTerrace": 42,
            "salePriceTable": 42,
            "salePricePUOnline": 42,
            "salePriceDEOnline": 42,
            "source": "LWCKHARU9JGSYMEP",
            "points": 4200,
            "erpID": "CSCHF",
            "shortName": "Chicken Fajita",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.016,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.052,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.045,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.12,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.15,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "FGPZT3042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "SFTOT0017",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.003,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT3035",
                "invSaleItemID": "SWSFSAC0021",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": [
          {
            "id": "023",
            "familyGroupID": 26,
            "description": "Pepperoni",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "024",
            "familyGroupID": 26,
            "description": "Beef",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000010100",
            "grouppingID": 3,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "033",
            "familyGroupID": 26,
            "description": "Spanish Mushroom",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 18,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "043",
            "familyGroupID": 26,
            "description": "Black Olives",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 16,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "047",
            "familyGroupID": 26,
            "description": "Jalapeno",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 13,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "059",
            "familyGroupID": 26,
            "description": "Onion",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 12,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "060",
            "familyGroupID": 26,
            "description": "Tomato Topping",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 17,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "061",
            "familyGroupID": 26,
            "description": "Green Pepper",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 15,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "071",
            "familyGroupID": 26,
            "description": "Red Pepper",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 14,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "097",
            "familyGroupID": 26,
            "description": "Tabasco Red",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 22,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "098",
            "familyGroupID": 26,
            "description": "Tabasco Green",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 23,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "101",
            "familyGroupID": 26,
            "description": "Ranch Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000011000010",
            "grouppingID": 2,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "134",
            "familyGroupID": 26,
            "description": "Steak Philly",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000011010",
            "grouppingID": 3,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "175",
            "familyGroupID": 26,
            "description": "Buffalo Chicken",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000110000010",
            "grouppingID": 3,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "178",
            "familyGroupID": 26,
            "description": "Nachos",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 21,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "179",
            "familyGroupID": 26,
            "description": "Sliced Cheese",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000010",
            "grouppingID": 2,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "204",
            "familyGroupID": 26,
            "description": "Thin Brown ",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000010",
            "grouppingID": 1,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "307",
            "familyGroupID": 26,
            "description": "BBQ Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000010",
            "grouppingID": 2,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "308",
            "familyGroupID": 26,
            "description": "Spicy Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "601",
            "familyGroupID": 26,
            "description": "Tomato Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "636",
            "familyGroupID": 26,
            "description": "Thin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "664",
            "familyGroupID": 26,
            "description": "Grilled Chicken",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000000000",
            "grouppingID": 3,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671",
            "familyGroupID": 26,
            "description": "Maestro Cheese",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671LESS",
            "familyGroupID": 26,
            "description": "Less Cheese***",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "N",
            "points": null
          },
          {
            "id": "DO001",
            "familyGroupID": 26,
            "description": "Cheesy Crust ",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "DO002",
            "familyGroupID": 26,
            "description": "Double Cheddar ",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 1,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "FGPZT3042",
            "familyGroupID": 26,
            "description": "Thick",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMSEA0027",
            "familyGroupID": 26,
            "description": "Sumac Powder",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 19,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOA0016",
            "familyGroupID": 26,
            "description": "Cheddar Cheese Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0010",
            "familyGroupID": 26,
            "description": "Ultrathin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0012",
            "familyGroupID": 26,
            "description": "Classic ",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0009",
            "familyGroupID": 26,
            "description": "Dynamite Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000010000010",
            "grouppingID": 2,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0014",
            "familyGroupID": 26,
            "description": "Chef's Special Tomato Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0015",
            "familyGroupID": 26,
            "description": "Chef's Special Alfredo Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0016",
            "familyGroupID": 26,
            "description": "Pesto Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0021",
            "familyGroupID": 26,
            "description": "Chilly Lemon Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000001000000",
            "grouppingID": 4,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFT0F0005",
            "familyGroupID": 26,
            "description": "Shredded Parmesan",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0013",
            "familyGroupID": 26,
            "description": "Cucumber Pickled Slices",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 24,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0015",
            "familyGroupID": 26,
            "description": "Fresh Cherry Tomatoes",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOT0017",
            "familyGroupID": 26,
            "description": "Fajita Seasoning",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 20,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW101",
            "familyGroupID": 26,
            "description": "Ranch Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW307",
            "familyGroupID": 26,
            "description": "BBQ Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW308",
            "familyGroupID": 26,
            "description": "Spicy Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWRMTOA0016",
            "familyGroupID": 26,
            "description": "Chedder Cheese Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0007",
            "familyGroupID": 26,
            "description": "Arabic Sauce",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0009",
            "familyGroupID": 26,
            "description": "Dynamite Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0016",
            "familyGroupID": 26,
            "description": "Pesto Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0021",
            "familyGroupID": 26,
            "description": "Chilly Lemon Swirl",
            "salePrice": 4,
            "salePriceWithout": 0,
            "salePriceDel": 4,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          }
        ]
      },
      {
        "id": 29,
        "description": "Chef´s Selection Medium",
        "webDescription": "",
        "shortName": "Chef´s Selection",
        "majorGroupID": 1,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 3,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "M",
        "familyGroupPrefix": "CSM",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 2,
        "products": [
          {
            "id": "FGPZT2025",
            "description": "Olive Oil Pesto Medium",
            "salePrice": 31,
            "familyGroupID": 29,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 31,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken, Fresh Cherry Tomatoes, Cheese Mix, Pesto Sauce ",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 31,
            "salePriceTerrace": 31,
            "salePriceTable": 31,
            "salePricePUOnline": 31,
            "salePriceDEOnline": 31,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3100,
            "erpID": "CSMOOP",
            "shortName": "Olive Oil Pesto",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "SFSAC0014",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.07,
                "allergens": "00001000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "SFT0F0005",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.01,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "SFTOC0015",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 5,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2025",
                "invSaleItemID": "SWSFSAC0016",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2026",
            "description": "Alfredo Chicken Medium",
            "salePrice": 31,
            "familyGroupID": 29,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 31,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Grilled Chicken, Spanish Mushroom, Alfredo Sauce, Cheese Mix ",
            "order": 3,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 31,
            "salePriceTerrace": 31,
            "salePriceTable": 31,
            "salePricePUOnline": 31,
            "salePriceDEOnline": 31,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3100,
            "erpID": "CSMACH",
            "shortName": "Alfredo Chicken",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2026",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.064,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2026",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2026",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2026",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2026",
                "invSaleItemID": "SFSAC0015",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.06,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2027",
            "description": "Pomodoro Medium",
            "salePrice": 31,
            "familyGroupID": 29,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 31,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Italian Rocca, Cherry Tomatoes, Cheese mix, Italian Balsamic Vinegar, Tomato Sauce",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "S",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 31,
            "salePriceTerrace": 31,
            "salePriceTable": 31,
            "salePricePUOnline": 31,
            "salePriceDEOnline": 31,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3100,
            "erpID": "CSMPOM",
            "shortName": "Pomodoro",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2027",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2027",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2027",
                "invSaleItemID": "SFSAC0014",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.07,
                "allergens": "00001000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2027",
                "invSaleItemID": "SFTOC0015",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 5,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2035",
            "description": "Chicken Fajita Medium",
            "salePrice": 31,
            "familyGroupID": 29,
            "majorGroupID": 1,
            "toppingsEq": 0,
            "salePriceDelivery": 31,
            "orderType": "DRVE",
            "noHalfNDHalf": "N",
            "webDescription": "Tomato Sauce, Spiced Chicken, Premium Cheese Mix, Green Pepper, Red Pepper, Onion, Jalapeno, Fajita Sauce ",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "N",
            "stockControlType": "N",
            "maxToppings": 15,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 31,
            "salePriceTerrace": 31,
            "salePriceTable": 31,
            "salePricePUOnline": 31,
            "salePriceDEOnline": 31,
            "source": "LWCKHTARU9JGSYMEP",
            "points": 3100,
            "erpID": "CSMCHF",
            "shortName": "Chicken Fajita",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.011,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "664",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "01000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "FGPZT2042",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "SFTOT0017",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.0015,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2035",
                "invSaleItemID": "SWSFSAC0021",
                "indexNbr": 1,
                "required": "N",
                "orderType": "T",
                "quantity": 0.018,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": [
          {
            "id": "023",
            "familyGroupID": 29,
            "description": "Pepperoni",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "024",
            "familyGroupID": 29,
            "description": "Beef",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "00000000010100",
            "grouppingID": 3,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "033",
            "familyGroupID": 29,
            "description": "Spanish Mushroom",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 18,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "043",
            "familyGroupID": 29,
            "description": "Black Olives",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 16,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "047",
            "familyGroupID": 29,
            "description": "Jalapeno",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 13,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "059",
            "familyGroupID": 29,
            "description": "Onion",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 12,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "060",
            "familyGroupID": 29,
            "description": "Tomato Topping",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 17,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "061",
            "familyGroupID": 29,
            "description": "Green Pepper",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 15,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "071",
            "familyGroupID": 29,
            "description": "Red Pepper",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 14,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "097",
            "familyGroupID": 29,
            "description": "Tabasco Red",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 22,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "098",
            "familyGroupID": 29,
            "description": "Tabasco Green",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 23,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "101",
            "familyGroupID": 29,
            "description": "Ranch Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000011000010",
            "grouppingID": 2,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "134",
            "familyGroupID": 29,
            "description": "Steak Philly",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000011010",
            "grouppingID": 3,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "175",
            "familyGroupID": 29,
            "description": "Buffalo Chicken",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000110000010",
            "grouppingID": 3,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "178",
            "familyGroupID": 29,
            "description": "Nachos",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 21,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "179",
            "familyGroupID": 29,
            "description": "Sliced Cheese",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000010",
            "grouppingID": 2,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "203",
            "familyGroupID": 29,
            "description": "Thin Brown ",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000010",
            "grouppingID": 1,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "307",
            "familyGroupID": 29,
            "description": "BBQ Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000010",
            "grouppingID": 2,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "308",
            "familyGroupID": 29,
            "description": "Spicy Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "601",
            "familyGroupID": 29,
            "description": "Tomato Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 2,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "635",
            "familyGroupID": 29,
            "description": "Thin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "664",
            "familyGroupID": 29,
            "description": "Grilled Chicken",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 1,
            "allergens": "01000000000000",
            "grouppingID": 3,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671",
            "familyGroupID": 29,
            "description": "Maestro Cheese",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "671LESS",
            "familyGroupID": 29,
            "description": "Less Cheese***",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "N",
            "points": null
          },
          {
            "id": "DO003",
            "familyGroupID": 29,
            "description": "Double Cheddar",
            "salePrice": 6,
            "salePriceWithout": 0,
            "salePriceDel": 6,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 1,
            "order": 1,
            "calories": 183,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "FGPZT2042",
            "familyGroupID": 29,
            "description": "Thick",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000101000000",
            "grouppingID": 1,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMPDF0001",
            "familyGroupID": 29,
            "description": "Cauliflower Crust ",
            "salePrice": 10,
            "salePriceWithout": 0,
            "salePriceDel": 10,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 2,
            "calories": 0,
            "promotedPrice": 10,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMSEA0027",
            "familyGroupID": 29,
            "description": "Sumac Powder",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 19,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "RMTOA0016",
            "familyGroupID": 29,
            "description": "Cheddar Cheese Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0009",
            "familyGroupID": 29,
            "description": "Ultrathin",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFCRF0013",
            "familyGroupID": 29,
            "description": "Classic ",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000100000000",
            "grouppingID": 1,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0007",
            "familyGroupID": 29,
            "description": "Arabic Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000100",
            "grouppingID": 2,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0009",
            "familyGroupID": 29,
            "description": "Dynamite Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000010000010",
            "grouppingID": 2,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0014",
            "familyGroupID": 29,
            "description": "Chef's Special Tomato Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 10,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0015",
            "familyGroupID": 29,
            "description": "Chef's Special Alfredo Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000001000000",
            "grouppingID": 2,
            "order": 11,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0016",
            "familyGroupID": 29,
            "description": "Pesto Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00001000000000",
            "grouppingID": 2,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFSAC0021",
            "familyGroupID": 29,
            "description": "Chilly Lemon Swirl",
            "salePrice": 0,
            "salePriceWithout": 0,
            "salePriceDel": 0,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000001000000",
            "grouppingID": 4,
            "order": 8,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFT0F0005",
            "familyGroupID": 29,
            "description": "Shredded Parmesan",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 3,
            "allergens": "00000001000000",
            "grouppingID": 3,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0013",
            "familyGroupID": 29,
            "description": "Cucumber Pickled Slices",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 24,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOC0015",
            "familyGroupID": 29,
            "description": "Fresh Cherry Tomatoes",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 0,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SFTOT0017",
            "familyGroupID": 29,
            "description": "Fajita Seasoning",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 14,
            "allergens": "00000000000000",
            "grouppingID": 3,
            "order": 20,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW101",
            "familyGroupID": 29,
            "description": "Ranch Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 3,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW307",
            "familyGroupID": 29,
            "description": "BBQ Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 1,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SW308",
            "familyGroupID": 29,
            "description": "Spicy Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 2,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWRMTOA0016",
            "familyGroupID": 29,
            "description": "Chedder Cheese Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 9,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0007",
            "familyGroupID": 29,
            "description": "Arabic Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 5,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0009",
            "familyGroupID": 29,
            "description": "Dynamite Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 6,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0016",
            "familyGroupID": 29,
            "description": "Pesto Swirl",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 4,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          },
          {
            "id": "SWSFSAC0021",
            "familyGroupID": 29,
            "description": "Chilly Lemon Sauce",
            "salePrice": 3,
            "salePriceWithout": 0,
            "salePriceDel": 3,
            "salePriceDelWithout": 0,
            "canBePromoted": "N",
            "kitchenOrder": 24,
            "allergens": "00000000000000",
            "grouppingID": 4,
            "order": 7,
            "calories": 0,
            "promotedPrice": 0,
            "critical": "S",
            "points": null
          }
        ]
      },
      {
        "id": 32,
        "description": "Take & Bake",
        "webDescription": "",
        "shortName": "Take & Bake",
        "majorGroupID": 18,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 4,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "CH",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 3,
        "products": [
          {
            "id": "FGPZT2045",
            "description": "Take & Bake Vegetarian",
            "salePrice": 25,
            "familyGroupID": 32,
            "majorGroupID": 18,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Medium Pizza: Maestro Sauce, Cheese Mix, Tomato, Olives, Onion, Green Pepper, Mushroom. *Uncooked Pizza, Oven Needed*",
            "order": 32,
            "allergens": "00000000000000",
            "highlightWeb": "NB",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "FGPZT2045",
            "shortName": "Take & Bake Vegetarian",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.048,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.024,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2045",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2046",
            "description": "Take & Bake Fresh Rocca",
            "salePrice": 25,
            "familyGroupID": 32,
            "majorGroupID": 18,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVE",
            "noHalfNDHalf": "S",
            "webDescription": "Medium Pizza: Rocca, Parmesan Cheese, Balsamic Vinegar, Maestro Cheese, Tomato, Ultrathin Crust. *Uncooked, Oven Needed*",
            "order": 31,
            "allergens": "00000000000000",
            "highlightWeb": "B",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHAR9JGSYMEP",
            "points": 2500,
            "erpID": "FGPZT2046",
            "shortName": "Take & Bake Fresh Rocca",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2046",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.08,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2046",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2046",
                "invSaleItemID": "SFCRF0009",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000100000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2047",
            "description": "Take & Bake Margarita",
            "salePrice": 25,
            "familyGroupID": 32,
            "majorGroupID": 18,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "N",
            "webDescription": "Medium Pizza: Tomato Sauce, Maestro Secret Cheese Mix. Thin Crust *Uncooked, Oven Needed*",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "NB",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "FGPZT2047",
            "shortName": "Take & Bake Margarita",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2047",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2047",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2047",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "FGPZT2048",
            "description": "Take & Bake Lahapeno",
            "salePrice": 25,
            "familyGroupID": 32,
            "majorGroupID": 18,
            "toppingsEq": 0,
            "salePriceDelivery": 25,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Medium Pizza: Maestro Sauce, Cheese mix, Jalapeno Pepper, Red and Green Tabasco Sauce, Green and Red Bell Pepper, Thin Crust. *Uncooked, Oven Needed*",
            "order": 33,
            "allergens": "00000000000000",
            "highlightWeb": "NBH",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 25,
            "salePriceTerrace": 25,
            "salePriceTable": 25,
            "salePricePUOnline": 25,
            "salePriceDEOnline": 25,
            "source": "LWCKHARU9JGSYMEP",
            "points": 2500,
            "erpID": "FGPZT2048",
            "shortName": "Take & Bake Lahapeno",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "047",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.022,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.039,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "071",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.03,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "097",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.002,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "098",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.003,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.04,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "635",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT2048",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.1,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": []
      },
      {
        "id": 33,
        "description": "Kids Pizza",
        "webDescription": "",
        "shortName": "Kids",
        "majorGroupID": 19,
        "hNDhGroupping": "",
        "hNDnType": "A",
        "freeToppingsNbr": 0,
        "order": 6,
        "baseProduct": "",
        "showAtWeb": "S",
        "size": "N",
        "familyGroupPrefix": "KP",
        "saleGrouppingWeb": 1,
        "hNdhFee": 0,
        "webSalesGroup": 1,
        "webSubsalesGroup": 4,
        "products": [
          {
            "id": "FGPZT1054",
            "description": "Chicken Small  Pizza",
            "salePrice": 15,
            "familyGroupID": 33,
            "majorGroupID": 19,
            "toppingsEq": 0,
            "salePriceDelivery": 15,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Maestro Sauce, Cheese Mix, Somato, Slices Chicken Bites ",
            "order": 50,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 15,
            "salePriceTerrace": 15,
            "salePriceTable": 15,
            "salePricePUOnline": 15,
            "salePriceDEOnline": 15,
            "source": "LWCKP",
            "points": 0,
            "erpID": "FGPZT1054",
            "shortName": "Chicken",
            "calories": 0,
            "portions": 1,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "FGPZT1054",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT1054",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "FGPZT1054",
                "invSaleItemID": "FGPZT1042",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PSMA",
            "description": "Margarita Small",
            "salePrice": 15,
            "familyGroupID": 33,
            "majorGroupID": 19,
            "toppingsEq": 0,
            "salePriceDelivery": 15,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Tomato Sauce, Maestro Secret Cheese Mix",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 15,
            "salePriceTerrace": 15,
            "salePriceTable": 15,
            "salePricePUOnline": 15,
            "salePriceDEOnline": 15,
            "source": "LWCKP",
            "points": 0,
            "erpID": "PSMA",
            "shortName": "Margarita",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PSMA",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSMA",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.075,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSMA",
                "invSaleItemID": "FGPZT1042",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PSPE",
            "description": "Pepperoni Small",
            "salePrice": 15,
            "familyGroupID": 33,
            "majorGroupID": 19,
            "toppingsEq": 0,
            "salePriceDelivery": 15,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Maestro Sauce, Cheese Mix, Pepperoni ",
            "order": 5,
            "allergens": "00000000000000",
            "highlightWeb": "",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 15,
            "salePriceTerrace": 15,
            "salePriceTable": 15,
            "salePricePUOnline": 15,
            "salePriceDEOnline": 15,
            "source": "LWCKP",
            "points": 0,
            "erpID": "PSPE",
            "shortName": "Pepperoni",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PSPE",
                "invSaleItemID": "023",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.025,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSPE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSPE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSPE",
                "invSaleItemID": "FGPZT1042",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          },
          {
            "id": "PSVE",
            "description": "Vegetarian Small",
            "salePrice": 15,
            "familyGroupID": 33,
            "majorGroupID": 19,
            "toppingsEq": 0,
            "salePriceDelivery": 15,
            "orderType": "LDRVCE",
            "noHalfNDHalf": "S",
            "webDescription": "Maestro Sauce, Cheese Mix, Tomato, Olives, Onion, Green Pepper and Mushroom ",
            "order": 4,
            "allergens": "00000000000000",
            "highlightWeb": "V",
            "stockControlType": "S",
            "maxToppings": 0,
            "promotedToppingsQtty": 0,
            "enabled": "S",
            "salePriceCounter": 15,
            "salePriceTerrace": 15,
            "salePriceTable": 15,
            "salePricePUOnline": 15,
            "salePriceDEOnline": 15,
            "source": "LWCKP",
            "points": 0,
            "erpID": "PSVE",
            "shortName": "Vegetarian",
            "calories": 0,
            "portions": 8,
            "favoriteToppings": null,
            "recipe": [
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "033",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "043",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.01,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "059",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.012,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "060",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "061",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.013,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "601",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.02,
                "allergens": "00000000000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "671",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 0.05,
                "allergens": "00000001000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              },
              {
                "saleItemID": "PSVE",
                "invSaleItemID": "FGPZT1042",
                "indexNbr": 1,
                "required": "S",
                "orderType": "T",
                "quantity": 1,
                "allergens": "00000101000000",
                "critical": "S",
                "charge": "N",
                "yield": 100
              }
            ]
          }
        ],
        "toppings": []
      }
    ],
    "toppingGroups": [
      {
        "id": 1,
        "description": "Dough",
        "webDescription": "Dough",
        "minToppings": 1,
        "maxToppings": 1,
        "maxFreeToppings": 0,
        "highlightChangesKitchen": "N",
        "sameToppingHalves": "N"
      },
      {
        "id": 2,
        "description": "Sauces",
        "webDescription": "Sauces",
        "minToppings": 0,
        "maxToppings": 2,
        "maxFreeToppings": 0,
        "highlightChangesKitchen": "N",
        "sameToppingHalves": "N"
      },
      {
        "id": 3,
        "description": "Toppings",
        "webDescription": "Toppings",
        "minToppings": 0,
        "maxToppings": 15,
        "maxFreeToppings": 0,
        "highlightChangesKitchen": "N",
        "sameToppingHalves": "N"
      },
      {
        "id": 4,
        "description": "Swirl",
        "webDescription": "Swirl",
        "minToppings": 0,
        "maxToppings": 2,
        "maxFreeToppings": 0,
        "highlightChangesKitchen": "N",
        "sameToppingHalves": "N"
      }
    ]
  }

export const staticPromotions: any[] = [
    {
      "id": "07MISS10SAR",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-08-01",
      "endingDate": "2090-08-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "S",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "07MISY10",
      "promoType": "descuento",
      "description": "5 SAR Discount",
      "salePrice": 5,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "5 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 25,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "08MISO87",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 20,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "08MISS10SAR",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-08-01",
      "endingDate": "2090-08-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "09MISB93",
      "promoType": "descuento",
      "description": "15 SAR Discount",
      "salePrice": 15,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "15 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 15,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "09MISS10SAR",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-08-01",
      "endingDate": "2090-08-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "2L30",
      "promoType": "acumulable",
      "description": "2 large for 30 SAR Each",
      "salePrice": 0,
      "startingDate": "2021-07-04",
      "endingDate": "2021-12-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "D",
      "webDescription": "2 Large pizza of 30sar each",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 1,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 6,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 6,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "2L30E",
      "promoType": "acumulable",
      "description": "2 Large for 30 Each",
      "salePrice": 0,
      "startingDate": "2021-06-20",
      "endingDate": "2021-12-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "R",
      "webDescription": "Buy 2 Large for 30 SAR each.  If you choose any of Chef's selections pizzas +6 SAR. Cheesy Crust, Double Cheddar, and brown dough +6 SAR ",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 0,
      "source": "WP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "S",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 6,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 6,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        }
      ]
    },
    {
      "id": "2LP25",
      "promoType": "acumulable",
      "description": "2 Large Pizzas at 25 SAR each",
      "salePrice": 0,
      "startingDate": "2021-11-21",
      "endingDate": "2021-11-24",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "R",
      "webDescription": "Promo",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "W",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 11,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 11,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "3M85",
      "promoType": "acumulable",
      "description": "Medium Combo ",
      "salePrice": 0,
      "startingDate": "2020-09-22",
      "endingDate": "2043-09-22",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Your choice of 3 Medium pizza and 2.25 Liter Coke. If you choose any of Chef Selection, Cheesy Crust, double cheddar and brown dough + 6SAR",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "C",
      "hidden": "N",
      "order": 5,
      "source": "WCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "29",
          "saleItemID2": "2",
          "saleItemID3": "32",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "29",
          "saleItemID2": "2",
          "saleItemID3": "32",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "M",
          "saleItemID": "29",
          "saleItemID2": "2",
          "saleItemID3": "32",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        },
        {
          "lineNbr": 3,
          "applicable": "F",
          "saleItemID": "22",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 2,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of drink"
        }
      ]
    },
    {
      "id": "5BOGP21",
      "promoType": "acumulable",
      "description": "BOGO+5",
      "salePrice": 0,
      "startingDate": "2021-11-17",
      "endingDate": "2021-11-23",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "R",
      "webDescription": "Promo",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 31,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "ACA4TEN",
      "promoType": "descuento",
      "description": "15 SAR Discount",
      "salePrice": 15,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "15 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 15,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "ACT4TEN",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-07-22",
      "endingDate": "2090-08-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "ATXYSM2",
      "promoType": "descuento",
      "description": "5 SAR Discount",
      "salePrice": 5,
      "startingDate": "2018-07-29",
      "endingDate": "2090-08-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "5 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "ATXYSM3",
      "promoType": "descuento",
      "description": "5 SAR Discount",
      "salePrice": 5,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "5 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 25,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "BOGOAGG",
      "promoType": "acumulable",
      "description": "BOGO",
      "salePrice": 0,
      "startingDate": "2021-11-18",
      "endingDate": "2021-11-24",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "\r\n",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "W",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "CAULI35",
      "promoType": "acumulable",
      "description": "Fresh Rocca- Cauliflower Dough",
      "salePrice": 0,
      "startingDate": "2021-08-05",
      "endingDate": "2090-08-06",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "LDRV",
      "webDescription": "Medium Rocca with Cauliflower Dough, get it with Water or Diet Coke or Orange Juice",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 0,
      "source": "LWCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "S",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "I",
          "saleItemID": "FGPZT2058",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "F",
          "saleItemID": "10",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": ";FGBVC0011;FGBET1026;FGBET1022;",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "COLA",
      "promoType": "acumulable",
      "description": "Cola Combo",
      "salePrice": 0,
      "startingDate": "2021-06-11",
      "endingDate": "2099-06-30",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "N",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Your choice of 1 large pizza and 1 liter Coca Cola or Sprite. If you choose any of chef selection, cheese crust, double cheddar and brown dough +6SAR",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 1,
      "source": "WP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "F",
          "saleItemID": "3",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "F",
          "saleItemID": "21",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "COMBODIY",
      "promoType": "acumulable",
      "description": "DIY",
      "salePrice": 0,
      "startingDate": "2021-06-29",
      "endingDate": "2030-06-29",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "2 Large pizza, side dish and 2.25 coca cola for 95 SR. If you choose any of Chef's selections pizzas +6 SAR. Cheesy Crust, Double Cheddar, and brown dough +6 SAR",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 7,
      "source": "LWCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -11,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGCMA8008;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 3,
          "applicable": "F",
          "saleItemID": "22",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "COMBOMIDNIGHT",
      "promoType": "acumulable",
      "description": "Midnight Combo",
      "salePrice": 0,
      "startingDate": "2021-06-29",
      "endingDate": "2030-06-29",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "Your choice of 2 medium pizzas, 2 appetizers, 1 dessert, and 1 liter coca cola, Chef Selection +6 SAR, Cheesy Crust and Double Cheddar and brown dough +6 SAR",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 6,
      "source": "LWCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "29",
          "saleItemID2": "29",
          "saleItemID3": "2",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -30,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "29",
          "saleItemID2": "29",
          "saleItemID3": "2",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -27,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 3,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 4,
          "applicable": "F",
          "saleItemID": "13",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGPZT2044;FGDST2006;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 5,
          "applicable": "F",
          "saleItemID": "21",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "COMBOPARTY",
      "promoType": "acumulable",
      "description": "Build your own Party Combo",
      "salePrice": 0,
      "startingDate": "2021-06-29",
      "endingDate": "2030-06-29",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "Your choice of 2 large pizzas, 2 Appetizers, 1 desserts, and 2.25 liter Coca Cola. If you choose any of Chef's selections pizzas +6 SAR. Cheesy Crust and Double Cheddar and brown dough +6 SAR",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 5,
      "source": "LWCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -23,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -23,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 3,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 4,
          "applicable": "F",
          "saleItemID": "13",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGPZT2044;FGDST2006;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 5,
          "applicable": "F",
          "saleItemID": "22",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "COMBOSUPER",
      "promoType": "acumulable",
      "description": "Build your own Super Combo",
      "salePrice": 0,
      "startingDate": "2021-06-29",
      "endingDate": "2030-06-29",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "Your choice of 3 Large Pizzas, 2 Appetizers, 2 Desserts, and 2.25 Coca Cola. If you choose any of Chef's selections pizzas +6 SAR. Cheesy Crust and Double Cheddar and brown dough +6 SAR",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 8,
      "source": "LWCKP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -20,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -20,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "26",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": -15,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 3,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGCMA8008;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 4,
          "applicable": "F",
          "saleItemID": "8",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGCMA8008;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 5,
          "applicable": "F",
          "saleItemID": "13",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGPZT2044;FGDST2006;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 6,
          "applicable": "F",
          "saleItemID": "13",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": ";FGPZT2044;FGDST2006;",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 7,
          "applicable": "F",
          "saleItemID": "22",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "DELIVERY",
      "promoType": "descuento",
      "description": "Free delivery above 60",
      "salePrice": 18,
      "startingDate": "2020-03-18",
      "endingDate": "2021-12-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "D",
      "webDescription": "Free delivery above 60",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 60,
      "wizard": "P",
      "hidden": "S",
      "order": 55,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "DFGYIV3",
      "promoType": "descuento",
      "description": "10 SAR Discount",
      "salePrice": 10,
      "startingDate": "2018-09-05",
      "endingDate": "2090-09-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "10 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 20,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "DFGYUV3",
      "promoType": "descuento",
      "description": "6 SAR Discount",
      "salePrice": 6,
      "startingDate": "2018-07-29",
      "endingDate": "2090-08-04",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DRV",
      "webDescription": "6 SAR Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 30,
      "wizard": "C",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": 0,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": []
    },
    {
      "id": "GAMED",
      "promoType": "acumulable",
      "description": "2 Large pizza 49 SAR",
      "salePrice": 0,
      "startingDate": "2021-11-11",
      "endingDate": "2021-11-30",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Promo",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 23,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "LOYAL",
      "promoType": "acumulable",
      "description": "Maestro Loyalty Program",
      "salePrice": 0,
      "startingDate": "2017-01-03",
      "endingDate": "2090-07-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 60,
      "orderType": "DR",
      "webDescription": "Get a free medium pizza for every 6 loyalty slices. Only for registered customers.",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 50,
      "source": "WP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "32",
          "saleItemID2": "2",
          "saleItemID3": "29",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 25,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Medium pizza"
        }
      ]
    },
    {
      "id": "LRANCHBOTTLE",
      "promoType": "acumulable",
      "description": "Pizza Ranch Botle",
      "salePrice": 0,
      "startingDate": "2018-12-19",
      "endingDate": "2099-06-30",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "N",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Buy a large and get a Ranch Bottle",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "N",
      "order": 4,
      "source": "WP",
      "promoCodes": "",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "S",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "F",
          "saleItemID": "3",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 12,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Large pizza"
        },
        {
          "lineNbr": 1,
          "applicable": "I",
          "saleItemID": "FGSBC0001",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Ranch sauce bottle for free"
        }
      ]
    },
    {
      "id": "NOON",
      "promoType": "acumulable",
      "description": "20% Discount",
      "salePrice": 20,
      "startingDate": "2021-06-11",
      "endingDate": "2021-12-31",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "S",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "R",
      "webDescription": "20 % Discount",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "C",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 20,
          "discountType": "%",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "NOON30",
      "promoType": "descuento",
      "description": "30% off ON 50 SAR+",
      "salePrice": 30,
      "startingDate": "2021-11-22",
      "endingDate": "2021-11-28",
      "validityDates": "D",
      "byPrice": "%",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "R",
      "webDescription": "Promo\r\n",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 50,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "W",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": []
    },
    {
      "id": "PHBOGO",
      "promoType": "acumulable",
      "description": "BOGO",
      "salePrice": 0,
      "startingDate": "2021-11-18",
      "endingDate": "2021-11-24",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Promo",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "N",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "RW2021",
      "promoType": "descuento",
      "description": "Riyadh Wheelers 2021 Season",
      "salePrice": 15,
      "startingDate": "2021-11-11",
      "endingDate": "2022-04-15",
      "validityDates": "D",
      "byPrice": "%",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Promo",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": []
    },
    {
      "id": "UNIBOGO",
      "promoType": "acumulable",
      "description": "BOGO + 5",
      "salePrice": 0,
      "startingDate": "2021-11-20",
      "endingDate": "2021-11-25",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "S",
      "loyaltyPoints": 0,
      "orderType": "DR",
      "webDescription": "Promo",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "P",
      "hidden": "S",
      "order": 0,
      "source": "WP",
      "promoCodes": "*",
      "familyGroupWebShow": null,
      "onePerCustomer": "N",
      "showWebHome": "N",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": "C",
      "crossQuantityAsk": "N",
      "crossMultiple": "N",
      "crossShowAlways": "N",
      "crossValidDays": 0,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "M",
          "saleItemID": "26",
          "saleItemID2": "3",
          "saleItemID3": "3",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 15,
          "chargeLine": "S",
          "discountAmount": 31,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        }
      ]
    },
    {
      "id": "WOWNUG",
      "promoType": "pack",
      "description": "Kids Meal Chicken Bites",
      "salePrice": 18,
      "startingDate": "2020-05-20",
      "endingDate": "2090-05-22",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "N",
      "loyaltyPoints": 0,
      "orderType": "DRVCE",
      "webDescription": "7 pieces of chicken bites served with Smiley Potatoes, your choice of a drink and a toy",
      "holidays": "S",
      "holidayEve": "S",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "C",
      "hidden": "N",
      "order": 15,
      "source": "LWCKARMP",
      "promoCodes": "",
      "familyGroupWebShow": 33,
      "onePerCustomer": "N",
      "showWebHome": "S",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "I",
          "saleItemID": "FGCMA8002",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "F",
          "saleItemID": "10",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": ";FGBET1026;FGBVT0008;FGBVC0011;",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of drink"
        },
        {
          "lineNbr": 3,
          "applicable": "I",
          "saleItemID": "FGAPT2028",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Chicken bites"
        }
      ]
    },
    {
      "id": "WOWPIZ",
      "promoType": "pack",
      "description": "Kids Meal Small Pizza",
      "salePrice": 18,
      "startingDate": "2020-05-13",
      "endingDate": "2090-05-22",
      "validityDates": "D",
      "byPrice": "D",
      "onePerOrder": "N",
      "recurringPromo": 0,
      "daysOfWeek": "LMXJVSD",
      "hourlyRestriction": "N",
      "startingTime1": "00:00:00",
      "endingTime1": "00:00:00",
      "startingTime2": "00:00:00",
      "endingTime2": "00:00:00",
      "manuallyAdded": "N",
      "loyaltyPoints": 0,
      "orderType": "DRVCE",
      "webDescription": "Small Pizza (Margarita, Vegetarian , Pepperoni or Chicken) Served with French fries, your choice of a drink and toy.",
      "holidays": "N",
      "holidayEve": "N",
      "chargeExtraToppings": "N",
      "minimumOrderAmount": 0,
      "wizard": "C",
      "hidden": "N",
      "order": 16,
      "source": "LWCKARMP",
      "promoCodes": "",
      "familyGroupWebShow": 33,
      "onePerCustomer": "N",
      "showWebHome": "S",
      "crossType": null,
      "crossFindType": null,
      "crossFindCode": null,
      "crossSuggestIn": null,
      "crossQuantityAsk": null,
      "crossMultiple": null,
      "crossShowAlways": null,
      "crossValidDays": null,
      "lines": [
        {
          "lineNbr": 0,
          "applicable": "I",
          "saleItemID": "FGCMA8001",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": "",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": ""
        },
        {
          "lineNbr": 1,
          "applicable": "F",
          "saleItemID": "33",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": ";PSMA;PSVE;PSPE;FGPZT1054;",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of Kids pizza"
        },
        {
          "lineNbr": 2,
          "applicable": "F",
          "saleItemID": "10",
          "saleItemID2": "",
          "saleItemID3": "",
          "minToppingsQtty": 0,
          "maxToppingsQtty": 9,
          "chargeLine": "S",
          "discountAmount": 0,
          "discountType": "D",
          "includedItemsID": ";FGBET1026;FGBVT0008;FGBVC0011;",
          "excludedItemsID": "",
          "includedToppingsID": "",
          "excludedToppingsID": "",
          "webDescription": "Your selection of drink"
        }
      ]
    }
  ]

export const BrancPickupDeliveryAndZoneDefault = {
    "delivery": {
      "zone": {
        "id": 6,
        "areaID": "5",
        "stDirectoryID": 1,
        "description": "Malga",
        "deliveryFeeItem": "DELIVERY",
        "city": "Riyadh - الرياض",
        "province": "Riyadh - الرياض",
        "maxAmountDeliveryFee": 0,
        "deliveryFeeAmount": 17,
        "locationDistance": 200,
        "deliveryFeeItem2": null,
        "webDeliveryFeeItem": "DELIVERY20",
        "webMaxAmountDeliveryFee": 40,
        "webDeliveryFeeItem2": "DELIVERY"
      },
      "branch": {
        "id": 5,
        "name": "Malqa",
        "streetName": "King Fahed Road",
        "stNumber": "",
        "city": "Riyadh - الرياض",
        "phoneNbr": "920017777",
        "minAmountDelivery": 60,
        "minAmountPickup": 0,
        "opened": 1,
        "maxAmountGateway": 1000,
        "lat": 24.796069,
        "lng": 46.62901,
        "deliveryTime": "00:30:00",
        "pickupTime": "00:10:00",
        "serviceType": "DRC",
        "timezoneName": "Asia/Riyadh",
        "salePricePickupTakeaway": "L",
        "isAlive": "N",
        "recentActivity": "N",
        "opening1": "2021-05-25T11:00:00.000Z",
        "opening2": "2021-05-25T19:00:00.000Z",
        "closing1": "2021-05-25T22:00:00.000Z",
        "closing2": "2021-05-25T19:00:00.000Z"
      }
    },
    "pickup": {
      "id": 5,
      "name": "Malqa",
      "streetName": "King Fahed Road",
      "stNumber": "",
      "city": "Riyadh - الرياض",
      "phoneNbr": "920017777",
      "minAmountDelivery": 60,
      "minAmountPickup": 0,
      "opened": 1,
      "maxAmountGateway": 1000,
      "lat": 24.796069,
      "lng": 46.62901,
      "deliveryTime": "00:30:00",
      "pickupTime": "00:10:00",
      "serviceType": "DRC",
      "timezoneName": "Asia/Riyadh",
      "salePricePickupTakeaway": "L",
      "isAlive": "N",
      "recentActivity": "N",
      "opening1": "2021-05-25T11:00:00.000Z",
      "opening2": "2021-05-25T19:00:00.000Z",
      "closing1": "2021-05-25T22:00:00.000Z",
      "closing2": "2021-05-25T19:00:00.000Z",
      "distance": 0
    }
  };