import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { environment } from '@environments/environment';

export interface OnActionEventInterface {
  action: 'addProduct' | 'addQty' | 'reduceQty',
  quantity: number
}

@Component({
  selector: 'app-button-add-quantity-cart',
  templateUrl: './button-add-quantity-cart.component.html',
  styleUrls: ['./button-add-quantity-cart.component.scss'],
})
export class ButtonAddQuantityCartComponent implements OnInit {

  qty: number = 1
  assets: string = environment.assetsUrl
  @Input() set quantity(value: number) {
    this.qty = value ? value : 1
  }
  @Output() onAction: EventEmitter<OnActionEventInterface> = new EventEmitter()
  constructor() {

  }

  ngOnInit() {
  }

  addProduct() {
    this.onAction.emit({
      action: 'addProduct',
      quantity: this.qty
    })
  }

  addQty() {
    this.qty++
    this.onAction.emit({
      action: 'addQty',
      quantity: this.qty
    })
  }

  reduceQty() {
    if (this.qty > 1) {
      this.qty--
    }
    this.onAction.emit({
      action: 'reduceQty',
      quantity: this.qty
    })
  }

}
