import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { FormMetadataButtonInterface, FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { buttonRegisterRegister,fieldsFullRegister,buttonConfirmRegister,onlyPassRegister,fieldsOnlyPhone } from '../login-form/forms';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  @Input() registerType: string; // full,PhoneAutoComplete,onlyPass
  @Input() validatePhone: any; // data passed to component
  @Output() onFormRegisterSubmited: EventEmitter<any> = new EventEmitter()
 
  buttonRegisterRegister: FormMetadataButtonInterface[];
  buttonConfirmRegister: FormMetadataButtonInterface[];
  fieldsFullR: FormMetadataFieldInterface[];
  fieldsOnlyPass: FormMetadataFieldInterface[];
  fieldsOnlyPhone: FormMetadataFieldInterface[];

  formMetadata: FormMetadata
  assets: string = environment.assetsUrl;
  constructor(private _router: Router) {

    this.fieldsFullR = fieldsFullRegister;
    this.buttonRegisterRegister = buttonRegisterRegister;

    this.fieldsOnlyPass = onlyPassRegister;
    this.buttonConfirmRegister = buttonConfirmRegister;

    this.fieldsOnlyPhone = fieldsOnlyPhone;

  }

  ngOnInit() {
    this.formMetadata = this.getFormConfig();
    // console.log(this.validatePhone);
  }

  private getFormConfig(): FormMetadata {

    if (this.registerType == 'full') {
      return new FormMetadata({
        buttons: this.buttonRegisterRegister,
        commitMode: 'submit',
        fields: this.fieldsFullR,
        focusOnFirstElement: true,
        formName: 'registerForm',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("registerForm", "submit", false, this.buttonRegisterRegister, [], this.fieldsFullR, true)
    }

    if (this.registerType == 'onlyPass') {
      return new FormMetadata({
        buttons: this.buttonConfirmRegister,
        commitMode: 'submit',
        fields: this.fieldsOnlyPass,
        formName: 'onlyPass',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("onlyPass", "submit", false, this.buttonConfirmRegister, [], this.fieldsOnlyPass)
    }

    if (this.registerType == 'PhoneAutoComplete') {
      this.fieldsFullR.forEach(field => {
          if(field.id == 'phone'){
            field.value = this.validatePhone.data.withoutCCode;
            field.disabled = true;
            field.isReadOnly = true;
            field.class = "hideCountryFlags";
          }
      });
      return new FormMetadata({
        buttons: this.buttonRegisterRegister,
        commitMode: 'submit',
        fields: this.fieldsFullR,
        formName: 'PhoneAutoComplete',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("PhoneAutoComplete", "submit", false, this.buttonRegisterRegister, [], this.fieldsFullR)
    }

    if (this.registerType == 'EmailAutoComplete') {
      this.fieldsFullR.forEach(field => {
          if(field.id == 'email'){
            field.placeholder = this.validatePhone.data.email;
            field.disabled = true;
          }
      });
      return new FormMetadata({
        buttons: this.buttonRegisterRegister,
        commitMode: 'submit',
        fields: this.fieldsFullR,
        formName: 'EmailAutoComplete',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("EmailAutoComplete", "submit", false, this.buttonRegisterRegister, [], this.fieldsFullR)
    }

    if (this.registerType == 'registerSocial') {
      return new FormMetadata({
        buttons: this.buttonRegisterRegister,
        commitMode: 'submit',
        fields: this.fieldsOnlyPhone,
        formName: 'registerSocial',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("registerSocial", "submit", false, this.buttonRegisterRegister, [], this.fieldsOnlyPhone)
    }

  }

  public SubmitRegister(event): void {
    // console.log(event);
    let data: any = {};
    data.registerType = this.registerType;
    data.country = event.value.country;

    if (this.registerType == 'full') {
       data.phone = event.value.phone;
       data.email = event.value.email ? event.value.email : false;
       data.password = event.value.password;
    }

    if (this.registerType == 'PhoneAutoComplete') {
      data.email = event.value.email ? event.value.email : false;
      data.password = event.value.password;
    }

    if (this.registerType == 'onlyPass') {
        data.password = event.value.password;
    }

    if (this.registerType == 'EmailAutoComplete') {
      data.email = this.validatePhone.data.email;
      data.password = event.value.password;  
      data.phone = event.value.phone;  
    }

    if (this.registerType == 'registerSocial') {
      data.phone = event.value.phone;  
    }

    this.onFormRegisterSubmited.emit(data);
  }

  goToMenu(): void {
    this._router.navigate(['/menu']);
  }





}
