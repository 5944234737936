import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { environment } from '@environments/environment';
import { LangService } from '@services/lang.service';
import { fieldsLoginPhone, buttonLogin, buttonPinQitaf, fieldsPinQitaf } from '@core/components/login-form/forms';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-qitaf',
  templateUrl: './qitaf.component.html',
  styleUrls: ['./qitaf.component.scss'],
})
export class QitafComponent implements OnInit {

  private LanguageCode: string;
  private RequestId: string;
  private RequestDate: string;
  private MSISDN: number;
  private Pin: number;

  private retryRedeem: any;
  public srChoice: number = 0;
  public srToChoice = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]

  @Input() actionToDo: string; // maestroCredit or qitafRedeem

  public page = {
    step: 'qitafOTP', // qitafOTP, maestroCredit, qitafRedeem
    msg: '',
    showMsg: false,
    errorMsg: false,
    successMsg: false
  }

  formMetadata: FormMetadata
  formMetadataPin: FormMetadata


  constructor(
    private readonly _api: ApiService,
    private langService: LangService,
    private modalService: ModalService
  ) {
    this.LanguageCode = this.langService.getCurrentLang() == 'en' ? 'en-US' : 'ar-SA';
    this.retryRedeem = false;

  }

  ngOnInit() {
    this.formMetadata = new FormMetadata({
      buttons: buttonLogin,
      commitMode: 'submit',
      fields: fieldsLoginPhone,
      focusOnFirstElement: true,
      formName: 'enterPhoneForm',
      groups: [],
      isReadOnly: false
    })

    this.formMetadataPin = new FormMetadata({
      buttons: buttonPinQitaf,
      commitMode: 'submit',
      fields: fieldsPinQitaf,
      formName: 'enterPinForm',
      groups: [],
      isReadOnly: false
    })


  }

  public startAgain() {
    this.page = {
      step: 'qitafOTP',
      msg: '',
      showMsg: false,
      errorMsg: false,
      successMsg: false
    }
    this.retryRedeem = false;
  }

  private qitafOTP() {

    this.RequestId = this.guid();
    this.RequestDate = this.generateRequestData();

    let data = {
      sandbox: !environment.production,
      BranchId: environment.gateWays.qitaf.BranchId,
      TerminalId: environment.gateWays.qitaf.TerminalId,
      RequestId: this.RequestId,
      MSISDN: this.MSISDN,
      LanguageCode: this.LanguageCode,
      RequestDate: this.RequestDate
    }
    this._api.qitafOTP(data).subscribe(data => {

      let res = data.response[0];
      let success = res.success;
      let ResponseCode = res.ResponseCode;
      let ResponseText = res.ResponseText;

      let msg = this.getErrorQitaf(ResponseCode, "qitafOTP", ResponseText);

      if (success) {
        this.page.step = this.actionToDo;
        this.successMsg(msg);
      } else {
        this.errorMsg(msg);
      }

    }, error => {
      console.log(error);
    });
  }

  public qitafRedeem(retryRedeem = false) {

    let data;

    if (!retryRedeem) {

      this.RequestId = this.guid();
      this.RequestDate = this.generateRequestData();

      data = {
        sandbox: !environment.production,
        BranchId: environment.gateWays.qitaf.BranchId,
        TerminalId: environment.gateWays.qitaf.TerminalId,
        RequestId: this.RequestId,
        RequestIdReverse: this.guid(),
        MSISDN: this.MSISDN,
        LanguageCode: this.LanguageCode,
        RequestDate: this.RequestDate,
        Pin: this.Pin,
        Amount: this.srChoice,
        Payment: this.actionToDo == 'payment',
        OrderCode: 0
      }

    } else {
      data = retryRedeem;
      data.Pin = this.Pin;
      data.Amount = this.srChoice
    }

    this._api.qitafRedeem(data).subscribe(dataRes => {

      let res = dataRes.response[0];
      let success = res.success;
      let ResponseCode = res.ResponseCode;
      let ResponseText = res.ResponseText;
      let reversed = res.reversed;
      let restartTransaction = res.restartTransaction;

      let msg = this.getErrorQitaf(ResponseCode, "qitafRedeem", ResponseText);

      // console.log(res, success, msg);

      if (success) {

        if (dataRes.Payment) {
          // tracking order
        } else {
          // wallets
        }

      } else if (reversed || restartTransaction) {
        this.showError(msg);
        this.startAgain();
      } else if (!restartTransaction) {
        this.errorMsg(msg);
        this.retryRedeem = data;
      }


    }, error => {
      console.log(error);
    });
  }

  private guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  private generateRequestData() {

    var today = new Date();
    var mm = today.getMonth() + 1;
    var dd = today.getDate();
    var hh = today.getHours();
    var mmi = today.getMinutes();
    var ss = today.getSeconds();
    return [today.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-') + "T" + (hh > 9 ? '' : '0') + hh + ":" + (mmi > 9 ? '' : '0') + mmi + ":" + (ss > 9 ? '' : '0') + ss;

  }

  private getErrorQitaf(errorCode, FUNC, returned) {
    errorCode = errorCode.toString();
    switch (FUNC) {
      case "qitafOTP":
        switch (errorCode) {
          case "0":
            return "LANG_OTP_0";
          case "1030":
            return "LANG_OTP_1030";
          case "1019":
            return "LANG_OTP_1019";
          case "1109":
            return "LANG_OTP_1109";
          case "0001":
            return "LANG_OTP_0001";
          case "2310":
            return "LANG_OTP_2310";
          case "2311":
            return "LANG_OTP_2311";
          default:
            return returned;
        }
      case "qitafRedeem":
        switch (errorCode) {
          case "0":
            return "LANG_REDEEM_0";
          case "1007":
            return "LANG_REDEEM_1007";
          case "1015":
            return "LANG_REDEEM_1015";
          case "1030":
            return "LANG_REDEEM_1030";
          case "1014":
            return "LANG_REDEEM_1014";
          case "1050":
            return "LANG_REDEEM_1050";
          case "1012":
            return "LANG_REDEEM_1012";
          case "1040":
            return "LANG_REDEEM_1040";
          case "1109":
            return "LANG_REDEEM_1109";
          case "2310":
            return "LANG_REDEEM_2310";
          case "2311":
            return "LANG_REDEEM_2311";
          case "0001":
            return "LANG_REDEEM_0001";
          default:
            return returned;
        }
      case "ReverseQitafPointRedemption":
        switch (errorCode) {
          case "0":
            return "LANG_REVERSE_0";
          case "1040":
            return "LANG_REVERSE_1040";
          case "1018":
            return "LANG_REVERSE_1018";
          case "1021":
            return "LANG_REVERSE_1021";
          case "1109":
            return "LANG_REVERSE_1109";
          default:
            return returned;
        }
      default:
        return "LANG_QITAF_TIME_OUT";
    }
  }

  private clearErrors() {
    this.page.showMsg = false;
    this.page.successMsg = false;
    this.page.errorMsg = false;
    this.page.msg = '';
  }

  private successMsg(msg: string) {
    this.page.showMsg = true;
    this.page.successMsg = true;
    this.page.errorMsg = false;
    this.page.msg = msg;
  }

  private errorMsg(msg: string) {
    this.page.showMsg = true;
    this.page.errorMsg = true;
    this.page.successMsg = false;
    this.page.msg = msg;

  }

  private showError(msg: string) {
    this.modalService.openModalWithCustomMsg(msg).then(
      () => { },
      () => { }
    )
  }

  public onForSubmitPhone(event): void {

    let phone = Number(event.value.phone);

    if (event.valid === true) {
      this.MSISDN = phone;
      this.qitafOTP();
    }

  }

  public onForSubmitPin(event): void {

    let pin = Number(event.value.pin);

    if (event.valid === true) {
      this.Pin = pin;
      this.qitafRedeem(this.retryRedeem);
    }

  }

  public handleSRChoice(event) {
    this.srChoice = Number(event.detail.value); // SR
  }

}
