import { Component, Input, OnInit } from '@angular/core';
import { FormErrorsListInterface, FormMetadataFieldInterface } from '../common/interfaces';
import { FormType } from '../common/types';
import { kiosk } from '@core/constants';


@Component({
  selector: 'ng-ionic-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {
  @Input() set errors(e: FormErrorsListInterface) {
    this.errorsList = e
  }
  @Input() set form(f: FormType) {
    this.errorForm = f
  }
  @Input() set formSubmitted(s: boolean) {
    this.isFormSubmitted = s
  }
  @Input() field: FormMetadataFieldInterface
  errorForm: FormType
  errorsList: FormErrorsListInterface
  isFormSubmitted: boolean = false
  isKiosk: boolean = kiosk
  kioskBranch: any
  
  constructor( ) { 

  }
  ngOnInit(): void { }
  trackByFn(index: number, element: any): number {
    return index
  }
}
