import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-create-your-own',
  templateUrl: './create-your-own.component.html',
  styleUrls: ['./create-your-own.component.scss'],
})
export class CreateYourOwnComponent implements OnInit {
  assets: string = environment.assetsUrl;
  constructor() { }

  ngOnInit() {}

}