import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { kiosk } from '@core/constants';


@Component({
  selector: 'app-button-add-menu',
  templateUrl: './button-add-menu.component.html',
  styleUrls: ['./button-add-menu.component.scss'],
})
export class ButtonAddMenuComponent implements OnInit {

  @Input() product: any;
  @Input() subGroup: any
  @Input() set loyalty(value: boolean) {
    this.isLoyalty = value ? value : false
  }

  @Input() set points(value: any) {
    this.pointsLoyalty = value.points ? value.points : 0
    this.productPoints = value.pointsRequired
    if(this.pointsLoyalty >= this.productPoints){
      this.canAdd = true
   }else{
    this.canAdd = false
   }

  }


  @Output() showCustomizer = new EventEmitter();
  pointsLoyalty: number 
  productPoints: number 
  isLoyalty: boolean = false
  canAdd: boolean = true
  isKiosk: boolean = kiosk
  kioskBranch: any

  constructor() {
   
  }

  ngOnInit() {

  }



  changeData() {
  }


}
