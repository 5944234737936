import { environment } from '@environments/environment';
import { MenuGetGroupAndSubGroupFromFamilyInterface, PgCustomizer, PgMenu, CreateOrderLineItemsInterface } from '@lib/utils';
import { MenuInterface, ProductCalculatedResponse, } from '@lib/promo-engine';

export function setImageIDtoMenu(menu: MenuInterface): MenuInterface {
    let assetsUrl: string = environment.assetsUrl
    let pgCustomizer = new PgCustomizer(null, null, menu, assetsUrl)
    let pgMenu = new PgMenu(menu, [], true)
    menu.families.forEach(
        value => {
            pgCustomizer.updateFamily(value)
            let data: MenuGetGroupAndSubGroupFromFamilyInterface = pgMenu.getGroupAndSubGroupFromFamily(value.id)
            value.products.forEach(
                v => {
                    pgCustomizer.updateProduct(v)
                    let items: CreateOrderLineItemsInterface = pgCustomizer.getItemsToCreateOrderLine(1)
                    let productCalculated: ProductCalculatedResponse = pgCustomizer.calculateProduct(
                        null,
                        menu,
                        items.productID,
                        items.toppings
                    )
                    v.allergens = productCalculated.allergens
                    v.imageID = pgCustomizer.getCurrentProductImageID(data.subGroup)
                }
            )
        }
    )
    return menu
}
export function isMaestro(): boolean {
    const pgClient: number = environment.gateWays.checkout.client
    return (pgClient === 488 || pgClient === 48801)
}
export function isGroundr(): boolean {
    const pgClient: number = environment.gateWays.checkout.client
    return (pgClient === 48805)
}