import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';
import { PlatformService } from '@services/platform.service';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomBrowserService {

  constructor(
    private iab: InAppBrowser,
    public router: Router,
    private platformService: PlatformService
    ) { }

    /* // 'https://s01.pizzagest.info/redirection.html'
    <button id="myButton">Press me</button>
      <script type="text/javascript">
      document.getElementById("myButton").addEventListener("click", function(){
        var message = {status: "success",data:{}};
        webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
      }, false);
      </script>
    */
  public openUrl(url: any,showDone = false, openOtherTab = false) { // 'https://s01.pizzagest.info/redirection.html'

    if (!this.platformService.isWeb()) {

      var ref = this.iab.create(url, '_blank', { toolbar: showDone ? 'yes' : 'no',zoom:'no',location: showDone &&  this.platformService.isAndroid() ? 'yes' : 'no'});
      var that = this;
      ref.on('message').subscribe((event) => {
        const postObject: any = event
        that.processResponse((postObject.data));
        ref.close();
      })

    }else{
      if(openOtherTab){
        window.open(url, '_blank');
      }else{
        window.open(url, '_self');
      }

      // window.location.href = url;
    }
  }

  private processResponse(data: any) {

    // console.log(data);

    let status = data["status"];
    let res = data.data;
    let gWC = this.getUrlParameter(res, 'gWC');

    if (gWC == environment.gateWays.hyperPay.letter) {

      if (status == 'success') {
        let code = this.getUrlParameter(res, 'code');
        let ref = this.getUrlParameter(res, 'ref');
        let rId = this.getUrlParameter(res, 'rId');
        let cC = this.getUrlParameter(res, 'cC');
        let cT = this.getUrlParameter(res, 'cT');
        this.router.navigate(['/paymentvalidation'], { queryParams: { code: code, ref: ref, rId: rId, cC: cC, cT: cT } })
      }

      if (status == 'error') {
        let error = this.getUrlParameter(res, 'error')
        this.router.navigate(['/paymentvalidation'], { queryParams: { error: error } })
      }

    }

    if (gWC == environment.gateWays.checkout.letter) {

      if (status == 'success') {
        let cctype = this.getUrlParameter(res, 'cctype');
        let code = this.getUrlParameter(res, 'code');
        let mask = this.getUrlParameter(res, 'mask');
        let orderId = this.getUrlParameter(res, 'orderId');
        let tokenPay = this.getUrlParameter(res, 'tokenPay');
        this.router.navigate(['/paymentvalidation'], { queryParams: {gWC:gWC, cctype: cctype, code: code, mask: mask, orderId: orderId, tokenPay: tokenPay } })
      }

      if (status == 'error') {
        let error = this.getUrlParameter(res, 'error')
        this.router.navigate(['/paymentvalidation'], { queryParams: { error: error } })
      }

    }
  }

  getUrlParameter = (url, name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(url);
    return results === null ? false : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

}
