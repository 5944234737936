import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import { environment } from '@environments/environment';
import { LangService } from '@services/lang.service';
import { takeUntil } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { IonSlides } from '@ionic/angular';
import { ApiService } from '@services/api.service';
import { DataService } from '@services/data.service';
import { PlatformService } from '@services/platform.service';
import { FaeventsService } from '@services/faevents.service'
import { kiosk } from '@core/constants';

@Component({
  selector: 'app-slider-promos',
  templateUrl: './slider-promos.component.html',
  styleUrls: ['./slider-promos.component.scss'],
})
export class SliderPromosComponent implements OnInit {

  @Input() set promos(value: any) {
    this.promotions = value.sort((a, b) =>
      a.order - b.order
    )
  }

  @Input() set update(value: boolean) {
    if(value === true && this.promotions.length > 0){
      console.log("updated")
      try {
        this.sliderElement.update();
      } catch (e) { }
    }

  }

  @Input() isLateralPromoUser: boolean = false;
  @Input() isAccountPromoUser: boolean = false;
  @Output() toShowPromoDetails = new EventEmitter();

  productsUrl: string = environment.productsUrl
  orientation: string;
  public assets: string = environment.assetsUrl
  private unsubscribe = new Subject<void>()
  @ViewChild('slides') sliderElement: IonSlides;
  language: string = 'ar'
  promotions = []
  windowWidth: number
  slideOpts: {}
  isKiosk: boolean = kiosk
  kioskBranch: any
    
  constructor(
    public langService: LangService,
    private readonly _api: ApiService,
    private readonly dataService: DataService,
    private readonly platformService: PlatformService,
    private faevents: FaeventsService,
    private apiService : ApiService,
    ) {

  }

  ngOnInit(): void {
    
    const langConfig = this.langService.getLangConfig()
    this.orientation = langConfig.dir
    this.language = langConfig.lang

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
        this.language = result.lang
        const langConfig = this.langService.getLangConfig()
        this.orientation = langConfig.dir
      }
    )

    if(!this.platformService.isServer()){
      this.windowWidth = window.innerWidth;

    if(this.isKiosk){

      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.11,
        spaceBetween: 10,
        centeredSlides: true,
      }

    }else if(this.windowWidth > 1400){
      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.2,
        spaceBetween: 10,
        centeredSlides: true,

    
      }
    }else if(this.windowWidth > 1199 && this.windowWidth <= 1399){
      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.16,
        spaceBetween: 10,
        centeredSlides: true,

    
      }
    }else if(this.windowWidth > 991 && this.windowWidth <= 1199){
      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.3,
        spaceBetween: 10,
        centeredSlides: true,

    
      }
    }else if(this.windowWidth > 575 && this.windowWidth <= 991){
      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.35,
        spaceBetween: 1,
        centeredSlides: true,

    
      }
    }
    else{
      this.slideOpts = {
        speed: 5000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        slidesPerView: 1.15,
        spaceBetween: 10,
        centeredSlides: true,
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        on: {
          beforeInit() {
            const swiper = this;
    
            swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
            swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
    
            swiper.params.watchSlidesProgress = true;
            swiper.originalParams.watchSlidesProgress = true;
          },
          setTranslate() {
            const swiper = this;
            const {
              width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
            } = swiper;
            const params = swiper.params.coverflowEffect;
            const isHorizontal = swiper.isHorizontal();
            const transform$$1 = swiper.translate;
            const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
            const rotate = isHorizontal ? params.rotate : -params.rotate;
            const translate = params.depth;
            // Each slide offset from center
            for (let i = 0, length = slides.length; i < length; i += 1) {
              const $slideEl = slides.eq(i);
              const slideSize = slidesSizesGrid[i];
              const slideOffset = $slideEl[0].swiperSlideOffset;
              const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;
    
              let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
              let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
              // var rotateZ = 0
              let translateZ = -translate * Math.abs(offsetMultiplier);
    
              let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
              let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;
    
              // Fix for ultra small values
              if (Math.abs(translateX) < 0.001) translateX = 0;
              if (Math.abs(translateY) < 0.001) translateY = 0;
              if (Math.abs(translateZ) < 0.001) translateZ = 0;
              if (Math.abs(rotateY) < 0.001) rotateY = 0;
              if (Math.abs(rotateX) < 0.001) rotateX = 0;
    
              const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    
              $slideEl.transform(slideTransform);
              $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
              if (params.slideShadows) {
                // Set shadows
                let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
                let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
                if ($shadowBeforeEl.length === 0) {
                  $shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
                  $slideEl.append($shadowBeforeEl);
                }
                if ($shadowAfterEl.length === 0) {
                  $shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
                  $slideEl.append($shadowAfterEl);
                }
                if ($shadowBeforeEl.length) $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
                if ($shadowAfterEl.length) $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0;
              }
            }
    
            // Set correct perspective for IE10
            if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
              const ws = $wrapperEl[0].style;
              ws.perspectiveOrigin = `${center}px 50%`;
            }
          },
          setTransition(duration) {
            const swiper = this;
            swiper.slides
              .transition(duration)
              .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
              .transition(duration);
          }
        }
    
      }
    }
    }
  }

  ionViewWillEnter() {
    
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if( this.windowWidth > 991 ){
        if(event.target.src != this.assets + "images/promonotfound_web.png"){
          event.target.src = this.assets + "images/promonotfound_web.png"
        }
      }else if( this.windowWidth > 575 &&  this.windowWidth <= 991 ){
        if(event.target.src != this.assets + "images/promonotfound_tablet.png"){
          event.target.src = this.assets + "images/promonotfound_tablet.png"
        }
      }else{
        if(event.target.src != this.assets + "images/promonotfound.png"){
          event.target.src = this.assets + "images/promonotfound.png"
        }
      }

    }
  }

  goToPromos(promo) {

    try {
      let evt = {
        item_category: "Promotions",
        promotion_shortname: promo.description,
        id: promo.id,
        short_name: promo.description,
        item_name: promo.sourceName
      };
      this.faevents.preLogEvent("view_item", evt)
    } catch (e) {
      this.apiService.logger("error", "", e, "view_item")
    }
    this._api.eventPg(promo,"promo_click");
    this.dataService.getPromotions().then(response => {
      let promotions = response
      let currentPromotion
      if (this.isLateralPromoUser) {
        currentPromotion = promotions.find(result => result.id === promo.promoID)

        this._api.setData({
          promotion: currentPromotion,
          promoUser: promo
        })
        this.toShowPromoDetails.emit();
      } else {
        currentPromotion = promotions.find(result => result.id === promo.id)
        console.log(promotions)
        console.log(currentPromotion)
        this._api.setData({
          promotion: currentPromotion,
        })
        this.toShowPromoDetails.emit();
      }
    })

  }

  next() {
    this.sendEvt();
    this.sliderElement.slideNext()
  }

  prev() {
    this.sendEvt();
    this.sliderElement.slidePrev()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;


    setTimeout(() => {
      this.sliderElement.update();
    }, 500);
  }

  public onslide() {

  }

  private sendEvt() {
    try {
      this.sliderElement.getActiveIndex().then(pos => {
        let evt = {
          item_category: "Promotions",
          promotion_shortname: this.promotions[pos].description,
          id: this.promotions[pos].id
        };
        this.faevents.preLogEvent("view_item_list", evt)
      });
    } catch (e) {
      this.apiService.logger("error", "", e, "view_item_list")
    }
  }

}
