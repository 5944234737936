import { EventEmitter, Injectable } from '@angular/core';
import { UtilsService } from '@core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class SelectPromiseTimeService {
  private promiseTimes: [];
  private promiseTimeSelected: any;
  private clearPromiseTime: EventEmitter<any> = new EventEmitter();
  private shareDefaultValue: EventEmitter<any> = new EventEmitter();
  private updatePromiseTime: EventEmitter<any> = new EventEmitter();
  private refreshTimeDelivery: EventEmitter<any> = new EventEmitter();

  constructor(
    private utils: UtilsService
  ) {
    this.promiseTimes = [];
    this.promiseTimeSelected = false;
  }

  public newPromiseTimes(promisesTimes) {
    this.promiseTimes = promisesTimes;
    this.promiseTimeSelected = promisesTimes && promisesTimes.length>0 ?this.getPromiseTimesOptions()[0] : false;
    this.shareDefaultValue.emit(this.promiseTimeSelected);
  }

  public getPromiseTimesOptions() {
    let promiseTimesOptions = [];
    this.promiseTimes.forEach(pTime => {
      promiseTimesOptions.push({
        text: this.utils.formatAMPM(new Date(pTime)),
        value: pTime
      });
    });

    return promiseTimesOptions;
  }

  public setPromiseTimeSelected(promiseTimeSelected) {
    this.promiseTimeSelected = promiseTimeSelected;
    if (!promiseTimeSelected) {
        this.cleaPromiseTimeSelected();
    }else{
      this.refreshDeliveryTime();
    }
  }
  public refreshDeliveryTime(){
    this.refreshTimeDelivery.emit(this.promiseTimeSelected);
  }

  public onrefreshDeliveryTime(): EventEmitter<any> {
    return this.refreshTimeDelivery;
  }
  public clearPromiseTimes() {
    this.promiseTimeSelected = false;
    this.promiseTimes = [];
    this.clearPromiseTime.emit({});
  }

  public getPromiseTimeSelected() {
    return this.promiseTimeSelected;
  }

  public cleaPromiseTimeSelected() {
    this.clearPromiseTime.emit({});
  }

  public onClearPromiseTime(): EventEmitter<any> {
    return this.clearPromiseTime;
  }

  public OnDefaultValue(): EventEmitter<any> {
    return this.shareDefaultValue;
  }

  public OnUpdatePromiseTime(): EventEmitter<any> {
    return this.updatePromiseTime;
  }

  public onHideChangeTime(){
    this.promiseTimeSelected = false;
    this.refreshDeliveryTime();
  }

  public updatePromiseTimes(times) {
    // console.log("updatePromiseTimes");
    this.promiseTimes = times;
    let index = 0;
    let pos = 0;
    let find = false;

    this.promiseTimes.forEach(element => {
      if (element == this.promiseTimeSelected.value) {
        find = true;
        pos = index;
      }
      index++;
    });

    this.promiseTimeSelected = times && times.length > 0 ? this.getPromiseTimesOptions()[pos] : false;

    if (!find) {
      // console.log("no find");
      this.updatePromiseTime.emit(this.promiseTimeSelected);
    }

  }

  clearPromisesNoDeliveryTime(){
    this.promiseTimeSelected = false;
    this.promiseTimes = [];
    this.cleaPromiseTimeSelected();
  }

  public resetService(){
    this.promiseTimes = [];
    this.promiseTimeSelected = false;
  }

}
