// Family Product Recipe

export enum MenuPriceTypes {
  Local = 'L',
  Domicilio = 'D',
}

export interface FilterMenuOptions {
  source?: string;
  orderType?: string;
  language?: string;
  group?: number;
}

export interface MenuFamilyProductToppingInterface {
  allergens: string;
  charge: string;
  critical: string;
  indexNbr: number;
  invSaleItemID: string;
  orderType: string;
  quantity: number;
  required: string;
  saleItemID: string;
  yield: number;
}

// Family Product

export interface MenuFamilyProductInterface {
  allergens: string;
  branchID: number;
  calories: number;
  description: string;
  enabled: string;
  erpID: string;
  familyGroupID: number;
  favoriteToppings: null;
  promotedToppingsQtty: number;
  highlightWeb: string;
  id: string;
  //Deprecated
  imageID?: string;
  majorGroupID: number;
  maxToppings: number;
  noHalfNDHalf: string;
  order: number;
  orderType: string;
  pgCustomerID: number;
  points: number;
  portions: number;
  recipe: MenuFamilyProductToppingInterface[];
  //Deprecated
  requireSauce?: string;
  salePrice: number;
  salePriceCounter: number;
  salePriceDEOnline: number;
  salePriceDelivery: number;
  salePricePUOnline: number;
  salePriceTable: number;
  salePriceTerrace: number;
  size?: string;
  shortName?: string;
  showAtWeb?: string;
  source: string;
  sourceName?: string;
  stockControlType: string;
  toppingsEq: number;
  //No used
  ts?: string;
  webDescription: string;
  //Deprecated
  webImageID?: string;
  // Web Group
  webGroupID?: number;
  // Plastic Law
  plasticLawProduct?: string;
}

// Family Topping

export interface MenuFamilyToppingInterface {
  allergens: string;
  branchID: number;
  calories: number;
  canBePromoted: string;
  critical: string;
  description: string;
  familyGroupID: number;
  grouppingID: number;
  id: string;
  kitchenOrder: number;
  order: number;
  pgCustomerID: number;
  promotedPrice: number;
  promotedDeliveryPrice: number;
  salePrice: number;
  salePriceDel: number;
  salePriceDelWithout: number;
  salePriceWithout: number;
  sourceName?: string;
  points?: number;
  //No used
  ts?: string;
}

// Family

export interface MenuFamilyInterface {
  baseProduct: string;
  description: string;
  familyGroupPrefix: string;
  freeToppingsNbr: number;
  hNDhGroupping: string;
  hNDnType: string;
  hNdhFee: number;
  id: number;
  majorGroupID: number;
  order: number;
  products: MenuFamilyProductInterface[];
  saleGrouppingWeb: number;
  shortName?: string;
  showAtWeb: string;
  size: string;
  sourceName?: string;
  toppings: MenuFamilyToppingInterface[];
  webSalesGroup: number;
  webSubsalesGroup: number;
  webDescription?: string;
}

// Major Group

export interface MenuMajorGroupInterface {
  alcohol: string;
  chargeByWeight: string;
  description: string;
  halfNdHalfAllowed: string;
  id: number;
  imageId?: string;
  loyaltyPoints: number;
  order: number;
  shortName?: string;
  sourceName?: string;
  taxDelivery: number;
  taxDinein: number;
  toppingsAllowed: string;
  webDescription: string;
}

// Topping Group

export interface MenuToppingGroupInterface {
  description: string;
  highlightChangesKitchen: string;
  id: number;
  maxFreeToppings: number;
  maxToppings: number;
  minToppings: number;
  order?: number;
  sameToppingHalves: string;
  sourceName?: string;
  webDescription: string;
}

// Web Group

export interface MenuWebGroupInterface {
  description: string;
  id: number;
  order: number;
  webDescription: string;
  webDescriptionExt: string;
}

// Menu

export interface MenuInterface {
  families: MenuFamilyInterface[];
  majorGroups: MenuMajorGroupInterface[];
  toppingGroups: MenuToppingGroupInterface[];
  webGroups?: MenuWebGroupInterface[];
  ts?: string;
}

export interface MenuOutOfStock {
  products: string[];
  toppings: string[];
}

export enum HalvesProductTypes {
  Aritmetica = 'A',
  MasCara = 'C',
}
