import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service'
import {StorageVariables } from '@core/constants';

@Injectable({
  providedIn: 'root'
})
export class KioskService {

  branchKiosk: any = false

  constructor(private readonly storageService: StorageService) { }

  public getKioskBranch(){

    this.storageService.getItem(StorageVariables.kioskBranch).subscribe(kioskBranch => {
      this.branchKiosk = kioskBranch
    },
    error => {
      console.log(error)
    })

    return this.branchKiosk
  }

  public setKioskBranch(id){
    this.branchKiosk = id;
    this.storageService.setItem(StorageVariables.kioskBranch, id);
  }


}
