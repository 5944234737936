import { Injectable } from '@angular/core';
import { DeviceLanguageCodeResult } from '@capacitor/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { PlatformService } from '@services/platform.service';
import { CustomObservableType, ObservableType, RxjsService } from '@services/rxjs.service';
import { LangConfigInterface } from '@app/app.interface';
import { LtrConfig, RtlConfig } from '@core/constants';
import { CustomErrorManager, ErrorFromPromoCodeInterface } from '@core/error-codes';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  constructor(
    private translate: TranslateService,
    private platformService: PlatformService,
    private rxjsService: RxjsService
  ) { }
  addLangs(langs: string[]): void {
    this.translate.addLangs(langs)
  }
  getCurrentDeviceLang(): CustomObservableType<string> {
    const result: CustomObservableType<string> = this.rxjsService.createCustomObservable()
    if (this.platformService.isBrowser()) {
      this.platformService.getCurrentDeviceLang().subscribe(
        (response: DeviceLanguageCodeResult) => {
          result.next(response.value.substr(0, 2))
          result.complete()
        }
      )
    } else {
      result.error('Platform server')
      result.complete()
    }
    return result
  }
  getCurrentLang(): string {
    return this.translate.currentLang ? this.translate.currentLang : this.getDefaultLang()
  }
  getDefaultLang(): string {
    return this.translate.getDefaultLang()
  }
  getInstantTranslation(key: string): string {
    return this.translate.instant(key)
  }
  getLangs(): string[] {
    return this.translate.getLangs()
  }
  getTranslation(key: string, params = {}): ObservableType<string> {
    return this.translate.get(key, params)
  }
  // Check
  isRtl(): boolean {
    return this.getCurrentLang() === 'ar'
  }
  // Config
  getLangConfig(): LangConfigInterface {
    const config: LangConfigInterface = this.isRtl() ? RtlConfig : LtrConfig
    config.lang = this.getCurrentLang()
    return config
  }
  // Setters
  setLang(lang: string): ObservableType<any> {
    return this.translate.use(lang)
  }
  setDefaultLang(lang: string): void {
    this.translate.setDefaultLang(lang)
  }
  // Events
  onDefaultLangChange(): ObservableType<DefaultLangChangeEvent> {
    return this.translate.onDefaultLangChange
  }
  onLangChange(): ObservableType<LangChangeEvent> {
    return this.translate.onLangChange
  }
  changeLanguage(): void {
    const langList: string[] = this.getLangs()
    const langPosition: number = 1 - langList.indexOf(this.getLangConfig().lang)
    const lang: string = langList[langPosition]
    this.setLang(lang).subscribe().unsubscribe()
  }
  // Promo Engine Errors
  getCustomMsgFromPromoEngineResult(errors: any[], promoCode?: string): Promise<string> {
    const errorManager: CustomErrorManager = new CustomErrorManager(null)
    const errorResult: ErrorFromPromoCodeInterface = promoCode ? errorManager.getErrorMsgFromPromoCodeResult(promoCode, errors) : errorManager.getErrorMsgFromPromotionResult(errors)
    // console.log(errorResult)
    return this.getTranslation(errorResult.msg, errorResult.params).toPromise()
  }
}
