import {
    MenuFamilyInterface,
    MenuFamilyProductInterface,
    MenuInterface,
    PromoInterface,
    CartCalculatedResponse,
    OrderTypes,
    LegacySources,
    ProductCalculatedResponse,
    OrderLineTypes,
    PromoLineInterface,
    ProductOptions,
    PromoOptions,
    PromoTypes,
    CalculatedLinesResponse,
    PromotionCalculatedResponse,
    PromoEngine,
    PromoUserInterface,
    IsPromoLineApplicable
} from '../../../promo-engine/public-api';
import { CreateOrderInterface } from './order';
import { MenuGroupInterface } from './menu';

export interface CalculatingLineInterface {
    code?: string
    customID?: number
    discount?: number
    id?: string
    sublines?: ProductCalculatedResponse[]
    saleItemID: string
    toppings?: string[]
    type: OrderLineTypes
    unitPrice: number
}

export interface CounterInterface<T = number> {
    [key: string]: T
}

export class PgCommon {
    constructor() { }
    // Promo Engine
    getPromoEngine(menu: MenuInterface, promotions: PromoInterface[]): PromoEngine {
        return new PromoEngine(
            menu ? this.copyArray(menu.families) : [],
            menu ? this.copyArray(menu.majorGroups) : [],
            menu ? this.copyArray(menu.toppingGroups) : [],
            this.copyArray(promotions),
            [],
            null
        )
    }
    calculateComposedProduct(options: ProductOptions, menu: MenuInterface, lines: any[]): ProductCalculatedResponse {
        const promoEngine = this.getPromoEngine(menu, [])
        return promoEngine.calcular_precio_compuesto(options, lines[0], lines[1])
    }
    calculateProduct(options: ProductOptions, menu: MenuInterface, productID: string, toppings: string[]): ProductCalculatedResponse {
        const promoEngine = this.getPromoEngine(menu, [])
        return promoEngine.calcular_precio_producto(productID, this.copyArray(toppings), options)
    }
    calculatePromotion(options: PromoOptions, menu: MenuInterface, promotions: PromoInterface[], promoID: string, lines: CalculatedLinesResponse[], promoCode: string = null, points: number = 0): CartCalculatedResponse {
        const promoEngine = this.getPromoEngine(menu, promotions)
        if (promoCode) {
            options = {
                ...options,
                promoCode
            }
        }
        if (points > 0) {
            promoEngine.setOptions({
                loyaltyPoints: points
            })
        }
        lines.sort((a, b) => b.unitPrice - a.unitPrice)
        return promoEngine.calcular_precio_promo(promoID, lines, options)
    }
    calculatePromotionDiscount(unitPrice: number, promoLine: PromoLineInterface): number {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.calcular_descuento(unitPrice, promoLine.discountType, promoLine.discountAmount)
    }
    calculatePromotionHourAvailability(d: string, options: { hourlyRestriction: string, startingTime1: string, endingTime1: string, startingTime2: string, endingTime2: string }): boolean {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.isAplicableHours(d, options)
    }
    calculatePromoLineDiscount(promoLine: PromoLineInterface, product: MenuFamilyProductInterface, options: PromoOptions): number {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.getProductPriceFromPromo(promoLine, product, options)
    }
    cleanLines(lines: CalculatedLinesResponse[]): any[] {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.cleanLines(lines)
    }
    calculateAutoBasket(options: (ProductOptions | PromoOptions), menu: MenuInterface, promotions: PromoInterface[], extraPromotions: PromoInterface[], lines: CalculatedLinesResponse[], points: number) {
        const promoEngine = this.getPromoEngine(menu, promotions.filter(p => p.promoType !== PromoTypes.CrossSales))
        if (points > 0) {
            promoEngine.setOptions({
                loyaltyPoints: points
            })
        }
        const result = promoEngine.auto(lines, options, extraPromotions)
        return (result && Array.isArray(result) && result.length > 0) ? this.engageLines(result, false) : []
    }
    calculateDeliveryFee(
        options: ProductOptions,
        menu: MenuInterface,
        promotions: PromoInterface[],
        lines: CalculatedLinesResponse[],
        zone: any,
        customerStatus: any,
        deliveryFeePromoCode: string,
        customerPromotions: PromoUserInterface[]
    ): ProductCalculatedResponse | PromotionCalculatedResponse {
        const promoEngine: PromoEngine = this.getPromoEngine(menu, promotions)
        promoEngine.setOptions({
            webDeliveryFeeItem: zone.webDeliveryFeeItem,
            webDeliveryFeeItem2: zone.webDeliveryFeeItem2,
            webMaxAmountDeliveryFee: zone.webMaxAmountDeliveryFee,
            userStatus: (customerStatus && 'status' in customerStatus) ? customerStatus.status : null,
            feePromoCode: deliveryFeePromoCode/*,
            customerPromos: (customerPromotions && Array.isArray(customerPromotions) && customerPromotions.length > 0) ? customerPromotions.map(p => p.promoID) : []*/
        })
        return promoEngine.getFee(
            options,
            this.disengageLines(lines),
            (customerPromotions && Array.isArray(customerPromotions) && customerPromotions.length > 0) ? customerPromotions/*.map(p => p.promoID)*/ : []
        )
    }
    /**
     * 
     * @returns Las lineas del carrito recalculadas
     */
    calculateLines(options: (ProductOptions | PromoOptions), menu: MenuInterface, promotions: PromoInterface[], lines: CalculatedLinesResponse[], promoCode: string = null, points: number = 0, isReorder: boolean = false) {
        const deliverySaleItems: string[] = [
            'delivery',
            'delivery20'
        ]
        const promoEngine = this.getPromoEngine(menu, promotions)
        if (promoCode) {
            options = {
                ...options,
                promoCode
            }
        }
        if (points > 0) {
            promoEngine.setOptions({
                loyaltyPoints: points
            })
        }
        let linesToCalculate: CalculatedLinesResponse[] = lines.reduce(
            (result: CalculatedLinesResponse[], value) => {
                let isDeliveryItem: boolean = deliverySaleItems.indexOf(value.saleItemID.toLowerCase()) >= 0
                let isPromotion: boolean = value.type === OrderLineTypes.crossSales || value.type === OrderLineTypes.promotion
                if (isReorder) {
                    if (isPromotion) {
                        let promotion: PromoInterface = promotions.find(p => p.id === value.saleItemID)
                        let isDeliveryPromoItem: boolean = (promotion.promoType === PromoTypes.Delivery)
                        if (!isDeliveryPromoItem) {
                            let addOnlySublines = (
                                isDeliveryItem ||
                                !promotion ||
                                (
                                    promotion && (promotion.promoType === PromoTypes.Descuento || promotion.promoType === PromoTypes.Loyalty || promotion.loyaltyPoints > 0 || promotion.onePerCustomer === 'S')
                                )
                            )
                            if (addOnlySublines) {
                                result = result.concat(value.sublines)
                            } else {
                                result.push(value)
                            }
                        }
                    } else {
                        if (!isDeliveryItem) {
                            result.push(value)
                        }
                    }
                } else {
                    if (!isDeliveryItem || (isDeliveryItem && isPromotion)) {
                        result.push(value)
                    }
                }
                return result
            }, []
        )
        linesToCalculate.sort((a, b) => b.unitPrice - a.unitPrice)
        return promoEngine.recalcLines(
            linesToCalculate,
            options
        )
    }
    checkBaseProduct(family: MenuFamilyInterface, product: MenuFamilyProductInterface): boolean {
        return (family.baseProduct !== null && family.baseProduct === product.id)
    }
    checkCustomProduct(family: MenuFamilyInterface, group: MenuGroupInterface, product: MenuFamilyProductInterface): boolean {
        const isBaseProduct: boolean = this.checkBaseProduct(family, product)
        const isHalfNdHalf: boolean = (group !== null) ? (group.halfNdHalfAllowed === 'S' && product.noHalfNDHalf !== 'S') : false
        return (isBaseProduct && isHalfNdHalf)
    }
    checkLines(options: (ProductOptions | PromoOptions), menu: MenuInterface, promotions: PromoInterface[], lines: CalculatedLinesResponse[], promoCode: string = null, points: number = 0) {
        const deliverySaleItems: string[] = [
            // 'delivery',
            // 'delivery20'
        ]
        const promoEngine = this.getPromoEngine(menu, promotions)
        if (promoCode) {
            options = {
                ...options,
                promoCode
            }
        }
        if (points > 0) {
            promoEngine.setOptions({
                loyaltyPoints: points
            })
        }
        return promoEngine.checkLines(
            lines.filter(
                value => deliverySaleItems.indexOf(value.saleItemID.toLowerCase()) < 0
            ),
            options
        )
    }
    checkErrorLines(options: (ProductOptions | PromoOptions), menu: MenuInterface, promotions: PromoInterface[], lines: CalculatedLinesResponse[], promoCode: string = null, points: number = 0) {
        const deliverySaleItems: string[] = [
            // 'delivery',
            // 'delivery20'
        ]
        const promoEngine = this.getPromoEngine(menu, promotions)
        if (promoCode) {
            options = {
                ...options,
                promoCode
            }
        }
        if (points > 0) {
            promoEngine.setOptions({
                loyaltyPoints: points
            })
        }
        return promoEngine.checkErrorLines(
            lines.filter(
                value => deliverySaleItems.indexOf(value.saleItemID.toLowerCase()) < 0
            ),
            options
        )
    }
    compareLines(l1: ProductCalculatedResponse, l2: ProductCalculatedResponse) {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.compareLines(l1, l2)
    }
    comparePromoLines(p1: PromotionCalculatedResponse, p2: PromotionCalculatedResponse) {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.comparePromoLines(p1, p2)
    }
    createOrderLineID(): number {
        return (new Date()).getTime()
    }
    disengageLines(lines: CalculatedLinesResponse[]) {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.disengageLines(lines)
    }
    engageLines(lines: CalculatedLinesResponse[], engagePromotions: boolean) {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.engageLines(lines, engagePromotions)
    }
    getTopping(menu: MenuInterface, id: string) {
        const promoEngine = this.getPromoEngine(menu, [])
        return promoEngine.buscar_topping(id)
    }
    isPromoLineApplicable(
        product: MenuFamilyProductInterface,
        promoEngineResult: ProductCalculatedResponse,
        promoLine: PromoLineInterface
    ): IsPromoLineApplicable {
        const promoEngine = this.getPromoEngine(null, [])
        return promoEngine.isAplicable(promoLine, product, promoEngineResult)
    }
    // Copy
    deepCopy<T>(source: T): T {
        const result: T = Array.isArray(source)
            ? (source as T[]).map((item: T, index: number) => { return this.deepCopy<T>(item) })
            : source instanceof Date
                ? new Date(source.getTime())
                : source && typeof source === 'object'
                    ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                        Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
                        o[prop] = this.deepCopy(source[prop]);
                        return o;
                    }, Object.create(Object.getPrototypeOf(source)))
                    : source as T
        return result
    }
    copy<T>(source: T): T {
        return JSON.parse(JSON.stringify(source)) as T
    }
    copyArray<T>(source: T[]): T[] {
        return source.map(item => (typeof item === 'object') ? ({ ...item }) : this.copy(item))
    }
    // softCopyArray
    //
    checkPromotionOrderType(promo: PromoInterface): CounterInterface<boolean> {
        let promotionOrderType: string = promo.orderType
        let result: CounterInterface<boolean> = {
            [OrderTypes.Domicilio]: promotionOrderType.includes(OrderTypes.Domicilio),
            [OrderTypes.Recoger]: promotionOrderType.includes(OrderTypes.Recoger)
        }
        return result
    }
    getAvailableFamiliesOfProduct(families: MenuFamilyInterface[], product: MenuFamilyProductInterface, excludeSingleSizeFamilies: boolean): MenuFamilyInterface[] {
        const result: MenuFamilyInterface[] = []
        if (families.length > 0) {
            const family: MenuFamilyInterface = this.copy(this.findInArray(families, 'id', product.familyGroupID))
            const productCommonID: string = this.getCommonProductId(family, product)
            families.forEach(
                value => {
                    let isCurrentFamily: boolean = (family.id === value.id)
                    let commonPropertyID: string = isCurrentFamily ? 'id' : 'erpID'
                    let commonID: string = isCurrentFamily ? product.id : (value.familyGroupPrefix + productCommonID)
                    let products: MenuFamilyProductInterface[] = this.filterInArray(value.products, commonPropertyID, commonID)
                    let hasProducts: boolean = products.length > 0
                    let allowToAdd: boolean = excludeSingleSizeFamilies ? (hasProducts && (value.size !== null && value.size !== 'N')) : hasProducts
                    if (allowToAdd) {
                        const f: MenuFamilyInterface = this.copy(value)
                        f.products = products
                        result.push(f)
                    }
                }
            )
        }
        result.sort((a, b) => a.order - b.order)
        return result
    }
    getCommonProductId(family: MenuFamilyInterface, product: MenuFamilyProductInterface): string {
        const familyPrefix: string = family.familyGroupPrefix
        const familyPrefixLength: number = familyPrefix ? familyPrefix.length : 0
        const productId: string = product.erpID
        const productIdSubstr: string = productId.substring(0, familyPrefixLength)
        const resultId: string = (familyPrefixLength > 0 && familyPrefix === productIdSubstr) ? productId.substring(familyPrefixLength, 99) : productId
        return resultId
    }
    getPercentageFromNumbers(partial: number, total: number): number {
        return (total > 0) ? this.parseToDecimals((partial * 100) / total) : 0
    }
    getEmptyOrder(branchByDefault: number): CreateOrderInterface {
        return {
            addedCrossSales: [],
            addressID: 0,
            branchPickUpID: branchByDefault,
            branchDeliveryID: branchByDefault,
            change: 0,
            calculatedLines: [],
            clickNcar: 'N',
            customerID: 0,
            device: '',
            excludedOrderType: null,
            deliveryFeeLine: null,
            deliveryFeePromoCode: null,
            language: '',
            lineItems: [],
            orderNumber: 0,
            orderType: OrderTypes.Recoger,
            paymentMethod: 1,
            promoCodes: [],
            promisedTime: '',
            promoLineItems: [],
            source: LegacySources.Web,
            status: 'N',
            ts: (new Date()).toISOString()
        }
    }
    toggleBinaryResult(r: number): number {
        return r ^= 1
    }
    trackByMethod(index: number): number {
        return index
    }
    // Arrays
    filterIfNotInArray<T = any>(arr: T[], prop: string, search: any): T[] {
        return arr.filter(result => result[prop] !== search)
    }
    filterInArray<T = any>(arr: T[], prop: string, search: any): T[] {
        return arr.filter(result => result[prop] === search)
    }
    findInArray<T = any>(arr: T[], prop: string, search: any): T {
        return arr.find(result => result[prop] === search)
    }
    findIndexInArray<T = any>(arr: T[], prop: string, search: any): number {
        return arr.findIndex(result => result[prop] === search)
    }
    someInArray<T = any>(arr: T[], prop: string, search: any): boolean {
        return arr.some(result => result[prop] === search)
    }
    pushToArrayNtimes<T = any>(arr: T[], times: number, value: T): T[] {
        return arr.concat(new Array(times).fill(value))
    }
    trackByFn(index: number, element: any): number {
        return index
    }
    // Convert
    toArray<T>(stuff: any) {
        return Object.values<T>(stuff)
    }
    toObject(stuff: any[], property: string) {
        return stuff.reduce((obj: any, item: any) => {
            obj[item[property]] = item
            return obj
        }, {})
    }
    static timeDiff(date1: string | Date, date2: string | Date) {
        const diff = Math.floor(Math.abs(((new Date(date2).getTime() - new Date(date1).getTime()) / 1000) / 60));
        if (diff < 60) {
            return diff + 'min'
        }
        else {
            return Math.floor(diff / 60) + 'h '
        }
    }
    // Operations
    parseToDecimals(n: number | string, decimals: number = 2): number {
        if (typeof n === 'string') {
            n = parseFloat(n)
        }
        return Math.round(parseFloat((n * Math.pow(10, decimals)).toFixed(decimals))) / Math.pow(10, decimals)
    }

    formatDate(d) {
        return new Date(d.substr(0, 4), d.substr(5, 2) - 1, d.substr(8, 2), d.substr(11, 2), d.substr(14, 2), d.substr(17, 2))
    }

    cutText(text: string, init: number, end: number) {
        let result: string
        try {
            if (text.length <= end) {
                return text
            } else {
                result = text.substring(init, end) + "...";
                return result
            }
        } catch (e) { }
        return text
    }

    static timeDiffDeliveryTime(date1: string | Date, date2: string | Date) {
        const diff = Math.floor(Math.abs(((new Date(date2).getTime() - new Date(date1).getTime()) / 1000) / 60));
        if (diff >= 60) {
            let res = Math.floor(diff / 60) + ' hours ';
            res += (diff % 60) > 0 ? (diff % 60) + ' min' : '';
            return res;
        } else {
            return diff + ' min'
        }
    }

    /**
     * @returns an array from a string separated by ';' ignoring empty elements
     */
    public checkItems(items: string): string[] {
        return (items && items.length > 0) ? items.split(';').filter(Boolean) : []
    }
}