import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperPipe'
})
export class UppercasePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (args.options?.checkArabic) {
      if (this.isArabic(value)) {
        value = this.arabicToLatinNumber(value)
      }
    }

    if (args.options?.filter == "upperPipe") {
      return value.toUpperCase();
    } else {
      return value
    }
  }

  isArabic = function (enDigit) {
    var newValue = "";
    for (var i = 0; i < enDigit.length; i++) {
      var ch = enDigit.charCodeAt(i);
      if (ch >= 1632 && ch <= 1641) {
        return true;
      }

    }
    return false;
  }

  arabicToLatinNumber = function (enDigit) {
    var newValue = "";
    for (var i = 0; i < enDigit.length; i++) {
      var ch = enDigit.charCodeAt(i);
      if (ch >= 1632 && ch <= 1641) {
        // arabian digit range
        var newChar = ch - 1584;
        newValue = newValue + String.fromCharCode(newChar);
      } else
        newValue = newValue + String.fromCharCode(ch);
    }
    return "" + newValue.trim();
  };

}
