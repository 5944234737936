import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Capacitor, DeviceInfo, DeviceLanguageCodeResult, DevicePlugin } from "@capacitor/core";
import { Platform } from '@ionic/angular';
import { ObservableType, RxjsService } from '@services/rxjs.service';
import { enumDeviceTypes, LegacySources } from '@lib/promo-engine';
import { KioskService } from '@services/kiosk.service';
import { kiosk } from '@core/constants';

export type CurrentDeviceLangType = ObservableType<DeviceLanguageCodeResult>
@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  isKiosk: boolean = kiosk
  kioskBranch: any

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private rxjsService: RxjsService,
    private ionicPlatform: Platform,
     private readonly kioskService: KioskService
  ) {     

  }
  // Native
  private get device(): DevicePlugin {
    return Capacitor.Plugins.Device
  }
  private get deviceLang(): Promise<DeviceLanguageCodeResult> {
    return this.device.getLanguageCode()
  }
  private get platform(): string {
    return Capacitor.getPlatform()
  }
  // Getters
  getCurrentDevice(): enumDeviceTypes {
    return this.isAndroid() ? enumDeviceTypes.android : (this.isIos() ? enumDeviceTypes.ios : (this.isDesktop() ? enumDeviceTypes.webDesktop : enumDeviceTypes.webMobile))
  }
  getCurrentDeviceLang(): CurrentDeviceLangType {
    return this.rxjsService.createObservableFromPromise(this.deviceLang)
  }
  getDeviceInfo(): Promise<DeviceInfo> {
    return this.device.getInfo()
  }
  getSource(): LegacySources {
    return LegacySources.Kiosk
  }
  isAndroid(): boolean {
    return this.platform === 'android'
  }
  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId)
  }
  isServer(): boolean {
    return isPlatformServer(this.platformId)
  }
  isIos(): boolean {
    return this.platform === 'ios'
  }
  isWeb(): boolean {
    return this.platform === 'web'
  }
  isSafari(): boolean {
    if (this.isWeb()) {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
          return false; // Chrome
        } else {
          return true; // Safari
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  isDesktop(): boolean {
    return this.isWeb() && this.ionicPlatform.is('desktop')
  }
  getDevice(): string {

    if (this.isIos()) {
      return enumDeviceTypes.ios;
    } else if (this.isAndroid()) {
      return enumDeviceTypes.android;
    } else if (this.isDesktop()) {
      return enumDeviceTypes.webDesktop;
    } else {
      return enumDeviceTypes.webMobile;
    }

  }
  isChrome(): boolean {
    if (this.isWeb()) {
      var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('chrome') > -1) {
          return true; // Chrome
        } else {
          return false; // another
        }

    } else {
      return false;
    }
  }
}
