import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnActionEventInterface } from '@core/components';
import { kiosk } from '@core/constants';


@Component({
  selector: 'app-button-add-quantity',
  templateUrl: './button-add-quantity.component.html',
  styleUrls: ['./button-add-quantity.component.scss'],
})
export class ButtonAddQuantityComponent implements OnInit {
  @Input() maxQuantity?: number = 99
  @Input() set quantity(value: number) {
    this.qty = value
  }
  @Input() canRemove?: boolean = true
  @Output() onAction: EventEmitter<OnActionEventInterface> = new EventEmitter()
  qty: number
  parent: string
  @Input() isLastOrder?: boolean = false
  @Input() isFavourites?: boolean = false
  @Input() isToppingList?: boolean = false
  isKiosk: boolean = kiosk
  kioskBranch: any

  constructor(private element: ElementRef) { 
  }
  ngOnInit(): void {
    if(typeof this.maxQuantity !== 'number'){
      this.maxQuantity = 99
    }
    this.parent = this.element.nativeElement.parentElement.className
  }
  addQty(): void {
    if (this.qty < this.maxQuantity) {
      this.onAction.emit({
        action: 'addQty',
        quantity: this.qty
      })
    }
  }
  reduceQty(): void {
    // if (this.quantity > 1) {
    //   this.quantity--
    // }
    this.onAction.emit({
      action: 'reduceQty',
      quantity: this.qty
    })
  }
}
