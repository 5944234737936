import { LangConfigInterface } from '@app/app.interface';
import { RouteListInterface } from '@app/app.interface';

// Storage variables

export enum StorageVariables {
  currentBranch = 'selectedBranch',
  fcmToken = 'FCMtoken',
  magicLink = 'magicLink',
  menu = 'Menu',
  ATDAFterMLink = 'ATDAFterMLink',
  order = 'Order',
  userID = 'uid',
  promos = 'Promos',
  lang = 'Lang',
  oldUser = 'test',
  user = 'user',
  PaymentTokens = 'PaymentTokens',
  access_token = 'access_token',
  linkwith = 'linkwith',
  allowLocation = 'allowLocation',
  showedOpeningScreen = 'showedOpeningScreen',
  favProducts = 'favProducts',
  push = 'notificationsReceived',
  crossSales = 'crossSales',
  lastOrder = 'lastOrder',
  showLastOrder = 'showLastOrder',
  orderPayment = 'orderPayment',
  defPayCode = 'defPayCode',
  branchDelivery = 'branchDelivery',
  branchPickup = 'branchPickup',
  currentZone = 'currentZone',
  referalToapply = 'referalToapply',
  selectedAddress = 'selectedAddress',
  userSelectBranch = 'userSelectBranch',
  toDoAfterLogin = 'toDoAfterLogin',
  location = 'location',
  lastPickUpOrder = 'lastPickUpOrder',
  addresses = 'addresses',
  locationAndZone = 'locationAndZone',
  savedPromosPickup = 'savedPromosPickup',
  savedPromosDelivery = 'savedPromosDelivery',
  savedCombos = 'savedCombos',
  customerLoyaltyPoints = 'customerLoyaltyPoints',
  orderToRate = 'orderToRate',
  last_order_evt = 'last_order_evt',
  payWithWallet = "payWithWallet",
  insider_Basket = "insider_Basket",
  kioskBranch = "kioskBranch",
  kioskNumber = "kioskNumber",
  menuOutOfStock = 'menuOutOfStock',
  deepLinkQueryParams = 'deepLinkQueryParams'
}

export const Version: string = "3.1.267";

export const LtrConfig: LangConfigInterface = {
  dir: 'ltr',
  side: 'end'
}

export const RtlConfig: LangConfigInterface = {
  dir: 'rtl',
  side: 'end'
}

export const RoutesMenu: RouteListInterface[] = [
  {
    'icon': 'menu-menu',
    'iconDesktop': false,
    'name': 'LANG_MENU',
    'path': '/menu',
    'mobile': true,
    'desktop': true
  },
  {
    'icon': 'menu-promo',
    'iconDesktop': false,
    'name': 'LANG_PROMOTIONS',
    'path': '/promotions',
    'mobile': true,
    'desktop': true
  },
  {
    'icon': 'menu-cart',
    'iconDesktop': false,
    'name': 'LANG_BASKET',
    'path': '/cart',
    'mobile': true,
    'desktop': false
  },
  {
    'icon': 'menu-tracking',
    'iconDesktop': false,
    'name': 'LANG_ORDER_TRACKER',
    'path': '/tracking',
    'mobile': true,
    'desktop': true
  },
  {
    'icon': 'menu-my-maestro',
    'iconDesktop': false,
    'name': 'LANG_MY_MAESTRO',
    'path': '/account',
    'mobile': true,
    'desktop': true
  },
  {
    'icon': '',
    'iconDesktop': false,
    'name': 'LANG_CONTACT',
    'path': '/contact',
    'mobile': false,
    'desktop': true
  },
  {
    'icon': 'menu-message-center',
    'iconDesktop': true,
    'name': 'LANG_MESSAGE_CENTER',
    'path': '/messages',
    'mobile': true,
    'desktop': false
  },
  // {
  //   'icon': 'menu-my-maestro',
  //   'iconDesktop': false,
  //   'name': 'account test',
  //   'path': '/accountest',
  //   'mobile' : true ,
  //   'desktop' : true
  // },
]

export const RoutesMenuKiosk: RouteListInterface[] = [
  {
    'icon': 'menu-menu',
    'iconDesktop': false,
    'name': 'LANG_MENU',
    'path': '/menu',
    'mobile': true,
    'desktop': true
  },
  {
    'icon': 'menu-promo',
    'iconDesktop': false,
    'name': 'LANG_PROMOTIONS',
    'path': '/promotions',
    'mobile': true,
    'desktop': true
  }
]

export const ContactList: object[] = [
  {
    'icon': 'icon-chat-man',
    'name': 'Nahel Khattab (man)',
    'phone': '+9669200 170000'
  },
  {
    'icon': 'icon-chat-woman',
    'name': 'Leila Abaid (women)',
    'phone': '+9669200 170000'
  }
]

export const menuNdPromotionsExpiration: number = 86400000

export const orderExpiration: number = 7200000

export const RequiredToppings: string[] = [
  '671'
]

export const ChatBotUrl: string = ''

export const tpvExpiration: number = 90000