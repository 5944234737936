import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-doughts',
  templateUrl: './doughts.component.html',
  styleUrls: ['./doughts.component.scss'],
})
export class DoughtsComponent implements OnInit {
  @Input() family: any
  @Input() product: any
  constructor() { }


  showCheckCustomRadio: boolean = false;
  showX2CustomRadio: boolean = false;
  classCustomRadio: string = "radioCustom";

  doughts = [/*{ ToppingCode: "204", ToppingName: "Thin Brown", },
  { ToppingCode: "636", ToppingName: "Thin" },
  { ToppingCode: "DO001", ToppingName: "Cheesy Crust" },
  { ToppingCode: "DO002", ToppingName: "Double Cheddar" },
  { ToppingCode: "FGPZT3042", ToppingName: "Thick" },
  { ToppingCode: "SFCRF0010", ToppingName: "Ultrathin" },
  { ToppingCode: "SFCRF0012", ToppingName: "Classic" }*/
  ]
  ngOnInit(): void {
    this.family.toppings.forEach((value: any) => {
      if (value.grouppingID == 1) {
        this.doughts.push(value)
      }
    })
    this.doughts.sort((a, b) =>
      a.order - b.order
    )
  }
  addTopping() {
    if (!this.showCheckCustomRadio && !this.showX2CustomRadio) {
      this.showCheckCustomRadio = true;
      this.classCustomRadio = 'radioCustomSelected'
    } else if (this.showCheckCustomRadio) {
      this.showCheckCustomRadio = false;
      this.showX2CustomRadio = true;
      this.classCustomRadio = 'radioCustomSelectedX2'
    } else {
      this.showCheckCustomRadio = false;
      this.showX2CustomRadio = false;
      this.classCustomRadio = 'radioCustom'
    }


  }

}
