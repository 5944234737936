import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormMetadataButtonInterface, FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { environment } from '@src/environments/environment';
import { ApiService } from '@services/api.service';
import { UserDataService } from '@services/user-data.service';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-account-details-form',
  templateUrl: './account-details-form.component.html',
  styleUrls: ['./account-details-form.component.scss'],
})
export class AccountDetailsFormComponent implements OnInit {


  formMetadataName: FormMetadata
  formMetadataBirth: FormMetadata
  formMetadataPhone: FormMetadata
  formMetadataEmail: FormMetadata
  formMetadataSubscribe: FormMetadata
  formMetadataFull: FormMetadata

  @Output() setValueToShow = new EventEmitter();
  @Input() data: any;
  @Output() onUpdateCustomer;
  @Output() showLoyaltySuccess = new EventEmitter();
  assets: string = environment.assetsUrl;

  buttonSave: FormMetadataButtonInterface[];
  buttonSubscribe: FormMetadataButtonInterface[];
  fieldName: FormMetadataFieldInterface[];
  fieldPhone: FormMetadataFieldInterface[];
  fieldEmail: FormMetadataFieldInterface[];
  fieldBirthDay: FormMetadataFieldInterface[];
  fieldSubscribe: FormMetadataFieldInterface[];

  constructor(private readonly _router: Router, private readonly _api: ApiService,  private userDataService: UserDataService, private dataService: DataService) {
    this.buttonSave = this.getButtonSave();
    this.buttonSubscribe = this.getButtonSubscribe();
    this.fieldName = this.getFieldName();
    this.fieldBirthDay = this.getFieldBirthDay();
    this.fieldPhone = this.getFieldPhone();
    this.fieldEmail = this.getFiledEmail();
    this.fieldSubscribe = this.getFieldSubscribe();
    this.onUpdateCustomer = new EventEmitter();

  }

  ngOnInit() {
    this.formMetadataName = this.getFormConfig('name');
    this.formMetadataBirth = this.getFormConfig('birthday');
    this.formMetadataPhone = this.getFormConfig('phone');
    this.formMetadataEmail = this.getFormConfig('email');
    this.formMetadataSubscribe = this.getFormConfig('subscribe');
    this.formMetadataFull = this.getFormConfig('full');

  }

  save(event: any) {

    if (event.valid === true) {
       
      // console.log(event);
      if (this.data.type == 'name') {
        this.setValueToShow.emit('cart');
        let args = { name: event.value.name };
        this.onUpdateCustomer.emit(args);
      }
      if (this.data.type == 'birthday') {
        this.setValueToShow.emit('cart');
        let args = { birthDate: event.value.birthday };
        this.onUpdateCustomer.emit(args);
      }
      if (this.data.type == 'phone') {
        this.setValueToShow.emit('cart');
        let args = { phone: event.value.phone,country:event.value.country };
        this._router.navigate(['/login'], { queryParams: { action: 'updatePhone', phone: args.phone,country:args.country } })

      }
      if (this.data.type == 'email') {
        this.setValueToShow.emit('cart');
        let args = { email: event.value.email };
        this.onUpdateCustomer.emit(args);
      }

      if (this.data.type == 'subscribe') {
        let args =  { name: event.value.name, birthDate: event.value.birthday};

        if((this.data.user.emailValidated == 'N' || this.data.user.email !== event.value.email ) || this.data.user.email == undefined){
          args["email"] = event.value.email
        }
        
        this._api.customerUpdate(args).subscribe(
          result => {
            this.dataService.setNeedRequestUser(true)
            if(args["email"]){
              this._router.navigate(['/login'], { queryParams: { action: 'updateEmail', email: args["email"] } })
            }else{
                this.showLoyaltySuccess.emit(true);        
                this.setValueToShow.emit('cart');
            } 
          },
          fail => { }
        );
      }

      if (this.data.type == 'full') {
        let args =  { name: event.value.name, birthDate: event.value.birthday};

        if((this.data.user.emailValidated == 'N' || this.data.user.email !== event.value.email ) || this.data.user.email == undefined){
          args["email"] = event.value.email
        }
        
        this._api.customerUpdate(args).subscribe(
          result => {

            this._api.getCustomerProfile().subscribe(profile => {
              this.userDataService.saveUser(profile).subscribe(userProfile => {
                this.dataService.setNeedRequestUser(true)
                if(args["email"]){
                  this.setValueToShow.emit('cart');
                  this._router.navigate(['/login'], { queryParams: { action: 'updateEmail', email: args["email"] } })
                }else{
                    this.showLoyaltySuccess.emit(true);        
                    this.setValueToShow.emit('cart');
                } 
              }, error => { });
            }, error => { })
            

          },
          fail => { }
        );
      }

    }
  }

  close() {
    this.setValueToShow.emit('cart');
  }

  private getButtonSave(): FormMetadataButtonInterface[] {
    const buttons: FormMetadataButtonInterface[] = [
      {
        buttonType: 'S',
        color: 'primary',
        label: 'LANG_SAVE',
        position: 4,
        class: "",
        expand: 'block',
        fill: 'solid',
        size: "default"
      }
    ]
    return buttons
  }

  private getButtonSubscribe(): FormMetadataButtonInterface[] {
    const buttons: FormMetadataButtonInterface[] = [
      {
        buttonType: 'S',
        color: 'primary',
        label: 'LANG_SUBSCRIBE',
        position: 4,
        class: "",
        expand: 'block',
        fill: 'solid',
        size: "default"
      }
    ]
    return buttons
  }

  private getFieldName(): FormMetadataFieldInterface[] {
    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "name",
        isReadOnly: false,
        fieldType: 'text',
        name: 'name',
        placeholder: 'LANG_NAME',
        position: 1,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_NAME',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: '',
            params: {
              length: 50
            },
            validatorType: 'maxLength'
          },
          {
            errorMsg: '',
            params: {
              length: 3
            },
            validatorType: 'minLength'
          }
        ],
        value: ''
      }
    ]
    return fields
  }

  private getFieldBirthDay(): FormMetadataFieldInterface[] {

    let year = new Date().getFullYear() - 1;

    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "birthday",
        isReadOnly: false,
        fieldType: 'datepicker',
        name: 'birthday',
        placeholder: 'LANG_BIRTHDATE',
        position: 1,
        class: "datepicker",
        disabled: false,
        label: "",
        options: {
          datePicker: {
            cancelText: "cancel",
            doneText: "confirm",
          },

          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          },
          checkArabic: true
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_BIRTHDATE',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: '',
            params: {
              maximum: year
            },
            validatorType: 'max'
          },
          {
            errorMsg: '',
            params: {
              minimum: 1930
            },
            validatorType: 'min'
          }
        ],
        value: ''
      }]
    return fields;
  }

  private getFieldPhone(): FormMetadataFieldInterface[] {
    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "phone",
        isReadOnly: false,
        fieldType: 'tel',
        name: 'phone',
        placeholder: 'LANG_PHONE',
        position: 2,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          },
          checkArabic: true
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_PHONE',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: 'LANG_INVALID_PHONE',
            params: {
                        length: 10
            },
            validatorType: 'maxLength'
          }
        ],
        value: ''
      }]
    return fields;
  }

  private getFiledEmail(): FormMetadataFieldInterface[] {
    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "email",
        isReadOnly: false,
        fieldType: 'email',
        name: 'email',
        placeholder: 'LANG_EMAIL',
        position: 3,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [          
          {
          errorMsg: 'LANG_REQUIRED_EMAIL',
          params: {},
          validatorType: 'required'
        },
          {
            errorMsg: 'LANG_INVALID_EMAIL',
            params: {},
            validatorType: 'email'
          },
          {
            errorMsg: '',
            params: {
              length: 100
            },
            validatorType: 'maxLength'
          }
        ],
        value: ''
      }
    ]
    return fields;
  }

  private getFieldSubscribe(): FormMetadataFieldInterface[] {

    let year = new Date().getFullYear() - 1;

    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "name",
        isReadOnly: false,
        fieldType: 'text',
        name: 'name',
        placeholder: 'LANG_NAME',
        position: 1,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_NAME',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: '',
            params: {
              length: 50
            },
            validatorType: 'maxLength'
          },
          {
            errorMsg: '',
            params: {
              length: 3
            },
            validatorType: 'minLength'
          }
        ],
        value: ''
      },
      {
        group: "",
        id: "birthday",
        isReadOnly: false,
        fieldType: 'datepicker',
        name: 'birthday',
        placeholder: 'LANG_BIRTHDATE',
        position: 3,
        class: "datepicker",
        disabled: false,
        label: "",
        options: {
          datePicker: {
            cancelText: "cancel",
            doneText: "confirm",
          },

          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_BIRTHDATE',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: '',
            params: {
              maximum: year
            },
            validatorType: 'max'
          },
          {
            errorMsg: '',
            params: {
              minimum: 1930
            },
            validatorType: 'min'
          }
        ],
        value: ''
      },
      {
        group: "",
        id: "email",
        isReadOnly: false,
        fieldType: 'email',
        name: 'email',
        placeholder: 'LANG_EMAIL',
        position: 2,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          }
        },
        validators: [
          {
            errorMsg: 'LANG_REQUIRED_EMAIL',
            params: {},
            validatorType: 'required'
          },
          {
            errorMsg: 'LANG_INVALID_EMAIL',
            params: {},
            validatorType: 'email'
          },
          {
            errorMsg: '',
            params: {
              length: 100
            },
            validatorType: 'maxLength'
          }
        ],
        value: ''
      }
    ]
    return fields
  }

  private getFormConfig(type: string): FormMetadata {
    // console.log(this.fieldName)
    if (type == 'name') {
      this.fieldName.forEach(field => {
        if (field.id == 'name') {
          field.value = this.data.user.name ? this.data.user.name : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSave,
        commitMode: 'submit',
        fields: this.fieldName,
        focusOnFirstElement: true,
        formName: 'updateName',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updateName", "submit", false, this.buttonSave, [], this.fieldName, true)
    }
    if (type == 'birthday') {
      this.fieldBirthDay.forEach(field => {
        if (field.id == 'birthday') {
          field.value = this.data.user.birthDate ? this.data.user.birthDate : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSave,
        commitMode: 'submit',
        fields: this.fieldBirthDay,
        formName: 'updateBirthDay',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updateBirthDay", "submit", false, this.buttonSave, [], this.fieldBirthDay)
    }
    if (type == 'phone') {
      this.fieldPhone.forEach(field => {
        if (field.id == 'phone') {
          field.value = this.data.user.phone ? this.data.user.phone : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSave,
        commitMode: 'submit',
        fields: this.fieldPhone,
        focusOnFirstElement: true,
        formName: 'updatePhone',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updatePhone", "submit", false, this.buttonSave, [], this.fieldPhone, true)
    }
    if (type == 'email') {
      this.fieldEmail.forEach(field => {
        if (field.id == 'email') {
          field.value = this.data.user.email ? this.data.user.email : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSave,
        commitMode: 'submit',
        fields: this.fieldEmail,
        focusOnFirstElement: true,
        formName: 'updateEmail',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updateEmail", "submit", false, this.buttonSave, [], this.fieldEmail, true)
    }

    if (type == 'subscribe') {
      this.fieldSubscribe.forEach(field => {
        if (field.id == 'name') {
          field.value = this.data.user.name ? this.data.user.name : '';
        }
        if (field.id == 'birthday') {
          field.value = this.data.user.birthDate ? this.data.user.birthDate : '';
        }
        if (field.id == 'email') {
          field.value = this.data.user.email ? this.data.user.email : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSubscribe,
        commitMode: 'submit',
        fields: this.fieldSubscribe,
        focusOnFirstElement: true,
        formName: 'updateSubscribe',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updateEmail", "submit", false, this.buttonSave, [], this.fieldEmail, true)
    }

    if (type == 'full' ) {
      console.log(this.data)
      this.fieldSubscribe.forEach(field => {
        if (field.id == 'name') {
          field.value = this.data.user.name ? this.data.user.name : '';
        }
        if (field.id == 'birthday') {
          field.value = this.data.user.birthDate ? this.data.user.birthDate : '';
        }
        if (field.id == 'email') {
          field.value = this.data.user.email ? this.data.user.email : '';
        }
      })
      return new FormMetadata({
        buttons: this.buttonSave,
        commitMode: 'submit',
        fields: this.fieldSubscribe,
        focusOnFirstElement: true,
        formName: 'updateSubscribe',
        groups: [],
        isReadOnly: false
      })
      // return new FormConfig("updateEmail", "submit", false, this.buttonSave, [], this.fieldEmail, true)
    }
  }
}
