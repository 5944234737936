import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MenuFamilyCustomToppingInterface,
  PgOrder,
  PgOrderLine,
  CreateOrderInterface,
  CounterInterface,
  CreateOrderLineItemsInterface
} from '@lib/utils';
import { environment } from '@environments/environment';
import {
  MenuFamilyInterface,
  ProductOptions,
  PromoInterface,
  MenuOutOfStock,
  MenuInterface,
  PromoLineInterface
} from '@lib/promo-engine';
import { OnActionEventInterface } from '../button-add-quantity-cart/button-add-quantity-cart.component';
import { ModalService } from '@services/modal.service';
import { ApiService } from '@services/api.service';
import { FaeventsService } from '@services/faevents.service'
import { kiosk, StorageVariables } from '@core/constants';
import { DataService } from '@services/data.service';
import { StorageService } from '@services/storage.service';
import { PlatformService } from '@services/platform.service';

@Component({
  selector: 'app-cart-line',
  templateUrl: './cart-line.component.html',
  styleUrls: ['./cart-line.component.scss'],
})
export class CartLineComponent implements OnInit {
  pgOrder: PgOrder = new PgOrder(null, null, [])
  @Input() set line(value: PgOrderLine) {
    if (value && value instanceof PgOrderLine) {
      this.updateStockToppings(value.pgCustomizer.getCurrentFamily())
      value.pgCustomizer.updateStockToppings(this.stockToppings)
      value.pgCustomizer.checkStockToppings()
      this.pgLine = value
      this.isHalfNdHalf = this.pgLine.pgCustomizer.checkProductCustom()
      this.updateData()
      this.updateDescription()
    }
  }
  @Input() set stock(value: MenuOutOfStock) {
    this.menuOutOfStock = value ? value : null
    if (this.menuOutOfStock) {
      this.updateStockToppings(this.pgLine.pgCustomizer.getCurrentFamily())
      this.pgLine.pgCustomizer.updateStockToppings(this.stockToppings)
      this.pgLine.pgCustomizer.checkStockToppings()
    }
  }
  @Input() set order(value: CreateOrderInterface) {
    if (value && 'lineItems' in value && 'promoLineItems' in value) {
      this.pgOrder.updateOrder(
        value,
        this.menu,
        this.promotions
      )
    }
  }
  @Input() menu: MenuInterface
  @Input() promotions: PromoInterface[]
  @Input() isPromotion: boolean
  @Input() canRemove: boolean = true
  @Input() limitMaxQty: boolean = true
  @Input() promoEngineOptions: ProductOptions
  @Input() isLastOrder: boolean = false
  @Input() isFavProduct: boolean = false
  @Input() showQuantityInPromo: boolean = false
  
  @Output() onOrderLineChange = new EventEmitter<PgOrderLine>()
  @Output() onOrderLineModify = new EventEmitter<PgOrderLine>()
  @Output() onOrderLineRemove = new EventEmitter<PgOrderLine>()
  pgLine: PgOrderLine
  productsUrl: string
  isHalfNdHalf: boolean
  // subgroup: string
  product: any
  @Input() favourites: boolean = false;
  description: string
  @Input() promotion: PromoInterface
  isKiosk: boolean = kiosk
  kioskBranch: any
  menuOutOfStock: MenuOutOfStock = null
  stockToppings: CounterInterface<boolean> = null
  @Input() lastLinePromo: boolean
  @Input() promoLine: PromoLineInterface = null
  
  constructor(
    private readonly modalService: ModalService,
    private apiService: ApiService,
    private faevents: FaeventsService,
    private readonly dataService: DataService,
    private readonly storageService: StorageService,
    private readonly platformService: PlatformService,
  ) { }

  ngOnInit(): void {
    this.productsUrl = environment.productsUrl
  }
  changeDough(topping: MenuFamilyCustomToppingInterface): void {
    const currentLine: PgOrderLine = this.pgLine.deepCopy(this.pgOrder.getLineById(this.pgLine.getID()))
    const line: PgOrderLine = this.pgLine.deepCopy(this.pgLine)
    line.pgCustomizer.addTopping(
      topping,
      topping.side,
      this.promoEngineOptions,
      this.promoLine
    )
    line.pgCustomizer.updateSelectedDough()
    line.pgCustomizer.updateToppingsDescription()
    if (currentLine && currentLine instanceof PgOrderLine && currentLine.getQuantity() > line.getQuantity()) {
      this.pgOrder.removeLineById(currentLine.getID(), line.getQuantity())
      this.updateOrderLine(line, true, true)
    } else {
      this.updateOrderLine(line)
    }
  }
  changeFamily(family: MenuFamilyInterface): void {
    const line: PgOrderLine = this.pgLine.deepCopy(this.pgLine)
    if (line.pgCustomizer.getCurrentFamily().id !== family.id) {
      const items = line.getItemsFromCustomizer(line.getQuantity())
      line.pgCustomizer.changeFamily(family.id)
      items.familyID = family.id
      items.productID = line.pgCustomizer.getCurrentProduct().id
      items.selectedDoughs = line.pgCustomizer.getSelectedDoughs()
      line.updateCustomizer(items)
      line.pgCustomizer.setDoughsByDefault()
      const checkMinResult = line.pgCustomizer.checkMin(this.promoLine)
      const hasAnyError: boolean = [
        ...Object.values(checkMinResult.group),
        checkMinResult.product
      ].some(value => value === false)
      if (hasAnyError) {
        const doughGroupID: number | string = line.pgCustomizer.getDoughGroupID()
        if (checkMinResult.group.hasOwnProperty(doughGroupID) && checkMinResult.group[doughGroupID] === false) {
          this.modalService.openModalWithCustomMsg('', 1039).then(
            () => { },
            () => { }
          )
        }
      }
      const currentLine: PgOrderLine = this.pgLine.deepCopy(this.pgOrder.getLineById(this.pgLine.getID()))
      if (currentLine && currentLine instanceof PgOrderLine && currentLine.getQuantity() > line.getQuantity()) {
        this.pgOrder.removeLineById(currentLine.getID(), line.getQuantity())
        this.updateOrderLine(line, true, true)
      } else {
        this.updateOrderLine(line)
      }
    }
  }
  changeQuantity(eventQuantity: OnActionEventInterface): void {
    let line: PgOrderLine = this.pgLine.deepCopy(this.pgOrder.getLineById(this.pgLine.getID()))
    if (!(line && line instanceof PgOrderLine) && this.isFavProduct) {
      let that: this = this
      let currentOrder: CreateOrderInterface = this.pgOrder.getOrder()
      let currentLine: CreateOrderLineItemsInterface = currentOrder.lineItems.find(l => that.pgOrder.compareLines(l.promoEngineResult, that.pgLine.getPromoEngineResult()))
      line = (currentLine && 'id' in currentLine) ? this.pgOrder.getLineById(currentLine.id) : this.pgLine.deepCopy(this.pgLine)
    }
    if (line && line instanceof PgOrderLine) {
      if (eventQuantity.action === 'addQty') {
        this.sendEvt(line, "add_to_cart");
        line.updateQuantity(line.getQuantity() + 1)
        this.updateOrderLine(line, false)
      } else if (eventQuantity.action === 'reduceQty') {
        this.sendEvt(line, "remove_from_cart");
        if (line.getQuantity() > 1) {
          line.updateQuantity(line.getQuantity() - 1)
          this.updateOrderLine(line, false)
        } else {
          this.removeLine(line)
        }
      }
    }
  }
  modifyOrderLine(): void {
    // const currentLine: PgOrderLine = this.pgLine.deepCopy(this.pgOrder.getLineById(this.pgLine.getID()))
    const line: PgOrderLine = this.pgLine.deepCopy(this.pgLine)
    this.onOrderLineModify.emit(line)
  }
  removeLine(line: PgOrderLine): void {
    this.onOrderLineRemove.emit(line)
  }
  removePromoLine(): void {
    const line: PgOrderLine = this.pgLine.deepCopy(this.pgLine)
    this.onOrderLineRemove.emit(line)
  }
  updateOrderLine(line: PgOrderLine, updatePromoEngineResult: boolean = true, updateEntireCart: boolean = false): void {
    if (updatePromoEngineResult) {
      let pgLine: PgOrderLine = this.pgOrder.createLine(line.getItemsFromCustomizer(line.getQuantity()), null)
      if (updateEntireCart) {
        line = pgLine
      } else {
        line.updatePromoEngineResult(pgLine.getPromoEngineResult())
      }
    }
    if (updateEntireCart) {
      this.pgOrder.addLine(line)
      this.storageService.setItem(StorageVariables.order, this.pgOrder.getOrder()).subscribe(
        () => {
          this.apiService.setData('showCart')
        },
        () => {
          this.apiService.setData('showCart')
        }
      )
    } else {
      this.onOrderLineChange.emit(line)
    }
  }
  updateData(): void {
    this.product = this.pgLine.pgCustomizer.getCurrentProduct()
  }
  updateDescription(): void {
    this.description = this.pgLine.cutText(this.pgLine.pgCustomizer.getToppingsDescription(), 0, 220)
  }
  updateStockToppings(family: MenuFamilyInterface): void {
    if (this.isKiosk) {
      this.stockToppings = this.dataService.checkStockToppingsFamily(this.menuOutOfStock, family.toppings)
    }
  }
  removeFavorite(): void {
    this.onOrderLineRemove.emit()
  }
  sendEvt(line: PgOrderLine, evtName) {
    // console.warn(evtName,line)
    try {
      let product = line.pgMenu.getProduct(line.pgCustomizer.getCurrentProduct().id);
      let family = line.pgMenu.getFamily(product.familyGroupID);
      let dough = line.pgCustomizer.findToppingsAdded(line.pgCustomizer.getDoughGroupID());
      let swirl = line.pgCustomizer.findToppingsAdded(line.pgCustomizer.getSwirlGroupID(), true);
      let anothers = line.pgCustomizer.findToppingsAdded(false);

      let item: any = {
        price: line.getUnitPrice(),
        item_name: product.sourceName,
        item_id: product.id,
        item_category: family.sourceName,
        item_category2: family.size ? family.size : 'N',
        item_category3: dough ? dough : 'n/a',
        item_category4: swirl ? swirl : 'n/a',
        item_category5: anothers ? anothers : 'n/a',
        quantity: 1
      }

      let evt = {
        items: [item],
        value: line.getUnitPrice() * item.quantity,
        currency: "SAR",
        short_name: product.sourceName
      };

      if (evtName == 'remove_from_cart' && this.promotion) {
        evt["promotion_shortname"] = this.promotion.description;
      }

      this.faevents.preLogEvent(evtName, evt)
      this.apiService.eventPg(line.pgCustomizer.getCurrentProduct(), evtName);
    } catch (e) {
      this.apiService.logger("error", "", e, evtName)
    }

  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.productsUrl + "images/productnotfound.png") {
        event.target.src = this.productsUrl + "images/productnotfound.png"
      }
    }
  }
}
