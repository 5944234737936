import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchPipe implements PipeTransform {
  transform(list: any[], filterText: string): any {
    return list && filterText ? list.filter((result: any) => {
      return Object.keys(result).some((prop) => {
        if (result[prop] !== null && typeof result[prop] === 'string') {
          const value = result[prop].toLowerCase()
          return value.includes(filterText.toLowerCase())
        }
      })
    }) : list
  }
}
