import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { UserDataService, PaymentTokenInterface } from '@services/user-data.service';
import { AlertController } from '@ionic/angular';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {

  assets: string = environment.assetsUrl;
  @Output() setValueToShow = new EventEmitter();
  creditCardTest: any;
  creditCards: [];
  detailIcon: any;
  private unsubscribe = new Subject<void>();
  
  constructor(private userDataService: UserDataService, public alertController: AlertController, public langService: LangService) {

  }

  ngOnDestroy(): void {
    // console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  ngOnInit() {


    // ***** TO SAVE TEST CREDIT CARDS **** 
    // this.creditCardTest = [{
    //   gateway: 'R',
    //   mask: '420000******0001',
    //   token: '8a82944963ba47810163bbcea03d470a',
    //   type: 'AMEX'
    // },{
    //   gateway: 'R',
    //   mask: '420000******0002',
    //   token: '8a82944963ba47810163bbcea03d4701',
    //   type: 'Visa'
    // }]

    // this.userDataService.savePaymentTokens(this.creditCardTest).subscribe(
    //   (result) => {
    //       ;
       
    //   }, (error) => {
   
    //   }
    // )

    this.userDataService.getPaymentTokens().subscribe(
      (result) => {
        this.creditCards = result;
      }, (error) => {
        // console.log(error);
      }
    )


    this.detailIcon = this.langService.isRtl() ? 'chevron-back' : 'chevron-forward';
    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
          
         this.detailIcon = result.lang === 'ar' ? 'chevron-back' : 'chevron-forward';
        //  console.log( this.detailIcon)
      }
    )

  }

  goToWallet() {
    this.setValueToShow.emit('wallet');
  }


  async removeCreditCard(index, creditCard) {

    const alert = await this.alertController.create({
      cssClass: '',
      header: this.langService.getInstantTranslation("LANG_SURE_REMOVE_CARD"),
      message: "<br>"+creditCard.mask,
      buttons: [
        {
          text: this.langService.getInstantTranslation("LANG_CANCEL"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: this.langService.getInstantTranslation("LANG_CONFIRM"),
          handler: () => {
            this.creditCards.splice(index, 1);


            this.userDataService.savePaymentTokens(this.creditCards).subscribe(
              (result) => {
                  ;
               
              }, (error) => {
                // console.log(error);
              }
            )
          }
        }
      ]
    });

    await alert.present();
  }
 



}
