import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ModalService } from '@services/modal.service';
import { RxjsService } from '@services/rxjs.service';
import { DataService } from '@services/data.service';
import { UserDataService } from '@services/user-data.service';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss'],
})
export class PromoCodesComponent implements OnInit {
  constructor(
    private readonly modalService: ModalService,
    private readonly rxJsService: RxjsService,
    private readonly dataService: DataService,
    private readonly userDataService: UserDataService
  ) { }
  assets: string = environment.assetsUrl
  ngOnInit() { }
  showModal() {
    this.userDataService.getUser().subscribe(
      async user => {
        await this.modalService.openModalPromoCode()
      },
      async (error: any) => {
        await this.modalService.openModalWithCustomMsg('', 1017)
      }
    )
  }
}
