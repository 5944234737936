import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CreateOrderLineItemsInterface,
  MenuGroupInterface,
  CreateOrderInterface,
  PgOrder,
  PgOrderLine,
  PgOrderLinesType,
  PgOrderPromoLine,
  WizardLineInterface
} from '@lib/utils';
import { MenuInterface, PromoOptions, PromoDetailApplicableTo, PromoTypes, PromoInterface, MenuOutOfStock } from '@lib/promo-engine';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';
import { DataService } from '@services/data.service';
import { ModalService } from '@services/modal.service';
import { OnActionEventInterface } from '@core/components';
import { PlatformService } from '@services/platform.service';
import { kiosk } from '@core/constants';
import { LangService } from '../../services/lang.service';
import { Platform } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';

export interface CartPromoLineInterface {
  groups: MenuGroupInterface[]
  lines: PgOrderLine[]
  wizardLine: WizardLineInterface
}

export interface CartPromoModifyLineInterface {
  line: PgOrderLine
  promoLine: PgOrderPromoLine
  wizardLine: WizardLineInterface
}

export interface CartPromoChangeLineInterface {
  fromRemove: boolean
  promoLine: PgOrderPromoLine
  wizardLine: WizardLineInterface
}

@Component({
  selector: 'app-cart-promo-line',
  templateUrl: './cart-promo-line.component.html',
  styleUrls: ['./cart-promo-line.component.scss'],
})
export class CartPromoLineComponent implements OnInit {
  @Input() set line(value: PgOrderPromoLine) {
    if (value && value instanceof PgOrderPromoLine) {
      this.lines = []
      this.pgPromoLine = this.pgOrder.deepCopy(value)
      this.checkQuantitySelector()
      this.updateLines()
    }
  }
  @Input() set stock(value: MenuOutOfStock) {
    this.menuOutOfStock = value ? value : null
  }
  @Input() set order(value: CreateOrderInterface) {
    if (value && 'lineItems' in value && 'promoLineItems' in value) {
      this.currentOrder = JSON.parse(JSON.stringify(value))
      this.pgOrder.updateOrder(
        value,
        this.menu,
        this.promotions
      )
    }
  }
  @Input() menu: MenuInterface
  @Input() promotions: PromoInterface[]
  @Input() promoEngineOptions: PromoOptions
  @Output() closeCart = new EventEmitter<boolean>()
  @Output() onOrderLineChange = new EventEmitter<CartPromoChangeLineInterface>()
  @Output() onOrderLineModify = new EventEmitter<CartPromoModifyLineInterface>()
  @Output() onOrderLineRemove = new EventEmitter<PgOrderLinesType>()
  addedLines: PgOrderLine[]
  assets: string = environment.assetsUrl
  promosUrl: string = environment.productsUrl
  disableQuantitySelector: boolean = false
  lines: CartPromoLineInterface[]
  pgOrder: PgOrder
  pgPromoLine: PgOrderPromoLine
  promoCompletedPercentage: number
  isKiosk: boolean = kiosk
  kioskBranch: any
  menuOutOfStock: MenuOutOfStock = null
  removeEntirePromotion: boolean = false
  currentOrder: CreateOrderInterface
  isDeliveryPromotion: boolean = false
  isCrossSales: boolean = false
  @Input() isLastOrder: boolean = false
  hideDetail: boolean = true;
  lang: string
  private unsubscribe = new Subject<void>()

  constructor(
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly modalService: ModalService,
    private readonly platformService: PlatformService,
    private readonly langService: LangService,
    private platform: Platform
  ) {
    this.pgOrder = new PgOrder(null, null, null)
  }
  ngOnInit(): void {
    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.lang = result.lang
            const langConfig = this.langService.getLangConfig()
          }
        )
      }
    )


    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang

  }
  changeQuantity(event: OnActionEventInterface): void {
    if (event.action === 'addQty') {
      this.pgPromoLine.updateQuantity(event.quantity + 1)
      this.onOrderLineChange.emit({
        fromRemove: false,
        promoLine: this.pgPromoLine,
        wizardLine: null
      })
    } else if (event.action === 'reduceQty') {
      if (event.quantity > 1) {
        this.pgPromoLine.updateQuantity(event.quantity - 1)
        this.onOrderLineChange.emit({
          fromRemove: false,
          promoLine: this.pgPromoLine,
          wizardLine: null
        })
      } else {
        this.removePromotion()
      }
    }
  }
  checkQuantitySelector(): void {
    let promotion: PromoInterface = this.pgPromoLine.getCurrentPromotion()
    this.isDeliveryPromotion = promotion.promoType === PromoTypes.Delivery
    this.removeEntirePromotion = !promotion || (promotion && (promotion.promoType === PromoTypes.Descuento || promotion.promoType === PromoTypes.Loyalty || promotion.loyaltyPoints > 0 || promotion.onePerCustomer === 'S'))
    this.disableQuantitySelector = !this.pgPromoLine.getIsManuallyAdded() || this.removeEntirePromotion
    this.isCrossSales = (promotion && promotion.promoType === PromoTypes.CrossSales)
    if (this.isCrossSales) {
      this.disableQuantitySelector = false
    }
  }
  checkWizardLineType(wizardLine: WizardLineInterface): string {
    let result: string = ''
    switch (wizardLine.type) {
      case PromoDetailApplicableTo.multiple:
      case PromoDetailApplicableTo.family:
        result = 'family'
        break
      case PromoDetailApplicableTo.group:
        result = 'group'
        break
    }
    return result
  }
  chooseProduct(line: CartPromoLineInterface): void {
    this.apiService.setData({
      promoLine: this.pgPromoLine,
      selectedWizardLine: line.wizardLine,
    })
    this.closeCart.emit(true)
    this.router.navigate(['/wizard'])
  }
  modifyLineFromPromotion(event: PgOrderLine, wizardLine: WizardLineInterface): void {
    this.onOrderLineModify.emit({
      line: event,
      promoLine: this.pgPromoLine,
      wizardLine: wizardLine
    })
  }
  modifyEntirePromotion(): void {
    this.onOrderLineModify.emit({
      line: null,
      promoLine: this.pgPromoLine,
      wizardLine: null
    })
  }
  removeLineFromPromotion(event: PgOrderLine, wizardLine: WizardLineInterface): void {
    const id: number = wizardLine.id
    if (wizardLine.type === PromoDetailApplicableTo.saleItem || this.removeEntirePromotion) {
      this.modalService.openModalWithCustomMsgNdChooseOption(1019, '').then(
        (resp: any) => {
          if (resp && 'data' in resp && 'result' in resp.data && resp.data.result === true) {
            console.log(id)
            this.pgPromoLine.removeProductsFromLine(id)
            this.removePromotion()
            this.dataService.setIdPromoToScroll(undefined)
          }
        },
        (err: any) => { }
      )
    } else {
      if (this.pgPromoLine.getIsManuallyAdded()) {
        this.pgPromoLine.removeProductsFromLine(id)
        this.dataService.setTypeLineAdded("Promo")
        let someLineIsCompleted = this.pgPromoLine.getWizardLines().reduce((result: boolean, v, index) => {
          if (v.addedItems.length > 0) {
            result = true
          }
          return result
        }, false)
        if (someLineIsCompleted) {
          this.dataService.setIdPromoToScroll("promo-" + this.pgPromoLine.getID() + "-" + id)
        } else {
          this.dataService.setIdPromoToScroll("promo-" + this.pgPromoLine.getID() + "-null")
        }
        this.onOrderLineChange.emit({
          fromRemove: true,
          promoLine: this.pgPromoLine,
          wizardLine: wizardLine
        })
        this.updateLines()
      } else {
        this.onOrderLineRemove.emit(event)
      }
    }
  }
  removePromotion(): void {
    this.dataService.setIdPromoToScroll(undefined)
    this.onOrderLineRemove.emit(this.pgPromoLine)
  }
  updateLines(): void {
    this.lines = []
    this.dataService.getMenu().then(
      (result: MenuInterface) => {
        this.pgPromoLine.getWizardLines().forEach(
          value => {
            let addItem: CartPromoLineInterface = null
            let position: number = this.lines.findIndex(
              result => result.wizardLine.id === value.id
            )
            if (value.addedItems.length > 0) {
              if (value.showInCart === true) {
                addItem = {
                  groups: (value.type === PromoDetailApplicableTo.saleItem) ? [] : value.items,
                  lines: value.addedItems.map(
                    v => {
                      if (v && 'promoEngineResult' in v && 'customID' in v.promoEngineResult) {
                        v.id = v.promoEngineResult.customID
                      }
                      return this.pgOrder.createLine({
                        ...v,
                        menu: result
                      }, v.promoEngineResult)
                    }
                  ),
                  wizardLine: value
                }
              }
            } else {
              addItem = {
                groups: value.items,
                lines: [],
                wizardLine: value
              }

            }
            if (addItem !== null && position < 0) {
              this.lines.push(addItem)
            }
          }
        )
        this.updatePercentage()
      }
    )
  }
  updatePercentage(): void {
    this.promoCompletedPercentage = this.pgPromoLine.getPercentageFromNumbers(this.pgPromoLine.getWizardCurrentStep(), this.pgPromoLine.getWizardTotalSteps())
  }
  updateWizardLine(event: PgOrderLine, wizardLine: WizardLineInterface): void {
    let items: CreateOrderLineItemsInterface = event.getItemsFromCustomizer(event.getQuantity())
    items.id = event.getID()
    items.promoEngineResult = event.getPromoEngineResult()
    if (!this.pgPromoLine.getIsManuallyAdded()) {
      items.quantity = this.pgPromoLine.getQuantity()
      if (items.promoEngineResult && 'quantity' in items.promoEngineResult) {
        items.promoEngineResult.quantity = items.quantity
      }
    }
    wizardLine.addedItems = [items]
    this.pgPromoLine.updateWizardLine(wizardLine)
    this.onOrderLineChange.emit({
      fromRemove: false,
      promoLine: this.pgPromoLine,
      wizardLine: wizardLine
    })
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.assets + "images/promonotfound.png") {
        event.target.src = this.assets + "images/promonotfound.png"
      }
    }
  }



  showDetails(): void {

    this.hideDetail = !this.hideDetail;
    console.log("result " + this.hideDetail)
  }

}
