import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { StorageService } from '@services/storage.service'
import { Router } from '@angular/router';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';
import { StorageVariables } from '@core/constants';
interface NotificationReceived {
  title: string;
  body: string;
  ts: number;
  unreaded: boolean;
  imgUrl : string| boolean
  customData : any
}
const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PushnotifService {

  private list: Array<NotificationReceived> = [];
  private notifReceived: EventEmitter<any> = new EventEmitter();
  private updateNotifications: EventEmitter<any> = new EventEmitter();

  public sharedNotification : NotificationReceived;
  constructor(
    private storage: StorageService,
    private ngZone: NgZone,
    public readonly _router: Router,

  ) {
    // let debug = true; // descomentar para tests
    // if (debug) {
    //   let test = [];
    //   for (let index = 0; index < 10; index++) {
    //     let test1: NotificationReceived = {
    //       title: "Titulo " + (index + 1),
    //       body: "Body de la notificacion numero " + (index + 1),
    //       ts: 1619094653 - (100000 * index + 1),
    //       unreaded: true,
    //       imgUrl: index % 2 == 0 ? false : 'https://mponlineassets.s3.me-south-1.amazonaws.com/assets/promos/LRANCHBOTTLE.png'
    //     };
    //     test.push(test1);
    //   }
    //   this.list = test;
    //   this.storage.setItem(StorageVariables.push, this.list);
    //   this.notifReceived.emit(this.list);
    // }
  }

  public onNotifReveceived(): EventEmitter<any> {
    return this.notifReceived;
  }

  public onUpdateNotifications(): EventEmitter<any> {
    return this.notifReceived;
  }

  public requestNotifPermission(): void {

    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    this.addListeners();

  }

  public getFCMtoken(): void {
    this.storage.getItem(StorageVariables.fcmToken).subscribe(
      (result) => {
        // console.log('FCMtoken', result.FCMtoken)
      },
      (error) => {
      }
    )
  }

  private addListeners(): void {

    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        // console.log('Push token: ' + JSON.stringify(token));
        this.storage.setItem(StorageVariables.fcmToken, { FCMtoken: token.value });
      }
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener('pushNotificationReceived', // when app is open and displayed on
      (notification: PushNotification) => {
        // console.log('Push received: ' + JSON.stringify(notification));
        let customData = false;
        let ts = notification.data["google.c.a.ts"];
        if(notification.data["customData"]){
           customData = JSON.parse(notification.data["customData"]);
           if(!ts){
             if(customData["ts"]){
               ts = customData["ts"];
             }
           }
        }
        const nReceived: NotificationReceived = { title: notification.title, body: notification.body, ts: ts,unreaded:true,imgUrl : notification.data?.fcm_options?.image ? notification.data.fcm_options.image : false ,customData:customData};
        this.saveNotification(nReceived);
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed', // when app is in background or foreground
      (notification: PushNotificationActionPerformed) => {
        // console.log('Push action performed: ' + JSON.stringify(notification));
        let customData = false;
        let ts = notification.notification.data["google.c.a.ts"];
        if(notification.notification.data["customData"]){
           customData = JSON.parse(notification.notification.data["customData"]);
           if(!ts){
            if(customData["ts"]){
              ts = customData["ts"];
            }
          }
        }
        const nReceived: NotificationReceived = { title: notification.notification.title, body: notification.notification.body, ts: ts,unreaded:true,imgUrl : notification.notification.data?.fcm_options?.image ? notification.notification.data.fcm_options.image : false,customData:customData };
        this.saveNotification(nReceived,true);
      }
    );
  }


  public getNotifications(): Promise<NotificationReceived[]> {
    return new Promise(
      (resolve, reject) => {
        this.storage.getItem(StorageVariables.push).subscribe(
          (notificationsSaved: NotificationReceived[]) => {
            resolve(notificationsSaved)
          },
          error => { reject(error) }
        )
      }
    )
  }

  private saveNotification(newNotif: NotificationReceived,tap : boolean = false) {
    this.getNotifications().then((notifications: NotificationReceived[]) => {
      this.list = notifications;
      this.list.unshift(newNotif);
      this.storage.setItem(StorageVariables.push, this.list);
      this.notifReceived.emit(this.list);
      this.updateNotifications.emit(this.list);
      if(tap){
        this.ngZone.run(() => {
          if(this._router.url !== '/messages'){
            this._router.navigate(['/messages']);
          }
        });
      }
    }, error => {
      this.list.unshift(newNotif);
      this.storage.setItem(StorageVariables.push, this.list);
      this.notifReceived.emit(this.list);
      this.updateNotifications.emit(this.list);
    })
  }

  public deleteNotification(newNotif: NotificationReceived) {
    this.getNotifications().then((notifications: NotificationReceived[]) => {
      this.list = notifications.filter(obj => obj.ts !== newNotif.ts);
      this.storage.setItem(StorageVariables.push, this.list);
      this.updateNotifications.emit(this.list);
    }, error => {
      this.list = [];
      this.storage.setItem(StorageVariables.push, this.list);
      this.updateNotifications.emit(this.list);
    })

  }

  public setReaded(newNotif: NotificationReceived) {
    this.getNotifications().then((notifications: NotificationReceived[]) => {
      notifications.forEach(element => {
        if (element.ts == newNotif.ts) {
          element.unreaded = false;
        }
      });
      this.list = notifications;
      this.storage.setItem(StorageVariables.push, this.list);
      this.updateNotifications.emit(this.list);
    }, error => {
      this.list = [];
      this.storage.setItem(StorageVariables.push, this.list);
      this.updateNotifications.emit(this.list);
    })
  }

  public getList(): Array<NotificationReceived> {
    return this.list;
  }

  public getDate(newNotif: NotificationReceived): Date {
    let _unixTime = newNotif.ts;
    return new Date(_unixTime * 1000);
  }

  public getSelectedNotification () : NotificationReceived | boolean {
    return this.sharedNotification ? this.sharedNotification : false;
  }

  public setSelectedNotification (notification : NotificationReceived)  {
    this.sharedNotification = notification;
  }

  

}
