import { Injectable, EventEmitter } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { CustomObservableType, ObservableType, RxjsService } from '@services/rxjs.service';
import { StorageVariables } from '@core/constants';
import { ApiService } from '@services/api.service';
import { CustomerFavProductsInterface } from '../interfaces/services';
import { LangService } from '@services/lang.service';
import { PlatformService } from './platform.service';
import { PgOrder } from '@lib/utils';

export interface PaymentTokenInterface {
  gateway: string
  mask: string
  token: string
  type: string
}

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private readonly paymentTokens: string = StorageVariables.PaymentTokens
  private readonly token: string =StorageVariables.access_token 
  private readonly user: string = StorageVariables.user
  private isLogedEventEmitter = new EventEmitter()
  private customer : any |boolean;
  private linkStatus :boolean;
  private reorder : any = false;
  private zoneReorder : any = false;
  public isLoged : any = false;
  private paymentMethod : any = false;
  constructor(
    private readonly storageService: StorageService,
    private readonly rxJsService: RxjsService,
    private readonly _api: ApiService,
    private langService : LangService,
    private platformService : PlatformService
  ) {
    this.linkStatus = false;
    this.customer = false;
   }
  getPaymentTokens(): ObservableType<any> {
    return this.storageService.getItem(this.paymentTokens)
  }
  getToken(): CustomObservableType<any> {
    return this.storageService.getItem(this.token)
  }
  getUser(): CustomObservableType<any> {
    return this.storageService.getItem(this.user);
  }
  savePaymentTokens(paymentTokens: PaymentTokenInterface[]): ObservableType<any> {
    const list: ObservableType<any>[] = [
      this.storageService.removeItem(this.paymentTokens),
      this.storageService.setItem(this.paymentTokens, paymentTokens)
    ]
    return this.rxJsService.createObservableFromMultipleRequests(list)
  }
  saveToken(token: string): ObservableType<any> {
    this.storageService.removeItem(this.token)
    return this.storageService.setItem(this.token, token)
  }
  saveUser(user: any, emit = true): CustomObservableType<string> {
    const saveUserObservable: CustomObservableType<string> = this.rxJsService.createCustomObservable<string>()
    this.setCustomerProfile(user)
    if(emit){
      this.isLogedEventEmitter.emit(JSON.stringify(user))
    }

    this._api.getCustomerFavProducts().subscribe(
      (result: CustomerFavProductsInterface[]) => {
        this.rxJsService.createObservableFromMultipleRequests([
          this.storageService.setItem(StorageVariables.favProducts, result),
          this.storageService.setItem(this.user, JSON.stringify(user))
        ]).subscribe(
          (result: [CustomerFavProductsInterface[], string]) => {
            saveUserObservable.next(result[1])
            saveUserObservable.complete()
          }
        )
      },
      () => {
        this.storageService.setItem(this.user, JSON.stringify(user)).subscribe(
          (result: string) => {
            saveUserObservable.next(result)
            saveUserObservable.complete()
          }
        )
      }
    )
    return saveUserObservable
  }

  public notifyUserStatus() {
    let u = this.storageService.getItem(this.user).subscribe(
      (user) => {
        this.isLogedEventEmitter.emit(user);
      },
      () => {
        this.isLogedEventEmitter.emit(false);
      });
  }

  public getIsLoged() : EventEmitter<any> {
    return this.isLogedEventEmitter;
  }
  removePaymentTokens(): ObservableType<any> {
    return this.storageService.removeItem(this.paymentTokens)
  }
  removeFavProducts() {
    return this.storageService.removeItem(StorageVariables.favProducts)
  }
  removeToken() {
    return this.storageService.removeItem(this.token)
  }
  removeUser() {
    return this.storageService.removeItem(this.user)
  }
  removeSelectedAddress() {
    return this.storageService.removeItem("selectedAddress")
  }
  removeSelectedBranch() {
    return this.storageService.removeItem("userSelectBranch")
  }
  removeLastOrder(){
    return this.storageService.removeItem("lastOrder")
  }

  removeUserPromotions(){
    return this.storageService.removeItem("userPromotions")
  }

  removeNotificationsReceived(){
    return this.storageService.removeItem("notificationsReceived")
  }

  removeSavedPromosDelivery(){
    return this.storageService.removeItem("savedPromosDelivery")
  }

  clearAnotherData(){
     this.storageService.removeItem("magicLink")
     this.storageService.removeItem("lastPickUpOrder")
     this.storageService.removeItem("orderToRate")
  }


  logout() {
    this.clearAnotherData();
    this.isLoged = false;
    this.removeFavProducts()
    this.removeToken();
    this.removeUser();
    this.removeSelectedAddress();
    this.removeSelectedBranch();
    this.removePaymentTokens();
    this.removeLastOrder();
    this.removeUserPromotions();
    this.removeNotificationsReceived();
    this.removeSavedPromosDelivery();
    this.isLogedEventEmitter.emit(false);
  }

   isOldUser(): ObservableType<any> {
    return this.storageService.getItemDecompressedFromLStorage(StorageVariables.oldUser);
  }

  getAccessOldUser(user:any): any {
    if(user.hasOwnProperty('Pass') && user.Pass!= ''){
      return {phone:user.Phone,pass:user.Pass};
    }
  }
  setlinkStatus(linking){
    this.linkStatus = linking;
  }

  isLinkEnabled(): any{
    return this.linkStatus == true;
  }


  private setCustomerProfile(customer:any){
    this.customer = customer;
  }

  public getCustomerProfile(): CustomObservableType<any> {

    let customerResult: CustomObservableType<any> = this.rxJsService.createCustomObservable()

    if (this.customer) {
      customerResult.next(this.customer)
      customerResult.complete();
    } else {
      this._api.getCustomerProfile().subscribe(profile => {
        this.customer = profile;
        this.saveUser(profile, false).subscribe(userProfile => {
          customerResult.next(this.customer)
          customerResult.complete();
        }, error => {
          customerResult.error(this.customer)
          customerResult.complete();
        });
      }, error => {
        customerResult.error(this.customer)
        customerResult.complete();
      })
    }

    return customerResult
  }

  updateFCMtoken() {
    let lang = this.langService.getCurrentLang();
    let device = this.platformService.getDevice();
    this.storageService.getItem(StorageVariables.fcmToken).subscribe(token => {
      this._api.updateFCMtoken(token.FCMtoken, lang, device).subscribe(res => { }, error => { })
    }, error => { })
  }

  setPaymentMethod(paymentMethod){
    this.paymentMethod = paymentMethod;
  }

  getPaymentMethod(){
    return this.paymentMethod;
  }

  setDefaultPaymentMethod(id){
    this.storageService.setItem(StorageVariables.defPayCode,id);
  }

  getDefaultPaymentMetod() : ObservableType<any> {
    return this.storageService.getItem(StorageVariables.defPayCode);
  }


  async getUser_() {
    var res = { user: false };
    res.user = await this.getUser().toPromise().catch(e => res.user = false);
    return res.user;
  }

  async getReorder() {
    if (this.reorder) {
      return this.reorder;
    } else {
      let user = await this.getUser_();
      if (!user) {
        return [];
      } else {
        let res = await this._api.getLastOrder(1).toPromise()
        if (res) {
          this.reorder = res;
          return this.reorder;
        } else {
          return [];
        }
      }
    }
  }

  async getZoneReorder(position: any) {
    if (this.zoneReorder) {
      return this.zoneReorder;
    } else {
      var res = { data: false };
      res.data = await this._api.findZoneDelivery("" + position.lat + "," + position.lng + "&loading=false").toPromise().catch(e => res.data = false);
      this.zoneReorder = res.data;
      return res.data;
    }
  }


  public async getPromoUserAvailables(menu, order, promotions) {
    let availablePromos = []

    let promos = await this._api.getCustomerPromotions().toPromise()
    let pgOrder = new PgOrder(order, menu, promotions)
    promos.forEach(
      promo => {
        if (!pgOrder.checkPromoUserID(promo['ID'])) {
          availablePromos.push(promo)
        }
      })

    return availablePromos

  }



}
