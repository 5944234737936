import { UncompletedLinesInterface } from "./interfaces/services"

export interface ErrorCodesInterface {
    code: number
    msg: string
}

// Last Error Code: 1067

const CartErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1019,
        msg: 'LANG_REMOVE_PRODUCT_FROM_PROMOTION'
    },
    {
        code: 1021,
        msg: 'LANG_CANNOT_MODIFY_PROMOTION'
    },
    {
        code: 1039,
        msg: 'LANG_DOUGH_NOT_AVAILABLE_FOR_THIS_SIZE'
    },
    {
        code: 1040,
        msg: 'LANG_MUST_COMPLETE_PROMO_TO_FINISH_ORDER'
    },
    {
        code: 1041,
        msg: 'LANG_PROMO_PRODUCTS_NOT_VALID'
    },
    {
        code: 1063,
        msg: 'LANG_CHOOSE_PAYMENT_METHOD'
    }
]

const CustomizerErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1000,
        msg: 'LANG_CHOOSE_DOUGH_MANDATORY'
    },
    {
        code: 1001,
        msg: 'LANG_MUST_BE_LOGGED_IN_TO_ADD_PRODUCT_TO_FAVOURITES'
    },
    {
        code: 1002,
        msg: 'LANG_TOPPING_MIN_NOT_REACHED'
    },
    {
        code: 1018,
        msg: 'LANG_TOPPING_LIMIT_EXCEEDED_DO_YOU_WANT_REMOVE'
    },
    {
        code: 1020,
        msg: 'LANG_LOYALTY_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1055,
        msg: 'LANG_CHEESE_MANDATORY'
    },
    {
        code: 1064,
        msg: 'LANG_ITEM_OUT_OF_STOCK'
    },
    {
        code: 1066,
        msg: 'LANG_QTY_FOR_TOPPING_GROUP_EXCEEDED'
    },
    {
        code: 1067,
        msg: 'LANG_THIS_PRODUCT_NOT_AVAILABLE_THIS_PROMO'
    }
]

const PromoCodeErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1003,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1004,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1005,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1006,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1007,
        msg: 'LANG_PROMOCODE_NOT_AVAILABLE_AT_THIS_TIME'
    },
    {
        code: 1008,
        msg: 'LANG_NO_PRODUCTS_IN_CART_PROMOCODE'
    },
    {
        code: 1009,
        msg: 'LANG_NO_PRODUCTS_TO_APPLY_PROMOCODE'
    },
    {
        code: 1010,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1011,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1012,
        msg: 'LANG_PROMOCODE_ONCE_PER_CLIENT'
    },
    {
        code: 1013,
        msg: 'LANG_PROMOCODE_MIN_AMOUNT'
    },
    {
        code: 1014,
        msg: 'LANG_PROMOCODE_NOT_ENOUGH_POINTS'
    },
    {
        code: 1015,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1016,
        msg: 'LANG_PROMOCODE_DOESNT_EXIST'
    },
    {
        code: 1017,
        msg: 'LANG_MUST_BE_LOGGED_IN_TO_REDEEM_PROMO_CODE'
    },
    {
        code: 1059,
        msg: 'LANG_PROMOCODE_ALREADY_USED'
    },
    {
        code: 1060,
        msg: 'LANG_PROMOCODE_APPLIED'
    }
]

const PromotionResultErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1022,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1023,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1024,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1025,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1026,
        msg: 'LANG_PROMO_NOT_AVAILABLE_AT_THIS_TIME'
    },
    {
        code: 1027,
        msg: 'LANG_NO_PRODUCTS_IN_CART_PROMOTION'
    },
    {
        code: 1028,
        msg: 'LANG_NO_PRODUCTS_TO_APPLY_PROMOTION'
    },
    {
        code: 1029,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1030,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1031,
        msg: 'LANG_PROMOTION_ONCE_PER_CLIENT'
    },
    {
        code: 1032,
        msg: 'LANG_PROMOTION_MIN_AMOUNT'
    },
    {
        code: 1033,
        msg: 'LANG_PROMOTION_NOT_ENOUGH_POINTS'
    },
    {
        code: 1034,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1035,
        msg: 'LANG_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1056,
        msg: 'LANG_PROMO_NOT_AVAILABLE_IN_DELIVERY'
    },
    {
        code: 1057,
        msg: 'LANG_PROMO_NOT_AVAILABLE_IN_PICKUP'
    },
    {
        code: 1058,
        msg: 'LANG_ONLY_ONE_DISCOUNT_PROMO_ALLOWED'
    },
    {
        code: 1065,
        msg: 'LANG_PROMO_ONLY_FIRST_ORDER'
    }
]

const VoucherErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1036,
        msg: 'LANG_CAN_NOT_RECHARGE_WALLET'
    },
    {
        code: 1037,
        msg: 'LANG_VOUCHER_ALREADY_USED'
    },
    {
        code: 1038,
        msg: 'LANG_VOUCHER_ALREADY_EXPIRED'
    }
]

const SaveTicketResultErrorCodes: ErrorCodesInterface[] = [
    {
        code: 1042,
        msg: 'LANG_PRICES_NOT_MATCH'
    },
    {
        code: 1043,
        msg: 'LANG_PROMISE_TIME_OUT_OF_BOUNDS'
    },
    {
        code: 1044,
        msg: 'LANG_CUSTOMER_NOT_FOUND'
    },
    {
        code: 1045,
        msg: 'LANG_PAYMENT_METHOD_NOT_VALID'
    },
    {
        code: 1046,
        msg: 'LANG_EMPTY_LINES'
    },
    {
        code: 1047,
        msg: 'LANG_ADDRESS_NOT_FOUND'
    },
    // {
    //     code: 1048,
    //     msg: 'LANG_NO_PHONE_VALIDATED'
    // },
    {
        code: 1049,
        msg: 'LANG_DELIVER_FEE_NOT_MATCH'
    },
    {
        code: 1050,
        msg: 'LANG_NOT_SAVED'
    },
    {
        code: 1051,
        msg: 'LANG_PROMO_CODE_USED'
    },
    {
        code: 1052,
        msg: 'LANG_CUSTOMER_PROMO_NOT_AVAILABLE'
    },
    {
        code: 1053,
        msg: 'LANG_PRODUCT_NOT_FOUND'
    },
    {
        code: 1054,
        msg: 'LANG_TOPPING_NOT_FOUND'
    },
    {
        code: 1061,
        msg: 'LANG_ORDER_DUPLICATED'
    },
    {
        code: 1062,
        msg: 'LANG_PROMO_NOT_FOUND'
    }
]

const PhoneNoValidated: ErrorCodesInterface[] = [
    {
        code: 6000,
        msg: 'LANG_NO_PHONE_VALIDATED_MAKE_ORDER'
    }
]

const errorCodes: ErrorCodesInterface[] = [
    ...CustomizerErrorCodes,
    ...PromoCodeErrorCodes,
    ...CartErrorCodes,
    ...PromotionResultErrorCodes,
    ...VoucherErrorCodes,
    ...SaveTicketResultErrorCodes,
    ...PhoneNoValidated
]

export interface ErrorFromPromoCodeInterface {
    code?: number
    msg: string
    params: object
}

export class CustomErrorManager {
    private errorCode: number
    constructor(code: number) {
        this.errorCode = code
    }
    public getErrorMsg(): string {
        const position: number = errorCodes.findIndex(e => e.code === this.errorCode)
        return (position >= 0) ? errorCodes[position].msg : 'LANG_UNKNOWN_ERROR'
    }
    public getErrorMsgFromPromoCodeResult(promoCode: string, promoError: any[]): ErrorFromPromoCodeInterface {
        let errorCode: number = null
        let params: object = {
            value: promoCode
        }
        if (promoError && Array.isArray(promoError) && promoError.length > 0) {
            switch (promoError[0].code) {
                case 0:// PromotionExceptionTypes.NotFound
                    errorCode = 1003
                    break
                case 1:// PromotionExceptionTypes.NotAvailableOrderType
                    errorCode = 1004
                    break
                case 1.1:// PromotionExceptionTypes.NotAvailableOrderType Delivery
                    errorCode = 1056
                    break
                case 1.2:// PromotionExceptionTypes.NotAvailableOrderType Pick Up
                    errorCode = 1057
                    break
                case 1.3:
                    errorCode = 1058
                    break
                case 2:// PromotionExceptionTypes.NotAvailableSource
                    errorCode = 1005
                    break
                case 3:// PromotionExceptionTypes.NotValidType
                    errorCode = 1006
                    break
                case 4:// PromotionExceptionTypes.NotAplicableHours
                    errorCode = 1007
                    break
                case 5:// PromotionExceptionTypes.NoLinesToApply
                    errorCode = 1008
                    break
                case 6:// PromotionExceptionTypes.NoMatchLines
                    errorCode = 1009
                    break
                case 7:// PromotionExceptionTypes.CrossNotAplicable
                    errorCode = 1010
                    break
                case 8:// PromotionExceptionTypes.CrossManual
                    errorCode = 1011
                    break
                case 9:// PromotionExceptionTypes.OnePerOrder
                    errorCode = 1012
                    break
                case 10:// PromotionExceptionTypes.MinimumOrderAmount
                    errorCode = 1013
                    params['minAmount'] = promoError[0].description
                    break
                case 11:// PromotionExceptionTypes.NoEnoughPoints
                    errorCode = 1014
                    break
                case 12:// PromotionExceptionTypes.PromoCodeNotApplicable
                    errorCode = 1015
                    break
                case 13:// PromotionExceptionTypes.PromoCustomerNotApplicable
                    errorCode = 1016
                    break
                case 14: // Repeated
                    errorCode = 1059
                    break
                case "003":// Voucher Error
                    errorCode = 1036
                    break
                case "004":// Voucher Error
                    errorCode = 1037
                    break
                case "005":// Voucher Error
                    errorCode = 1038
                    break
                case "PromoOnlyFirstOrder":
                    errorCode = 1065
                    params['id'] = promoError[0].promoID
                    break
            }
        } else {
            errorCode = 1003
        }
        this.errorCode = errorCode
        return { code: errorCode, msg: this.getErrorMsg(), params }
    }
    public getErrorMsgFromPromotionResult(promoError: any[]): ErrorFromPromoCodeInterface {
        let errorCode: number = null
        let params: object = {}
        if (promoError && Array.isArray(promoError) && promoError.length > 0) {
            switch (promoError[0].code) {
                case 0:// PromotionExceptionTypes.NotFound
                    errorCode = 1022
                    break
                case 1:// PromotionExceptionTypes.NotAvailableOrderType
                    errorCode = 1023
                    break
                case 1.1:// PromotionExceptionTypes.NotAvailableOrderType Delivery
                    errorCode = 1056
                    break
                case 1.2:// PromotionExceptionTypes.NotAvailableOrderType Pick Up
                    errorCode = 1057
                    break
                case 1.3:
                    errorCode = 1058
                    break
                case 2:// PromotionExceptionTypes.NotAvailableSource
                    errorCode = 1024
                    break
                case 3:// PromotionExceptionTypes.NotValidType
                    errorCode = 1025
                    break
                case 4:// PromotionExceptionTypes.NotAplicableHours
                    errorCode = 1026
                    break
                case 5:// PromotionExceptionTypes.NoLinesToApply
                    errorCode = 1027
                    break
                case 6:// PromotionExceptionTypes.NoMatchLines
                    errorCode = 1028
                    break
                case 7:// PromotionExceptionTypes.CrossNotAplicable
                    errorCode = 1029
                    break
                case 8:// PromotionExceptionTypes.CrossManual
                    errorCode = 1030
                    break
                case 9:// PromotionExceptionTypes.OnePerOrder
                    errorCode = 1031
                    break
                case 10:// PromotionExceptionTypes.MinimumOrderAmount
                    errorCode = 1032
                    params['minAmount'] = promoError[0].description
                    break
                case 11:// PromotionExceptionTypes.NoEnoughPoints
                    errorCode = 1033
                    break
                case 12:// PromotionExceptionTypes.PromoCodeNotApplicable
                    errorCode = 1034
                    break
                case 13:// PromotionExceptionTypes.PromoCustomerNotApplicable
                    errorCode = 1035
                    break
                case 14: // Repeated
                    errorCode = 1059
                    break
                case "PricesNotMatch": // TicketExceptionTypes.PricesNotMatch OK
                    errorCode = 1042
                    break
                case "PromiseTimeOutOfBounds": // TicketExceptionTypes.PromiseTimeOutOfBounds OK
                    errorCode = 1043
                    break
                case "CustomerNotFound": // TicketExceptionTypes.CustomerNotFound OK
                    errorCode = 1044
                    break
                case "PaymentMethodNotValid": // TicketExceptionTypes.PaymentMethodNotValid OK
                    errorCode = 1045
                    break
                case "EmptyLines": // TicketExceptionTypes.EmptyLines OK
                    errorCode = 1046
                    break
                case "AddressNotFound": // TicketExceptionTypes.AddressNotFound OK
                    errorCode = 1047
                    break
                case "DeliveryFeeNotMatch": // TicketExceptionTypes.DeliveryFeeNotMatch OK
                    errorCode = 1049
                    break
                case "NotSaved": // TicketExceptionTypes.NotSaved OK
                    errorCode = 1050
                    break
                case "PromoCodeUsed": // TicketExceptionTypes.PromoCodeUsed OK
                    errorCode = 1051
                    break
                case "CustomerPromoNotAvailable": // TicketExceptionTypes.CustomerPromoNotAvailable OK
                    errorCode = 1052
                    break
                case "ProductNotFound":// TicketExceptionTypes.CheckLines.ProductNotFound OK
                    errorCode = 1053
                    break
                case "ToppingNotFound":// TicketExceptionTypes.CheckLines.ToppingNotFound OK
                    errorCode = 1054
                    break
                case "Duplicate":// Duplicate OK
                    errorCode = 1061
                    break
                case "PromoNotFound":// TicketExceptionTypes.CheckLines.PromoNotFound OK
                    errorCode = 1062
                    break
                case "PromoOnlyFirstOrder":
                    errorCode = 1065
                    params['id'] = promoError[0].promoID
                    break
            }
        } else {
            errorCode = 1006
        }
        this.errorCode = errorCode
        return { code: errorCode, msg: this.getErrorMsg(), params }
    }
    public updateUncompletedLinesFromCart(errorCodes: UncompletedLinesInterface[]): UncompletedLinesInterface[] {
        return errorCodes.map(
            value => {
                this.errorCode = value.errorCode
                value.errorMsg = this.getErrorMsg()
                return value
            }
        )
    }
    public getCustomMsgFromErrorPhoneResult(promoError: any[]): ErrorFromPromoCodeInterface {
        let errorCode: number = null
        let params: object = {
            value: ''
        }
        if (promoError && Array.isArray(promoError) && promoError.length > 0) {
            switch (promoError[0].code) {
                case 6: errorCode = 6000; break
            }
        } else {
            errorCode = 6000
        }
        this.errorCode = errorCode
        return { msg: this.getErrorMsg(), params }
    }
}