import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  menu: any = [];
  submenu: any = [];
  value: string;
  subMenuValue: string;
  @Output() sendValue = new EventEmitter();
  @Output() sendSubMenuValue = new EventEmitter();
  @Output() closeCart = new EventEmitter();
  windowWidth: number;
  @Input() showSubmenu = new EventEmitter();
  @Input() showNearest = new EventEmitter();
  @Input() showSelectedAddress = new EventEmitter();
  @Input() set submenuSelectedByScroll(value) {   
    this.subMenuValue = value
  }


  constructor() { }

  ngOnInit() {

    this.menu = [{ name: "LANG_OUR_COMBOS", items: ["LANG_COMBOS_PROMOS", "LANG_NEAREST_BRANCH", "LANG_SELECTED_ADDRESS"]}, { name: "LANG_LOYALTY", items: [] }, { name:  "LANG_REFERALS" ,  items: []} ]
    this.value = this.menu[0].name
    this.submenu = this.menu[0].items
    this.subMenuValue = this.menu[0].items[0]
    this.sendValue.emit(this.value);
    // this.sendSubMenuValue.emit(this.subMenuValue);
  }

  menuChanged(ev: any) {
    this.value =  ev.detail.value
    this.sendValue.emit(this.value);

    this.menu.forEach(item => {
      if(item.name == ev.detail.value){
        this.submenu = item.items;
      }
    })

  }

  subMenuChanged(value) {
    this.subMenuValue =  value
    this.sendSubMenuValue.emit(this.subMenuValue);
  }

  goToCart() {
    this.closeCart.emit(true);
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;

  }


}
