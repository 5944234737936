import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@core/guards/login.guard';
import { HomeGuard } from '@core/guards/home.guard';
import { UserResolverService } from '@services/UserResolverService';
import { AccountGuard } from '@core/guards/account.guard';
import { Router } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [HomeGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('@pages/menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('@pages/branches/branches.module').then(m => m.BranchesPageModule)
  },
  {
    path: 'customizer',
    loadChildren: () => import('@pages/customizer/customizer.module').then(m => m.CustomizerPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('@pages/cart/cart.module').then(m => m.CartPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('@pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AccountGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then(m => m.WalletPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'accountest',
    loadChildren: () => import('./pages/accountest/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/delivery/delivery.module').then(m => m.DeliveryPageModule)
  },
  {
    path: 'tracking',
    loadChildren: () => import('./pages/tracking/tracking.module').then(m => m.TrackingPageModule),
    resolve: { user: UserResolverService }
  },
  {
    path: 'wizard',
    loadChildren: () => import('./pages/wizard/wizard.module').then(m => m.WizardPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/notifications-panel/notifications-panel.module').then(m => m.NotificationsPanelPageModule)
  },
  {
    path: 'paymentvalidation',
    loadChildren: () => import('./pages/paymentvalidation/paymentvalidation.module').then(m => m.PaymentvalidationPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'finished-order',
    loadChildren: () => import('./pages/finished-order/finished-order.module').then(m => m.FinishedOrderPageModule)
  },
  {
    path: 'geidea',
    loadChildren: () => import('./pages/geidea/geidea.module').then( m => m.GeideaPageModule)
  },
  {
    path: '**',
    redirectTo: '/menu'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
      enableTracing: false,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private _router: Router,
  ) {
      routes.splice(2, 0, {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      })
      this._router.resetConfig(routes);
  }
}