import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { FormComponent } from './form/form.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { UppercasePipe } from '@core/pipes/uppercase.pipe';
import { KeyboardComponent } from '@core/components/keyboard/keyboard.component';


@NgModule({
  declarations: [
    FormComponent,
    FormErrorsComponent,
    UppercasePipe,
    KeyboardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    FormComponent,
    FormsModule,
    ReactiveFormsModule,
    UppercasePipe,
    KeyboardComponent
  ]
})
export class AngularIonicFormsModule { }
