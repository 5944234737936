import { Injectable } from '@angular/core';
import { BranchInterface } from '@lib/promo-engine';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  // Arrays
  filterInArray(arr: any[], prop: string, search: any): any[] {
    return arr.filter(result => result[prop] === search)
  }
  findInArray(arr: any[], prop: string, search: any): any {
    return arr.find(result => result[prop] === search)
  }
  findIndexInArray(arr: any[], prop: string, search: any): number {
    return arr.findIndex(result => result[prop] === search)
  }
  toArray(stuff: any) {
    return Object.values(stuff)
  }
  toObject(stuff: any[], property: string) {
    return stuff.reduce((obj: any, item: any) => {
      obj[item[property]] = item
      return obj
    }, {})
  }
  linealDistance(me, branch: BranchInterface | any) {

    function LatLng(lat, lng) {
      this.lat = parseFloat(lat);
      this.lng = parseFloat(lng);
      this.__cache = {};
    }

    LatLng.prototype = {
      toString: function () {
        return [this.lat, this.lng].join(",");
      },

      distanceTo: function (latLng) {
        var cacheKey = latLng.toString();
        if (cacheKey in this.__cache) {
          return this.__cache[cacheKey];
        }

        var deg2rad = 0.017453292519943295;
        var lat1 = this.lat * deg2rad;
        var lng1 = this.lng * deg2rad;
        var lat2 = latLng.lat * deg2rad;
        var lng2 = latLng.lng * deg2rad;
        var a = (
          (1 - Math.cos(lat2 - lat1)) +
          (1 - Math.cos(lng2 - lng1)) * Math.cos(lat1) * Math.cos(lat2)
        ) / 2;
        var distance = 12742 * Math.asin(Math.sqrt(a));
        this.__cache[cacheKey] = distance.toFixed(1);
        return distance.toFixed(1);
      }
    };
    return parseFloat(new LatLng(me.lat, me.lng).distanceTo(new LatLng(branch.lat, branch.lng)));
  }

   getNearestBranch(position,branches): any {

    let nearest : boolean |any = false;
    let distance = 999999999999999;

    branches.forEach(branch => {
      let distanceToBranch= this.linealDistance({ lat: position.lat, lng: position.lng }, branch)
      if (distanceToBranch < distance) {
        nearest = branch;
        distance = distanceToBranch;
      }
    });

    return nearest;

  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = (hours<10 ? "0"+""+hours : hours) + ':' + minutes + ' ' + ampm;
    return strTime;
}

getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
}
