import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';

import { environment } from '@environments/environment';
import { Version } from '@core/constants';
import { PlatformService } from '@services/platform.service';
import { CustomBrowserService } from '@services/custom-browser.service';
import { LangService } from '@services/lang.service';
import { Platform } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';
import { twitterUrl } from '@core/constants';
import { facebookUrl } from '@core/constants';
import { instagramUrl } from '@core/constants';
import { playStoreUrl } from '@core/constants';
import { appStoreUrl } from '@core/constants';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewChecked {

  @ViewChild('footer') element: ElementRef;
  contentHeight: number;
  @Output() height = new EventEmitter();

  assets: string = environment.assetsUrl;
  version = Version
  year: number
  source: string
  private unsubscribe = new Subject<void>()
  lang: string

  constructor(private platformService: PlatformService,
    private cbs: CustomBrowserService,
    private readonly langService: LangService,
    private platform: Platform
  ) {
    this.year = new Date().getFullYear();

    this.source = this.platformService.getSource()
  }


  ngOnInit(): void {
    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.lang = result.lang
          }
        )
      }
    )

    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang

  }


  ngAfterViewChecked(): void {
    this.contentHeight = this.element.nativeElement.offsetHeight;
    this.height.emit(this.contentHeight);
  }

  openUrl(type) {
    switch (type) {
      case 'twitter':
        this.cbs.openUrl(twitterUrl + this.lang, true, true);
        break
      case 'facebook':
        this.cbs.openUrl(facebookUrl, true, true);
        break
      case 'instagram':
        this.cbs.openUrl(instagramUrl, true, true);
        break
      case 'playstore':
          this.cbs.openUrl(playStoreUrl+ this.lang, true, true);
        break
      case 'appstore':
          this.cbs.openUrl(appStoreUrl, true, true);
        break
    }

  }


}
