export interface BranchCustomDataInterface {
  closedIn: string;
  openIn: string;
  opened?: boolean;
  distance: number;
  status: number;
}

export interface BranchInterface {
  city: string;
  closing1: string;
  closing2: string;
  customData?: BranchCustomDataInterface;
  deliveryTime: string;
  id: number;
  isAlive: string;
  lat: number;
  lng: number;
  minAmountDelivery: number;
  minAmountPickup: number;
  name: string;
  nonRegisteredMaxAmount: number;
  opened: number;
  opening1: string;
  opening2: string;
  phoneNbr: string;
  pickupTime: string;
  registeredMaxAmount: number;
  serviceType: string;
  stNumber: string;
  streetName: string;
  recentActivity: string;
  distance: number;
  province: string;
  postalCode?: number;
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  taxID?: string;
  companyName?: string;
  maxAmountGateway?: number;
  maxAmountNoGateway?: number;
}

export interface BranchPaymentMethodInterface {
  description: string;
  enabled: string;
  fixedFee: number;
  minimumFee: number;
  order: number;
  orderType: string;
  paymentGateway: string;
  paymentMethodID: number;
  variableFee: number;
  webDescription: string;
}

export interface ZoneInterface {
  areaID: string;
  city: string;
  deliveryFeeAmount: number;
  deliveryFeeItem: string;
  deliveryFeeItem2: string;
  description: string;
  id: number;
  locationDistance: number;
  maxAmountDeliveryFee: number;
  province: string;
  stDirectoryID: number;
  webDeliveryFeeItem: string;
  webDeliveryFeeItem2: string;
  webMaxAmountDeliveryFee: number;
  minAmountDelivery?: number
}
