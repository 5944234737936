import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalendarModal, CalendarModalOptions, CalendarResult } from 'ion2-calendar';
import { LangService } from '@services/lang.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  date: string;
  type: 'string';
  assets: string = environment.assetsUrl;
  @Output() range= new EventEmitter()
  
  constructor(public ModalController: ModalController, public langService: LangService) {  }

  ngOnInit() {}

  async openCalendar() {

    const options: CalendarModalOptions = {
      pickMode: 'range',
      title: this.langService.getCurrentLang() == 'en' ? "Select range" : "اختر نطاقا" ,
      color:'primary',
      weekStart: 1,
      canBackwardsSelected: true,
      doneIcon: true,
      to: new Date(),
      monthFormat: 'MM/YYYY',
      weekdays: this.langService.getCurrentLang() == 'en' ? ['Sun', 'Mon','Tues','Wed','Thurs','Fri','Sat'] : ['الأحد', 'الأثنين','الثلاثاء','الأربعاء','الخميس','الجمعة','السبت']
    };

  let myCalendar =  await this.ModalController.create({
    component: CalendarModal,
    componentProps: { options }
  });

  myCalendar.present();

  const event: any = await myCalendar.onDidDismiss();
  const date: CalendarResult = event.data;
  let start = event.data.from.string;
  let end = event.data.to.string;

  this.range.emit({'start': start, 'end': end});

}




}
