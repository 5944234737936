import { Component, Input, OnInit } from '@angular/core';
import { OrderTypes, PromoInterface } from '@lib/promo-engine';
import { CreateOrderSummaryInterface, PgCommon, PgMenu } from '@lib/utils';
import { environment } from '@environments/environment';
import { LangService } from '@services/lang.service';
import { takeUntil } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { kiosk } from '@core/constants';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {


  @Input() pgMenu: PgMenu;
  @Input() deliveryFee: number = 0;

  @Input() set promotions(value) {
    if(value){
      this.promos = value 
    }

  }

  @Input() set orderSumm(value) {
    if(value){
      this.order = value
      this.isDelivery = (this.order.orderType === OrderTypes.Domicilio)
    }

  }
  promos: PromoInterface[]
  order: CreateOrderSummaryInterface
  lineToPush: any
  discount: number = 0
  subtotal: number
  isKiosk: boolean = kiosk
  kioskBranch: any
  assets: string = environment.assetsUrl;
  currentLanguage: string;
  private unsubscribe = new Subject<void>();
  public isDelivery: boolean = false
  public pgCommon: PgCommon = new PgCommon()

  constructor( public langService: LangService) {  }

  ngOnInit() {
console.log(this.order)


this.langService.onLangChange().pipe(
  takeUntil(this.unsubscribe)
).subscribe(
  (result: LangChangeEvent) => {
      
    this.currentLanguage = result.lang
  }
)

const langConfig = this.langService.getLangConfig()
this.currentLanguage = langConfig.lang
  }

  getPromoName(item){
    let promoName: string; 

    this.promos.forEach(
      promo => {
        if(promo.id == item){
          promoName = promo.description
        }

      })

      return promoName;
  }

  getProductName(item){
   return this.pgMenu.getProduct(item).description 
  }

  getProductPrice(item){
    return this.pgMenu.getProduct(item).salePrice 
   }

   isPromo(line){
     return line.hasOwnProperty('sublines')
   }

}
