import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-tracking-status',
  templateUrl: './tracking-status.component.html',
  styleUrls: ['./tracking-status.component.scss'],
})
export class TrackingStatusComponent implements OnInit {

  @Input() set orderTracking(value: number) {
    this.order = value
    this.setOrderData()
  }

  @Input() isLastOrder: boolean;


  @Output() deliverToCar= new EventEmitter()

  assets: string = environment.assetsUrl;
  total: number = 0
  orderType: string;
  orderStatus: string;
  status: number;
  orderNumber: number;
  order: any = false;
  intervalOrders : any ;
  orderDeliverToCar: string;
  productionTime: any
  deliveryTime: any
  currentRoute : string
  
  constructor(public readonly _router: Router) {
    
   }

  ngOnInit() {
    this.currentRoute =  this._router.url 
  }


  setOrderData() {
    let order = this.order;
    // console.log(order);
    this.orderType = order.orderType;
    this.orderStatus = order.orderStatus || 'Unknown';
    this.orderNumber = order.dailyOrderNumber;
    this.total = order.total;
    this.orderDeliverToCar = order.clickNcar;
    
// if(order.minutesToPromise > 60){
//   var cociente = Math.floor(order.minutesToPromise/60);
//   var resto = order.minutesToPromise % 60;

//   this.deliveryTime = 
// }else{
  this.deliveryTime = order.minutesToPromise;
// }

    this.deliverToCar.emit(this.orderDeliverToCar);

    switch (this.orderStatus) {
      case 'Unknown':
        this.status = 0;
        break;
      case 'Received':
        this.status = 0;
        break;
      case 'Preparing':
        this.status = 1;
        break;
      case 'OnTheWay':
        this.status = 2;
        break;
      case ('Arriving'):
        this.status = 3;
        break;
      case ('PickYourOrder'):
        this.status = 3;
        break;
      case ('Delivered'):
        this.status = 4;
        break;
      case ('Error'):
        this.status = -2;
        break;
      case 'Cancelled':
        this.status = -1;
        break
      default: this.status = 0; break;
    }
    // console.log(this.status,this.orderStatus);
  }

}
