import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ScriptService } from '@services/script.service';

@Component({
  selector: 'app-phone-contacts',
  templateUrl: './phone-contacts.component.html',
  styleUrls: ['./phone-contacts.component.scss'],
})
export class PhoneContactsComponent implements OnInit {

  assets: string = environment.assetsUrl;
  contactList: object[] = [
    {
      'icon': 'icon-chat-man',
      'name': 'Kareem Alshamaa (man)',
      'phone': '+9669200 170000'
    },
    {
      'icon': 'icon-chat-woman',
      'name': 'Alanoud Almansour (women)',
      'phone': '+9669200 170000'
    }
  ]
  @Input() mode: any;

  constructor(private readonly scriptService: ScriptService) { }

  ngOnInit() { }
  openChat(): void {
    this.scriptService.loadScriptsChatBot().then(
      (result: Promise<any>[]) => {
          
        // console.log(window['Freshbots'])
        if (window && window['Freshbots']) {
          let chatBot: any = window['Freshbots']
          chatBot.initiateWidget({
            autoInitChat: true,
            getClientParams: function () {
              return {
                "cstmr::eml": "",
                "cstmr::lng": "ar",
                "cstmr::nm": ""
              };
            }
          },
            (successResponse) => {
              chatBot.showWidget(
                () => {
                  // console.log('Hi')
                },
                () => {
                  // console.log('bye')
                }
              )
              // chatBot.deActivate()
            },
            (errorResponse) => {
              console.log(errorResponse)
            })
        }
      }
    )
  }
}
