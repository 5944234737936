import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '@src/environments/environment';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ApiService } from '@services/api.service';
import { UserDataService } from '@services/user-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RxjsService } from '@services/rxjs.service';
import { DataService } from '@services/data.service';
import { MenuFamilyProductInterface, MenuInterface, PromoInterface, PromoTypes } from '@lib/promo-engine';
import { CreateOrderInterface, MenuGroupInterface, PgMenu, PgOrder, PgOrderLinesType } from '@lib/utils';
import { MenuDataInterface, ShowCustomizerEvent } from '@core/components/menu-list/menu-list.component';
import { PlatformService } from '@services/platform.service';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { StorageService } from '@services/storage.service';
import { StorageVariables } from '@core/constants';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('* => *', animate('5000ms'))
    ])
  ]
})
export class LoyaltyComponent implements OnInit, OnDestroy {
  @Input() set basePage(value: number) {
    if (value) {
      this.basePageWidth = value
    }
  }

  @Input() set newRequest(value: boolean) {
    if (value) {
      this.getUser()
      this.dataService.setNeedRequestUser(true)
    }
  }

  basePageWidth: number
  assets: string = environment.assetsUrl;
  cards: object[];
  showTerms: boolean = false;
  visibility: string = 'hidden';
  loyaltyProgram: any;
  status: any;
  color: string;
  textCard: string;
  hiddeInfo: boolean;
  @Output() showSubscribeForm = new EventEmitter();
  @Output() scrollMenu = new EventEmitter();
  pointLastDays: number;
  statusLoyalty: string;
  private unsubscribe = new Subject<void>();
  pgOrder: PgOrder
  lines: PgOrderLinesType[]

  groups: MenuGroupInterface[]
  public menuData: MenuDataInterface
  pgMenu: PgMenu
  promotions: PromoInterface[] = []
  points: number = 0
  spentPoints: number = 0
  checkCartQty: boolean = false
  @Output() showCustomizer = new EventEmitter<ShowCustomizerEvent>()
  navSubscription: any
  lang: string
  windowWidth: number
  showRedeemable: boolean = false;

  constructor(
    private readonly _api: ApiService,
    private userDataService: UserDataService,
    private readonly _router: Router,
    private readonly rxJsService: RxjsService,
    private readonly dataService: DataService,
    private readonly platformService: PlatformService,
    private readonly platform: Platform,
    private readonly langService: LangService,
    private readonly storageService: StorageService
  ) {
    if (!this.platformService.isServer()) {
      this.navSubscription = this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.fillCart();
      })
    }
  }

  ngOnDestroy(): void {
    console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.navSubscription.unsubscribe();
  }

  fillCart(): void {
    this.rxJsService.createObservableFromMultipleRequests([
      this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
      this.rxJsService.createObservableFromPromise(this.dataService.getOrder()),
      this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
    ]).subscribe(
      (result: [MenuInterface, CreateOrderInterface, PromoInterface[]]) => {
        //   
        const menu: MenuInterface = result[0]
        const order: CreateOrderInterface = result[1]
        const promotions: PromoInterface[] = result[2]
        this.pgOrder = new PgOrder(order, menu, promotions)
        let promoLines = this.pgOrder.getPromotionLines().filter(
          p => p.getCurrentPromotion().promoType === PromoTypes.Loyalty
        )
        this.spentPoints = promoLines.reduce(
          (result: number, value, index) => {
            let promoEngineResult = value.getPromoEngineResult()
            if (promoEngineResult && 'sublines' in promoEngineResult && Array.isArray(promoEngineResult.sublines) && promoEngineResult.sublines.length > 0) {
              result += value.getPromoEngineResult().sublines[0].points
            }
            return result
          }, 0
        )
      },
      () => {
        this.pgOrder = new PgOrder(null, null, null)
        this.lines = []
      }
    )
  }


  ngOnInit() {
    this.cards = [
      { title: "LANG_WHITE", class: "whiteCard", advantages: ["LANG_WHITE1", "LANG_WHITE2"] },
      { title: "LANG_SILVER", class: "silverCard", advantages: ["LANG_SILVER1", "LANG_SILVER2", "LANG_SILVER3"] },
      { title: "LANG_GOLD", class: "goldCard", advantages: ["LANG_GOLD1", "LANG_GOLD2", "LANG_GOLD3", "LANG_GOLD4"] }
    ]


    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.lang = result.lang
          }
        )
      }
    )

    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang


    if (!this.platformService.isServer()) {
      this._api.getNewValue.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (data: any) => {
          console.log("data", data)
          this.checkCartQty = true
          this.fillCart()
          if (!this.platformService.isServer()) {
            setTimeout(
              () => {
                this.checkCartQty = false
              }, 100)
          }

          if (data == 'OnUpdateLangEvent') {
          this.rxJsService.createObservableFromMultipleRequests([
            this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
            this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
          ]).subscribe(
            (result: [MenuInterface, PromoInterface[]]) => {
              const menu: MenuInterface = result[0]
              const promotions: PromoInterface[] = result[1]
              this.pgMenu = new PgMenu(menu, promotions, true)
              this.groups = this.pgMenu.getLoyaltyGroups(this.points - this.spentPoints, this.showRedeemable)
              this.menuData = {
                groups: this.groups,
                menu: this.pgMenu.getMenu(),
                promotions
              }
              this.setScrollPositionToGroups()
            }
          )

        }

        }
      )

      this.windowWidth = window.innerWidth;
    }


  this.getUser()



  }


  getUser(){
    this.userDataService.getUser().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe( // detectar los cambios
      (user) => {
        this.hiddeInfo = false;
        this._api.getCustomerLoyaltyPoints().subscribe(loyaltyProgram => {
          this.storageService.setItem(StorageVariables.customerLoyaltyPoints, loyaltyProgram).subscribe().unsubscribe()
          this.loyaltyProgram = loyaltyProgram;
          let that = this;
          this.points = 0
          loyaltyProgram.forEach(function (value, index) {
            // console.log(value["amount"])
            that.points += value["amount"];
          })

          // console.log(this.points)

        }, error => {
          this.errorMessage("getCustomerLoyaltyPoints", { error: error })
        })

        this._api.getCustomerStatus().subscribe(status => {
          this.status = status;
          // console.log(status);
          this.color = this.status.status;

          switch (this.status.status) {
            case 'gold':
              this.textCard = "LANG_GOLD";
              break;
            case 'silver':
              this.textCard = "LANG_SILVER";
              break;
            case 'white':
              this.textCard = "LANG_WHITE";
              break;
          }
          this.statusLoyalty = this.status.status;
          this.pointLastDays = this.status.amount;

        }, error => {
          this.errorMessage("getCustomerStatus", { error: error })
        })


        this.rxJsService.createObservableFromMultipleRequests([
          this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
          this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
        ]).subscribe(
          (result: [MenuInterface, PromoInterface[]]) => {

            const menu: MenuInterface = result[0]
            const promotions: PromoInterface[] = result[1]
            this.pgMenu = new PgMenu(menu, promotions, true)
            this.groups = this.pgMenu.getLoyaltyGroups(this.points - this.spentPoints, this.showRedeemable)
            this.menuData = {
              groups: this.groups,
              menu: this.pgMenu.getMenu(),
              promotions
            }
            this.setScrollPositionToGroups()
          }
        )


      }, error => {
        this.hiddeInfo = true;
      })
  }

  goToLogin() {
    this._router.navigate(['/login'])
  }

  private errorMessage(from: string, error: any) {
    console.log(from);
    console.log(JSON.stringify(error));
  }

  showSubscribe() {
    this.showSubscribeForm.emit(true);
    // console.log("entro");
  }

  setScrollPositionToGroups(): void {
    // Rellenamos la posición de cada producto
    if (!this.platformService.isServer()) {
      setTimeout(
        () => {
          this.groups.forEach(
            value => {
              value.subGroups.forEach(
                val => {
                  let items: (MenuFamilyProductInterface | PromoInterface)[] = []
                  if (val.promotions.length === 0) {
                    val.products.forEach(
                      v => {
                        let familyId: number = v.familyGroupID
                        let allowAdd: boolean = val.baseProducts[familyId] ? (val.baseProducts[familyId] !== v.id) : true
                        if (allowAdd) {
                          items.push(v)
                        }
                      }
                    )
                  } else {
                    val.promotions.forEach(
                      v => {
                        items.push(v)
                      }
                    )
                  }
                  items.sort((a, b) => a.order - b.order)


                  if (this.windowWidth  < 576) {
                    val.scrollPosition.firstProduct = document.getElementById('loyalty'+items[0].id).offsetTop
                    val.scrollPosition.lastProduct = (document.getElementById('loyalty'+items[items.length - 1].id).offsetTop + (document.getElementById('loyalty'+items[items.length - 1].id).offsetHeight))
                  } else {
                    val.scrollPosition.firstProduct = document.getElementById('loyalty'+items[0].id).offsetTop - 60
                    val.scrollPosition.lastProduct = (document.getElementById('loyalty'+items[items.length - 1].id).offsetTop - 60 + (document.getElementById('loyalty'+items[items.length - 1].id).offsetHeight))
                  }
                }
              )
            }
          )
        }, 200
      )
    }
  }

  onMenuChangeEvent(event: string): void {
    this.scrollToProduct(event)
  }

  updateShowCustomizer(event: ShowCustomizerEvent): void {
    this.showCustomizer.emit(event)
  }

  scrollToProduct(event: string): void {
    const productElement: HTMLElement = document.getElementById('loyalty'+event)
    if (productElement) {
      this.scrollMenu.emit(event);
    }
  }

  toggleTerms() {
    this.showTerms = !this.showTerms;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
  }


}
