import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '@environments/environment';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { FormMetadataButtonInterface, FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';

@Component({
  selector: 'app-waiting-magic-link',
  templateUrl: './waiting-magic-link.component.html',
  styleUrls: ['./waiting-magic-link.component.scss'],
})
export class WaitingMagicLinkComponent implements OnInit {


  assets: string = environment.assetsUrl;
  @Input() type: string;
  @Input() set loginWithOthers(value: boolean) {
      this.loginWithOthersMethods = value
  }

  @Input() phone: string;

  @Output() showLogins;
  @Output() pinEntered;
  @Output() resends;
  loginWithOthersMethods: boolean

  formMetadata: FormMetadata

  constructor() {
    this.showLogins = new EventEmitter();
    this.pinEntered = new EventEmitter();
    this.resends = new EventEmitter();

  }

  ngOnInit() {

    const buttons: FormMetadataButtonInterface[] = [
      {
        buttonType: 'S',
        color: 'primary',
        label: 'LANG_CONTINUE_PIN',
        position: 4,
        class: "",
        expand: 'block',
        fill: 'solid',
        size: "default"
      }
    ]

    const fields: FormMetadataFieldInterface[] = [
      {
        group: "",
        id: "pin",
        isReadOnly: false,
        fieldType: 'number',
        name: 'pin',
        placeholder: '',
        position: 1,
        class: "",
        disabled: false,
        label: "",
        options: {
          label: {
            color: "success",
            position: "stacked"
          },
          size: {
            lg: '12',
            md: '12',
            sm: '12',
            xl: '12',
            xs: '12'
          },
          checkArabic: true
        },
        validators: [{
          errorMsg: '',
          params: {
            regEx: ''
          },
          validatorType: 'pattern'
        },
        {
          errorMsg: 'LANG_CODE_REQUIRED',
          params: {},
          validatorType: 'required'
        },
        {
          errorMsg: '',
          params: {
            length: 6
          },
          validatorType: 'maxLength'
        },
        {
          errorMsg: '',
          params: {
            length: 6
          },
          validatorType: 'minLength'
        }],
        value: ''
      }
    ]

    this.formMetadata = new FormMetadata({
      buttons: buttons,
      commitMode: 'submit',
      fields: fields,
      formName: 'registerForm',
      groups: [],
      isReadOnly: false
    })
  }

  public showLoginsScreen() {
    this.showLogins.emit({});
  }

  public onForSubmitPin(event: any) {
    // console.log("onForSubmitPin", event);
    let pin = event.value.pin
    this.pinEntered.emit({ pin: pin });
  }

  public resend(event: any) {
    this.resends.emit(event);
  }


}
